/* global fetch */
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

class WarningTblCleanup extends Component {
  constructor(props) {
    super(props);
    this.renderVmReason = this.renderVmReason.bind(this);
    this.state = {
      value: this.props.ineligibleDsList,
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "metric_datasource_name",
          text: "Metric Data Source",
          sort: true,
          headerStyle: {
            minWidth: "60px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
          },
        },
        {
          dataField: "status",
          text: "Reason",
          sort: true,
          formatter: this.renderVmReason,
          style: {
            padding: "0px !important",
            fontWeight: "600",
            wordBreak: "break-word",
            textAlign: "center",
          },
        },
      ],
    };
  }

  renderVmReason(cell, Ds) {
    return (
      <div className="textfieldCentered">
        Cannot access associated Data Source
      </div>
    );
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: "center", borderTop: "1px solid gray" }}>
          <BootstrapTable
            id="list_table"
            bordered={false}
            bodyClasses="tbodyColor"
            classes=" table-responsive"
            bootstrap4={true}
            keyField="basicvm_id"
            data={this.state.value}
            columns={this.state.columns}
            wrapperClasses="scrollTable"
          />
        </div>
      </div>
    );
  }
}

export default WarningTblCleanup;
