/* global fetch */
import React, {Component} from "react";
import {  withRouter } from 'react-router-dom';
import {Row} from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import { connect } from "react-redux";

class MegaMenu extends Component {
    constructor(props) {
        super(props);
        this.showList = this.showList.bind(this);
        this.state = {
            errorPopup: false,
            modalLoader: false,
            isErrorHeader: true,
            errormessage: '',
            isRedirect: false
        };
    }

    setErrorPopupState = (event) => {
        this.setState({errorPopup: event});
        if (this.state.isRedirect) {

            this.props.history.push('/');
        }
    }

    showList = (fnName) => {        
        //this.setState({modalLoader: true});
        //this.props.history.push({pathname: "/manageOptimizations"});
        fetch(Constdata.SET_FUNCTION_URL + "/" + fnName, {
            method: 'GET',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then((response) => response.json())
                .then((responseJson) => {
                    this.setState({modalLoader: false});
                    if (responseJson.success === true) {
                        if (fnName === "rightsizing") {
                            this.props.history.push({pathname: "/manageOptimizations", providerName: responseJson.orgInfo.providerName});
                        } else if (fnName === "cleanup") {
                            this.props.history.push({pathname: "/manageCleanup"});
                        } else if (fnName === "parking") {
                            this.props.history.push({pathname: "/manageParking"});
                        }

                    } else if (responseJson.success === false) {
                        this.setState({modalLoader: false, errorPopup: true, errormessage: responseJson.message});
                    } else if (responseJson.status === 403 || responseJson.status === 401) {
                        this.setState({isRedirect: true, modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false});
                    } else {
                        this.setState({modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader: false});
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        return false;
    }

    render() {
        return (
                <div>
                    <div className="pageBgWhite" style={{borderRadius: "10px", padding: "2px"}} role="main">
                        <Row style={{backgroundColor: "#005765", display: "flex", justifyContent: "center"}}>
                            <div style={{fontSize: "20px", color: "white", padding: "5px"}}>Select Function</div>
                        </Row>
                        <div style={{display:"flex", padding:"5px 1px 1px 1px", width:"100%"}}>
                            <div style={{border:"1px solid gray", margin:"1px", cursor:"pointer", flexGrow:"1", width:"33.33%"}} onClick={(e) => {this.showList("rightsizing");}}>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                    <div style={{fontSize: "20px", fontWeight:"bold", cursor:"pointer", color: "#005765"}} onClick={(e) => {this.showList("rightsizing");}}>{this.props.companyDetails.ServiceName} Rightsizing</div>
                                </div>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                    <div style={{fontSize: "16px", fontWeight:"bold", cursor:"pointer", color: "#b2b2b2", textAlign:'center', padding:'5px'}}>Resize resources to optimally match their utilization and health</div>
                                </div>
                            </div>
                            <div style={{border:"1px solid gray", margin:"1px" , cursor:"pointer", flexGrow:"1", width:"33.33%"}} onClick={(e) => {this.showList("parking");}}>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                    <div style={{fontSize: "20px", fontWeight:"bold", cursor:"pointer", color: "#005765"}} onClick={(e) => {this.showList("parking");}}>{this.props.companyDetails.ServiceName} Parking</div>
                                </div>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                    <div style={{fontSize: "16px", fontWeight:"bold", cursor:"pointer", color: "#b2b2b2", textAlign:'center', padding:'5px'}}>Shutdown active resources at times they are dormant</div>
                                </div>
                            </div>
                            <div style={{border:"1px solid gray", margin:"1px" , cursor:"pointer", flexGrow:"1", width:"33.33%"}} onClick={(e) => {this.showList("cleanup");}}>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                    <div style={{fontSize: "20px",  fontWeight:"bold", cursor:"pointer", color: "#005765"}} onClick={(e) => {this.showList("cleanup");}}>{this.props.companyDetails.ServiceName} Cleanup</div>
                                </div>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                    <div style={{fontSize: "16px", fontWeight:"bold", cursor:"pointer", color: "#b2b2b2", textAlign:'center', padding:'5px'}}>Identify and delete unused resources</div>
                                </div>
                            </div>
                        </div>
                        <Row style={{display:"flex", justifyContent:"center"}}>
                            <div style={{color:"#747474", fontSize:"18px", padding:"5px"}}>
                                Select any function to manage optimization
                            </div>
                        </Row>
                    </div>
                    {(this.state.errorPopup === true) ? <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState} isError={this.state.isErrorHeader}></SerraError> : ""}
                    {(this.state.modalLoader === true) ? <SerraLoader></SerraLoader> : ""}
                </div>
                );
    }
}

const mapStateToProps = (state) => {
    return {
        companyDetails: state.companyDetails.data,
    }
  }
export default connect(mapStateToProps, null)(withRouter(MegaMenu));


