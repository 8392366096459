import React, { Component } from 'react';
import { Button }  from 'reactstrap';
// import '../SinginComponent/Signin.css';
import * as Constdata from '../../Constants/ApiConstants';
import SerraError from '../CommonComponent/Error';
import Header from '../HeaderComponent/Header';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import * as message from '../../Constants/MessageConstant';
class Signupsuccess extends Component {
  constructor(props) {
    super(props);
    this.resendVerification = this.resendVerification.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.state={
      errormessage: '',
      errorPopup: false
    };
  }
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
  }
  resendVerification(){
      fetch(Constdata.RESEND_ACTIVATION_LINK+"?t_id="+this.props.location.state.key, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then((response) => response.json())
      .then((responseJson) => {
        this.processResponse(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  processResponse=(response)=>{
    if (response.success === true){
      this.setState({ errorPopup: true, errormessage: response.message });
    }else{
      this.setState({ errorPopup: true, errormessage: response.message });
    }
  }
  componentDidMount(){
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Sign Up" });
    this.props.registerListners();
    document.getElementById("menuDisplay").style.display = "none";
    document.getElementById("selectDisplay").style.display = "none";
}
  render() {
      let br = <br />;
    return (
      <div className="wrapper">
        <Header processLogut={this.processLogout}/>
        <div id="accountSuccess" className="bg-container1">&nbsp;</div>
        <div id="SignInClass">
          <div className="HeadingSuccess">Sign-up Completion</div>
          <div className="outer-container outer-container-success">
          <div  style={{textAlign:"center"}}><h5>Thank you for signing up to use {this.props.companyDetails.CompanyName} {this.props.companyDetails.ServiceNameFullName}!</h5></div>
            <div style={{textAlign:"center",fontWeight:"400"}}><p>One more thing:</p></div>
            <div className="container">
            <div style={{textAlign:"center"}}><p>We have sent verification email to <b>{this.props.location.state.key}</b> {br}
              Please click on the link in the email to verify {br}
              If you didn't receive the email, click button below to have it resent</p>
              </div>
              <div style={{width: "25%",margin: "auto", textAlign:"center"}}><Button  id="confirmbtn_list" onClick={() => { this.resendVerification(); }}>Resend </Button> </div>
            </div>
          </div>
        </div>
        {(this.state.errorPopup === true) ? <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState}></SerraError> : ""}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      companyDetails: state.companyDetails.data,
  }
}

export default connect(mapStateToProps, null)(Signupsuccess);
