import React, { Component } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.css';
import Header from "../HeaderComponent/Header";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraError from "../CommonComponent/Error";
import ReactGA from "react-ga4";
import SerraLoader from "../CommonComponent/Loader";
import { connect } from "react-redux";
class Cards extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.togglePPU = this.togglePPU.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.toggleChoosePPU = this.toggleChoosePPU.bind(this);
    this.toggleRequest = this.toggleRequest.bind(this);
    this.requestFreeTrial = this.requestFreeTrial.bind(this);
    this.toggleisRequest = this.toggleisRequest.bind(this);
    this.planprocessResponse = this.planprocessResponse.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.state = {
      modal: false,
      modalPPU: false,

      modal1: false,
      modalChoosePPU: false,
      selectmodal: false,
      errorPopup: false,
      errormessage: "",
      isErrorHeader: true,
      PPURequest: false,
      PPUisRequest: false,
      email: "",
      PPURequestSubmit: false,
      Confirm: "",
      planRequestSubTitle: "",
      modalLoader: false,
    };
  }
  componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Plan Selection",
    });
    this.props.registerListners(true);
    document.getElementById("mddp").style.display = "none";
    document.getElementById("vmpp").style.display = "none";
    var pgAc = document.getElementById("pageRef");
    if (pgAc !== undefined && pgAc !== null) {
      pgAc.text = this.props.companyDetails.ServiceName + " Plan Selection";
    }
    fetch(Constdata.ISPLANSELECTED_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.planprocessResponse(responseJson);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  }
  planprocessResponse = (response) => {
    if (response.success === true && response.data.landingPage !== "card") {
      this.setState({ Id: response.data.orgId, data: response.data });
      if (response.data.landingPage === "report") {
        this.props.history.push({
          pathname: `/${response.data.landingPage}`,
          state: { data: this.state.data.data },
        });
      } else {
        this.props.history.push({
          pathname: `/${response.data.landingPage}`,
          state: { data: this.state.data },
        });
      }
    } else if (response.success === false) {
      this.setState({ errorMsg: response.message });
    }
  };
  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  togglePPU = () => {
    this.setState((prevState) => ({
      modalPPU: !prevState.modalPPU,
    }));
  };
  toggle1 = () => {
    if (this.state.modal1 === true) {
      this.setState({ modal1: false });
    } else {
      fetch(Constdata.GETREQUESTEDPLAN_URL, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.success === true) {
            if (
              responseJson.data.isFreeTrial === false &&
              responseJson.data.isPPU === false
            ) {
              this.setState({ email: responseJson.data.email, modal1: true });
            } else {
              if (responseJson.data.isPPU === true) {
                this.setState({
                  email: responseJson.data.email,
                  planRequestSubTitle: "Pay Per Use",
                  PPURequestSubmit: true,
                  Confirm:
                    "Your Paid Plan Request has already been submitted and is being processed. Please check your email (" +
                    responseJson.data.email +
                    ") for update.",
                });
              } else if (responseJson.data.isFreeTrial === true) {
                this.setState({
                  email: responseJson.data.email,
                  planRequestSubTitle: "Free Trial",
                  PPURequestSubmit: true,
                  Confirm:
                    "Your Free Trial Request is being processed.  We will get back to you as soon as possible at (" +
                    responseJson.data.email +
                    ").  Thank you.",
                });
              }
            }
          } else if (
            responseJson.status === 403 ||
            responseJson.status === 401
          ) {
            this.props.history.push("/");
          } else {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.ServerError,
              isErrorHeader: false,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.CacheErrorText,
            isErrorHeader: false,
          });
        });
    }
  };
  toggleChoosePPU = () => {
    this.setState((prevState) => ({
      modalChoosePPU: !prevState.modalChoosePPU,
    }));
  };
  toggleSelect = () => {
    this.setState((prevState) => ({
      selectmodal: !prevState.selectmodal,
    }));
  };

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    this.props.history.push("/");
  };
  toggleisRequest = () => {
    fetch(Constdata.GETREQUESTEDPLAN_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          if (
            responseJson.data.isFreeTrial === false &&
            responseJson.data.isPPU === false
          ) {
            this.setState({
              email: responseJson.data.email,
              PPUisRequest: true,
            });
          } else {
            if (responseJson.data.isPPU === true) {
              this.setState({
                email: responseJson.data.email,
                planRequestSubTitle: "Pay Per Use",
                PPURequestSubmit: true,
                Confirm:
                  "Your Paid Plan Request has already been submitted and is being processed. Please check your email (" +
                  responseJson.data.email +
                  ") for update.",
              });
            } else if (responseJson.data.isFreeTrial === true) {
              this.setState({
                email: responseJson.data.email,
                planRequestSubTitle: "Free Trial",
                PPURequestSubmit: true,
                Confirm:
                  "Your Free Trial Request is being processed.  We will get back to you as soon as possible at (" +
                  responseJson.data.email +
                  ").  Thank you.",
              });
            }
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.props.history.push("/");
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };

  toggleRequest = () => {
    this.setState({ PPURequest: false, modalLoader: true });
    fetch(Constdata.PPUREQUEST_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          this.setState({
            planRequestSubTitle: "Pay Per Use",
            PPURequest: true,
            PPUisRequest: false,
            modalLoader: false,
          });
        } else if (responseJson.success === false) {
          this.setState({
            errorPopup: true,
            errormessage: responseJson.message,
            modalLoader: false,
          });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.props.history.push("/");
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };

  requestFreeTrial = () => {
    this.setState({ PPURequest: false, modalLoader: true });
    fetch(Constdata.FREETRIALREQUEST_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.success === true &&
          responseJson.message === "Free Trial request successful"
        ) {
          this.setState({
            planRequestSubTitle: "Free Trial",
            PPURequest: true,
            modal1: false,
            modalLoader: false,
          });
        } else if (responseJson.success === false) {
          this.setState({
            errorPopup: true,
            errormessage: responseJson.message,
            modalLoader: false,
          });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.props.history.push("/");
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };

  handleLogout = async () => {
    this.setState({ PPURequest: false, modalLoader: true });
    await fetch(Constdata.LOGOUT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.processResponse(responseJson);
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };

  processResponse = (response) => {
    if (response.success === true) {
      if (this.props.processLogout) {
        this.props.processLogout();
      }
      this.props.history.push("/");
      this.setState({ modalLoader: false });
    } else if (response.success === false) {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: response.message,
        modalLoader: false,
      });
    } else if (response.status === 403 || response.status === 401) {
      if (this.props.processLogout) {
        this.props.processLogout();
      }
      this.props.history.push("/");
    } else {
      if (this.props.processLogout) {
        this.props.processLogout();
      }
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };

  render() {
    let br = <br />;
    return (
      <div style={{ overflow: "hidden" }}>
        <Header
          processLogout={this.props.processLogut}
          registerListners={this.props.registerListners}
        ></Header>
        <div
          className="container-verf container-verf-select-plan-padding"
          role="main"
        >
          <div className="frm-container">
            <div>
              <div className="descDivCard container-verf-margin pageBgWhite bxShadow">
                <div
                  dangerouslySetInnerHTML={{ __html: message.ChoosePlanText }}
                />
                {/* <div className="subDescDivCard container-verf-margin pageBgWhite">
                <div  dangerouslySetInnerHTML={{__html: message.CoosePlanSubDescpription}} />
            </div> */}
              </div>

              <div className="cardContainer">
                <Row
                  className="pageBgWhite bxShadow"
                  style={{
                    margin: "15px",
                    padding: "16px",
                    borderRadius: "10px",
                  }}
                >
                  <Col
                    sm="2"
                    style={{ display: "flex", justifyContent: "center" }}
                  ></Col>
                  <Col
                    sm="4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card className="plan-card">
                      <CardHeader className="plan-card-header">
                        <div className="planCardHeaderTitle">Free Trial</div>
                      </CardHeader>
                      <CardBody className="plan-card-body">
                        <div>
                          <Row>
                            <div
                              className="plan-card-body-row plan-card-body-row-override-pad"
                              style={{
                                marginTop: "65px",
                                marginBottom: "30px",
                              }}
                            >
                              <Link onClick={this.toggle}>Know More</Link>
                            </div>
                          </Row>
                          <Modal
                            size="lg"
                            isOpen={this.state.modal}
                            toggle={this.toggle}
                            backdrop="static"
                          >
                            <ModalHeader
                              id="modalheader_list"
                              toggle={this.toggle}
                              cssModule={{ "modal-title": "modalHeader" }}
                            >
                              <div
                                className="modalCompanyName"
                                dangerouslySetInnerHTML={{
                                  __html: message.SerraLabsHeader,
                                }}
                              />{" "}
                              <div className="modalTitle">
                                Free Trial Information
                              </div>
                            </ModalHeader>
                            <ModalBody id="knowMore">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message.FreeTrialText,
                                }}
                              />
                            </ModalBody>
                            <ModalFooter id="knowMoreFooter">
                              <Button
                                id="confirmbtn_list"
                                onClick={this.toggle}
                              >
                                Close
                              </Button>
                            </ModalFooter>
                          </Modal>{" "}
                          <Modal
                            isOpen={this.state.modal1}
                            toggle={this.toggle1}
                            size="lg"
                            backdrop={"static"}
                          >
                            <ModalHeader
                              id="modalheader_list"
                              cssModule={{ "modal-title": "modalHeader" }}
                            >
                              <div
                                className="modalCompanyName"
                                dangerouslySetInnerHTML={{
                                  __html: message.SerraLabsHeader,
                                }}
                              />
                              <div className="modalTitle">
                                Free Trial Request
                              </div>{" "}
                            </ModalHeader>
                            <ModalBody>
                              <div>
                                <div className="box-titel">
                                  <p style={{ textAlign: "justify" }}>
                                    Thank you for selecting the Free Trial.
                                    After you click the "Select Free Trial"
                                    button, we will process your request and
                                    update you through your email (
                                    {this.state.email}).
                                  </p>
                                </div>
                              </div>
                            </ModalBody>
                            <ModalFooter id="knowMoreFooter">
                              <Button
                                id="confirmbtn_list"
                                onClick={this.requestFreeTrial}
                              >
                                Select Free Trial
                              </Button>
                              <Button
                                id="confirmbtn_list"
                                onClick={(e) => {
                                  this.setState({ modal1: false });
                                }}
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                          <Row>
                            <div style={{ width: "100%" }}>
                              <Button
                                id="btnCards"
                                style={{ margin: "0px" }}
                                onClick={this.toggle1}
                                block
                              >
                                Choose
                              </Button>
                            </div>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    sm="4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card className="plan-card">
                      <CardHeader className="plan-card-header">
                        <div className="planCardHeaderTitle">Paid Plan</div>
                      </CardHeader>
                      <CardBody className="plan-card-body">
                        {/* <Row> <div className="plan-card-body-row"></div></Row> */}
                        <Row>
                          <div
                            className="plan-card-body-row plan-card-body-row-override-pad"
                            style={{ marginTop: "65px", marginBottom: "30px" }}
                          >
                            <Link onClick={this.togglePPU}>Know More</Link>
                          </div>
                        </Row>
                        <Modal
                          size="lg"
                          isOpen={this.state.modalPPU}
                          toggle={this.togglePPU}
                          backdrop="static"
                        >
                          <ModalHeader
                            id="modalheader_list"
                            toggle={this.togglePPU}
                            cssModule={{ "modal-title": "modalHeader" }}
                          >
                            <div
                              className="modalCompanyName"
                              dangerouslySetInnerHTML={{
                                __html: message.SerraLabsHeader,
                              }}
                            />{" "}
                            <div className="modalTitle">
                              Paid Plan Information
                            </div>
                          </ModalHeader>
                          <ModalBody id="knowMore">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: message.PPUText,
                              }}
                            />
                          </ModalBody>
                          <ModalFooter id="knowMoreFooter">
                            <Button
                              id="confirmbtn_list"
                              onClick={this.togglePPU}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </Modal>
                        <Row>
                          <Button
                            id="btnCards"
                            style={{ margin: "0px" }}
                            onClick={this.toggleisRequest}
                            block
                          >
                            Choose
                          </Button>{" "}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    sm="2"
                    style={{ display: "flex", justifyContent: "center" }}
                  ></Col>
                </Row>
              </div>
            </div>
          </div>
          {this.state.errorPopup === true ? (
            <SerraError
              Errordata={this.state.errormessage}
              ErrorPopup={this.state.errorPopup}
              setErrorMethod={this.setErrorPopupState}
              isError={this.state.isErrorHeader}
            ></SerraError>
          ) : (
            ""
          )}
          {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        </div>
        <Modal
          isOpen={this.state.PPUisRequest}
          toggle={this.toggleisRequest}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            {" "}
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsHeader }}
            />
            <div className="modalTitle">Paid Plan Request</div>{" "}
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">
                <p style={{ textAlign: "justify" }}>
                  Thank you for your interest in the Paid Plan. After you click
                  the "Request Paid Plan" button, we will process your selection
                  and update you through your email ({this.state.email}).
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button
              id="confirmbtn_list"
              onClick={this.toggleRequest}
              style={{ width: "250px" }}
            >
              Request Paid Plan
            </Button>
            <Button
              id="confirmbtn_list"
              onClick={(e) => {
                this.setState({ PPUisRequest: false });
              }}
            >
              Cancel
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.PPURequest}
          toggle={this.toggleRequest}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            {" "}
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsHeader }}
            />
            <div className="modalTitle">
              {this.state.planRequestSubTitle} Request Confirmation
            </div>{" "}
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">
                {this.state.planRequestSubTitle === "Free Trial" ? (
                  <p>
                    Your {this.state.planRequestSubTitle} is approved. Kindly
                    login again to proceed.
                  </p>
                ) : (
                  <p>
                    Your {this.state.planRequestSubTitle} Request has already
                    been submitted and is being processed. Please check your
                    email ({this.state.email}) for updates.
                  </p>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button
              id="confirmbtn_list"
              onClick={(e) => {
                if (this.state.planRequestSubTitle === "Free Trial") {
                  this.handleLogout();
                } else {
                  this.setState({ PPURequest: false });
                }
              }}
            >
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.PPURequestSubmit}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            {" "}
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsHeader }}
            />
            <div className="modalTitle">
              {this.state.planRequestSubTitle} Request Status
            </div>{" "}
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">{this.state.Confirm}</div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button
              id="confirmbtn_list"
              onClick={(e) => {
                this.setState({ PPURequestSubmit: false });
              }}
            >
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyDetails: state.companyDetails.data,
  };
};

export default connect(mapStateToProps, null)(Cards);
