import React, { useEffect, useState } from "react";
import { Button, Row, Label } from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import { Table, Col } from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";

const OrganizationDetails = (props) => {
  const [errorPopup, setErrorPopup] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [isErrorHeader, setIsErrorHeader] = useState(false);
  const [errormessage, setErrormessage] = useState(false);
  const [users, setUsers] = useState([]);
  const [isAdmin, setAdmin] = useState([]);
  const [isRedirect, setRedirect] = useState(false);
  const [orgName, setOrgName] = useState("");

  useEffect(() => {
    setModalLoader(true);
    props.registerListners(true);
    setOrgName(props.orgName);
    fetch(Constdata.ORGANIZATION_STATUS_URL + "/" + props.orgId, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          setOrgName(
            props.orgName
              ? props.orgName
              : responseJson.data.dataSources &&
                  responseJson.data.dataSources.length > 0 &&
                  responseJson.data.dataSources[0].organization
          );
          organizationFilter(responseJson.data);
          setModalLoader(false);
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          setModalLoader(false);
          setErrorPopup(true);
          setErrormessage(message.SessionExpiredText);
          setIsErrorHeader(false);
          setRedirect(true);
        } else {
          setModalLoader(false);
          setErrorPopup(true);
          setErrormessage(message.ServerError);
          setIsErrorHeader(false);
        }
      })
      .catch((error) => {
        setModalLoader(false);
        setErrorPopup(true);
        setErrormessage(message.CacheErrorText);
        setIsErrorHeader(false);
      });
  }, [props.orgId]);

  const organizationFilter = (val1) => {
    const userList = [];
    const adminList = [];
    val1.users &&
      val1.users.filter((val) => {
        let userName = val.userName;
        let providerName = val.providerName && val.providerName.toUpperCase();
        let dataSourceName = val.dataSourceName;
        if (adminList[userName] === undefined) {
          adminList[userName] = val.isAdmin;
        }
        if (userList[userName] === undefined) {
          userList[userName] = {};
        }
        if (userList[userName][providerName] === undefined) {
          userList[userName][providerName] = [];
        }
        if (userList[userName]["dataSource"] === undefined) {
          userList[userName]["dataSource"] = [];
        }

        let dsData =
          val1.dataSources &&
          val1.dataSources.find(
            (ds) => ds.dataSourceName === val.dataSourceName
          );
        if (dsData) {
          let d1 =
            userList[userName]["dataSource"][dsData.organization] &&
            userList[userName]["dataSource"][dsData.organization].find(
              (findDS) => findDS.dataSourceName === dsData.dataSourceName
            );
          if (d1 === undefined) {
            if (
              userList[userName]["dataSource"][dsData.organization] ===
              undefined
            ) {
              userList[userName]["dataSource"][dsData.organization] = [dsData];
            } else {
              userList[userName]["dataSource"][dsData.organization] = [
                ...userList[userName]["dataSource"][dsData.organization],
                dsData,
              ];
            }
          }
        }
        if (userList[userName][providerName][dataSourceName] === undefined) {
          userList[userName][providerName][dataSourceName] = [val];
        } else {
          userList[userName][providerName][dataSourceName] = [
            ...userList[userName][providerName][dataSourceName],
            val,
          ];
        }
      });
    setUsers(userList);
    setAdmin(adminList);
  };

  const toggle = (key, type) => {
    var expTable = document.getElementById(key + "_" + type);
    var rowAWS = document.getElementById("AWS_" + type + "_" + key);
    var rowAZURE = document.getElementById("AZURE_" + type + "_" + key);
    var rowCleanup = document.getElementById("Cleanup_" + type + "_" + key);
    var emptyRow = document.getElementById("emptyRow_" + type + "_" + key);
    if (expTable && expTable.className === "orgBtn closeDiv") {
      expTable.className = "orgBtn openDiv";
      if (rowAWS) {
        rowAWS.style.display = "table-row";
      }
      if (rowAZURE) {
        rowAZURE.style.display = "table-row";
      }
      rowCleanup.style.display = "table-row";
      emptyRow.style.display = "table-row";
    } else {
      expTable.className = "orgBtn closeDiv";
      if (rowAWS) {
        rowAWS.style.display = "none";
      }
      if (rowAZURE) {
        rowAZURE.style.display = "none";
      }
      rowCleanup.style.display = "none";
      emptyRow.style.display = "none";
    }
  };

  const getStringDate = (date) => {
    let current_datetime = new Date(new Date(date).toGMTString());
    var hours = current_datetime.getHours();
    var minutes = current_datetime.getMinutes();
    var month = current_datetime.getMonth() + 1;
    var day = current_datetime.getDate();
    var seconds = current_datetime.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    var tzAbbr = "";
    var dateObject = new Date(),
      dateString = dateObject + "",
      tzAbbr =
        dateString.match(/\(([^\)]+)\)$/) ||
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
      tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
    }
    return (
      current_datetime.getFullYear() +
      "-" +
      month +
      "-" +
      day +
      "  " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      " " +
      tzAbbr
    );
  };

  const setErrorPopupState = () => {
    setErrorPopup(false);
    if (isRedirect) {
      props.processLogut();
      props.history.push("/");
    }
  };

  return (
    <div>
      <div
        className="container-verf container-verf-margin pageBgWhite"
        style={{ borderRadius: "10px" }}
        role="main"
      >
        <div className="frm-container">
          <Row>
            <Col style={{ textAlign: "center" }}>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
              >
                {orgName} Organization Details
              </div>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  padding: "4px",
                }}
              >
                As of {getStringDate(new Date())}
              </div>
            </Col>
          </Row>

          <Table style={{ marginTop: "25px" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Admin</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(users).map((val, key) => (
                <>
                  <tr key={key} style={{ textAlign: "center" }}>
                    <th scope="row">{key + 1}</th>
                    <td>{val}</td>
                    <td>{isAdmin[val] ? "Yes" : "No"}</td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={`${
                          Object.keys(users[val].dataSource).length > 0
                            ? "orgBtn"
                            : "orgBtnDisable"
                        } closeDiv`}
                        onClick={() =>
                          Object.keys(users[val].dataSource).length > 0
                            ? toggle(key, "details")
                            : false
                        }
                        id={key + "_details"}
                        style={{
                          width: "100px",
                        }}
                      >
                        Details
                      </div>
                      {Object.keys(users[val].dataSource).length <= 0 ? (
                        <div className="orgInfoBtn">
                          <i
                            className="fa fa-info"
                            aria-hidden="true"
                            data-toggle="tooltip"
                            data-placement="right"
                            title={message.OrgStatusDetailsInfo}
                          />
                        </div>
                      ) : null}

                      {/* <div
                        outline
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                      >
                        <img
                          style={{ width: "36px" }}
                          // onClick={() =>
                          //   props.deleteOrg(val.orgId, val.adminUser)
                          // }
                          src={require("../../images/Delete_Icon.svg")}
                          title="Delete"
                        />
                      </div> */}
                    </td>
                  </tr>
                  {Object.keys(users[val]).some((val) => val === "AWS") ? (
                    <tr id={"AWS_details_" + key} style={{ display: "none" }}>
                      <td
                        colSpan="4"
                        style={{ borderBottom: "1px solid white" }}
                      >
                        <Table
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <thead>
                            <tr>
                              <th
                                colSpan="7"
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                  fontWeight: "600",
                                  lineHeight: "40px",
                                }}
                              >
                                AWS VM Details
                              </th>
                            </tr>
                            <tr style={{ borderTop: "1px solid #dee2e6" }}>
                              <th>#</th>
                              <th>Data Source</th>
                              <th>VM Name</th>
                              <th>Rightsize status</th>
                              <th>Rightsize Analysis Date</th>
                              <th>Parking status</th>
                              <th>Parking Analysis Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(users[val]).some(
                              (val) => val === "AWS"
                            ) ? (
                              Object.keys(users[val]["AWS"]).map((val1, key1) =>
                                users[val]["AWS"][val1].map((value, key2) => (
                                  <tr
                                    style={{
                                      textAlign: "center",
                                      borderTop: "1px solid #dee2e6",
                                    }}
                                  >
                                    {key2 === 0 ? (
                                      <td
                                        rowSpan={users[val]["AWS"][val1].length}
                                      >
                                        {key1 + 1}
                                      </td>
                                    ) : null}
                                    {key2 === 0 ? (
                                      <td
                                        rowSpan={users[val]["AWS"][val1].length}
                                      >
                                        {value.dataSourceName}
                                      </td>
                                    ) : null}
                                    <td>{value.vmName}</td>
                                    <td>{value.vmRightsizeState}</td>
                                    <td>
                                      {getStringDate(
                                        value.vmRightsizeAnalysisDate
                                      )}
                                    </td>
                                    <td>{value.vmParkingState}</td>
                                    <td>
                                      {getStringDate(
                                        value.vmParkingAnalysisDate
                                      )}
                                    </td>
                                  </tr>
                                ))
                              )
                            ) : (
                              <tr>
                                <td colSpan="7" style={{ textAlign: "center" }}>
                                  No VMs Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ) : null}

                  {Object.keys(users[val]).some((val) => val === "AZURE") ? (
                    <tr id={"AZURE_details_" + key} style={{ display: "none" }}>
                      <td
                        colSpan="4"
                        style={{ borderBottom: "1px solid white" }}
                      >
                        <Table
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <thead>
                            <tr>
                              <th
                                colSpan="7"
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                  fontWeight: "600",
                                  lineHeight: "40px",
                                }}
                              >
                                AZURE VM Details
                              </th>
                            </tr>
                            <tr style={{ borderTop: "1px solid #dee2e6" }}>
                              <th>#</th>
                              <th>Data Source</th>
                              <th>VM Name</th>
                              <th>Rightsize status</th>
                              <th>Rightsize Analysis Date</th>
                              <th>Parking status</th>
                              <th>Parking Analysis Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(users[val]).some(
                              (val) => val === "AZURE"
                            ) ? (
                              Object.keys(users[val]["AZURE"]).map(
                                (val1, key1) =>
                                  users[val]["AZURE"][val1].map(
                                    (value, key2) => (
                                      <tr
                                        style={{
                                          textAlign: "center",
                                          borderTop: "1px solid #dee2e6",
                                        }}
                                      >
                                        {key2 === 0 ? (
                                          <td
                                            rowSpan={
                                              users[val]["AZURE"][val1].length
                                            }
                                          >
                                            {key1 + 1}
                                          </td>
                                        ) : null}
                                        {key2 === 0 ? (
                                          <td
                                            rowSpan={
                                              users[val]["AZURE"][val1].length
                                            }
                                          >
                                            {value.dataSourceName}
                                          </td>
                                        ) : null}
                                        <td>{value.vmName}</td>
                                        <td>{value.vmRightsizeState}</td>
                                        <td>
                                          {getStringDate(
                                            value.vmRightsizeAnalysisDate
                                          )}
                                        </td>
                                        <td>{value.vmParkingState}</td>
                                        <td>
                                          {getStringDate(
                                            value.vmParkingAnalysisDate
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )
                              )
                            ) : (
                              <tr>
                                <td colSpan="7" style={{ textAlign: "center" }}>
                                  No VMs Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ) : null}

                  <tr id={"Cleanup_details_" + key} style={{ display: "none" }}>
                    <td colSpan="4">
                      <Table
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                      >
                        <thead>
                          <tr>
                            <th
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "600",
                                lineHeight: "40px",
                              }}
                            >
                              CleanUp Details
                            </th>
                          </tr>
                          <tr
                            style={{
                              borderTop: "1px solid #dee2e6",
                              borderBottom: "1px solid #dee2e6",
                            }}
                          >
                            <th>#</th>
                            <th>Organization</th>
                            <th>Provider</th>
                            <th>Data Source</th>
                            <th>Cleanup status</th>
                            <th>Cleanup Analysis Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(users[val].dataSource).map((val2, key) =>
                            users[val].dataSource[val2].map((dt, key1) => (
                              <tr key={key1} style={{ textAlign: "center" }}>
                                {key1 === 0 ? (
                                  <td
                                    rowSpan={users[val].dataSource[val2].length}
                                  >
                                    {key1 + 1}
                                  </td>
                                ) : null}
                                {key1 === 0 ? (
                                  <td
                                    rowSpan={users[val].dataSource[val2].length}
                                  >
                                    {dt.organization}
                                  </td>
                                ) : null}
                                <td>{dt.providerName}</td>
                                <td>{dt.dataSourceName}</td>
                                <td>{dt.cleanupStatus}</td>
                                <td>
                                  {getStringDate(dt.cleanupDateTimeStamp)}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      id={"emptyRow_details_" + key}
                      colSpan="4"
                      style={{
                        height: "30px",
                        display: "none",
                        borderBottom: "1px solid white",
                        borderTop: "1px solid white",
                      }}
                    ></td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
          <div
            class="btn-toolbar pageBgWhite"
            role="toolbar"
            aria-label="Toolbar with button groups"
            style={{
              display: "flex",
              padding: "20px",
              marginTop: "20px",
              justifyContent: "flex-end",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <div role="group" aria-label="First group">
              <Button
                className="btn-cancel btn btn-light"
                onClick={props.toggle}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
      {errorPopup === true ? (
        <SerraError
          Errordata={errormessage}
          ErrorPopup={errorPopup}
          setErrorMethod={() => setErrorPopupState()}
          isError={isErrorHeader}
        ></SerraError>
      ) : (
        ""
      )}
      {modalLoader === true ? <SerraLoader></SerraLoader> : ""}
    </div>
  );
};

export default OrganizationDetails;
