export default class Plans {
    static free = {
      title: "Free Trial",
    };
  
    static paid = {
      title: "Paid Plan",
    };
  
    static subscription = {
      title: "Subscription",
    };
  }
  