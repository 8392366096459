import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Tooltip,
  Container,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import * as Constdata from "../../Constants/ApiConstants";
import SerraError from "../CommonComponent/Error";
import Header from "../HeaderComponent/Header";
import ReactGA from "react-ga4";
import SerraLoader from "../CommonComponent/Loader";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { connect } from "react-redux";
import * as message from "../../Constants/MessageConstant";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.toggle = this.toggle.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.handleTerms = this.handleTerms.bind(this);
    this.onTermsAndConditionsClick = this.onTermsAndConditionsClick.bind(this);
    this.state = {
      num1: 1,
      num2: 5,
      result: "",
      hidden: true,
      tooltipOpen: false,
      submitDisabled: true,
      verifyColor: "",
      passwordcheck: "",
      confirmPass: "",
      fields: {},
      errors: {},
      formValid: false,
      errormessage: "",
      errorPopup: false,
      hasAgreed: false,
      isDisableconfirm: true,
      isfalse: false,
      modalLoader: false,
    };
  }
  componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Sign Up",
    });
    //this.props.registerListners();
    // document.getElementById("menuDisplay").style.display = "none";
    // document.getElementById("selectDisplay").style.display = "none";
    loadCaptchaEnginge(6, "#AFAFAF");
  }

  handleTerms = () => {
    this.setState((prevState) => {
      return { hasAgreed: !prevState.hasAgreed };
    });
  };
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "First Name cannot be empty";
    }
    if (typeof fields["firstName"] !== "undefined") {
      if (!fields["firstName"].trim().match(/^[a-zA-Z\-\']+$/)) {
        formIsValid = false;
        errors["firstName"] = "Invalid character(s) present";
      }
    }
    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = " Last Name cannot be empty";
    }
    if (typeof fields["lastName"] !== "undefined") {
      if (!fields["lastName"].trim().match(/^[a-zA-Z\-\']+$/)) {
        formIsValid = false;
        errors["lastName"] = "Invalid character(s) present";
      }
    }
    //password
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "password cannot be empty";
    }
    if (typeof fields["password"] !== "undefined") {
      if (
        !fields["password"].match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
        )
      ) {
        formIsValid = false;
        errors["password"] =
          "Your password in weak !! ,Please check password criteria hovering on the password field";
      }
    }
    //passwordConfirmpassword
    if (!fields["passwordConfirm"]) {
      formIsValid = false;
      errors["passwordConfirm"] = "Confirm password cannot be empty";
    }
    if (typeof fields["passwordConfirm"] !== "undefined") {
      if (
        !fields["passwordConfirm"]
          .trim()
          .match(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
          )
      ) {
        formIsValid = false;
        errors["passwordConfirm"] =
          "Your password in weak !! ,Please check password criteria hovering on the password field";
      }
    }
    //orgName
    if (!fields["orgName"].trim()) {
      formIsValid = false;
      errors["orgName"] = "Company Name cannot be empty";
    }
    //Email
    if (!fields["username"].trim()) {
      formIsValid = false;
      errors["username"] = "Work email cannot be empty";
    }
    if (typeof fields["username"] !== "undefined") {
      let lastAtPos = fields["username"].lastIndexOf("@");
      let lastDotPos = fields["username"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["username"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["username"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["username"] = "Email is not valid";
      }
    }
    //   if(!fields["verify"]){
    //     formIsValid = false;
    //     errors["verify"] = "Please verify capcha";
    //  }
    this.setState({ errors: errors, formValid: formIsValid });
    return formIsValid;
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  toggleShow = () => {
    console.log("inside toggle");
    this.setState({ hidden: !this.state.hidden });
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleSubmit(e) {
    e.preventDefault();
    const {
      firstName,
      lastName,
      password,
      passwordConfirm,
      username,
      orgName,
    } = this.state.fields;
    let fName = firstName.trim();
    let lNAme = lastName.trim();
    let pwd = password.trim();
    let cpwd = passwordConfirm.trim();
    let orgNm = orgName.trim();
    let uname = username.trim().toLowerCase();
    const payload = {
      firstName: fName,
      lastName: lNAme,
      password: pwd,
      passwordConfirm: cpwd,
      orgName: orgNm,
      username: uname,
    };

    if (!this.handleValidation()) {
      return;
    }
    this.setState({ modalLoader: true });

    fetch(Constdata.SIGNUP_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.processResponse(responseJson);
        this.setState({
          firstName: "",
          lastName: "",
          orgName: "",
          password: "",
          passwordConfirm: "",
          username: "",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  processResponse(response) {
    console.log("inside method", response);
    if (response.success === true) {
      this.setState({ modalLoader: false });
      this.props.history.push({
        pathname: "/success",
        state: { key: this.state.fields.username },
      });
    } else {
      this.setState({
        errorPopup: true,
        errormessage: response.message,
        modalLoader: false,
      });
      for (var i = 0; i < response.errorFields.length; i++) {
        if (response.errorFields[i].fieldName !== "") {
          this.setState({ error: response.errorFields[i].fieldName });
          this.state.errors[response.errorFields[i].fieldName] =
            response.errorFields[i].error;
        }
      }
    }
  }
  validator = () => {
    const { password } = this.state.fields;
    var reg =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;
    var test = reg.test(password);
    if (test) {
      this.setState(() => {
        return { passwordcheck: "valid", isDisableconfirm: false };
      });
    } else {
      this.setState(() => {
        return { passwordcheck: "invalid", isDisableconfirm: true };
      });
    }
  };
  confirmpassword = () => {
    const { password, passwordConfirm } = this.state.fields;
    if (password === passwordConfirm) {
      this.setState({ confirmPass: "valid" });
    } else {
      this.setState({ confirmPass: "invalid" });
    }
  };
  checkvalidity = (e) => {
    this.setState({ result: e.target.value });
  };

  isDisabled = () => {
    const { result, passwordcheck, confirmPass, hasAgreed, verifyColor } =
      this.state;
    const {
      firstName,
      lastName,
      passwordConfirm,
      password,
      username,
      orgName,
    } = this.state.fields;
    const isAllFilled =
      firstName &&
      lastName &&
      passwordConfirm &&
      password &&
      username &&
      orgName &&
      result &&
      hasAgreed;
    const isValidPass =
      passwordcheck === "valid" &&
      confirmPass === "valid" &&
      verifyColor === "valid";
    return !(isAllFilled && isValidPass);
  };
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
  };

  doSubmit = () => {
    let user_captcha = document.getElementById("user_captcha_input").value;
    this.setState({ result: user_captcha });
    if (validateCaptcha(user_captcha, false) === true) {
      this.setState({ verifyColor: "valid" });
    } else {
      this.setState({ verifyColor: "invalid" });
    }
  };

  resetCaptcha = () => {
    loadCaptchaEnginge(6, "#AFAFAF");
    document.getElementById("user_captcha_input").value = "";
    this.setState({ verifyColor: "invalid" });
  };

  onTermsAndConditionsClick() {
    window.open(this.props.companyDetails.TermsAndConditions);
  }

  render() {
    let passwordvalid = (
      <Input
        type={this.state.hidden ? "password" : "text"}
        onKeyUp={this.validator.bind(this)}
        className="form-control"
        id="password"
        name="password"
        value={this.state.fields["password"]}
        onChange={this.handleChange.bind(this, "password")}
        autoComplete="new-password"
      />
    );
    if (this.state.passwordcheck === "valid") {
      passwordvalid = (
        <Input
          type={this.state.hidden ? "password" : "text"}
          onKeyUp={this.validator.bind(this)}
          className="form-control"
          id="password"
          name="password"
          value={this.state.fields["password"]}
          onChange={this.handleChange.bind(this, "password")}
          autoComplete="new-password"
          valid
        />
      );
    } else if (this.state.passwordcheck === "invalid") {
      passwordvalid = (
        <Input
          type={this.state.hidden ? "password" : "text"}
          onKeyUp={this.validator.bind(this)}
          className="form-control"
          id="password"
          name="password"
          value={this.state.fields["password"]}
          onChange={this.handleChange.bind(this, "password")}
          autoComplete="new-password"
          invalid
        />
      );
    }
    let confirmpassword = (
      <Input
        type="password"
        className="form-control"
        id="passwordConfirm"
        name="passwordConfirm"
        value={this.state.fields["passwordConfirm"]}
        onChange={this.handleChange.bind(this, "passwordConfirm")}
        onKeyUp={this.confirmpassword.bind(this)}
        disabled={this.state.isDisableconfirm}
        autoComplete="new-password"
      />
    );
    if (this.state.confirmPass === "valid") {
      confirmpassword = (
        <Input
          type="password"
          className="form-control"
          id="passwordConfirm"
          name="passwordConfirm"
          value={this.state.fields["passwordConfirm"]}
          onChange={this.handleChange.bind(this, "passwordConfirm")}
          onKeyUp={this.confirmpassword.bind(this)}
          autoComplete="new-password"
          valid
        />
      );
    } else if (this.state.confirmPass === "invalid") {
      confirmpassword = (
        <Input
          type="password"
          className="form-control"
          id="passwordConfirm"
          name="passwordConfirm"
          value={this.state.fields["passwordConfirm"]}
          onChange={this.handleChange.bind(this, "passwordConfirm")}
          onKeyUp={this.confirmpassword.bind(this)}
          autoComplete="new-password"
          invalid
        />
      );
    }
    let verifycapcha = (
      <Input
        type="text"
        className="form-control"
        placeholder="Enter Captcha Value"
        id="user_captcha_input"
        name="user_captcha_input"
        onKeyUp={() => this.doSubmit()}
        disabled={!this.state.hasAgreed}
      />
    );
    if (this.state.verifyColor === "valid") {
      verifycapcha = (
        <Input
          type="text"
          className="form-control"
          placeholder="Enter Captcha Value"
          id="user_captcha_input"
          name="user_captcha_input"
          onKeyUp={() => this.doSubmit()}
          valid
        />
      );
    } else if (this.state.verifyColor === "invalid") {
      verifycapcha = (
        <Input
          type="text"
          className="form-control"
          placeholder="Enter Captcha Value"
          id="user_captcha_input"
          name="user_captcha_input"
          onKeyUp={() => this.doSubmit()}
          invalid
        />
      );
    }

    const Logo = <img src={this.props.companyDetails.LOGO} alt="" />;

    return (
      <>
        {/* <Header processLogout={this.props.processLogut} registerListners={this.props.registerListners} /> */}
        {/* <div className="bg-container1">&nbsp;</div> */}
        <Container fluid style={{ paddingLeft: "0", paddingRight: "0" }}>
          <Row>
            <Col
              className={
                this.props.companyDetails.CompanyName === "Serra Labs"
                  ? "signInImg"
                  : "signInNoSerra"
              }
              sm="8"
            >
              <div className="textPosition">
                {this.props.companyDetails.ServiceNameFullName}
                {this.props.companyDetails.CompanyName === "Serra Labs" ? (
                  <div className="textPositionHP">
                    OPTIMIZE YOUR CLOUD <br /> REDUCE COST, IMPROVE PERFORMANCE
                  </div>
                ) : null}
              </div>
            </Col>
            <Col sm="4" className="signInForm">
              <div>
                {this.props.companyDetails.CompanyName === "Serra Labs" ? (
                  <div className="signInLogo">
                    <a href={this.props.companyDetails.Website} target="blank">
                      {Logo}
                    </a>
                  </div>
                ) : (
                  <div className="signInLogo" style={{ display: "block" }}>
                    {Logo}
                    {process.env.REACT_APP_IS_PoweredByName === "true" ? (
                      <div className="powerByDiv">Powered by Serra Labs</div>
                    ) : null}
                  </div>
                )}
                <div className="HeadingTitleSignIn">
                  {this.props.companyDetails.ServiceName} Sign Up
                </div>
                {/* <div className="outer-container"> */}
                <div className="container">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      <div className="col form-group">
                        <label htmlFor="firstName">
                          First Name:<span className="requiredColor">*</span>
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="First name"
                          id="firstName"
                          name="firstName"
                          onChange={this.handleChange.bind(this, "firstName")}
                          value={this.state.fields["firstName"]}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["firstName"]}
                        </span>
                      </div>
                      <div className="col form-group">
                        <label htmlFor="lastName">
                          Last Name:<span className="requiredColor">*</span>
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Last name"
                          id="lastName"
                          name="lastName"
                          onChange={this.handleChange.bind(this, "lastName")}
                          value={this.state.fields["lastName"]}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["lastName"]}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="orgName">
                        Organization Name:
                        <span className="requiredColor">*</span>
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="orgName"
                        name="orgName"
                        value={this.state.fields["orgName"]}
                        onChange={this.handleChange.bind(this, "orgName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["orgName"]}
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">
                        Work Email ID:<span className="requiredColor">*</span>
                        <span>&nbsp;</span>
                        <span className="info-icon">
                          <i
                            className="fa fa-info-circle"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Please enter official case-sensitive name for the organization where you work"
                          />
                        </span>
                      </label>
                      <Input
                        type="email"
                        className="form-control"
                        id="username"
                        name="username"
                        placeholder="Work email"
                        value={this.state.fields["username"]}
                        onChange={this.handleChange.bind(this, "username")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["username"]}
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">
                        Password:<span className="requiredColor">*</span>
                      </label>
                      <InputGroup>
                        {passwordvalid}
                        <Tooltip
                          placement="right"
                          trigger="focus"
                          isOpen={this.state.tooltipOpen}
                          hideArrow={true}
                          target="password"
                          toggle={this.toggle}
                          className="tooltipSignup"
                          style={{ textAlign: "left" }}
                        >
                          <div>
                            <h6>
                              Password should be between 8 and 16 characters
                              with at least one each of
                            </h6>
                            <div>
                              lowercase letter{<br />}
                              uppercase letter{<br />}
                              numeric character{<br />}
                              special character{<br />}
                            </div>
                          </div>
                        </Tooltip>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i
                              className="fa fa-eye-slash"
                              onClick={this.toggleShow.bind(this)}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <div>
                        <span style={{ color: "red" }}>
                          {this.state.errors["password"]}
                        </span>{" "}
                      </div>
                      <div className="invalid-feedback">
                        Please provide a valid email.
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="confipasswordConfirmrmpassword">
                        Confirm Password:
                        <span className="requiredColor">*</span>
                      </label>
                      {confirmpassword}
                      <div>
                        <span style={{ color: "red" }}>
                          {this.state.errors["passwordConfirm"]}
                        </span>{" "}
                      </div>
                    </div>
                    <div className="checkbox">
                      <label htmlFor="hasAgreed">
                        <Input
                          className="form-check-input-nontable"
                          type="checkbox"
                          id="hasAgreed"
                          name="hasAgreed"
                          value={this.state.hasAgreed}
                          onChange={this.handleTerms}
                        />
                        I agree to the{" "}
                        <span className="sign-up-link">
                          <Link onClick={this.onTermsAndConditionsClick}>
                            {" "}
                            Terms and Conditions
                          </Link>
                        </span>
                      </label>
                    </div>
                    <div className="verf-heading">
                      Verification:<span className="requiredColor">*</span>
                    </div>
                    <div className="container-verf-question ">
                      <div className="verf-qestion">
                        <LoadCanvasTemplateNoReload />
                      </div>
                      <div className="reset-btn">
                        <Button
                          type="button"
                          id="btnRefresh"
                          size="lg"
                          onClick={() => this.resetCaptcha(this)}
                          block
                        >
                          <span className="reset-icon">
                            <i className="fa fa-refresh" />
                          </span>
                        </Button>
                      </div>
                    </div>
                    <div className="form-group">
                      <label />
                      {verifycapcha}
                      <div>
                        <span style={{ color: "red" }}>
                          {this.state.errors["verify"]}
                        </span>
                      </div>
                    </div>
                    <Button
                      id={
                        this.isDisabled()
                          ? "btnSinginSignup_disabled"
                          : "btnSinginSignup"
                      }
                      disabled={this.isDisabled()}
                      block
                    >
                      Sign Up
                    </Button>
                  </form>
                  <div className="info-container">
                    Already have an account?{" "}
                    <span className="sign-up-link">
                      <Link to={"/"}>Sign In</Link>
                    </span>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </Container>

        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyDetails: state.companyDetails.data,
  };
};

export default connect(mapStateToProps, null)(withRouter(Signup));
