import React, { Component } from "react";
import { Table, Row, Col } from "reactstrap";
import * as message from "../../Constants/MessageConstant";
// import './test.css';
import ColorComponent from "./SvgColorOverview";
import { priceDisplay } from "../../Constants/CommonConstant";
class ResizeRecommendationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.iconData,
      isAsp: this.props.isAsp !== undefined ? this.props.isAsp : false,
      hostOnly:
        this.props.data.metricsPlatform !== undefined &&
        this.props.data.metricsPlatform === "Host",
      entityType:
        this.props.entityType !== undefined
          ? this.props.entityType
          : this.props.isAsp !== undefined && this.props.isAsp === true
          ? "asp"
          : "vm",
    };
  }
  render() {
    const { data } = this.props;

    return (
      <div>
        <Row>
          <div className="info-graphics-container">
            <ColorComponent
              data={this.state.data[0].cpu}
              isResize={this.props.isResize}
              isLine={this.props.isLine}
              isAsp={this.state.isAsp}
              entityType={this.state.entityType}
              showCostPerfMarker={true}
              fromOverview={true}
            >
              {" "}
            </ColorComponent>
            {this.state.entityType === "db" ? (
              <ColorComponent
                data={this.state.data[0].disks}
                isResize={this.props.isResize}
                isLine={this.props.isLine}
                isAsp={this.state.isAsp}
                entityType={this.state.entityType}
                fromOverview={true}
              ></ColorComponent>
            ) : null}
            <ColorComponent
              data={this.state.data[0].memory}
              isResize={this.props.isResize}
              isLine={this.props.isLine}
              isAsp={this.state.isAsp}
              hostOnly={this.state.hostOnly}
              entityType={this.state.entityType}
              fromOverview={true}
            ></ColorComponent>
            <ColorComponent
              data={this.state.data[0].network}
              isResize={this.props.isResize}
              isLine={
                this.state.entityType === "db" ? false : this.props.isLine
              }
              isAsp={this.state.isAsp}
              entityType={this.state.entityType}
              fromOverview={true}
            ></ColorComponent>
            {this.state.entityType !== "db" ? (
              <ColorComponent
                data={this.state.data[0].disks}
                isResize={this.props.isResize}
                isLine={false}
                isAsp={this.state.isAsp}
                entityType={this.state.entityType}
                fromOverview={true}
              ></ColorComponent>
            ) : null}
          </div>
        </Row>
        <div className="panel" style={{ marginTop: "20px" }}>
          <Row>
            <Col id="ToPDF">
              <Table id="tableSizing" className="tableTextCenter">
                <thead>
                  <tr>
                    <th></th>
                    <th>Original Spec</th>
                    <th>Recommended Spec</th>
                    <th colSpan="2">Original Limits</th>
                    <th>Recommended Limits</th>
                    <th>Original Cost*</th>
                    <th>Recommended Cost*</th>
                  </tr>
                </thead>
                <tbody>
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowSpan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbCpuClass"
                                  : "CpuClass"
                              }
                            >
                              {" "}
                            </div>
                            <Col className="tableLeftHead">
                              {this.state.entityType === "db" ? "vCORE" : "CPU"}
                            </Col>
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td
                            className="textfield"
                            rowSpan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            {key.current_vmsize_name}{" "}
                            {key.hypervgeneration
                              ? " (" + key.hypervgeneration + ")"
                              : null}
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td
                            className="textfield"
                            rowSpan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            {key.recommended_vmsize_name}{" "}
                            {key.resize_hypervgeneration
                              ? " (" + key.resize_hypervgeneration + ")"
                              : null}
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="textfield">
                          <table style={{ width: "100%" }}>
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <tr>
                                <span className="labelColor">
                                  {this.state.entityType === "db"
                                    ? "Num vCore"
                                    : "Num vCPU"}
                                </span>
                              </tr>
                            )}
                            {this.state.isAsp ? (
                              <tr>
                                <span className="labelColor">CPU ACUs</span>
                              </tr>
                            ) : this.state.entityType === "db" ? null : (
                              <tr>
                                <span className="labelColor">CPU Speed</span>
                              </tr>
                            )}
                          </table>
                        </td>
                        <td className="numberfield">
                          <table style={{ width: "100%" }}>
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <tr>{key.current_vcpu}</tr>
                            )}
                            {this.state.entityType === "db" ? null : (
                              <tr>{key.current_speed}</tr>
                            )}
                          </table>
                        </td>
                        <td className="numberfield">
                          <table style={{ width: "100%" }}>
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <tr>{key.recommended_vcpu}</tr>
                            )}
                            {this.state.entityType === "db" ? null : (
                              <tr>{key.recommended_speed}</tr>
                            )}
                          </table>
                        </td>
                        {i === 0 ? (
                          <td
                            className="numberfield"
                            rowSpan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            <div>{priceDisplay(key.current_vm_price)}/mo</div>
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td
                            className="numberfield"
                            rowSpan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            <div>
                              {priceDisplay(key.recommended_vm_price)}/mo
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}

                  {this.state.entityType === "db"
                    ? data.osdisk.map((key, i) => {
                        return (
                          <tr>
                            {i === 0 ? (
                              <td rowSpan={data.osdisk.length}>
                                <div className="DbOsClass"></div>
                                <Col className="tableLeftHead">MEMORY</Col>
                              </td>
                            ) : (
                              ""
                            )}
                            <td className="textfield">
                              <div>
                                <span className="labelColor">Size</span>
                              </div>
                            </td>
                            <td className="numberfield">
                              <div>{key.current_os_disk_memory} GB</div>
                            </td>
                            <td className="numberfield">
                              <div>{key.recommended_os_disk_memory} GB</div>
                            </td>
                          </tr>
                        );
                      })
                    : null}

                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowSpan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbMemoryClass"
                                  : "MemoryClass"
                              }
                            ></div>
                            <Col className="tableLeftHead">
                              {this.state.entityType === "db"
                                ? "DATA DISK"
                                : "MEMORY"}
                            </Col>
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="textfield">
                          <span className="labelColor">
                            {this.state.entityType === "db" ? "IOPS" : "Size"}
                          </span>
                        </td>
                        <td className="numberfield">
                          <div>{key.current_memory}</div>
                        </td>
                        <td className="numberfield">
                          <div>{key.recommended_memory}</div>
                        </td>
                      </tr>
                    );
                  })}
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowSpan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbNetworkClass"
                                  : "NetworkClass"
                              }
                            ></div>
                            <Col className="tableLeftHead">
                              {this.state.entityType === "db"
                                ? "LOG DISK"
                                : "NETWORK"}
                            </Col>
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="textfield">
                          <span className="labelColor">
                            {this.state.entityType === "db"
                              ? "Log Rate"
                              : "Bandwidth"}
                          </span>
                        </td>
                        <td className="numberfield">
                          <div>{key.current_network_bandwidth}</div>
                        </td>
                        <td className="numberfield">
                          <div>{key.recommended_network_bandwidth}</div>
                        </td>
                      </tr>
                    );
                  })}
                  {this.state.entityType !== "db"
                    ? data.osdisk.map((key, i) => {
                        return (
                          <tr>
                            {i === 0 ? (
                              <td rowSpan={data.osdisk.length}>
                                <div className="OsClass"></div>
                                <Col className="tableLeftHead">
                                  {this.state.isAsp ? "STORAGE" : "OS Disk"}
                                </Col>
                              </td>
                            ) : (
                              ""
                            )}
                            {this.state.isAsp ? (
                              ""
                            ) : i === 0 ? (
                              <td
                                className="textfield"
                                rowSpan={data.osdisk.length}
                              >
                                {key.current_os_disk_name
                                  ? key.current_os_disk_name.toUpperCase()
                                  : key.current_os_disk_name}
                                {<br />}({key.current_os_disk_tier})
                              </td>
                            ) : (
                              ""
                            )}
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td className="textfield">
                                {key.recommended_os_disk_name}
                                {<br />}({key.recommended_os_disk_tier})
                              </td>
                            )}
                            <td className="textfield">
                              <table style={{ width: "100%" }}>
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>
                                    <span className="labelColor">
                                      Throughput
                                    </span>
                                  </tr>
                                )}
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>
                                    <span className="labelColor">IOPS</span>
                                  </tr>
                                )}
                                {this.state.isAsp ? (
                                  <span className="labelColor">Size</span>
                                ) : (
                                  <tr>
                                    <span className="labelColor">Size</span>
                                  </tr>
                                )}
                              </table>
                            </td>
                            <td className="numberfield">
                              <table style={{ width: "100%" }}>
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>{key.current_os_disk_bandwidth}</tr>
                                )}
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>{key.current_os_disk_iops}</tr>
                                )}
                                <tr>{key.current_os_disk_memory}</tr>
                              </table>
                            </td>

                            <td className="numberfield">
                              <table style={{ width: "100%" }}>
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>{key.recommended_os_disk_bandwidth}</tr>
                                )}
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>{key.recommended_os_disk_iops}</tr>
                                )}
                                <tr>{key.recommended_os_disk_memory}</tr>
                              </table>
                            </td>

                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td className="numberfield">
                                <div>
                                  {priceDisplay(key.current_os_disk_price)}/mo
                                </div>
                              </td>
                            )}
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td className="numberfield">
                                <div>
                                  {priceDisplay(key.recommended_os_disk_price)}
                                  /mo
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    : null}
                  {this.state.entityType !== "db"
                    ? Object.keys(data.dataDisk).map((key) => {
                        return data.dataDisk[key].map((value, i) => {
                          return (
                            <tr>
                              {i === 0 ? (
                                <td
                                  className="textfield"
                                  rowspan={data.dataDisk[key].length}
                                >
                                  <div className="OsClass" />
                                  <Col className="tableLeftHead">
                                    DISK {key}
                                  </Col>
                                </td>
                              ) : (
                                ""
                              )}
                              <td className="textfield">
                                {value.current_data_disk_name.toUpperCase()}
                                {<br />}({value.current_data_disk_tier})
                              </td>
                              <td className="textfield">
                                {value.recommended_data_disk_name} {<br />}(
                                {value.recommended_data_disk_tier})
                              </td>
                              <td className="textfield">
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <span className="labelColor">
                                      Throughput
                                    </span>
                                  </tr>
                                  <tr>
                                    <span className="labelColor">IOPS</span>
                                  </tr>
                                  <tr>
                                    <span className="labelColor">Size</span>
                                  </tr>
                                </table>
                              </td>
                              <td className="numberfield">
                                <table style={{ width: "100%" }}>
                                  <tr>{value.current_data_disk_bandwidth}</tr>
                                  <tr>{value.current_data_disk_iops}</tr>
                                  <tr>{value.current_data_disk_memory}</tr>
                                </table>
                              </td>

                              <td className="numberfield">
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    {value.recommended_data_disk_bandwidth}
                                  </tr>
                                  <tr>{value.recommended_data_disk_iops}</tr>
                                  <tr>{value.recommended_data_disk_memory}</tr>
                                </table>
                              </td>
                              <td className="numberfield">
                                <div>
                                  {priceDisplay(value.current_data_disk_price)}
                                  /mo
                                </div>
                              </td>
                              <td className="numberfield">
                                <div>
                                  {priceDisplay(
                                    value.recommended_data_disk_price
                                  )}
                                  /mo
                                </div>
                              </td>
                            </tr>
                          );
                        });
                      })
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>
          <div
            style={{ paddingLeft: "13px", fontSize: "16px", textAlign: "left" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: message.RecommendationFootNote,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ResizeRecommendationTable;
