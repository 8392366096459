
import React, { Component } from 'react';
import { Table, Row, Col } from 'reactstrap';
// import './test.css';
import ColorComponent from './SvgColorOverview';
import * as message from '../../Constants/MessageConstant';
class SizingHealthAnalysisTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data : this.props.iconData,
        isAsp: (this.props.isAsp !== undefined ? this.props.isAsp : false),
        hostOnly: (this.props.data.metricsPlatform === "Host"),
        entityType: (this.props.entityType !== undefined ? this.props.entityType : "vm")
        };
  }
  render() {
    const { data } = this.props;
    const Green = <img src={require('../../images/Green_01.png')} alt="" />;
		const Red = <img src={require('../../images/Red_01.png')} alt="" />;
		const Yellow = <img src={require('../../images/Yellow_01.png')} alt="" />;
    return (
      <div> 
          <Row> 
          <div className="info-graphics-container" style={{marginLeft:"25%"}}>          
          <ColorComponent data={this.state.data[0].cpu} isResize={this.props.isResize} isAsp={this.state.isAsp} entityType={this.state.entityType} showCostPerfMarker={true} fromOverview={true}> </ColorComponent>
          {this.state.entityType === "db" ?  <ColorComponent data={this.state.data[0].disks} isResize={this.props.isResize} isAsp={this.state.isAsp} entityType={this.state.entityType} fromOverview={true}></ColorComponent> : null }
          <ColorComponent data={this.state.data[0].memory} isResize={this.props.isResize} isAsp={this.state.isAsp} entityType={this.state.entityType} hostOnly={this.state.hostOnly} fromOverview={true}></ColorComponent>
          <ColorComponent data={this.state.data[0].network} isResize={this.props.isResize} isAsp={this.state.isAsp} entityType={this.state.entityType} fromOverview={true}></ColorComponent>
          {this.state.entityType !== "db" ?  <ColorComponent data={this.state.data[0].disks} isResize={this.props.isResize} isAsp={this.state.isAsp} entityType={this.state.entityType} fromOverview={true}></ColorComponent> : null }
          </div>
          </Row>
        <div className="panel" style={{marginTop:"20px"}}>
          <Row>
            <Table id="tableSizing" style={{marginLeft:"25%"}} >
                <thead>
                  <tr>
                    <th ></th>
                    <th >Utilization </th>
                    <th>Cost</th>
                    <th>Health </th>
                    <th>Performance</th>
                  </tr>
                </thead>
                <tbody>
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (<td rowSpan={data.vm.length} ><div className={this.state.entityType === "db" ? "DbCpuClass" : "CpuClass"}></div><Col className="tableLeftHead">{ this.state.entityType === "db" ?"vCORE" :"CPU"}</Col></td>) : ("")}
                        <td className="textfieldSizing">{key.current_vcpu_utilisation}</td>
            { key.current_vcpu_cost===message.CostHigh ?<td className="textfieldSizing" ><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostHighColor}} className="sizingeHelathColoredTD">$</div></div></td>
            : key.current_vcpu_cost===message.CostLow ? <td className="textfieldSizing" style={{color:"#393939"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostLowColor,color:"#393939"}} className="sizingeHelathColoredTD">$$</div></div></td>
            :<td className="textfieldSizing" ><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostOKColor}} className="sizingeHelathColoredTD">$$$</div></div></td>}
             <td className="textfieldSizing">{key.current_vcpu_health}</td>
            {key.current_vcpu_health===message.HealthGood?<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthGoodColor}} className="sizingeHelathColoredTD">{Red}</div></div></td>
            :key.current_vcpu_health===message.HealthFair?<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthFairColor}} className="sizingeHelathColoredTD">{Yellow}</div></div></td>
            :<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthPoorColor}} className="sizingeHelathColoredTD">{Green}</div></div></td>}
                      </tr>
                    );
                  }
                  )}
          {this.state.entityType === "db" ?  data.osdisk.map((key, i) => {
                    return (
                      (i === 0) ? (
                        <tr>
                          <td rowSpan={data.osdisk.length} ><div className="DbOsClass"></div><Col className="tableLeftHead">MEMORY</Col></td>
                          <td className="textfieldSizing"> {key.current_os_disk_utilisation}</td>
                          { key.current_os_disk_cost===message.CostHigh ?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostHighColor}}  className="sizingeHelathColoredTD">$</div></div></td>
                          :  key.current_os_disk_cost===message.CostLow ? <td className="textfieldSizing" style={{color:"#393939"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostLowColor,color:"#393939"}} className="sizingeHelathColoredTD">$$</div></div></td>
                        :<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostOKColor}} className="sizingeHelathColoredTD">$$$</div></div></td>}
           <td className="textfieldSizing">{key.current_os_disk_health}</td>
            {key.current_os_disk_health===message.HealthGood?<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthGoodColor}}  className="sizingeHelathColoredTD">{Red}</div></div></td>
            :key.current_os_disk_health===message.HealthFair?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthFairColor}} className="sizingeHelathColoredTD">{Yellow}</div></div></td>
            :<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthPoorColor}} className="sizingeHelathColoredTD">{Green}</div></div></td>}
                        </tr>
                      ) : ("")
                    );
                  }
                  ) : null}
          
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (<td rowSpan={data.vm.length} style={{width:"145px"}} ><div className={this.state.entityType === "db" ? "DbMemoryClass" : "MemoryClass"}></div><Col className="tableLeftHead">{ this.state.entityType === "db" ?"DATA DISK" :"MEMORY"}</Col></td>) : ("")}
                        <td className="textfieldSizing"> {this.state.hostOnly ? "N/A" : key.current_memory_utilisation}</td>
            { this.state.hostOnly ? <td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:"gray"}} className="sizingeHelathColoredTD">N/A</div></div></td> : 
                    key.current_memory_cost===message.CostHigh ?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostHighColor}} className="sizingeHelathColoredTD">$</div></div></td>
            : key.current_memory_cost===message.CostLow ? <td className="textfieldSizing" style={{color:"#393939"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostLowColor,color:"#393939"}} className="sizingeHelathColoredTD">$$</div></div></td>
            :<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostOKColor}} className="sizingeHelathColoredTD">$$$</div></div></td>}
             <td className="textfieldSizing">{this.state.hostOnly ? "N/A" :key.current_memory_health}</td>
            {this.state.hostOnly ? <td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:"gray"}} className="sizingeHelathColoredTD">N/A</div></div></td> :
                    key.current_memory_health===message.HealthGood?<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthGoodColor}} className="sizingeHelathColoredTD">{Red}</div></div></td>
            :key.current_memory_health===message.HealthFair?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthFairColor}} className="sizingeHelathColoredTD">{Yellow}</div></div></td>
            :<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthPoorColor}} className="sizingeHelathColoredTD">{Green}</div></div></td>}
                      </tr>
                    );
                  }
                  )}
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (<td rowSpan={data.vm.length}><div className={this.state.entityType === "db" ? "DbNetworkClass" : "NetworkClass"}></div><Col className="tableLeftHead">{ this.state.entityType === "db" ?"LOG DISK" :"NETWORK"}</Col></td>) : ("")}
                        <td className="textfieldSizing">{key.current_network_utilisation}</td>
                        {key.current_network_cost===message.CostHigh ?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostHighColor}} className="sizingeHelathColoredTD">$</div></div></td>
                        : key.current_network_cost===message.CostLow ? <td className="textfieldSizing" style={{ color:"#393939",}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostLowColor,color:"#393939"}} className="sizingeHelathColoredTD">$$</div></div></td>
                        :<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostOKColor}} className="sizingeHelathColoredTD">$$$</div></div></td>}
          <td className="textfieldSizing">{key.current_network_health}</td>
            {key.current_network_health===message.HealthGood?<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthGoodColor}} className="sizingeHelathColoredTD">{Red}</div></div></td>
            :key.current_network_health===message.HealthFair?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthFairColor}} className="sizingeHelathColoredTD">{Yellow}</div></div></td>
            :<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthPoorColor}} className="sizingeHelathColoredTD">{Green}</div></div></td>}
                      </tr>
                    );
                  }
                  )}
                  {this.state.entityType !== "db" ?  data.osdisk.map((key, i) => {
                    return (
                      (i === 0) ? (
                        <tr>
                          <td rowSpan={data.osdisk.length + Object.keys(data.dataDisk).length} ><div className="OsClass"></div><Col className="tableLeftHead">{this.state.isAsp ?  "STORAGE" : "DISK(s)"}</Col></td>
                          <td className="textfieldSizing"> {key.current_os_disk_utilisation}</td>
                          { key.current_os_disk_cost===message.CostHigh ?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostHighColor}}  className="sizingeHelathColoredTD">$</div></div></td>
                          :  key.current_os_disk_cost===message.CostLow ? <td className="textfieldSizing" style={{color:"#393939"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostLowColor,color:"#393939"}} className="sizingeHelathColoredTD">$$</div></div></td>
                        :<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostOKColor}} className="sizingeHelathColoredTD">$$$</div></div></td>}
           <td className="textfieldSizing">{key.current_os_disk_health}</td>
            {key.current_os_disk_health===message.HealthGood?<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthGoodColor}}  className="sizingeHelathColoredTD">{Red}</div></div></td>
            :key.current_os_disk_health===message.HealthFair?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthFairColor}} className="sizingeHelathColoredTD">{Yellow}</div></div></td>
            :<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthPoorColor}} className="sizingeHelathColoredTD">{Green}</div></div></td>}
                        </tr>
                      ) : ("")
                    );
                  }
                  ) : null}
                  {this.state.entityType !== "db" ?  Object.keys(data.dataDisk).map((key) => {
                    return data.dataDisk[key].map((value, i) => {
                      return (
                        (i === 0) ? (
                          <tr className="Rowclass">
                             <td className="textfieldSizing">{value.current_data_disk_utilisation}</td>
                  { value.current_data_disk_cost===message.CostHigh ?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostHighColor}} className="sizingeHelathColoredTD">$</div></div></td>
                        :value.current_data_disk_cost===message.CostLow ? <td className="textfieldSizing" style={{color:"#393939"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostLowColor,color:"#393939"}} className="sizingeHelathColoredTD">$$</div></div></td>
                        :<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.CostOKColor}} className="sizingeHelathColoredTD">$$$</div></div></td>}
               <td className="textfieldSizing">{value.current_data_disk_health}</td>
                {value.current_data_disk_health===message.HealthGood?<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthGoodColor}} className="sizingeHelathColoredTD">{Red}</div></div></td>
                :value.current_data_disk_health===message.HealthFair?<td className="textfieldSizing" style={{}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthFairColor}} className="sizingeHelathColoredTD">{Yellow}</div></div></td>
                :<td className="textfieldSizing" style={{color:"white"}}><div className="sizingHealthColoredTDCenteredDiv"><div style={{backgroundColor:message.HealthPoorColor}} className="sizingeHelathColoredTD">{Green}</div></div></td>}
                          </tr>
                        ) : ("")
                      );
                    }
                    );
                  }
                  ) : null}
                </tbody>
            </Table>            
          </Row>
        </div>
      </div>
    );
  }
}
export default SizingHealthAnalysisTable;