// THUNK
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data : {LOGO:'',CompanyName:''}
}

// SLICE
export const companyDetailsSlice =  createSlice({
    name : 'companyDetails',
    initialState,
    reducers : {
        setCompanyDetails : (state, action) => {
            state.data = action.payload
        }
    }
})

// ACTION
export const {reset,setCompanyDetails} = companyDetailsSlice.actions

// REDUCER
export default companyDetailsSlice.reducer

