import { createSlice } from '@reduxjs/toolkit'
// THUNK

const initialState = {
  payload: null
}

// SLICE
export const dataSourcePayloadSlice = createSlice({
  name: 'dataSourcePayload',
  initialState,
  reducers: {
    setDataSourcePayload: (state, action) => {
      state.payload = action.payload
    },
    reset: () => initialState
  }
})

// ACTION
export const { reset, setDataSourcePayload } = dataSourcePayloadSlice.actions

// REDUCER
export default dataSourcePayloadSlice.reducer