/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Label, Input, Tooltip } from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";

class VMRecommendationPreferences extends Component {
  constructor(props) {
    super(props);
    this.keyPress = this.keyPress.bind(this);
    this.handleRecommendationPreferenceRequest =
      this.handleRecommendationPreferenceRequest.bind(this);
    this.savePref = this.savePref.bind(this);
    this.toggleTooltips = this.toggleTooltips.bind(this);
    this.state = {
      errorPopup: false,
      modalLoader: false,
      isErrorHeader: true,
      errormessage: "",
      isRedirect: false,
      recommendationPreference: false,
      tooltipOpen: false,
    };
  }

  componentDidMount() {
    this.handleRecommendationPreferenceRequest();
  }

  handleRecommendationPreferenceRequest = () => {
    this.setState({ modalLoader: true });
    fetch(`${Constdata.SET_VM_RECOMMENDATION_PREFERENCES}/null`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responseJson", responseJson);
        if (responseJson.success === true) {
          this.setState({
            recommendationPreference: responseJson.data,
            modalLoader: false,
          });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };
  keyPress(e) {
    if (e.keyCode >= 65 && e.keyCode <= 90) {
      e.preventDefault();
    }
  }

  async savePref() {
    this.setState({ modalLoader: true });
    fetch(
      `${Constdata.SET_VM_RECOMMENDATION_PREFERENCES}/${this.state.recommendationPreference}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ modalLoader: false });
        this.props.handleRecommendationPreference(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleRecommendationPreference = () => {
    this.setState({
      recommendationPreference: !this.state.recommendationPreference,
    });
  };

  toggleTooltips = () =>
    this.setState({ tooltipOpen: !this.state.tooltipOpen });

  render() {
    return (
      <div>
        <div
          className="container-verf container-verf-margin pageBgWhite"
          style={{ borderRadius: "10px" }}
          role="main"
        >
          <div className="frm-container" style={{ textAlign: "center" }}>
            <Input
              class="form-check-input"
              onClick={(e) => this.handleRecommendationPreference(e)}
              checked={this.state.recommendationPreference}
              type="checkbox"
              id="rp"
              name="recommendationPreferences"
              value={this.state.recommendationPreference}
            />
            <Label for="rp" style={{ paddingLeft: "5px" }}>
              {this.props.providerName === "AZURE"
                ? "Recommended VM Size needs to support the same VM generation as the Analyzed VM Size"
                : "Recommended Instance Type should  be of the same generation (older or current) as the Analyzed Instance Type"}
              <i
                id="Tooltip"
                style={{ marginLeft: "10px" }}
                className="fa fa-info-circle"
                aria-hidden="true"
              />
              <Tooltip
                placement="bottom"
                isOpen={this.state.tooltipOpen}
                target={"Tooltip"}
                toggle={this.toggleTooltips}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black",
                  textAlign: "left",
                }}
              >
                <div style={{ lineHeight: 1.1 }}>
                  {this.props.providerName === "AZURE"
                    ? message.RecommendationPreferenceMsg
                    : message.RecommendationPreferenceMsgAWS}
                </div>
              </Tooltip>
            </Label>
            <div
              class="btn-toolbar pageBgWhite"
              role="toolbar"
              aria-label="Toolbar with button groups"
              style={{
                display: "flex",
                padding: "20px",
                marginTop: "20px",
                justifyContent: "flex-end",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <div class="mr-2" role="group" aria-label="Third group">
                <Button id="confirmbtn_list" onClick={this.savePref}>
                  Save
                </Button>
              </div>
              <div role="group" aria-label="First group">
                <Button
                  className="btn-cancel btn btn-light"
                  onClick={this.props.toggle}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      </div>
    );
  }
}
export default withRouter(VMRecommendationPreferences);
