import React, { Component } from 'react';
class ValidationSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }
    render() {
        const {data, bvmState} = this.props;
        let valCpuUtil = bvmState !== "VALIDATING" ? data.validationVM.sim_cpu_utils_result === "N/A" ? "na" : data.validationVM.sim_cpu_utils_result === "*" ? "star" : data.validationVM.sim_cpu_utils_result === null ? "skip" : data.validationVM.sim_cpu_utils_result.toLowerCase() : "";
        let valCpuHealth = bvmState !== "VALIDATING" ?  data.validationVM.sim_cpu_health_result === "N/A" ? "na" : data.validationVM.sim_cpu_health_result === "*" ? "star" : data.validationVM.sim_cpu_health_result === null ? "skip" : data.validationVM.sim_cpu_health_result.toLowerCase() : "";
        let valMemUtil = bvmState !== "VALIDATING" ?  data.validationVM.sim_memory_utils_result === "N/A" ? "na" : data.validationVM.sim_memory_utils_result === "*" ? "star" : data.validationVM.sim_memory_utils_result === null ? "skip" : data.validationVM.sim_memory_utils_result.toLowerCase() : "";
        let valMemHealth = bvmState !== "VALIDATING" ?  data.validationVM.sim_cpu_health_result === "N/A" ? "na" : data.validationVM.sim_memory_health_result === "*" ? "star" : data.validationVM.sim_memory_health_result === null ? "skip" : data.validationVM.sim_memory_health_result.toLowerCase() : "";
        let valNetUtil = bvmState !== "VALIDATING" ?  data.validationVM.sim_cpu_utils_result === "N/A" ? "na" : data.validationVM.sim_network_utils_result === "*" ? "star" : data.validationVM.sim_network_utils_result === null ? "skip" : data.validationVM.sim_network_utils_result.toLowerCase() : "";
        let valNetHealth = bvmState !== "VALIDATING" ?  data.validationVM.sim_cpu_health_result === "N/A" ? "na" : data.validationVM.sim_network_health_result === "*" ? "star" : data.validationVM.sim_network_health_result === null ? "skip" : data.validationVM.sim_network_health_result.toLowerCase() : "";

        let osDiskValUtil = bvmState !== "VALIDATING" ?  data.validationOSDisk.sim_osdisk_utils_result === "N/A" ? "na" : data.validationOSDisk.sim_osdisk_utils_result === "*" ? "star" : data.validationOSDisk.sim_osdisk_utils_result === null ? "skip" : data.validationOSDisk.sim_osdisk_utils_result.toLowerCase() : "";
        let osDiskValHealth = bvmState !== "VALIDATING" ?  data.validationOSDisk.sim_osdisk_health_result === "N/A" ? "na" : data.validationOSDisk.sim_osdisk_health_result === "*" ? "star" : data.validationOSDisk.sim_osdisk_health_result === null ? "skip" : data.validationOSDisk.sim_osdisk_health_result.toLowerCase() : "";

        return (
                <div className="table-wrapper">
                    <div className="validation-table-container">
                        <div className="head-titles">
                            <div className="title-col-1"></div>
                            <div className="title-col-2"></div>
                            <div className="title-col-3">Cost</div>
                            <div className="title-col-4">Performance</div>
                        </div>
                        <div className="info-cell">
                            <div className="info-cell-1">
                                <div className="info-graphic-empty">
                                    <svg id="cupIconValidation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68">
                                        <g id="cpuIconValidation">
                                            <path className="cpuIconPart2" d="M46.76,57.94V53.35H43.14v4.59H42.3V53.35H38.68v4.59h-.84V53.35H34.22v4.59h-.83V53.35H29.76v4.59h-.83V53.35H25.31v4.59h-.84V53.35H20.85v4.59H20V53.35H18a3.17,3.17,0,0,1-3.17-3.17v-2H10.24v-.84h4.59V43.71H10.24v-.83h4.59V39.25H10.24v-.83h4.59V34.79H10.24V34h4.59V30.34H10.24V29.5h4.59V25.88H10.24V25h4.59V21.42H10.24v-.83h4.59v-2A3.17,3.17,0,0,1,18,15.4h2V10.81h.84V15.4h3.62V10.81h.84V15.4h3.62V10.81h.83V15.4h3.63V10.81h.83V15.4H37.8V10.81h.84V15.4h3.62V10.81h.84V15.4h3.62V10.81h.83V15.4h2.06a3.18,3.18,0,0,1,3.17,3.17v2h4.59v.83H52.78V25h4.59v.84H52.78V29.5h4.59v.84H52.78V34h4.59v.83H52.78v3.63h4.59v.83H52.78v3.63h4.59v.83H52.78v3.62h4.59v.84H52.78v2a3.18,3.18,0,0,1-3.17,3.17h-2v4.59ZM24,21.78a2.79,2.79,0,0,0-2.78,2.78V44.19A2.79,2.79,0,0,0,24,47H43.62a2.79,2.79,0,0,0,2.78-2.78V24.56a2.79,2.79,0,0,0-2.78-2.78Z"/>
                                            <path className="cpuIconPart1" d="M49.61,15.9a2.68,2.68,0,0,1,2.67,2.67V50.18a2.68,2.68,0,0,1-2.67,2.67H18a2.68,2.68,0,0,1-2.67-2.67V18.57A2.68,2.68,0,0,1,18,15.9H49.61M24,47.47H43.62a3.28,3.28,0,0,0,3.28-3.28V24.56a3.28,3.28,0,0,0-3.28-3.28H24a3.28,3.28,0,0,0-3.28,3.28V44.19A3.28,3.28,0,0,0,24,47.47M48.05,10.31H46.22V14.9H43.6V10.31H41.76V14.9H39.14V10.31H37.3V14.9H34.68V10.31H32.85V14.9H30.22V10.31H28.39V14.9H25.77V10.31H23.93V14.9H21.31V10.31H19.47V14.9H18a3.67,3.67,0,0,0-3.67,3.67v1.52H9.74v1.83h4.59v2.62H9.74v1.84h4.59V29H9.74v1.84h4.59v2.62H9.74v1.83h4.59v2.63H9.74v1.83h4.59v2.62H9.74v1.84h4.59v2.62H9.74v1.84h4.59v1.51A3.67,3.67,0,0,0,18,53.85h1.51v4.59h1.84V53.85H24v4.59h1.84V53.85h2.62v4.59h1.83V53.85h2.63v4.59h1.83V53.85h2.62v4.59h1.84V53.85H41.8v4.59h1.84V53.85h2.62v4.59h1.83V53.85h1.52a3.67,3.67,0,0,0,3.67-3.67V48.67h4.59V46.83H53.28V44.21h4.59V42.37H53.28V39.75h4.59V37.92H53.28V35.29h4.59V33.46H53.28V30.84h4.59V29H53.28V26.38h4.59V24.54H53.28V21.92h4.59V20.09H53.28V18.57a3.68,3.68,0,0,0-3.67-3.67H48.05V10.31ZM24,46.47a2.28,2.28,0,0,1-2.28-2.28V24.56A2.28,2.28,0,0,1,24,22.28H43.62a2.28,2.28,0,0,1,2.28,2.28V44.19a2.28,2.28,0,0,1-2.28,2.28Z"/>
                                        </g>
                                        <rect className="bondingBox" width="68" height="68"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="info-cell-2 text-style">CPU</div>
                            <div className="info-cell-3">
                                <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + valCpuUtil + "-tick"}></div>
                            </div>
                            <div className="info-cell-4">
                                <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + valCpuHealth + "-tick"}></div>
                            </div>
                        </div>
                        <div className="info-cell">
                            <div className="info-cell-1">
                                <div className="info-graphic-empty">
                                    <svg id="memoryIconAnalysis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 68">
                                        <rect className="bondingBox" width="68" height="68"/>
                                        <path className="memoryIcon" d="M62.5,9.9h-7a2.9,2.9,0,0,1-5.7,0H21.2a2.8,2.8,0,1,1-5.6,0H9.5a2,2,0,0,0-1.9,1.9V47a1.9,1.9,0,0,0,1.9,1.9H12v9.2h2v-7h2.6v7h2.6v-7h2.5v7h2.6v-7h2.6v7h2.5v-7H32v7h2.6v-7h2.5v7h2.6v-7h2.6v7h2.5v-7h2.6v7H50v-7h2.5v7h2.6v-7h2.6v7H60V48.9h2.5A1.9,1.9,0,0,0,64.4,47V11.8A2,2,0,0,0,62.5,9.9Zm-35.8,33a1,1,0,0,1-1,1.1H13.1a1,1,0,0,1-1-1.1V24.7a1,1,0,0,1,1-1.1H25.7a1,1,0,0,1,1,1.1Zm16.6,0a1,1,0,0,1-1,1.1H29.7a1,1,0,0,1-1-1.1V24.7a1,1,0,0,1,1-1.1H42.3a1,1,0,0,1,1,1.1Zm16.6,0a1,1,0,0,1-1,1.1H46.3a1,1,0,0,1-1-1.1V24.7a1,1,0,0,1,1-1.1H58.9a1,1,0,0,1,1,1.1Z"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="info-cell-2 text-style">MEMORY</div>
                            <div className="info-cell-3">
                                <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + valMemUtil + "-tick"}></div>
                            </div>
                            <div className="info-cell-4">
                                <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + valMemHealth + "-tick"}></div>
                            </div>
                        </div>
                        <div className="info-cell">
                            <div className="info-cell-1">
                                <div className="info-graphic-empty">
                                    <svg id="networkArrowIconRecom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68">
                                        <path className="networkIcon" d="M58.9,41H54.6V33.5a1.8,1.8,0,0,0-2-1.7H35.9V27h4.3a1.9,1.9,0,0,0,1.9-1.6V12.1A1.9,1.9,0,0,0,40.6,10H27.1a2,2,0,0,0-1.2,1.8V25h0a1.9,1.9,0,0,0,1.8,2h4.4v4.7H15.3a1.9,1.9,0,0,0-1.9,1.7c0,.1-.1.3,0,.5V41H9.2c-1.1,0-2.1.5-2.1,1.6V56.4a.3.3,0,0,0,.1.2A1.7,1.7,0,0,0,9,58H21.5a1.9,1.9,0,0,0,1.9-1.8v-13a3,3,0,0,0-.2-1A1.7,1.7,0,0,0,21.4,41H17.1V35.7h15V41H27.6a1.9,1.9,0,0,0-1.7,2.1V56.2a1.9,1.9,0,0,0,2,1.8H40.4a1.9,1.9,0,0,0,1.7-2.1V42.8a1.8,1.8,0,0,0-2-1.8H35.9V35.7h15v5a.3.3,0,0,1-.3.3H46.5a2,2,0,0,0-1.9,2.1c.1,4.3.1,8.5,0,12.8v.2a2.2,2.2,0,0,0,2.1,2H58.9a1.9,1.9,0,0,0,2-1.6V42.8A2,2,0,0,0,58.9,41ZM18.7,46.5v6H11.8v-6Zm18.7,0v6H30.6v-6ZM30.6,21.6v-6h6.8v6ZM55.9,52.5H49.1v-6h6.8Z"/>
                                        <rect className="bondingBox" width="68" height="68"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="info-cell-2 text-style">NETWORK</div>
                            <div className="info-cell-3">
                                <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + valNetUtil + "-tick"}></div>
                            </div>
                            <div className="info-cell-4">
                                <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + valNetHealth + "-tick"}></div>
                            </div>
                        </div>
                        <div className="info-cell">
                            <div className="info-cell-1">
                                <div className="info-graphic-empty">
                                    <div className="disk-validation-svg">
                                        <svg id="osDiskIconRecom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 68">
                                            <defs> </defs>
                                            <path className="osDiskIcon" d="M38,58.29H1.34A1.48,1.48,0,0,1,.41,58,1.47,1.47,0,0,1,0,57.09c0-5.55,0-45.8,0-45.8,0-.87.34-1.2,1.22-1.2H38ZM27.84,39.87A14.23,14.23,0,1,0,19,43a1.42,1.42,0,0,0-.07-.22c-.75-1.62-1.52-3.23-2.23-4.86a3.18,3.18,0,0,1-.26-1.52A3.47,3.47,0,0,1,19,33.51a2.58,2.58,0,0,1,2.88.57c.88.88,4.81,4.73,6,5.8Zm-2.13,12a7.85,7.85,0,0,0,.77-.38c1.56-1.05,4.93-3.39,5-3.52a.49.49,0,0,0,.06-.7L20.39,35.75a.7.7,0,0,0-.62-.18,4.64,4.64,0,0,0-1.06.7.58.58,0,0,0-.06.74c1,2.17,5.43,11.76,6.63,14.37A3.13,3.13,0,0,0,25.71,51.9ZM9,52.72a2.88,2.88,0,0,0-2.82-2.94H6.1a2.89,2.89,0,0,0,0,5.77A2.88,2.88,0,0,0,9,52.71Z" transform="translate(0.5)"/>
                                            <circle className="diskCenter" cx="19.5" cy="28.74" r="3.08"/>
                                            <rect className="bondingBox" x="0.5" width="38" height="68"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="info-cell-2 text-style">OS DISK</div>
                            <div className="info-cell-3">
                                <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + osDiskValUtil + "-tick"}></div>
                            </div>
                            <div className="info-cell-4">
                                <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + osDiskValHealth + "-tick"}></div>
                            </div>
                        </div>
                        {data.validationDataDisks !== null ?
                                    Object.keys(data.validationDataDisks).map((key, i) => {
                                let dtDiskValUtil = data.validationDataDisks[i].sim_datadisk_utils_result === "N/A" ? "na" : data.validationDataDisks[i].sim_datadisk_utils_result === "*" ? "star" : data.validationDataDisks[i].sim_datadisk_utils_result === null ? "skip" : data.validationDataDisks[i].sim_datadisk_utils_result.toLowerCase();
                                let dtDiskValHealth = data.validationDataDisks[i].sim_datadisk_health_result === "N/A" ? "na" : data.validationDataDisks[i].sim_datadisk_health_result === "*" ? "star" : data.validationDataDisks[i].sim_datadisk_health_result === null ? "skip" : data.validationDataDisks[i].sim_datadisk_health_result.toLowerCase();
                                return  <div className="info-cell">
                                    <div className="info-cell-1">
                                        <div className="info-graphic-empty">
                                            <div className="disk-validation-svg">
                                                <svg id="osDiskIconRecom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 68">
                                                    <defs> </defs>
                                                    <path className="osDiskIcon" d="M38,58.29H1.34A1.48,1.48,0,0,1,.41,58,1.47,1.47,0,0,1,0,57.09c0-5.55,0-45.8,0-45.8,0-.87.34-1.2,1.22-1.2H38ZM27.84,39.87A14.23,14.23,0,1,0,19,43a1.42,1.42,0,0,0-.07-.22c-.75-1.62-1.52-3.23-2.23-4.86a3.18,3.18,0,0,1-.26-1.52A3.47,3.47,0,0,1,19,33.51a2.58,2.58,0,0,1,2.88.57c.88.88,4.81,4.73,6,5.8Zm-2.13,12a7.85,7.85,0,0,0,.77-.38c1.56-1.05,4.93-3.39,5-3.52a.49.49,0,0,0,.06-.7L20.39,35.75a.7.7,0,0,0-.62-.18,4.64,4.64,0,0,0-1.06.7.58.58,0,0,0-.06.74c1,2.17,5.43,11.76,6.63,14.37A3.13,3.13,0,0,0,25.71,51.9ZM9,52.72a2.88,2.88,0,0,0-2.82-2.94H6.1a2.89,2.89,0,0,0,0,5.77A2.88,2.88,0,0,0,9,52.71Z" transform="translate(0.5)"/>
                                                    <circle className="diskCenter" cx="19.5" cy="28.74" r="3.08"/>
                                                    <rect className="bondingBox" x="0.5" width="38" height="68"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-cell-2 text-style">DISK {i}</div>
                                    <div className="info-cell-3">
                                        <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + dtDiskValUtil + "-tick"}></div>
                                    </div>
                                    <div className="info-cell-4">
                                        <div className={bvmState === "VALIDATING" ? "vali-state-icon-cont pending" : "vali-state-icon-cont " + dtDiskValHealth + "-tick"}></div>
                                    </div>
                                </div>;
                            }) : null
                        }
                    </div>
                </div>

                );
    }
}
export default ValidationSummary;
