export const getStringDate = (date) => {
  if (date) {
    let current_datetime = new Date(new Date(date).toGMTString());
    var hours = current_datetime.getHours();
    var minutes = current_datetime.getMinutes();
    var month = current_datetime.getMonth() + 1;
    var day = current_datetime.getDate();
    var seconds = current_datetime.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    var tzAbbr = "";
    var dateObject = new Date(),
      dateString = dateObject + "",
      tzAbbr =
        dateString.match(/\(([^\)]+)\)$/) ||
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
      tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
      if (tzAbbr === "CUT") {
        tzAbbr = "UTC";
      }
    }
    return (
      current_datetime.getFullYear() +
      "-" +
      month +
      "-" +
      day +
      "  " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      " " +
      tzAbbr
    );
  } else {
    return " ";
  }
};

export const priceDisplay = (price, issign = false) => {
  if (price === "-" || price === "") {
    return price;
  }
  if (Number(price) === 0) {
    return "$0.00";
  }
  var sign = "";
  if (issign) {
    sign = "-";
  }

  if (String(Number(price)).indexOf(".") !== -1) {
    return `$${sign}${Number(price).toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`;
  }
  return `$${sign}${Number(price).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`;
};
