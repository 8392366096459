import React, { useEffect, useState } from "react";
import Header from "../HeaderComponent/Header";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import moment from "moment";
import SerraError from "../CommonComponent/Error";
import { getStringDate } from "../../Constants/CommonConstant";
import classnames from "classnames";
import OrganizationDSLevelVM from "./OrganizationDSLevelVM";
import OrganizationDSLevelASP from "./OrganizationDSLevelASP";
import OrganizationDSLevelSQLDB from "./OrganizationDSLevelSQLDB";
import OrganizationDSLevelVMParking from "./OrganizationDSLevelVMParking";
import jstz from "jstz";
import OrganizationDSLevelVMCleanup from "./OrganizationDSLevelVMCleanup";

const OrganizationAnalysisHistory = (props) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "week").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [modalLoader, setModalLoader] = useState(false);
  const [analysisHistory, setAnalysisHistory] = useState([]);
  const [dsDetailsModal, setDsDetailsModal] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [isErrorHeader, setIsErrorHeader] = useState(false);
  const [errormessage, setErrormessage] = useState(false);
  const [providerName, setProviderName] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabList, setActiveTabList] = useState("11");
  const [getOrgDetails, setOrgDetails] = useState(null);

  // const [showActionBtn, setShowActionBtn] = useState(false);

  useEffect(() => {
    console.log("props", props);
    var pgAc = document.getElementById("pageRef");
    if (pgAc !== undefined && pgAc !== null) {
      var header = `<div>
            <div style="display: flex; text-align: center; font-size: 22px; color:#610058;">Organazation Analysis History</div>
        </div>`;
      document.getElementById("pageRef").innerHTML = header;
      pgAc.disabled = true;
    }
  }, []);

  useEffect(() => {
    setModalLoader(true);
    fetch(Constdata.ORGANIZATION_LIST_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          setProviderName(responseJson.orgInfo.providerName);
          setAnalysisHistory(responseJson.data);
          setModalLoader(false);
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          setModalLoader(false);
          setErrorPopup(true);
          setErrormessage(message.SessionExpiredText);
          setIsErrorHeader(false);
        } else {
          setModalLoader(false);
          setErrorPopup(true);
          setErrormessage(message.ServerError);
          setIsErrorHeader(false);
        }
      })
      .catch((error) => {
        setModalLoader(false);
        setErrorPopup(true);
        setErrormessage(message.CacheErrorText);
        setIsErrorHeader(false);
      });
  }, []);

  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);
  };

  const handleOrgDSDetails = async (org) => {
    const fromDateObj = new Date(startDate);
    const toDateObj = new Date(endDate);

    if (startDate === null) {
      setErrorPopup(true);
      setErrormessage("Select from date");
      setIsErrorHeader(true);
      return false;
    } else if (endDate === null) {
      setErrorPopup(true);
      setErrormessage("Select to date");
      setIsErrorHeader(true);
      return false;
    } else if (fromDateObj > toDateObj) {
      setErrorPopup(true);
      setErrormessage("To date must be greater than or equal to from date");
      setIsErrorHeader(true);
      return false;
    }
    setOrgDetails(org);
    setDsDetailsModal(true);
  };

  function getFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) return null;
    const match = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );
    return match ? match[1].replace(/%20/g, " ") : null;
  }

  const downloadOrgDetails = async (orgDetails) => {
    let date1 = new Date(startDate);
    let start = date1.getTime();
    let date2 = new Date(endDate);
    let end = date2.getTime();

    if (startDate === null) {
      setErrorPopup(true);
      setErrormessage("Select from date");
      setIsErrorHeader(true);
      return false;
    } else if (endDate === null) {
      setErrorPopup(true);
      setErrormessage("Select to date");
      setIsErrorHeader(true);
      return false;
    } else if (date1 > date2) {
      setErrorPopup(true);
      setErrormessage("To date must be greater than or equal to from date");
      setIsErrorHeader(true);
      return false;
    }

    setModalLoader(true);
    const timezone = jstz.determine();
    const timezoneid = timezone.name();

    let payload3 = {
      startTime: start,
      endTime: end,
      orgId: orgDetails.orgId,
      orgName: orgDetails.orgName,
      timeZone: timezoneid,
    };

    fetch(Constdata.DOWNLOAD_ANALYSIS_REPORT_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload3),
    })
      .then(async (response) => {
        const filename = getFilenameFromContentDisposition(
          response.headers.get("content-disposition")
        );
        return response.blob().then((blob) => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        setModalLoader(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };
  const toggleDSModal = () => {
    setDsDetailsModal(false);
    setActiveTab("1");
  };

  const setErrorPopupState = () => {
    setErrorPopup(false);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const fromDateObj = new Date(startDate);
  //   const toDateObj = new Date(endDate);
  //   if (startDate === null) {
  //     setErrorPopup(true);
  //     setErrormessage("Select from date");
  //     setIsErrorHeader(true);
  //   } else if (endDate === null) {
  //     setErrorPopup(true);
  //     setErrormessage("Select to date");
  //     setIsErrorHeader(true);
  //   } else if (fromDateObj > toDateObj) {
  //     setErrorPopup(true);
  //     setErrormessage("To date must be greater than or equal to from date");
  //     setIsErrorHeader(true);
  //   }
  // };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleTabList = (tab) => {
    if (activeTabList !== tab) {
      setActiveTabList(tab);
    }
  };

  var show = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var hide = { display: "none" };

  const timeZone = (selectedDate) => {
    if (selectedDate === null) {
      return "";
    }
    var tzAbbr = "";
    var dateObject = new Date(),
      dateString = dateObject + "",
      tzAbbr =
        dateString.match(/\(([^\)]+)\)$/) ||
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
      tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
    }
    return tzAbbr;
  };
  return (
    <div>
      <div
        className="pageBgWhite"
        style={{
          width: "100%",
          margin: "0px 0px 0px 0px",
          borderRadius: "10px",
          minHeight: "300px",
          textAlign: "center",
        }}
      >
        <div style={{ paddingTop: "30px" }}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", gap: "40px" }}>
              <div className="dateContainer">
                {" "}
                <Label
                  style={{ whiteSpace: "nowrap" }}
                  className="visually-hidden"
                  for="exampleEmail"
                >
                  From Date :
                </Label>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    className="form-control custom-datepicker"
                    placeholderText="Select From Date"
                    dateFormat="yyyy-MM-dd HH:mm"
                    showTimeInput
                    timeZone="UTC"
                  />
                  <span style={{ position: "relative", fontWeight: "500" }}>
                    {timeZone(startDate)}
                  </span>
                </div>
              </div>
              <div className="dateContainer">
                {" "}
                <Label
                  style={{ whiteSpace: "nowrap" }}
                  className="visually-hidden"
                  for="examplePassword"
                >
                  To Date :
                </Label>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="form-control custom-datepicker"
                    placeholderText="Select To Date"
                    dateFormat="yyyy-MM-dd HH:mm"
                    showTimeInput
                  />
                  <span style={{ position: "relative", fontWeight: "500" }}>
                    {timeZone(endDate)}
                  </span>
                </div>
              </div>
            </div>
          </Row>
          <div>
            <div
              // className="frm-container"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                // margin: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Table hover>
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th style={{ width: "5%" }}>#</th>
                    <th style={{ width: "10%" }}>ID</th>
                    <th style={{ width: "60%" }}>Organization </th>
                    <th style={{ width: "25%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {analysisHistory.length > 0 &&
                    analysisHistory.map((val, key) => (
                      <>
                        <tr key={key + 1} style={{ height: "56px" }}>
                          <td>{key + 1}</td>
                          <td>{val.orgId}</td>
                          <td>{val.orgName}</td>
                          <td>
                            {
                              <Row style={{ justifyContent: "center" }}>
                                <Button
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    width: "100px",
                                    margin: "5px",
                                  }}
                                  color="info"
                                  className="orgBtn"
                                  onClick={() => handleOrgDSDetails(val)}
                                >
                                  Details
                                </Button>
                                <Button
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    width: "100px",
                                    margin: "5px",
                                    marginLeft: "15px",
                                  }}
                                  color="warning"
                                  onClick={() => downloadOrgDetails(val)}
                                >
                                  Download
                                </Button>
                              </Row>
                            }
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>

          {modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        </div>
      </div>
      {/* </div> */}

      <Modal
        className="orgHistoryDetails"
        backdrop="static"
        size="xl"
        isOpen={dsDetailsModal}
        toggle={toggleDSModal}
      >
        <ModalHeader
          toggle={toggleDSModal}
          id="modalheader_list"
          cssModule={{ "modal-title": "modalHeader" }}
        >
          <div
            className="modalCompanyName"
            dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
          />
          <div className="modalTitle">DataSource Analysis History</div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col style={{ textAlign: "center" }}>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                Details Of {getOrgDetails && getOrgDetails.orgName}
              </div>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  padding: "4px",
                }}
              >
                From {getStringDate(startDate)} To {getStringDate(endDate)}
              </div>
            </Col>
          </Row>

          <Row>
            <div
              className="rowContainer pageBgWhite"
              style={{
                width: "100%",
                margin: "0px 0px 0px 0px",
                borderRadius: "10px",
                marginTop: "15px",
              }}
            >
              <div
                className="container1 boxShadow"
                style={{ paddingBottom: "30px" }}
              >
                <Nav
                  tabs
                  className="navClass"
                  id="optTbl"
                  style={{ marginBottom: "20px" }}
                >
                  <NavItem
                    style={{
                      width: "33.33%",
                    }}
                  >
                    <NavLink
                      style={show}
                      className={classnames({
                        active: activeTabList === "11",
                      })}
                      onClick={() => {
                        toggleTabList("11");
                      }}
                    >
                      <div
                        style={{
                          width: providerName === "AZURE" ? "92%" : "97%",
                        }}
                      >
                        Rightsizing
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    style={{
                      width: "33.33%",
                    }}
                  >
                    <NavLink
                      style={show}
                      className={classnames({
                        active: activeTabList === "12",
                      })}
                      onClick={() => {
                        toggleTabList("12");
                      }}
                    >
                      <div
                        style={{
                          width: providerName === "AZURE" ? "92%" : "97%",
                        }}
                      >
                        Parking
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    style={{
                      width: "33.33%",
                    }}
                  >
                    <NavLink
                      style={show}
                      className={classnames({
                        active: activeTabList === "13",
                      })}
                      onClick={() => {
                        toggleTabList("13");
                      }}
                    >
                      <div
                        style={{
                          width: providerName === "AZURE" ? "92%" : "97%",
                        }}
                      >
                        Cleanup
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={activeTabList}
                  style={{ margin: "10px" }}
                >
                  {activeTabList === "11" ? (
                    <TabPane tabId="11">
                      <Nav tabs className="navClass" id="optTbl">
                        <NavItem
                          style={{
                            width: providerName === "AZURE" ? "33.33%" : "100%",
                          }}
                        >
                          <NavLink
                            style={show}
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}
                          >
                            <div
                              style={{
                                width: providerName === "AZURE" ? "92%" : "97%",
                              }}
                            >
                              VMs
                            </div>
                          </NavLink>
                        </NavItem>
                        {providerName === "AZURE" ? (
                          <>
                            <NavItem style={{ width: "33.33%" }}>
                              <NavLink
                                style={providerName === "AZURE" ? show : hide}
                                className={classnames({
                                  active: activeTab === "2",
                                })}
                                onClick={() => {
                                  toggleTab("2");
                                }}
                              >
                                <div></div>
                                <div style={{ width: "92%" }}>ASPs</div>
                              </NavLink>
                            </NavItem>
                            <NavItem style={{ width: "33.33%" }}>
                              <NavLink
                                style={providerName === "AZURE" ? show : hide}
                                className={classnames({
                                  active: activeTab === "3",
                                })}
                                onClick={() => {
                                  toggleTab("3");
                                }}
                              >
                                <div></div>
                                <div style={{ width: "92%" }}>DBaaS</div>
                              </NavLink>
                            </NavItem>
                          </>
                        ) : null}
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        style={{ margin: "10px" }}
                      >
                        {activeTab === "1" ? (
                          <TabPane tabId="1">
                            <OrganizationDSLevelVM
                              startDate={startDate}
                              endDate={endDate}
                              orgDetails={getOrgDetails}
                            />
                          </TabPane>
                        ) : activeTab === "2" ? (
                          <TabPane tabId="2">
                            <OrganizationDSLevelASP
                              startDate={startDate}
                              endDate={endDate}
                              orgDetails={getOrgDetails}
                            />
                          </TabPane>
                        ) : activeTab === "3" ? (
                          <TabPane tabId="3">
                            <OrganizationDSLevelSQLDB
                              startDate={startDate}
                              endDate={endDate}
                              orgDetails={getOrgDetails}
                            />
                          </TabPane>
                        ) : null}
                      </TabContent>
                    </TabPane>
                  ) : activeTabList === "12" ? (
                    <TabPane tabId="12">
                      <OrganizationDSLevelVMParking
                        startDate={startDate}
                        endDate={endDate}
                        orgDetails={getOrgDetails}
                      />
                    </TabPane>
                  ) : (
                    <TabPane tabId="13">
                      <OrganizationDSLevelVMCleanup
                        startDate={startDate}
                        endDate={endDate}
                        orgDetails={getOrgDetails}
                      />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>

      {errorPopup === true ? (
        <SerraError
          Errordata={errormessage}
          ErrorPopup={errorPopup}
          setErrorMethod={() => setErrorPopupState()}
          isError={isErrorHeader}
        ></SerraError>
      ) : (
        ""
      )}
    </div>
  );
};

export default OrganizationAnalysisHistory;
