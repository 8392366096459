import React, { Component } from 'react';
import { Input, Button, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import * as Constdata from '../../Constants/ApiConstants';
import SerraLoader from '../CommonComponent/Loader';
import SerraError from '../CommonComponent/Error';
import * as message from '../../Constants/MessageConstant';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import {reset, setDataSourcePayload} from '../../reducers/dataSourcePayloadSlice'

class AccountCreationPPU extends Component {
  constructor(props) {
    super(props);
    this.handleSumbit = this.handleSumbit.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.toggle = this.toggle.bind(this);
    this.IsDisabled=this.IsDisabled.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.testConfig = this.testConfig.bind(this);
    this.setErrorPopupState= this.setErrorPopupState.bind(this);
    this.toggleShow=this.toggleShow.bind(this);
    this.handleChange=this.handleChange.bind(this);

    this.state = {
      orgId: '',
      fields:this.props.AccountEdit === true ? this.props.data:{},
      errors: {},
      modal: false,
      provider: '',
      modalLoader:false,
      errormessage:'',
      errorPopup:false,
      hidden:true,
      isRedirect:false,
      modalAccntAddedPopup:false,
      loader:false,
    };
  }
  componentDidMount(){
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Account Creation" });
  
  }
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!fields["tenantId"]) {
      formIsValid = false;
      errors["tenantId"] = "Tenant Id cannot be empty";
    }
    //clientSecret
    if (!fields["clientSecret"]) {
      formIsValid = false;
      errors["clientSecret"] = "Client Secret cannot be empty";
    }
    //ClientId
    if (!fields["clientId"]) {
      formIsValid = false;
      errors["clientId"] = "Client Id cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleStoreSecret(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.checked;
    this.setState({ fields });
  }
  handleSumbit = (e) => {

    e.preventDefault();
    const { accountname, tenantId, clientSecret, clientId,id, isCPOEnabled } = this.state.fields;
    const payload = {
      accountname : accountname,
      tenantId: tenantId,
      clientSecret: clientSecret,
      clientId: clientId,
      storeSecret : true,
      isEdit:this.props.AccountEdit,
      id:id,
      isCPOEnabled: isCPOEnabled
    };
    var resPayload =this.props.dataSourcePayload;


    if(!this.props.AccountEdit){
      
      if(this.props.dataRes){
        if(this.props.dataRes.some((val)=>val.tenantId == tenantId)){
          this.setState({errormessage:'Azure configuration with provided Application (client) ID is already added',errorPopup:true,setErrorPopupState:''})
          return false
        }
        if(this.props.dataRes.some((val)=>val.accountname == accountname)){
          this.setState({errormessage:'Name '+accountname+' is already taken, Please provide different name',errorPopup:true,setErrorPopupState:''})
          return false
        }
      }

      if(resPayload &&  resPayload.some((val)=>val.tenantId == tenantId)){
        this.setState({errormessage:'Azure credential is in progress',errorPopup:true,setErrorPopupState:''})
        return false
      }else{
        if(resPayload){
          this.props.setDataSourcePayload([...this.props.dataSourcePayload,{...payload,status:'FETCHING',id:tenantId}])
        }else{
          this.props.setDataSourcePayload([{...payload,status:'FETCHING',id:tenantId}])
        }
      }
      this.props.cancelAccount();
    }else{
      if(resPayload){
        this.props.setDataSourcePayload([...this.props.dataSourcePayload,{...payload,status:'FETCHING',id:tenantId}])
      }else{
        this.props.setDataSourcePayload([{...payload,status:'FETCHING',id:tenantId}])
      }
      this.props.updateStatusOnSubmit(tenantId,'AZURE')
    }
     this.setState({ loader:true  });
   
    if (!this.handleValidation()) {
      return;
    }
    fetch(Constdata.ADDUPDATE_AZURE_CONFIG_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((responseJson) => {
        var newArr = this.props.dataSourcePayload.filter((x) => x.tenantId != tenantId)
        this.props.setDataSourcePayload(newArr)
        this.setState({ loader: false });
        this.processResponse(responseJson);
        this.props.addDataSourcesAzureResponse(responseJson,'')
      })
      .catch((error) => {
        console.error(error);
      });
  }

  processResponse(response) {
    
    if (response.success === true) {
      this.setState(function () {
        return ({ orgId: response.data.orgId ,configId: response.data.configId});
      });
      this.props.handleFetch();
    //  this.props.cancelAccount();      
    }else{
      this.props.handleFetch();
    }
 
 }
  testConfig = () => {

    const { accountname, tenantId, clientSecret, clientId,id } = this.state.fields;
    const payload = {
      accountname : accountname,
      tenantId: tenantId,
      clientSecret: clientSecret,
      clientId: clientId,
      storeSecret : true,
      isEdit:this.props.AccountEdit,
      id:id
    };

    // var resPayload =this.props.dataSourcePayload;
    // if(resPayload && (resPayload.clientId === clientId || resPayload.tenantId === tenantId || resPayload.clientSecret === clientSecret)){
    //   this.setState({errormessage:'Azure test config credentials in progress',errorPopup:true,setErrorPopupState:''})
    //   return false
    // }else{
    //   this.props.setDataSourcePayload(payload)
    // }

        this.setState({ loader: true });
   
    if (!this.handleValidation()) {
      return;
    }
    fetch(Constdata.TEST_AZURE_CONFIG_URL, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((responseJson) => {
        // this.props.resetDataSourcePayload()
        this.setState({loader:false})
        this.props.addDataSourcesAzureResponse(responseJson,'testConfig')
      })
      .catch((error) => {
        console.error(error);
        this.setState({ modalLoader: false, errorPopup: true, errormessage:message.CacheErrorText, isErrorHeader:false});
      });
  }
 
  IsDisabled =()=>{
    const { tenantId, clientSecret, clientId, accountname} = this.state.fields;
    return !tenantId || !clientSecret || !clientId || !accountname ||this.state.modalLoader || this.state.loader;
  }
  setErrorPopupState = (event) =>{
    this.setState({errorPopup: event});
    if(this.state.isRedirect){
     
    this.props.history.push('/');
    }
  }

  render() {
    return (
      <div>
        <div className="container-verf" style={{width:"100%"}} role="main">
            <div className="modalTitleDS">
              {this.props.AccountEdit === true ? "Update Azure Metrics Data Source" :  "Add Azure Metrics Data Source"}
            </div>
          <div className="account_container" style={{position:'relative',marginTop: "20px"}}>
            <form >
                <div className="form-group">
                <label htmlFor="accountname">Azure Metrics Data Source Name:</label>
                <Input placeholder="Provide Name for this Azure Data Source" type="text" id="accountname" name="accountname" value={this.state.fields["accountname"]} onChange={this.handleChange.bind(this, "accountname")} disabled={this.props.AccountEdit}/>
                <span style={{ color: "red" }}>{this.state.errors["accountname"]}</span>
              </div>
              <div className="form-group">
                <label htmlFor="tenantId">Tenant (Active Directory) ID:</label>
                <Input placeholder="Tenant (Active Directory) ID" type="text" id="tenantId" name="tenantId" value={this.state.fields["tenantId"]} onChange={this.handleChange.bind(this, "tenantId")} disabled={this.props.AccountEdit}/>
                <span style={{ color: "red" }}>{this.state.errors["tenantId"]}</span>
              </div>
              <div className="form-group">
                <label htmlFor="clientId">Application (client) ID: <span>&nbsp;</span></label> 
                <Input placeholder="Application (client) ID" type="text" id="clientId" name="clientId" value={this.state.fields["clientId"]} onChange={this.handleChange.bind(this, "clientId")} />
                <span style={{ color: "red" }}>{this.state.errors["clientId"]}</span>
                <Input type="hidden" id="isCPOEnabled" name="isCPOEnabled" value={this.state.fields["isCPOEnabled"]} />
              </div>
              <div className="form-group">
                <label htmlFor="clientSecret">Application (client) Secret:</label>
                <InputGroup>
                <Input placeholder="Application (client) Secret" type={this.state.hidden ? "password" : "text"} id="clientSecret" name="clientSecret" value={this.state.fields["clientSecret"]} onChange={this.handleChange.bind(this, "clientSecret")} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-eye-slash" onClick={this.toggleShow}></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                <span style={{ color: "red" }}>{this.state.errors["clientSecret"]}</span>
              </div>
             
{ /*             <div className="checkbox">                
                <Input type="checkbox" id="storeSecret" name="storeSecret" defaultChecked={this.state.fields["storeSecret"]}  value={this.state.fields["storeSecret"]} onChange={this.handleStoreSecret.bind(this, "storeSecret")} />
                <label htmlFor="storeSecret"><span>&nbsp;&nbsp;</span>Store Credentials</label> 
                <span style={{ color: "red" }}>{this.state.errors["storeSecret"]}</span>
              </div> */}
              <div className="prim_But1">
                <Button id={(this.IsDisabled())? "btn_disabled":"btnaccount_test"} disabled={this.IsDisabled()} onClick={(() => this.testConfig())} >Test</Button>
              </div>
              <div className="prim_But" >
                <Button id={(this.IsDisabled())? "btn_disabled":"btnaccount_save"}  type="submit" disabled={this.IsDisabled()} onClick={this.handleSumbit}>{this.props.AccountEdit === true ? "Update" :  "Add"}
                          </Button>
               <Button id={this.state.loader?"btncancel_disabled":"btnaccount_cancel"} onClick={this.props.cancelAccount} disabled={this.state.loader}>Cancel</Button>
              </div>
              {this.state.loader === true ? <SerraLoader loader='true'></SerraLoader> : ''}
            </form>
          </div>
          {(this.state.modalLoader === true) ? <SerraLoader></SerraLoader> :""}
          {(this.state.errorPopup === true) ?  <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState} isError={this.state.isErrorHeader}  subTitle={this.state.subTitle}></SerraError> :""}
          {/* <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
              <ModalHeader id="modalheader_list" toggle={this.toggle} cssModule={{'modal-title': 'w-100 text-center'}}><div  dangerouslySetInnerHTML={{__html: message.SerraLabsModalHeader}} /> </ModalHeader>
              <ModalBody>
                  <div  dangerouslySetInnerHTML={{__html: message.AzureClientInstructionText}}/>
                  <div  dangerouslySetInnerHTML={{__html: message.AzureVmDiagnosticList}}/>
              </ModalBody>
              <ModalFooter id="knowMoreFooter">
                  <Button id="confirmbtn_list" onClick={this.toggle}>OK</Button>
              </ModalFooter>
          </Modal> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
  return {
    dataSourcePayload : state.dataSourcePayload.payload,
    companyDetails: state.companyDetails.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      resetDataSourcePayload : () => dispatch(reset()),
      setDataSourcePayload : (data) => dispatch(setDataSourcePayload(data))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountCreationPPU);
