import React, { useEffect, useState } from "react";
import SerraLoader from "../CommonComponent/Loader";
import { Button, Row, Col } from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import { Table } from "reactstrap";
import SerraError from "../CommonComponent/Error";
import ComfirmModal from "../CommonComponent/ComfirmModal";
import { getStringDate } from "../../Constants/CommonConstant";
import jstz from "jstz";

const OrganizationList = (props) => {
  const [errorPopup, setErrorPopup] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [isErrorHeader, setIsErrorHeader] = useState(false);
  const [errormessage, setErrormessage] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [deleteComfirmation, setDeleteComfirmation] = useState(false);
  const [adminUser, setAdminUser] = useState(null);
  const [orgId, setOrgId] = useState(null);

  useEffect(() => {
    setModalLoader(true);
    fetch(Constdata.ORGANIZATION_LIST_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          setOrgList(responseJson.data);
          setModalLoader(false);
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          setModalLoader(false);
          setErrorPopup(true);
          setErrormessage(message.SessionExpiredText);
          setIsErrorHeader(false);
        } else {
          setModalLoader(false);
          setErrorPopup(true);
          setErrormessage(message.ServerError);
          setIsErrorHeader(false);
        }
      })
      .catch((error) => {
        setModalLoader(false);
        setErrorPopup(true);
        setErrormessage(message.CacheErrorText);
        setIsErrorHeader(false);
      });
  }, []);

  const setErrorPopupState = () => {
    setErrorPopup(false);
  };

  const cancleHandle = () => {
    setOrgId(null);
    setAdminUser(null);
    setDeleteComfirmation(false);
  };
  const confirmHandle = () => {
    props.deleteOrg(orgId, adminUser);
    setDeleteComfirmation(false);
  };

  const downloadOrgDetails = async (orgId) => {
    setModalLoader(true);

    const timezone = jstz.determine();
    const timezoneid = timezone.name();

    let payload = {
      orgId: orgId,
      timeZone: timezoneid,
    };

    fetch(Constdata.DOWNLOAD_STATUS_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        const filename = getFilenameFromContentDisposition(
          response.headers.get("content-disposition")
        );
        return response.blob().then((blob) => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        setModalLoader(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  function getFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) return null;
    const match = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );
    return match ? match[1].replace(/%20/g, " ") : null;
  }
  return (
    <div>
      <div
        className="container-verf container-verf-margin pageBgWhite"
        style={{ borderRadius: "10px" }}
        role="main"
      >
        <div className="frm-container">
          <Row>
            <Col style={{ textAlign: "center" }}>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                Organization List
              </div>
              {props.isDelete ? null : (
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    padding: "4px",
                  }}
                >
                  As of {getStringDate(new Date())}
                </div>
              )}
            </Col>
          </Row>

          <Table style={{ marginTop: "25px" }}>
            <thead>
              <tr>
                <th style={{ width: "5%" }}>#</th>
                <th style={{ width: "10%" }}>ID</th>
                <th style={{ width: "60%" }}>Organization </th>
                <th style={{ width: "25%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {orgList.map((val, key) => (
                <tr key={key} style={{ textAlign: "center" }}>
                  <td>{key + 1}</td>
                  <td>{val.orgId}</td>
                  <td>{val.orgName}</td>
                  <td>
                    <Row style={{ justifyContent: "center" }}>
                      {props.organizationListStatusModal ? (
                        <>
                          <Button
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              width: "100px",
                              margin: "5px",
                            }}
                            color="info"
                            className="orgBtn"
                            onClick={() =>
                              props.viewOrgStatus(val.orgId, val.orgName)
                            }
                          >
                            Details
                          </Button>
                          <Button
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              width: "100px",
                              margin: "5px",
                              marginLeft: "15px",
                            }}
                            color="warning"
                            onClick={() => downloadOrgDetails(val.orgId)}
                          >
                            Download
                          </Button>
                        </>
                      ) : (
                        <div outline style={{ cursor: "pointer" }}>
                          <img
                            onClick={() => props.deleteOrg(val.orgId)}
                            src={require("../../images/Delete_Icon.svg")}
                            title="Delete"
                          />
                        </div>
                      )}
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div
            class="btn-toolbar pageBgWhite"
            role="toolbar"
            aria-label="Toolbar with button groups"
            style={{
              display: "flex",
              padding: "20px",
              marginTop: "20px",
              justifyContent: "flex-end",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <div role="group" aria-label="First group">
              <Button
                className="btn-cancel btn btn-light"
                onClick={props.toggle}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
      {errorPopup === true ? (
        <SerraError
          Errordata={errormessage}
          ErrorPopup={errorPopup}
          setErrorMethod={() => setErrorPopupState()}
          isError={isErrorHeader}
        ></SerraError>
      ) : (
        ""
      )}
      {modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      <ComfirmModal
        message="Are you sure you want to delete this organization and its users?"
        confirmHandle={confirmHandle}
        cancleHandle={cancleHandle}
        toggle={deleteComfirmation}
      />
    </div>
  );
};

export default OrganizationList;
