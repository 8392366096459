import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import * as message from '../../Constants/MessageConstant';
class Signupsuccess extends Component {
  constructor(props) {
    super(props);
    console.log("hello success");
  }
  componentDidMount(){
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Success Mail" });
  }
  render() {
    return (
      <div className="wrapper">
        <div id="accountSuccess1" className="bg-container1">&nbsp;</div>
        <div id="SignInClass">
          <div className="HeadingSuccess">{this.props.companyDetails.CompanyName}</div>
          <div className="outer-container outer-container-success">
            <div className="container">
            <div style={{textAlign:"center"}}><div><p>Your email has been validated.</p> </div>
            <div><p>You can now start using {this.props.companyDetails.CompanyName+' '+this.props.companyDetails.ServiceNameFullName}.</p></div>
        <div style={{width: "25%",margin: "auto"}}><Link to={'/'}><Button type="submit" id="confirmbtn_list" size="100" block>Sign In</Button></Link></div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      companyDetails: state.companyDetails.data,
  }
}

export default connect(mapStateToProps, null)(Signupsuccess);
