//export const HOST = "http://devcpo.eastus.cloudapp.azure.com/api";

export const HOST = "/api";
export const DELETE_ACCOUNT_URL = HOST + "/deleteOrgnization";
export const LOGIN_URL = HOST + "/login";
export const SIGNUP_URL = HOST + "/registration";
export const SELECTPLAN_URL = HOST + "/selectPlan";
export const RESEND_ACTIVATION_LINK = HOST + "/resendRegistrationToken";
export const ADDUPDATE_AZURE_CONFIG_URL = HOST + "/updateConfiguration";
export const ADDUPDATE_AWS_CONFIG_URL = HOST + "/awsConfig";
export const TEST_AZURE_CONFIG_URL = HOST + "/testConfig";
export const TEST_AWS_CONFIG_URL = HOST + "/testAWSConfig";
export const VMLIST_URL = HOST + "/discovery/getVMListOfOrg";
export const STARTANALYSIS_URL = HOST + "/analysis/startAnalysis/";
export const CLEARCONFIG_URL = HOST + "/clearConfig";
export const FILEUPLOAD_URL = HOST + "/discovery/upload";
export const VMANALYSIS_URL = HOST + "/analysis/getAnalysisStatusOfVM";
export const LOGOUT_URL = HOST + "/logout";
export const ANALYSIS_OVERVIEW_URL = HOST + "/recommendations/getRecommendationForVMOfTypeAndDuration";
export const ASP_SUM_OVERVIEW_URL = HOST + "/recommendations/getASPRecommendation";
export const DB_SUM_OVERVIEW_URL = HOST + "/recommendations/getAzSQLDBRecommendation";
export const GETAZURECONFIG_URL = HOST + "/getConfig/";
export const RESETPASSWORD_URL = HOST + "/resetPassword";
export const SAVEPASSWORD_URL = HOST + "/savePassword";
export const SESSIONACTIVE_URL = HOST + "/isSessionAlive";
export const GETPDF_URL = HOST + "/recommendations/getPDF";
export const GETPDF_ASP_URL = HOST + "/recommendations/getIndividualASPPDF";
export const GETPDF_DB_URL = HOST + "/recommendations/getIndividualAzSQLDBPDF";
export const GETALLACCOUNT_URL= HOST + "/getAllConfig";
export const STOPALLACCOUNTREFRESH_URL = HOST + "/stopConfigRefresh";
export const STARTALLACCOUNTREFRESH_URL = HOST + "/startConfigRefresh";
export const DELETESINGLEACCOUNT_URL = HOST + "/deleteConfig";
export const PPUVMLIST_URL = HOST + "/discovery/getVMListOfOrgFilterBy";
export const GETREPORTDATA_URL = HOST + "/recommendations/getSummaryOfAll/";
export const GETREPORTDATA_ASP_URL = HOST + "/recommendations/getASPSummaryOfAll/";
export const GETREPORTDATA_DB_URL = HOST + "/recommendations/getAzSQLDBSummaryOfAll/";
export const GETREQUESTEDPLAN_URL = HOST + "/getRequestedPlan";
export const PPUREQUEST_URL= HOST + "/requestPPU";
export const ISPPUREQUESTED_URL= HOST + "/isPPURequested";
export const FREETRIALREQUEST_URL= HOST + "/requestFreetrial";
export const ISFREETRIALREQUESTED_URL= HOST + "/isFreeTrialRequested";
export const ISPLANSELECTED_URL= HOST + "/isplanselected";
export const PPUACCOUNTREFRESH_URL = HOST + '/refreshConfig';
export const GETDASHBOARDPDF_URL = HOST + "/dashboard/getDashboardPDF";
export const GETPDFONREPORT_URL= HOST + "/recommendations/getPDFOfPPU/";
export const GETPDFONREPORT_ASP_URL = HOST + "/recommendations/getAllASPPDF";
export const GETPDFONREPORT_PDF_URL = HOST + "/recommendations/getAllAzSQLDBPDF";
export const GETPRIVIOUSREPORT_URL = HOST + "/analysis/getLastReport";
export const GETPRESELECTEDFILTERS_URL = HOST + "/discovery/userFilters";
export const GETRECOMMENDATION_URL = HOST + "/recommendations/setUserSelectedRecommendation";
export const RESETVM_URL = HOST + "/discovery/resetAnalysis";
export const START_VALIDATION_URL = HOST + "/recommendations/startValidation";
export const USER_SELECT_RECOMMENDATION_URL = HOST + "/recommendations/setResizeVM";
export const USER_SELECT_RECOMMENDATION_ASP_URL = HOST + "/recommendations/setResizeASP";
export const USER_SELECT_RECOMMENDATION_DB_URL = HOST + "/recommendations/setResizeAzDB";
export const VM_ANALYSIS_COMPACT_DETAILD = HOST + "/recommendations/getCompactAnalysisResult";
export const ASP_ANALYSIS_COMPACT_DETAILD = HOST + "/recommendations/getCompactASPAnalysisResult";
export const DB_ANALYSIS_COMPACT_DETAILD = HOST + "/recommendations/getCompactAzDBAnalysisResult";
export const TEST_ANALYSIS = HOST + "/testAnalysis";
export const GET_ASP_LIST = HOST + "/discovery/getASPListOfOrgFilterBy";
export const START_ASP_ANALYSIS_URL = HOST + "/analysis/startASPAnalysis";
export const RESET_ASP_ANALYSIS_URL = HOST + "/discovery/resetASPAnalysis";
export const GET_DASHBOARD_DATA_URL = HOST + "/dashboard/currentStatus";
export const ENABLE_CONFIG=HOST+"/enableConfig";
export const DISABLE_CONFIG=HOST+"/disableConfig";
export const SELECT_REFRESH=HOST+"/selectedRefresh";
export const SET_METRIC_FETCH_DURATION = HOST + "/discovery/setMetricQueryPreferenceList";
export const GET_METRIC_FETCH_DURATION = HOST + "/discovery/getMetricQueryPreferenceList";
export const GET_DB_LIST = HOST + "/azsqldbanalysis/getAzSQLDBList";
export const START_DB_ANALYSIS_URL = HOST + "/azsqldbanalysis/startAzSQLDBAnalysis";
export const RESET_DB_ANALYSIS_URL = HOST + "/azsqldbanalysis/resetAzSQLDBAnalysis";
export const SET_FUNCTION_URL = HOST + "/setFunction";
export const GET_VM_PARKING_PREFERENCES = HOST + "/analysis/getVMParkingPreferences";
export const SET_VM_PARKING_PREFERENCES = HOST + "/analysis/setVMParkingPreferences";
export const CLEANUP_LIST_URL = HOST + "/analysis/cleanupStatus";
export const CLEANUP_START_ANALYSIS_URL = HOST + "/analysis/cleanupAnalysis";
export const CLEANUP_REPORT_URL = HOST + "/analysis/cleanupResult";
export const CLEANUP_RESET_URL = HOST + "/analysis/resetCleanupAnalysis";
export const CLEANUP_MANAGE_URL = HOST + "/recommendations/manageCleanup";
export const CLEANUP_PDF_REPORT_URL = HOST + "/recommendations/getCleanupPDF";
export const PARKING_VM_LIST_URL = HOST + "/discovery/getParkingVMListOfOrg";
export const PARKING_VM_START_ANALYSIS_URL = HOST + "/analysis/startVMParkingAnalysis";
export const PARKING_REPORT_URL = HOST + "/analysis/getVMParkingAnalysisResult";
export const PARKING_VM_RESET_URL = HOST + "/analysis/resetVMParkingAnalysis";
export const PARKING_PDF_REPORT_URL = HOST + "/recommendations/getVMParkingPDF";
export const PARKING_MANAGE_URL = HOST + "/recommendations/setResizeVMParking";
export const REQUEST_LOGIN_STATUS_URL = HOST + "/requestLoginStatus";
export const SET_VM_RECOMMENDATION_PREFERENCES = HOST + "/recommendations/setVMRecommendationPreference";
export const ORGANIZATION_STATUS_URL = HOST + "/admin/orgStatus";
export const ORGANIZATION_LIST_URL = HOST + "/support/orgList";
export const ORGANIZATION_DS_ANALYSIS_HISTORY_URL = HOST + "/support/getDSLevelVMAnalysisHistory";
export const ORGANIZATION_VM_ANALYSIS_HISTORY_URL = HOST + "/support/getVMAnalysisHistoryOfDatasource";
export const DOWNLOAD_ANALYSIS_REPORT_URL = HOST + "/support/downloadAnalysisReport";
export const ORGANIZATION_DS_ASP_ANALYSIS_HISTORY_URL = HOST + "/support/getDSLevelASPAnalysisHistory";
export const ORGANIZATION_ASP_ANALYSIS_HISTORY_BY_ORG_URL = HOST + "/support/getASPAnalysisHistoryOfDatasource";
export const ORGANIZATION_DS_SQLDB_ANALYSIS_HISTORY_URL = HOST + "/support/getDSLevelSQLDBAnalysisHistory";
export const ORGANIZATION_SQLDB_ANALYSIS_HISTORY_BY_ORG_URL = HOST + "/support/getSQLDBAnalysisHistoryOfDatasource";
export const ORGANIZATION_DS_PARKING_ANALYSIS_HISTORY_URL = HOST + "/support/getDSLevelVMParkingAnalysisHistory";
export const ORGANIZATION_VM_PARKING_ANALYSIS_HISTORY_URL = HOST + "/support/getVMParkingAnalysisHistoryOfDatasource";
export const ORGANIZATION_DS_CLEANUP_ANALYSIS_HISTORY_URL = HOST + "/support/getDSCleanupAnalysisHistory";
export const ORGANIZATION_VM_CLEANUP_ANALYSIS_HISTORY_URL = HOST + "/support/getCleanupAnalysisHistoryOfDataSource";
export const DOWNLOAD_STATUS_URL = HOST + "/admin/downloadOrgStatus";
