import React, { Component } from "react";
import ReactGA from "react-ga4";
import * as message from "../../Constants/MessageConstant";
class HelpAWS extends Component {
  componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Help Page" });
  }

  render() {
    return (
      <div
        class="container-verf container-verf-static-page pageBgWhite"
        style={{ padding: "15px" }}
      >
        <div align="center">
          <blockquote>
            <blockquote>
              <blockquote>
                <blockquote>
                  <blockquote>
                    <blockquote>
                      <blockquote>
                        <blockquote>
                          <blockquote>
                            <blockquote>
                              <blockquote>
                                <blockquote>
                                  <blockquote>
                                    <h1 align="center">
                                      <a
                                        name="mozTocId110131"
                                        class="mozTocH1"
                                      ></a>
                                      <font size="+3">
                                        AWS Configuration Guide
                                      </font>
                                      <br />
                                    </h1>
                                    <font size="-1">
                                      Revised 2021-06-01
                                      <br />
                                      <br />
                                    </font>
                                  </blockquote>
                                </blockquote>
                              </blockquote>
                            </blockquote>
                          </blockquote>
                        </blockquote>
                      </blockquote>
                    </blockquote>
                  </blockquote>
                </blockquote>
              </blockquote>
            </blockquote>
          </blockquote>
        </div>

        <div align="left">
          <font face="Helvetica">
            <b>Index</b>
          </font>
          <br />
          <br />
          <ul>
            <li>
              <a href="#mozTocId101">
                Setting before installing cloudwatch agent
              </a>
            </li>
            <li>
              <a href="#mozTocId102">
                Installing cloudwatch agent on Linux instance
              </a>
            </li>
            <li>
              <a href="#mozTocId103">
                Installing cloudwatch agent on Windows instance
              </a>
            </li>
            <li>
              <a href="#mozTocId104">Edit config.json</a>
            </li>
            <li>
              <a href="#mozTocId105">Config wizard questions and answers</a>
            </li>
            {/* <li><a href="#mozTocId106">Linux sample config.json</a></li>
                    <li><a href="#mozTocId107">Windows sample config.json</a></li> */}
          </ul>
        </div>

        <font face="Helvetica">
          <a name="mozTocId101"></a>
          <br />
        </font>

        <h2>
          <font face="Helvetica" size="+2">
            {" "}
            Create IAM roles and users for use with CloudWatch agent
          </font>
        </h2>

        <ol>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                In the navigation pane on the left, choose Roles and then Create
                role. (or search for roles in search box)
              </font>
            </p>
            <div>
              <img
                style={{ width: "50%", display: "initial" }}
                src={require("../../docs/AWS_Configuration_Doc/AwsDoc1.png")}
              />
            </div>
            <br />
          </li>

          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                Make sure that AWS service is selected under Trusted entity type
                and choose EC2 under Common use cases
              </font>
            </p>
            <div>
              <img
                style={{ width: "50%", display: "initial" }}
                src={require("../../docs/AWS_Configuration_Doc/AwsDoc2.png")}
              />
            </div>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                Choose Next.
              </font>
            </p>
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                In the list of policies, select the check box next to
                CloudWatchAgentServerPolicy. If necessary, use the search box to
                find the policy.And click on Next
              </font>
            </p>
            <div>
              <img
                style={{ width: "50%", display: "initial" }}
                src={require("../../docs/AWS_Configuration_Doc/AwsDoc3.png")}
              />
            </div>
            <br />
          </li>

          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                In Role name, enter a name for the role, such as
                CloudWatchAgentServerRole . Optionally give it a description.
                Then choose Create role.
              </font>
            </p>
            <div>
              <img
                style={{ width: "50%", display: "initial" }}
                src={require("../../docs/AWS_Configuration_Doc/AwsDoc4.png")}
              />
            </div>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                The role is now created.
              </font>
            </p>
          </li>
        </ol>

        <font face="Helvetica">
          <br />
        </font>

        <h2>
          <font face="Helvetica" size="+2">
            SET IAM Role to instance
          </font>
        </h2>
        <ol>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                Select instance from EC2 {`>`} Instances&nbsp;
              </font>
            </p>
            <div>
              <img
                style={{ width: "50%", display: "initial" }}
                src={require("../../docs/AWS_Configuration_Doc/AwsDoc5.png")}
              />
            </div>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                Select security and select Modify IAM role
              </font>
            </p>
            <div>
              <img
                style={{ width: "50%", display: "initial" }}
                src={require("../../docs/AWS_Configuration_Doc/AwsDoc6.png")}
              />
            </div>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                Search for CloudWatchAgentServerRole or the name given while
                creating role in dropdown and update IAM role
              </font>
            </p>
            <div>
              <img
                style={{ width: "50%", display: "initial" }}
                src={require("../../docs/AWS_Configuration_Doc/AwsDoc7.png")}
              />
            </div>
          </li>
        </ol>

        <font face="Helvetica">
          <a name="mozTocId102"></a>
          <br />
        </font>
        <h2>
          <font face="Helvetica" size="+2">
            Install agent on Linux instance
          </font>
        </h2>
        <h5>
          <font face="Helvetica">
            Connect to AWS instance using SSH (for Linux)
          </font>
        </h5>
        <h6>
          <font face="Helvetica">
            ssh -i {`<`}.pem file path{`> <`}userid of ec2 instance{`>@<public`}{" "}
            ip of ec2 instance{`>`}
          </font>
        </h6>
        <div>
          <img
            style={{ marginLeft: "80px", width: "50%", display: "initial" }}
            src={require("../../docs/AWS_Configuration_Doc/AwsDoc8.png")}
          />
        </div>
        <br />
        <ol>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                {" "}
                Install cloudwatch agent using{" "}
              </font>
            </p>
            <ul style={{ listStyleType: "lower-alpha" }}>
              <li>
                <p>
                  <font face="Helvetica" size="+1">
                    {" "}
                    sudo yum install amazon-cloudwatch-agent (first sudo yum
                    update then call this command) &nbsp;
                  </font>
                </p>
                <div>
                  <img
                    style={{ width: "50%", display: "initial" }}
                    src={require("../../docs/AWS_Configuration_Doc/AwsDoc9.png")}
                  />
                </div>
                <br />
              </li>
              <li>
                <p>
                  <font face="Helvetica" size="+1">
                    wget
                    https://s3.amazonaws.com/amazoncloudwatch-agent/amazon_linux/amd64/latest/amazon-cloudwatch-agent.rpm
                  </font>
                </p>
              </li>
              <li style={{ listStyleType: "none" }}>
                <p>
                  <font face="Helvetica" size="+1">
                    {" "}
                    sudo rpm -U ./amazon-cloudwatch-agent.rpm
                  </font>
                </p>
              </li>
              <li style={{ listStyleType: "none" }}>
                <p>
                  <font face="Helvetica" size="+1">
                    {" "}
                    apt-get install rpm (If above command give error rpm not
                    found)
                  </font>
                </p>
              </li>
              <li style={{ listStyleType: "none" }}>
                <p>
                  <font face="Helvetica" size="+1">
                    {" "}
                    If sudo rpm command gives error: Failed dependencies:
                    /bin/sh is needed by
                    amazon-cloudwatch-agent-1.247355.0b252062-1.x86_64
                  </font>
                </p>
              </li>
              <li style={{ listStyleType: "none" }}>
                <p>
                  <font face="Helvetica" size="+1">
                    {" "}
                    sudo apt-get install alien alien -i
                    ./amazon-cloudwatch-agent.rpm
                  </font>
                </p>
              </li>
              <li style={{ listStyleType: "none" }}>
                <p>
                  <font
                    face="Helvetica"
                    size="+1"
                    style={{ fontWeight: "bold" }}
                  >
                    For ubunto install
                  </font>
                  <br />
                  <font face="Helvetica" size="+1">
                    wget
                    https://s3.amazonaws.com/amazoncloudwatch-agent/ubuntu/amd64/latest/amazon-cloudwatch-agent.deb
                  </font>
                </p>
              </li>
              <li style={{ listStyleType: "none" }}>
                <p>
                  <font face="Helvetica" size="+1">
                    {" "}
                    sudo dpkg -i -E ./amazon-cloudwatch-agent.deb
                  </font>
                </p>
              </li>
            </ul>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                Create configuration file using wizard
              </font>
            </p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica" size="+1">
                    {" "}
                    sudo
                    /opt/aws/amazon-cloudwatch-agent/bin/amazon-cloudwatch-agent-config-wizard
                    &nbsp;
                  </font>
                </p>
              </li>
              <li>
                <p>
                  <font face="Helvetica" size="+1">
                    See{" "}
                    <a href="#mozTocId105">
                      Config File wizard questions and answers
                    </a>
                  </font>
                </p>
              </li>
            </ul>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                config.json file is by default at location
              </font>
            </p>
            <ol style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">/opt/aws/amazon-cloudwatch-agent/bin/</font>
                  </font>
                </p>
              </li>
            </ol>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                For linux os modify this file to add cpu_usage_steal metric and
                append_dimension parameter if not present
              </font>
            </p>
            <ol style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica" style={{ fontWeight: "bold" }}>
                    <font size="+1">To edit config.json file</font>
                  </font>
                </p>
              </li>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">
                      Refer <a href="#mozTocId104">Edit config.json file</a>
                    </font>
                  </font>
                </p>
              </li>
            </ol>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                start agent
              </font>
            </p>
            <ol style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">
                      sudo
                      /opt/aws/amazon-cloudwatch-agent/bin/amazon-cloudwatch-agent-ctl
                      -a fetch-config -m ec2 -s -c
                      file:/opt/aws/amazon-cloudwatch-agent/bin/config.json
                    </font>
                  </font>
                </p>
              </li>
            </ol>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                Check if cloudwatch agent is running using ps command
              </font>
            </p>
            <ol style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font size="+1">
                    [root@ip-172-31-85-169 bin]# ps -efww | grep cloudwatch
                  </font>
                </p>
              </li>
              <li>
                <font size="+1">
                  root 14594 1 0 10:00 ? 00:00:00
                  /opt/aws/amazon-cloudwatch-agent/bin/amazon-cloudwatch-agent
                  -config
                </font>
              </li>
              <li>
                <font size="+1">
                  /opt/aws/amazon-cloudwatch-agent/etc/amazon-cloudwatch-agent.toml
                  -envconfig
                </font>
              </li>
              <li>
                <font size="+1">
                  /opt/aws/amazon-cloudwatch-agent/etc/env-config.json -pidfile
                </font>
              </li>
              <li>
                <font size="+1">
                  /opt/aws/amazon-cloudwatch-agent/var/amazon-cloudwatch-agent.pid
                </font>
              </li>
              <li>
                <font size="+1">
                  root 14622 14188 0 10:01 pts/0 00:00:00 grep --color=auto
                  cloudwatch
                </font>
              </li>
              <li>
                <font size="+1">[root@ip-172-31-85-169 bin]#</font>
              </li>
            </ol>
          </li>
        </ol>

        <font face="Helvetica">
          <a name="mozTocId103"></a>
          <br />
        </font>
        <h2>
          <font face="Helvetica" size="+2">
            Install Cloudwatch Agent on Windows Instance
          </font>
        </h2>
        <h5>
          <font face="Helvetica">Connect to AWS instance using RDP client</font>
        </h5>

        <ol>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                Download the following file:{" "}
              </font>
            </p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica" size="+1">
                    https://s3.amazonaws.com/amazoncloudwatch-agent/windows/amd64/latest/amazon-cloudwatch-agent.msi
                  </font>
                </p>
              </li>
            </ul>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                If you downloaded an MSI package on a server running Windows
                Server, change to the directory containing the package and enter
                the following:
              </font>
            </p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica" size="+1">
                    {" "}
                    msiexec /i amazon-cloudwatch-agent.msi
                  </font>
                </p>
              </li>
              <li>
                <p>
                  <font face="Helvetica" size="+1">
                    This command also works from within PowerShell.
                  </font>
                </p>
              </li>
            </ul>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                Start the CloudWatch agent configuration wizard by entering the
                following:
              </font>
            </p>
            <ol style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">
                      cd "C:\Program Files\Amazon\AmazonCloudWatchAgent"
                      amazon-cloudwatch-agent-config-wizard.exe
                    </font>
                  </font>
                </p>
              </li>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">
                      Refer{" "}
                      <a href="#mozTocId105">
                        config wizard question and answer.
                      </a>
                    </font>
                  </font>
                </p>
              </li>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">
                      If you're storing the configuration file locally, the
                      configuration file config.json is stored in C:\Program
                      Files\Amazon\AmazonCloudWatchAgent on Windows Server
                    </font>
                  </font>
                </p>
              </li>
            </ol>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                For windows os modify this file to add "% Processor Time" metric
                and append_dimension parameter if not present
              </font>
            </p>
            <ol style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">
                      <pre>
                        {`
"Processor": {
                "measurement": [
                    "% User Time",
                    "% Idle Time",
                    "% Interrupt Time",
                    "% Processor Time"
                ],
                "append_dimensions": {
                    "InstanceId": "${"aws:InstanceId"}"
    },
    `}
                      </pre>
                    </font>
                  </font>
                </p>
              </li>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1" style={{ fontWeight: "bold" }}>
                      To edit config.json file
                    </font>
                  </font>
                </p>
              </li>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">
                      Refer <a href="#mozTocId104">Edit config.json file</a>
                    </font>
                  </font>
                </p>
              </li>
            </ol>
            <br />
          </li>
          <li>
            <p>
              <font face="Helvetica" size="+1">
                Start Agent from powershell
              </font>
            </p>
            <ol style={{ listStyleType: "none" }}>
              <li>
                <p>
                  <font face="Helvetica">
                    <font size="+1">
                      C:\Users\Administrator{">"} & 'C:\Program
                      Files\Amazon\AmazonCloudWatchAgent\amazon-cloudwatch-agent-ctl.ps1'
                      -a fetch-config -m ec2 -s -c file:'C:\Program
                      Files\Amazon\AmazonCloudWatchAgent\config.json'
                    </font>
                  </font>
                </p>
              </li>
            </ol>
          </li>
        </ol>

        <font face="Helvetica">
          <a name="mozTocId104"></a>
          <br />
        </font>
        <h2>
          <font face="Helvetica" size="+2">
            Edit config.json file
          </font>
        </h2>

        <h3>
          <font face="Helvetica" size="+1">
            On linux instance
          </font>
        </h3>
        <ol style={{ listStyleType: "none" }}>
          <li>cd /opt/aws/amazon-cloudwatch-agent/bin</li>
          <li>sudo vi config.json </li>
          <li>
            Update config.json as per{" "}
            <a href="#mozTocId106">linux sample config.json </a> file given
            below.
          </li>
          <br />
          <li>add “cpu_usage_steal” in metrics_collected {`->`} cpu section</li>
          <li>
            <pre>
              {`
"metrics_collected": {
            "cpu": {
                "measurement": [
                    "cpu_usage_idle",
                    "cpu_usage_iowait",
                    "cpu_usage_user",
                    "cpu_usage_system",
                    "cpu_usage_steal"
                  ],
`}
            </pre>
          </li>
          <li style={{ color: "red" }}>
            <div> (comma before previous property is important)</div>
            <br />
          </li>
          <li>
            <span style={{ color: "red" }}>
              {" "}
              Add append_dimensions is important, add this property before
              aggregation_dimension
            </span>
            <pre>
              {`
"append_dimensions": {
        "InstanceId": "${"aws:InstanceId"}"
    },
`}
            </pre>
          </li>
          <li>
            {" "}
            <h3>
              <font face="Helvetica" size="+1">
                To save file click Esc {`->`} wq! {`->`} Enter
              </font>
            </h3>
          </li>
        </ol>

        <h3>
          <font face="Helvetica" size="+1">
            On Windows instance
          </font>
        </h3>

        <ol style={{ listStyleType: "none" }}>
          <li>
            File is located at C:\Program Files\Amazon\AmazonCloudWatchAgent
          </li>
          <li>Open config.json in notepad</li>
          <li> Add above changes and save file.</li>
          <br />
          <li>
            <font face="Helvetica" style={{ fontWeight: "bold" }}>
              Important commands (Linux)
            </font>
          </li>
          <br />
          <li>
            Everytime after changing config.json, stop agent and then start it
            again.
          </li>
          <br />
          <li>
            <font face="Helvetica" style={{ fontWeight: "bold" }}>
              Stop agent
            </font>
          </li>
          <li>
            sudo
            /opt/aws/amazon-cloudwatch-agent/bin/amazon-cloudwatch-agent-ctl -m
            ec2 -a stop
          </li>
          <br />
          <li>
            <font face="Helvetica" style={{ fontWeight: "bold" }}>
              Agent Status
            </font>
          </li>
          <li>
            sudo
            /opt/aws/amazon-cloudwatch-agent/bin/amazon-cloudwatch-agent-ctl -m
            ec2 -a status
          </li>
          <br />
          <li>
            <font face="Helvetica" style={{ fontWeight: "bold" }}>
              Start Agent
            </font>
          </li>
          <li>
            sudo
            /opt/aws/amazon-cloudwatch-agent/bin/amazon-cloudwatch-agent-ctl -a
            fetch-config -m ec2 -s -c
            file:/opt/aws/amazon-cloudwatch-agent/bin/config.json
          </li>
          <br />
          <br />

          <li>
            <font face="Helvetica" style={{ fontWeight: "bold" }}>
              Important commands (windows)
            </font>
          </li>
          <br />
          <li>
            Everytime after changing config.json, stop agent and then start it
            again.
          </li>
          <br />
          <li>Open windows powershell</li>
          <br />
          <li>By default config.json file is located at:</li>
          <li>C:\Program Files\Amazon\AmazonCloudWatchAgent</li>
          <br />
          <li>
            <font face="Helvetica" style={{ fontWeight: "bold" }}>
              Agent Status command
            </font>
          </li>
          <li>
            PS C:\Users\Administrator{`>`} & 'C:\Program
            Files\Amazon\AmazonCloudWatchAgent\amazon-cloudwatch-agent-ctl.ps1'
            -a status
          </li>
          <br />
          <li>
            <font face="Helvetica" style={{ fontWeight: "bold" }}>
              Start Agent command
            </font>
          </li>
          <li>
            S C:\Users\Administrator{`>`} & 'C:\Program
            Files\Amazon\AmazonCloudWatchAgent\amazon-cloudwatch-agent-ctl.ps1'
            -a fetch-config -m ec2 -s -c file:'C:\Program
            Files\Amazon\AmazonCloudWatchAgent\config.json'
          </li>
          <br />
          <li>
            <font face="Helvetica" style={{ fontWeight: "bold" }}>
              stop Agent command
            </font>
          </li>
          <li>
            PS C:\Users\Administrator{`>`} & 'C:\Program
            Files\Amazon\AmazonCloudWatchAgent\amazon-cloudwatch-agent-ctl.ps1'
            -m ec2 -a stop
          </li>
        </ol>

        <font face="Helvetica">
          <a name="mozTocId105"></a>
          <br />
        </font>
        <h2>
          <font face="Helvetica" size="+2">
            Config File Wizard Question and Answers
          </font>
        </h2>
        <ol style={{ listStyleType: "none" }}>
          <li>
            [root@ip-172-31-85-169 ~]# sudo
            /opt/aws/amazon-cloudwatch-agent/bin/amazon-cloudwatch-agent-config-wizard
          </li>
          <li>
            <pre>
              {`
================================================================
= Welcome to the Amazon CloudWatch Agent Configuration Manager =
=                                                              =
= CloudWatch Agent allows you to collect metrics and logs from =
= your host and send them to CloudWatch. Additional CloudWatch =
= charges may apply.                                           =
================================================================
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
On which OS are you planning to use the agent?
1. linux
2. windows
3. darwin
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Trying to fetch the default region based on ec2 metadata...
Are you using EC2 or On-Premises hosts?
1. EC2
2. On-Premises
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Which user are you planning to run the agent?
1. root
2. cwagent
3. others
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you want to turn on StatsD daemon?
1. yes
2. no
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Which port do you want StatsD daemon to listen to?
default choice: [8125] – Press Enter for default choice
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
What is the collect interval for StatsD daemon?
1. 10s
2. 30s
3. 60s
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
What is the aggregation interval for metrics collected by StatsD daemon?
1. Do not aggregate
2. 10s
3. 30s
4. 60s
default choice: [4]:
4
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you want to monitor metrics from CollectD? WARNING: CollectD must be installed or the Agent will fail to start
1. yes
2. no
default choice: [1]:
2
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you want to monitor any host metrics? e.g. CPU, memory, etc.
1. yes
2. no
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you want to monitor cpu metrics per core?
1. yes
2. no
default choice: [1]:
2
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you want to add ec2 dimensions (ImageId, InstanceId, InstanceType, AutoScalingGroupName) into all of your metrics if the info is available?
1. yes
2. no
default choice: [1]:
2
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you want to aggregate ec2 dimensions (InstanceId)?
1. yes
2. no
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Would you like to collect your metrics at high resolution (sub-minute resolution)? This enables sub-minute resolution for all metrics, but you can customize for specific metrics in the output json file.
1. 1s
2. 10s
3. 30s
4. 60s
default choice: [4]:
4
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Which default metrics config do you want?
1. Basic
2. Standard
3. Advanced
4. None
default choice: [1]:
3
Current config as follows:
{
	"agent": {
		"metrics_collection_interval": 60,
		"run_as_user": "root"
	},
	"metrics": {
		"aggregation_dimensions": [
			[
				"InstanceId"
			]
		],
		"metrics_collected": {
			"cpu": {
				"measurement": [
					"cpu_usage_idle",
					"cpu_usage_iowait",
					"cpu_usage_user",
					"cpu_usage_system"
				],
				"metrics_collection_interval": 60,
				"totalcpu": false
			},
			"disk": {
				"measurement": [
					"used_percent",
					"inodes_free"
				],
				"metrics_collection_interval": 60,
				"resources": [
					"*"
				]
			},
			"diskio": {
				"measurement": [
					"io_time",
					"write_bytes",
					"read_bytes",
					"writes",
					"reads"
				],
				"metrics_collection_interval": 60,
				"resources": [
					"*"
				]
			},
            "mem": {
				"measurement": [
					"mem_used_percent"
				],
				"metrics_collection_interval": 60
			},
			"netstat": {
				"measurement": [
					"tcp_established",
					"tcp_time_wait"
				],
				"metrics_collection_interval": 60
			},
			"statsd": {
				"metrics_aggregation_interval": 60,
				"metrics_collection_interval": 10,
				"service_address": ":8125"
			},
			"swap": {
				"measurement": [
					"swap_used_percent"
				],
				"metrics_collection_interval": 60
			}
		}
	}
}
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Are you satisfied with the above config? Note: it can be manually customized after the wizard completes to add additional items.
1. yes
2. no
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you have any existing CloudWatch Log Agent (http://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/AgentReference.html) configuration file to import for migration?
1. yes
2. no
default choice: [2]:
2
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you want to monitor any log files?
1. yes
2. no
default choice: [1]:
2
Saved config file to /opt/aws/amazon-cloudwatch-agent/bin/config.json successfully.
Current config as follows:
{
	"agent": {
		"metrics_collection_interval": 60,
		"run_as_user": "root"
	},
    "metrics": {
		"aggregation_dimensions": [
			[
				"InstanceId"
			]
		],
		"metrics_collected": {
			"cpu": {
				"measurement": [
					"cpu_usage_idle",
					"cpu_usage_iowait",
					"cpu_usage_user",
					"cpu_usage_system"
				],
				"metrics_collection_interval": 60,
				"totalcpu": false
			},
			"disk": {
				"measurement": [
					"used_percent",
					"inodes_free"
				],
				"metrics_collection_interval": 60,
				"resources": [
					"*"
				]
			},
			"diskio": {
				"measurement": [
					"io_time",
					"write_bytes",
					"read_bytes",
					"writes",
					"reads"
				],
				"metrics_collection_interval": 60,
				"resources": [
					"*"
				]
			},
			"mem": {
				"measurement": [
					"mem_used_percent"
				],
				"metrics_collection_interval": 60
			},
			"netstat": {
				"measurement": [
					"tcp_established",
					"tcp_time_wait"
				],
				"metrics_collection_interval": 60
            },
			"statsd": {
				"metrics_aggregation_interval": 60,
				"metrics_collection_interval": 10,
				"service_address": ":8125"
			},
			"swap": {
				"measurement": [
					"swap_used_percent"
				],
				"metrics_collection_interval": 60
			}
		}
	}
}
Please check the above content of the config.
The config file is also located at /opt/aws/amazon-cloudwatch-agent/bin/config.json.
Edit it manually if needed.
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Do you want to store the config in the SSM parameter store?
1. yes
2. no
default choice: [1]:
1
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
What parameter store name do you want to use to store your config? (Use 'AmazonCloudWatch-' prefix if you use our managed AWS policy)
default choice: [AmazonCloudWatch-linux] -- Press Enter for default choice
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Trying to fetch the default region based on ec2 metadata...
Which region do you want to store the config in the parameter store?
default choice: [us-east-1]
Press enter for default choice
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Which AWS credential should be used to send json config to parameter store?
1. ASIATSFR47AWAGPQFIFO(From SDK)
2. Other
default choice: [1]:
2
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Which AWS credential should be used to send json config to parameter store?
1. ASIATSFR47AWAGPQFIFO(From SDK)
2. Other
default choice: [1]:
2
`}
            </pre>
          </li>
          <li>
            <pre>
              {` 
Please provide credentials to upload the json config file to parameter store.
AWS Access Key:
AWS Key e.g. AKIATSFR47AWLPEGNHOC
AWS Secret Key:
e.g. x67G1M2MmBgcq84v0PDqCwLiGXndwjVjHhhA27ic
Successfully put config to parameter store AmazonCloudWatch-linux.
Program exits now.
`}
            </pre>
          </li>
        </ol>

         <font face="Helvetica">
          <a name="mozTocId106"></a>
          <br />
        </font>
        <h2>
          <font face="Helvetica" size="+2">
            Linux sample config.json
          </font>
        </h2>
        <div>
          <pre>
            {`
    {
        "agent": {
            "metrics_collection_interval": 60,
            "run_as_user": "root"
        },
        "metrics": {
            "append_dimensions": {
                "InstanceId": "${"aws:InstanceId"}"
            },
   
            "aggregation_dimensions": [
                [
                    "InstanceId"
                ]
            ],
   
            "metrics_collected": {
                "cpu": {
                    "resources": ["*"],
                    "measurement": [
                        "cpu_usage_idle",
                        "cpu_usage_iowait",
                        "cpu_usage_user",
                        "cpu_usage_system",
                        "cpu_usage_guest",
                        "cpu_usage_steal"
                    ],
                    "totalcpu": false,
                    "metrics_collection_interval": 60
                },
                "disk": {
                    "measurement": [
                        "used_percent",
                        "inodes_free"
                    ],
                    "metrics_collection_interval": 60,
                    "resources": [
                        "*"
                    ]
                },
                "diskio": {
                    "measurement": [
                        "io_time",
                        "write_bytes",
                        "read_bytes",
                        "writes",
                        "reads"
                    ],
                    "metrics_collection_interval": 60,
                    "resources": [
                        "*"
                    ]
                },
                "mem": {
                    "measurement": [
                        "mem_used_percent"
                    ],
                    "metrics_collection_interval": 60
                },
                "netstat": {
                    "measurement": [
                        "tcp_established",
                        "tcp_time_wait"
                    ],
                    "metrics_collection_interval": 60
                },
                "statsd": {
                    "metrics_aggregation_interval": 60,
                    "metrics_collection_interval": 10,
                    "service_address": ":8125"
                },
                "swap": {
                    "measurement": [
                        "swap_used_percent"
                    ],
                    "metrics_collection_interval": 60
                }
            }
        }
    }
`}
          </pre>
        </div>

        {/* <font face="Helvetica">
          <a name="mozTocId107"></a>
          <br />
        </font>
        <h2>
          <font face="Helvetica" size="+2">
            Windows sample config.json
          </font>
        </h2>
        <div>
          <pre>
            {`
    {
        "metrics": {
            "append_dimensions": {
                "InstanceId": "${"aws:InstanceId"}"
            },
            "aggregation_dimensions": [
                [
                    "InstanceId"
                ]
            ],
            "metrics_collected": {
                "LogicalDisk": {
                    "measurement": [
                        "% Free Space"
                    ],
                    "metrics_collection_interval": 10,
                    "resources": [
                        "*"
                    ]
                },
                "Memory": {
                    "measurement": [
                        "% Committed Bytes In Use"
                    ],
                    "metrics_collection_interval": 10
                },
                "Paging File": {
                    "measurement": [
                        "% Usage"
                    ],
                    "metrics_collection_interval": 10,
                    "resources": [
                        "*"
                    ]
                },
                "PhysicalDisk": {
                    "measurement": [
                        "% Disk Time",
                        "Disk Write Bytes/sec",
                        "Disk Read Bytes/sec",
                        "Disk Writes/sec",
                        "Disk Reads/sec"
                    ],
                    "metrics_collection_interval": 10,
                    "resources": [
                        "*"
                    ]
                },
                "Processor": {
                    "measurement": [
                        "% User Time",
                        "% Idle Time",
                        "% Interrupt Time",
                        "% Processor Time"
                    ],
                    "metrics_collection_interval": 10,
                    "resources": [
                        "_Total"
                    ]
                },
                "TCPv4": {
                    "measurement": [
                        "Connections Established"
                    ],
                    "metrics_collection_interval": 10
                },
                "TCPv6": {
                    "measurement": [
                        "Connections Established"
                    ],
                    "metrics_collection_interval": 10
                },
                "statsd": {
                    "metrics_aggregation_interval": 60,
                    "metrics_collection_interval": 10,
                    "service_address": ":8125"
                }
            }
        }
    }
`}
          </pre>
        </div> */}
      </div>
    );
  }
}

export default HelpAWS;
