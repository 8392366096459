import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import { getStringDate } from "../../Constants/CommonConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";

const OrganizationDSLevelSQLDB = (props) => {
  const { startDate, endDate, orgDetails } = props;
  const [errorPopup, setErrorPopup] = useState(false);
  const [isErrorHeader, setIsErrorHeader] = useState(false);
  const [errormessage, setErrormessage] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [getDSName, setDSName] = useState(null);
  const [analysisDSHistory, setAnalysisDSHistory] = useState([]);
  const [analysisSqlDbHistoryOfDS, setAnalysisSqlDbHistoryOfDS] = useState([]);
  const [vmDetailsModal, setVmDetailsModal] = useState(false);
  const [fetchStauts, setFetchDStatus] = useState(false);

  useEffect(() => {
    handleOrgDSDetails();
  }, []);

  const handleOrgDSDetails = async () => {
    // setModalLoader(true);
    setFetchDStatus(true);
    setAnalysisDSHistory([]);
    let date1 = new Date(startDate);
    let start = date1.getTime();
    let date2 = new Date(endDate);
    let end = date2.getTime();

    let payload = {
      startTime: start,
      endTime: end,
      id: orgDetails.orgId,
      orgName: orgDetails.orgName,
    };
    await fetch(Constdata.ORGANIZATION_DS_SQLDB_ANALYSIS_HISTORY_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          setAnalysisDSHistory(responseJson.data);
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          setErrorPopup(true);
          setErrormessage(message.SessionExpiredText);
          setIsErrorHeader(false);
        } else {
          setErrorPopup(true);
          setErrormessage(message.ServerError);
          setIsErrorHeader(false);
        }
        setModalLoader(false);
        setFetchDStatus(false);
      })
      .catch((error) => {
        setModalLoader(false);
        setErrorPopup(true);
        setErrormessage(message.CacheErrorText);
        setIsErrorHeader(false);
      });
  };

  const handleOrgSqlDbDetails = async (ds) => {
    setDSName(
      ds.providername.toUpperCase() === "AZURE"
        ? ds.azureaccountname
        : ds.awsaccountname
    );
    setModalLoader(true);
    setAnalysisSqlDbHistoryOfDS([]);
    let date1 = new Date(startDate);
    let start = date1.getTime();
    let date2 = new Date(endDate);
    let end = date2.getTime();

    let payload2 = {
      startTime: start,
      endTime: end,
      orgId: orgDetails.orgId,
      configId:
        ds.providername.toUpperCase() === "AZURE"
          ? ds.azureconfigid
          : ds.awsconfigid,
      orgName: orgDetails.orgName,
    };

    await fetch(Constdata.ORGANIZATION_SQLDB_ANALYSIS_HISTORY_BY_ORG_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload2),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          setAnalysisSqlDbHistoryOfDS(responseJson.data);
          setVmDetailsModal(true);
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          setErrorPopup(true);
          setErrormessage(message.SessionExpiredText);
          setIsErrorHeader(false);
        } else {
          setErrorPopup(true);
          setErrormessage(message.ServerError);
          setIsErrorHeader(false);
        }
        setModalLoader(false);
      })
      .catch((error) => {
        setModalLoader(false);
        setErrorPopup(true);
        setErrormessage(message.CacheErrorText);
        setIsErrorHeader(false);
      });
  };

  const toggleVmModalOfDS = () => {
    setVmDetailsModal(false);
  };

  const setErrorPopupState = () => {
    setErrorPopup(false);
  };

  return (
    <>
      <Table
        hover
        style={{
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <thead
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <tr style={{ borderTop: "1px solid #dee2e6" }}>
            <th>#</th>
            <th style={{ width: "170px" }}>Datasource</th>
            <th>Provider</th>
            <th>Analyzable DBaaS</th>
            <th style={{ width: "170px" }}>
              Successful Analysis of Unique DBaaS
            </th>
            <th>Successful Analysis of DBaaS</th>
            <th>Resets of Successfully Analyzed DBaaS</th>
            <th style={{ width: "150px" }}>
              Unsuccessful Analysis of Unique DBaaS
            </th>
            <th>Unsuccessful Analysis of DBaaS</th>
            <th>Resets of Unsuccessfully Analyzed DBaaS</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {analysisDSHistory && analysisDSHistory.length > 0 ? (
            analysisDSHistory.map((ds, key) => (
              <tr style={{ textAlign: "center" }}>
                <td>{key + 1}</td>
                <td>
                  {ds.providername.toUpperCase() === "AZURE"
                    ? ds.azureaccountname
                    : ds.awsaccountname}
                </td>
                <td>{ds.providername}</td>
                <td>{ds.eligiblevmcount}</td>
                <td>{ds.atleastonesucceefulanalysis}</td>
                <td>{ds.analyzedcount}</td>
                <td>{ds.successfulresetcount}</td>
                <td>{ds.atleastfailedanalysis}</td>
                <td>{ds.failedanalysiscount}</td>
                <td>{ds.failedanalysisresetcount}</td>

                <td>
                  <Button
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      width: "100px",
                    }}
                    color="info"
                    className="orgBtn"
                    onClick={() => handleOrgSqlDbDetails(ds)}
                  >
                    Details
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr style={{ textAlign: "center" }}>
              <td colSpan={11}>
                <div style={{ padding: "30px" }}>
                  {fetchStauts
                    ? "Fetching Data Source"
                    : "No Data Source Found"}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal
        className="orgHistoryDetails"
        backdrop="static"
        size="xl"
        isOpen={vmDetailsModal}
        toggle={toggleVmModalOfDS}
      >
        <ModalHeader
          toggle={toggleVmModalOfDS}
          id="modalheader_list"
          cssModule={{ "modal-title": "modalHeader" }}
        >
          <div
            className="modalCompanyName"
            dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
          />
          <div className="modalTitle">
            DBaaS Analysis History Of {getDSName}
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col style={{ textAlign: "center" }}>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  padding: "4px",
                }}
              >
                From {getStringDate(startDate)} To {getStringDate(endDate)}
              </div>
            </Col>
          </Row>
          <Table
            hover
            style={{
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            <thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
              }}
            >
              <tr style={{ borderTop: "1px solid #dee2e6" }}>
                <th>#</th>
                <th style={{ width: "160px" }}>DB Name</th>
                <th style={{ width: "150px" }}>Region</th>
                <th>Analyzable</th>
                <th>Successful Analysis</th>
                <th>Resets of successful Analysis</th>
                <th>Unsuccessful Analysis</th>
                <th>Resets of unsuccessful Analysis</th>
                <th style={{ width: "130px" }}>First present in Inventory</th>
                <th style={{ width: "130px" }}>Last Present in Inventory</th>
              </tr>
            </thead>
            <tbody>
              {analysisSqlDbHistoryOfDS &&
              analysisSqlDbHistoryOfDS.length > 0 ? (
                analysisSqlDbHistoryOfDS.map((vm, key) => (
                  <tr style={{ textAlign: "center" }}>
                    <td>{key + 1}</td>
                    <td>{vm.vmname}</td>
                    <td>{vm.location}</td>
                    <td>{vm.iseligible ? "True" : "False"}</td>
                    <td>{vm.analyzedcount}</td>
                    <td>{vm.successfulresetcount}</td>
                    <td>{vm.failedanalysiscount}</td>
                    <td>{vm.failedanalysisresetcount}</td>
                    <td>
                      {vm.firstininventory !== 0
                        ? getStringDate(vm.firstininventory)
                        : "-"}
                    </td>
                    <td>
                      {vm.lastininventory !== 0
                        ? getStringDate(vm.lastininventory)
                        : "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ textAlign: "center" }}>
                  <td colSpan={10}>
                    <div style={{ padding: "30px" }}>No DBaaS Found</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
      {modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      {errorPopup === true ? (
        <SerraError
          Errordata={errormessage}
          ErrorPopup={errorPopup}
          setErrorMethod={() => setErrorPopupState()}
          isError={isErrorHeader}
        ></SerraError>
      ) : (
        ""
      )}
    </>
  );
};

export default OrganizationDSLevelSQLDB;
