/* global fetch */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Input,
  Button,
  Table,
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import ReactGA from "react-ga4";
import jstz from "jstz";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import IneligibleVMList from "../PPUComponent/IneligibleVMList";
import ManageParkingRecommendation from "./ManageParkingRecommendation";
import { connect } from "react-redux";

class VMParkingList extends Component {
  constructor(props) {
    super(props);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.renderVmStatus = this.renderVmStatus.bind(this);
    this.getStringDate = this.getStringDate.bind(this);
    this.vmSizeColFormater = this.vmSizeColFormater.bind(this);
    this.removeDuplicates = this.removeDuplicates.bind(this);
    this.handleVmlistRequest = this.handleVmlistRequest.bind(this);
    this.responseProcess = this.responseProcess.bind(this);
    this.toggleTable = this.toggleTable.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleAccount = this.handleAccount.bind(this);
    this.onChange = this.onChange.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.getRefreshStatus = this.getRefreshStatus.bind(this);
    this.fillupAccountFilter = this.fillupAccountFilter.bind(this);
    this.togglePerfInfo = this.togglePerfInfo.bind(this);
    this.toggleNext = this.toggleNext.bind(this);
    this.handelReset = this.handelReset.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.navigateToReport = this.navigateToReport.bind(this);
    this.toggleManageParkingPopup = this.toggleManageParkingPopup.bind(this);
    // this.eligibleVmsList = this.eligibleVmsList.bind(this)
    this.preSelectVM = this.preSelectVM.bind(this);
    this.freeTrialPlan = this.freeTrialPlan.bind(this);
    this.freeTrialPlanSupport = this.freeTrialPlanSupport.bind(this);
    this.isStaledsVms = this.isStaledsVms.bind(this);
    this.freeTrialAnalysisList = this.freeTrialAnalysisList.bind(this);
    this.state = {
      providerName: "",
      value: true,
      result: [],
      reportData: [],
      nonSelectable: [],
      errorPopup: false,
      errormessage: "",
      isErrorHeader: false,
      isFreeTrial: false,
      isParkingFreeTrialSuccess: false,
      isRedirect: false,
      isFromDidMount: true,
      modalTable: false,
      filterList: [],
      isUpdated: false,
      perfInfoModal: false,
      reportInfoModal: false,
      resetInfoModal: false,
      validationInfoModal: false,
      manageResizeInfoModal: false,
      isReport: true,
      isPerformAnalysis: true,
      isManageRecomm: true,
      isReset: true,
      timerId: 0,
      refreshTimerId: -1,
      refreshFlag: false,
      modalLoader: false,
      toggleManageParkingModal: false,
      clickResetStatus: false,
      disabledBtn: false,
      freeTrailPopup: false,
      userName: false,
      VmFilters: [
        { label: "Not Analyzed", value: "1" },
        { label: "Analyzing", value: "2" },
        { label: "Analyzed", value: "3" },
        { label: "Analysis Issue", value: "4" },
        { label: "Optimizing", value: "5" },
        { label: "Optimally Parked", value: "6" },
        { label: "Custom Parked", value: "7" },
        { label: "Parked", value: "8" },
        { label: "Resetting", value: "9" },
        { label: "Analysis Ineligible", value: "10" },
      ],
      notEligibleVms: [],
      getAllConfigData: [],
      VmFiltersValues: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
      columns: [
        {
          dataField: "basicvm_id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "name",
          text: "VM Name",
          sort: true,
          headerStyle: {
            minWidth: "60px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
            fontWeight: "600",
            color: "#393939",
          },
        },
        {
          dataField: "accountName",
          text: "Metric Data Source",
          sort: true,
          headerStyle: {
            minWidth: "60px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
          },
        },
        {
          dataField: "ostype",
          text: "OS Type",
          sort: true,
          headerStyle: {
            width: "140px",
          },
          style: {
            pointerEvents: "none",
          },
        },
        {
          dataField: "vmsize_Name",
          text: "VM Size",
          sort: true,
          formatter: this.vmSizeColFormater,
          headerStyle: {
            whiteSpace: "nowrap",
          },
          classes: (cell, row, rowIndex, colIndex) => {
            return "thExpandSelector";
          },
        },
        {
          dataField: "region",
          text: "Region",
          sort: true,
          headerStyle: {
            width: "140px",
          },
          style: {
            pointerEvents: "none",
          },
        },
        {
          dataField: "status",
          text: "Status",
          formatter: this.renderVmStatus,
          sort: true,
          style: {
            padding: "0px ",
            fontWeight: "600",
            color: "#393939",
          },
          headerStyle: {
            padding: "0px !important",
            width: "230px",
          },
        },
        {
          dataField: "status_change_datetime",
          text: "When",
          formatter: this.getStringDate,
          headerStyle: {
            width: "120px",
          },
          style: {
            pointerEvents: "none",
          },
        },
      ],
      expandRow: {
        showExpandColumn: false,
        onlyOneExpanding: false,
        animation: "grow",
        renderer: this.expandeRowRenderer,
        onExpand: (row, isExpand, rowIndex, e) => {
          if (isExpand) {
            if (document.getElementById(row.basicvm_id + "arr") !== null) {
              document.getElementById(row.basicvm_id + "arr").className =
                "fa fa-angle-up";
            }
          } else {
            if (document.getElementById(row.basicvm_id + "arr") !== null) {
              document.getElementById(row.basicvm_id + "arr").className =
                "fa fa-angle-down";
            }
          }
        },
      },
      isFreePlanNonAnalyzedSelected: false,
      isFreeTrialAnalysisStarted: false,
    };
  }
  timeoutId = null;

  async componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Clear timeout on component unmount
    }
    await clearInterval(this.state.refreshTimerId);
    await clearInterval(this.state.timerId);
  }

  async componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "CPO Parking",
    });
    this.props.registerListners();
    this.props.onRef(this);
    // this.refs.iScroll.addEventListener("scroll", () => {
    //   if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight) {
    //     this.loadMoreItems();
    //   }
    // });
    fetch(Constdata.GETPRESELECTEDFILTERS_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        await this.setState({
          viewPriviousdata: responseJson.data
            ? responseJson.data.lastReport
            : [],
          result:
            responseJson.orgInfo.plan === "FREE_TRIAL" &&
            responseJson.orgInfo.userName !== "support@serralabs.com"
              ? []
              : responseJson.data
              ? responseJson.data.selectedParkingIds
              : [],
          isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
          userName: responseJson.orgInfo.userName,
          isParkingFreeTrialSuccess:
            responseJson.orgInfo.isParkingFreeTrialCompleted,
          isFreePlanNonAnalyzedSelected: true,
        });
        await this.fillupAccountFilter();
        await this.getRefreshStatus(true);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }
  handelReset = async () => {
    this.setState({
      modalLoader: true,
      modalReset: false,
      clickResetStatus: true,
    });
    fetch(Constdata.PARKING_VM_RESET_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(this.state.reportData),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          await this.setState({
            clickResetStatus: false,
            isUpdated: true,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
            userName: responseJson.orgInfo.userName,
            isParkingFreeTrialSuccess:
              responseJson.orgInfo.isParkingFreeTrialCompleted,
          });
          await this.handleVmlistRequest();
          if (this.freeTrialPlanSupport()) {
            await this.isDisabled();
            await this.preSelectVM();
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: false,
            modalReset: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  fillupAccountFilter = () => {
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          let accountlist = [];
          let accountselValList = [];
          let accNameLst = [];
          if (responseJson.orgInfo.providerName === "AWS") {
            this.setState({ providerName: "AWS" });
            for (var i = 0; i < responseJson.data.awsConfigs.length; i++) {
              accountlist.push({
                label: responseJson.data.awsConfigs[i].accountname,
                value: "" + i,
              });
              accountselValList.push("" + i);
              accNameLst.push(responseJson.data.awsConfigs[i].accountname);
            }
          } else if (responseJson.orgInfo.providerName === "AZURE") {
            this.setState({ providerName: "AZURE" });
            for (var i = 0; i < responseJson.data.configs.length; i++) {
              accountlist.push({
                label: responseJson.data.configs[i].accountname,
                value: "" + i,
              });
              accountselValList.push("" + i);
              accNameLst.push(responseJson.data.configs[i].accountname);
            }
          }
          this.setState({
            accountList: accountlist,
            accountValList: accountselValList,
            userList: accNameLst,
          });
          this.handleVmlistRequest(true);
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  getRefreshStatus = async (fromDidMount) => {
    if (fromDidMount !== undefined && fromDidMount === false) {
      await this.fillupAccountFilter();
      //await this.populatePreselectRows(fromDidMount);
    }
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          let isFetching = false;

          if (responseJson.orgInfo.providerName === "AWS") {
            if (
              this.props.dataSourcePayload &&
              this.props.dataSourcePayload.length >= 1 &&
              responseJson.data.awsConfigs.length <= 0
            ) {
              this.timeoutId = setTimeout(() => {
                this.getRefreshStatus(fromDidMount);
              }, 15000);
            }

            await responseJson.data.awsConfigs.map(async (key) => {
              if (
                key.status.toLowerCase().includes("fetching") &&
                (key.refreshResourceIndex === 1 ||
                  (key.refreshResourceIndex & 2) === 2)
              ) {
                isFetching = true;
              }
            });
            this.setState({ getAllConfigData: responseJson.data.awsConfigs });
          } else {
            if (
              this.props.dataSourcePayload &&
              this.props.dataSourcePayload.length >= 1 &&
              responseJson.data.configs.length <= 0
            ) {
              this.timeoutId = setTimeout(() => {
                this.getRefreshStatus(fromDidMount);
              }, 15000);
            }

            await responseJson.data.configs.map(async (key) => {
              if (
                key.status.toLowerCase().includes("fetching") &&
                (key.refreshResourceIndex === 1 ||
                  (key.refreshResourceIndex & 2) === 2)
              ) {
                isFetching = true;
              }
            });
            this.setState({ getAllConfigData: responseJson.data.configs });
          }

          if (!isFetching) {
            await this.setState({
              refreshFlag: false,
              selectRow: { ...this.state.selectRow, hideSelectAll: false },
            });
            await this.props.doneRefresh(2);
            if (this.state.refreshTimerId !== -1) {
              clearInterval(this.state.refreshTimerId);
              await this.setState({ refreshTimerId: -1 });
            }
          } else {
            this.setState({
              refreshFlag: true,
              selectRow: { ...this.state.selectRow, hideSelectAll: true },
            });
            if (this.state.refreshTimerId === -1) {
              const timer = setInterval(() => {
                this.getRefreshStatus(fromDidMount);
                this.handleVmlistRequest(fromDidMount);
              }, 10000);
              await this.setState({
                refreshTimerId: timer,
              });
            }
          }
          this.isDisabled();
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          if (this.state.refreshTimerId !== -1) {
            clearInterval(this.state.refreshTimerId);
          }
          await this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            isRedirect: true,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
          await this.props.doneRefresh(2);
        } else {
          if (this.state.refreshTimerId !== -1) {
            clearInterval(this.state.refreshTimerId);
          }
          await this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            isRedirect: true,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
          await this.props.doneRefresh(2);
        }
      })
      .catch((error) => {
        if (this.state.refreshTimerId !== -1) {
          clearInterval(this.state.refreshTimerId);
          this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
          this.props.doneRefresh(2);
        }
        console.error(error);
        this.setState({
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  async handleFilter(key) {
    let filterlist = [];
    let filterValList = [];
    if (key !== null && key !== undefined) {
      if (key.name === "selectedAll") {
        if (key.isChecked === true) {
          this.state.VmFilters.map((data) => {
            filterlist.push(data.label.toUpperCase());
            filterValList.push(data.value);
          });
        }
      } else {
        filterlist = this.state.filterList.slice();
        filterValList = this.state.VmFiltersValues.slice();
        if (
          key.name === "select" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterNm = key.itemData.label.toUpperCase();
          let filterVal = key.itemData.value;
          if (!filterlist.includes(filterNm)) {
            await filterlist.push(filterNm);
          }
          if (!filterValList.includes(filterVal)) {
            await filterValList.push(filterVal);
          }
        } else if (
          key.name === "removed" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterNm = key.itemData.label.toUpperCase();
          let filterVal = key.itemData.value;
          if (filterlist.includes(filterNm)) {
            let idx1 = filterlist.indexOf(filterNm);
            filterlist.splice(idx1, 1);
          }
          if (filterValList.includes(filterVal)) {
            let idx = filterValList.indexOf(filterVal);
            filterValList.splice(idx, 1);
          }
        }
      }
      await this.setState({
        filterList: filterlist,
        VmFiltersValues: filterValList,
        modalLoader: true,
      });
      this.handleVmlistRequest();
    }
  }

  async handleAccount(key) {
    let userlist = [];
    let accountselValList = [];
    if (key !== null && key !== undefined) {
      if (key.name === "selectedAll") {
        if (key.isChecked === true) {
          this.state.accountList.map((data) => {
            if (!userlist.includes(data.label)) {
              userlist.push(data.label);
            }
            if (!accountselValList.includes(data.value)) {
              accountselValList.push(data.value);
            }
          });
        }
      } else {
        userlist = this.state.userList.slice();
        accountselValList = this.state.accountValList.slice();
        if (
          key.name === "select" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterNm = key.itemData.label;
          let filterVal = key.itemData.value;
          if (!userlist.includes(filterNm)) {
            await userlist.push(filterNm);
          }
          if (!accountselValList.includes(filterVal)) {
            await accountselValList.push(filterVal);
          }
        } else if (
          key.name === "removed" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterNm = key.itemData.label;
          let filterVal = key.itemData.value;
          if (userlist.includes(filterNm)) {
            let idx1 = userlist.indexOf(filterNm);
            userlist.splice(idx1, 1);
          }
          if (accountselValList.includes(filterVal)) {
            let idx = accountselValList.indexOf(filterVal);
            accountselValList.splice(idx, 1);
          }
        }
      }
      await this.setState({
        userList: userlist,
        accountValList: accountselValList,
        modalLoader: true,
      });
      this.handleVmlistRequest();
    }
  }

  getStringDate(cell, row) {
    let current_datetime = new Date(
      new Date(row.status_change_datetime).toGMTString()
    );
    var hours = current_datetime.getHours();
    var minutes = current_datetime.getMinutes();
    var month = current_datetime.getMonth() + 1;
    var day = current_datetime.getDate();
    var seconds = current_datetime.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    var tzAbbr = "";
    var dateObject = new Date(),
      dateString = dateObject + "",
      tzAbbr =
        dateString.match(/\(([^\)]+)\)$/) ||
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
      tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
    }

    return (
      <div style={{ textAlign: "center" }}>
        {current_datetime.getFullYear() +
          "-" +
          month +
          "-" +
          day +
          "  " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds +
          " " +
          tzAbbr}
      </div>
    );
  }

  async checkAll(isSelect, rows, e) {
    var data = [];
    var basicVmdata = [];
    if (isSelect) {
      Array.isArray(this.state.value) &&
        this.state.value.map((Vm) => {
          let hideIneligibleVmsInMainTbl =
            Vm.status === "ANALYSIS INELIGIBLE" &&
            (Vm.metricsPlatform === "Host" ||
              (Vm.metricsPlatform === "Host and Guest" &&
                Vm.analysisFailReason === null));

          if (
            Vm.iseligible === true &&
            this.isStaledsVms(Vm) &&
            !hideIneligibleVmsInMainTbl
          ) {
            data.push({
              basicVMId: Vm.basicvm_id,
              analysisSeqNum: Vm.analysisseqnum,
              bvmState: Vm.status,
              accountName: Vm.accountName,
              status_change_datetime: Vm.status_change_datetime,
              name: Vm.name,
              validationkey: Vm.validationkey,
              vmResultKeyMap: Vm.vmResultKeyMap,
              metricsPlatform: Vm.metricsPlatform,
              isVMAnalyzable: Vm.isVMAnalyzable,
            });
            basicVmdata.push(Vm.basicvm_id);
          }
        });

      await this.setState({
        reportData: data,
        result: basicVmdata,
      });
    } else {
      await this.setState({
        result: [],
        reportData: [],
      });
    }
    await this.isDisabled();
    await this.myFunction();
  }

  async onChange(row, isSelect, rowIndex, e) {
    if (isSelect) {
      await this.setState({
        result: [...this.state.result, row.basicvm_id],
        reportData: [
          ...this.state.reportData,
          {
            basicVMId: row.basicvm_id,
            analysisSeqNum: row.analysisseqnum,
            bvmState: row.status,
            accountName: row.accountName,
            status_change_datetime: row.status_change_datetime,
            name: row.name,
            validationkey: row.validationkey,
            vmResultKeyMap: row.vmResultKeyMap,
            metricsPlatform: row.metricsPlatform,
            isVMAnalyzable: row.isVMAnalyzable,
          },
        ],
      });
    } else {
      let remove = this.state.result.indexOf(row.basicvm_id);
      await this.setState({
        result: this.state.result.filter((_, key) => key !== remove),
        reportData: this.state.reportData.filter(
          (key) => key.basicVMId !== row.basicvm_id
        ),
      });
    }
    await this.setState({ result: [...new Set(this.state.result)] });
    await this.isDisabled();
    await this.myFunction();
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
  renderVmStatus(cell, Vm) {
    let platform = Vm.metricsPlatform === "Host" ? "(Host Metrics Only)" : "";

    let metricDtAvlDiv = <div></div>;
    /*if (Vm.metricDataAvalability !== undefined && Vm.metricDataAvalability !== null && Vm.metricDataAvalability.trim() !== "") {
            metricDtAvlDiv = (<div style={{marginLeft: "5px"}}><i className="fa fa-exclamation-circle" aria-hidden="true" data-toggle="tooltip" data-placement="right" title={Vm.metricDataAvalability} /></div>);
        }*/
    switch (Vm.status) {
      case "ANALYZING":
        return (
          <div className="textfieldCentered bgColor">
            <div>
              <i
                className="fa fa-circle-o-notch fa-spin"
                aria-hidden="true"
                data-toggle="tooltip"
                data-placement="right"
                title={Vm.status}
              />
            </div>
            <div>{Vm.status}</div>
            <div style={{ fontSize: "11px" }}>{Vm.analysisSubState}</div>
            {platform !== "" ? (
              <div style={{ fontSize: "12px" }}>{platform}</div>
            ) : null}
          </div>
        );
      case "ANALYSIS ISSUE":
        return (
          <div className="textfieldCentered">
            <Row>
              <Col sm="12" style={{ padding: "0px" }}>
                <div style={{ display: "inline-flex" }}>
                  <div>{Vm.status}</div>
                  {metricDtAvlDiv}
                </div>
                {Vm.analysisFailReason ? (
                  <i
                    style={{ position: "absolute", right: "10px" }}
                    className="fa fa-question-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={Vm.analysisFailReason}
                  />
                ) : null}
                {platform !== "" ? (
                  <div style={{ fontSize: "12px" }}>{platform}</div>
                ) : null}
              </Col>
            </Row>
          </div>
        );

      case "ANALYSIS INELIGIBLE":
        return (
          <div className="textfieldCentered">
            <Row>
              <Col sm="12" style={{ padding: "0px" }}>
                <div style={{ display: "inline-flex" }}>
                  <div>{Vm.status}</div>
                  {metricDtAvlDiv}
                </div>
                {Vm.analysisFailReason ? (
                  <i
                    style={{ position: "absolute", right: "10px" }}
                    className="fa fa-question-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={Vm.analysisFailReason}
                  />
                ) : null}
                {platform !== "" ? (
                  <div style={{ fontSize: "12px" }}>{platform}</div>
                ) : null}
              </Col>
            </Row>
          </div>
        );

      case "RESETTING":
        return (
          <div className="textfieldCentered bgColor">
            <label>
              <i
                className="fa fa-circle-o-notch fa-spin"
                aria-hidden="true"
                data-toggle="tooltip"
                data-placement="right"
                title={Vm.status}
              />
              <br></br>
              {Vm.status}
            </label>{" "}
          </div>
        );
      case "OPTIMALLY PARKED":
      case "CUSTOM PARKED":
      case "PARKED":
      case "OPTIMIZING":
        return (
          <div className="textfieldCentered">
            <Row>
              <Col sm="12" style={{ padding: "0px" }}>
                <a
                  href="#"
                  style={{ fontSize: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.freeTrialPlanSupport()) {
                      this.freeTrialPlan();
                    } else {
                      this.navigateToReport(Vm);
                    }
                  }}
                >
                  <div className="textfieldCentered">
                    <div style={{ display: "inline-flex" }}>
                      <div>{Vm.status}</div>
                      {metricDtAvlDiv}
                    </div>
                  </div>
                </a>
                {Vm.isResourceChanged === true ? (
                  <i
                    style={{ position: "absolute", right: "10px" }}
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={message.VMRersourceChangedFLagInfoTextAnalyzed}
                  />
                ) : null}
                {platform !== "" ? (
                  <div style={{ fontSize: "12px" }}>{platform}</div>
                ) : null}
              </Col>
            </Row>
          </div>
        );
      case "ANALYZED":
        return (
          <div className="textfieldCentered">
            <Row>
              <Col sm="12" style={{ padding: "0px" }}>
                <a
                  href="#"
                  style={{ fontSize: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.freeTrialPlanSupport()) {
                      this.freeTrialPlan();
                    } else {
                      this.navigateToReport(Vm);
                    }
                  }}
                >
                  <div style={{ display: "inline-flex" }}>
                    <div>{Vm.status}</div>
                    {metricDtAvlDiv}
                  </div>
                </a>

                {Vm.isResourceChanged === true ||
                Vm.isSameResizePreference === false ||
                Vm.isstaleds === true ? (
                  <i
                    style={{
                      position: "absolute",
                      right: "10px",
                    }}
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={`${
                      Vm.isResourceChanged === true
                        ? message.VMRersourceChangedFLagInfoTextAnalyzed
                        : ""
                    }\n${
                      Vm.isSameResizePreference === false
                        ? message.AnalyzedPreferenceMsg
                        : ""
                    }\n${Vm.isstaleds === true ? message.IsStaledsMsg : ""}`}
                  />
                ) : null}

                {platform !== "" ? (
                  <div style={{ fontSize: "12px" }}>{platform}</div>
                ) : null}
              </Col>
            </Row>
          </div>
        );
      default:
        return (
          <div className="textfieldCentered">
            <div style={{ display: "inline-flex" }}>
              <div>{Vm.status}</div>
              {metricDtAvlDiv}
            </div>
            {this.state.providerName === "AZURE" ? (
              platform !== "" ? (
                <div style={{ fontSize: "12px" }}>{platform}</div>
              ) : null
            ) : null}
          </div>
        );
    }
  }

  handleNext = async () => {
    const { reportData } = this.state;
    const timezone = jstz.determine();
    const timezoneid = timezone.name();
    let resultPayload = reportData.map((vm) => vm.basicVMId);
    await this.setState({ modalLoader: true, disabledBtn: true });
    const payload = {
      userGtmId: timezoneid,
      basicASPIDList: resultPayload,
    };
    fetch(Constdata.PARKING_VM_START_ANALYSIS_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.responseProcess(responseJson);
      })
      .catch((error) => {
        if (this.state.timerId !== 0) {
          clearInterval(this.state.timerId);
        }
        console.error(error);
        this.setState({
          disabledBtn: false,
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };
  responseProcess = async (response) => {
    this.setState({ disabledBtn: false });
    if (response.success === true) {
      await this.setState({
        togglemodalNext: false,
        isParkingFreeTrialSuccess: response.orgInfo.isParkingFreeTrialCompleted,
      });
      await this.handleVmlistRequest();
      if (this.freeTrialPlanSupport()) {
        await this.isDisabled();
        await this.preSelectVM();
      }
    } else if (response.success === false) {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: response.message,
        isErrorHeader: false,
        isRedirect: false,
      });
    } else if (response.status === 403 || response.status === 401) {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: message.SessionExpiredText,
        isErrorHeader: false,
      });
    } else {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    }
  };

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event, freeTrailPopup: false });
    if (this.state.isRedirect && !this.state.freeTrailPopup) {
      this.props.processLogut();
      this.props.history.push("/");
    }
  };

  async isDisabled() {
    const { reportData, isFreeTrialAnalysisStarted } = this.state;
    let isViewReportDisabled = false;
    let isAnalysisDisabled = false;
    let isResetDisabled = false;
    let recommendationDisabled = false;
    if (reportData.length === 0) {
      isViewReportDisabled = true;
      isAnalysisDisabled = true;
      isResetDisabled = true;
      recommendationDisabled = true;
    } else {
      reportData.map((key) => {
        var en =
          Array.isArray(this.state.value) &&
          this.state.value.find((e) => e.basicvm_id === key.basicVMId);
        switch (key.bvmState) {
          case "NOT ANALYZED":
            isViewReportDisabled = true;
            isResetDisabled = true;
            recommendationDisabled = true;
            break;
          case "ANALYZING":
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            recommendationDisabled = true;
            /*if(en.analysisSubState !== null && en.analysisSubState !== undefined && en.analysisSubState !== "" && en.analysisSubState.toLowerCase().includes("retrieving")){
                            isResetDisabled = true;
                        }*/
            break;
          case "ANALYSIS ISSUE":
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            recommendationDisabled = true;
            break;
          case "ANALYZED":
            isAnalysisDisabled = true;
            break;
          case "OPTIMIZING":
            isAnalysisDisabled = true;
            break;
          case "OPTIMALLY PARKED":
          case "CUSTOM PARKED":
          case "PARKED":
            recommendationDisabled = true;
            isAnalysisDisabled = true;
            break;
          case "RESETTING":
            recommendationDisabled = true;
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            isResetDisabled = true;
            break;
          case "ANALYSIS INELIGIBLE":
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            recommendationDisabled = true;
        }
      });
    }

    //Free Trial:- Only allowed resets for analysis issue and analysis failure VM states

    if (this.freeTrialPlanSupport()) {
      var disabledResetBtn = reportData.some(
        (e) =>
          e.bvmState !== "ANALYSIS ISSUE" &&
          e.bvmState !== "ANALYSIS INELIGIBLE"
      );
      if (disabledResetBtn) {
        isResetDisabled = true;
      }

      var disabledAnalysisBtn = reportData.some(
        (key) => key.isVMAnalyzable !== isFreeTrialAnalysisStarted
      );

      if (disabledAnalysisBtn) {
        isAnalysisDisabled = true;
      }
    }

    await this.setState({
      isReport: this.freeTrialPlanSupport() ? true : isViewReportDisabled,
      isPerformAnalysis: isAnalysisDisabled,
      isReset: isResetDisabled,
      isManageRecomm: this.freeTrialPlanSupport()
        ? true
        : recommendationDisabled,
    });
  }

  expandeRowRenderer(row) {
    return (
      <div
        style={{ textAlign: "center", display: "block ruby" }}
        id={row.basicvm_id + "expTable"}
      >
        <Table
          id="list_table"
          className="list_table_inner"
          style={{ width: "80%", position: "relative" }}
          align="center"
        >
          <thead>
            <th style={{ fontSize: "14px", zIndex: "0" }}>vCPU</th>
            <th style={{ fontSize: "14px", zIndex: "0" }}>vCPU Speed (GHz)</th>
            <th style={{ fontSize: "14px", zIndex: "0" }}>Memory (GB)</th>
            <th style={{ fontSize: "14px", zIndex: "0" }}>#Disks</th>
            <th style={{ fontSize: "14px", width: "26%", zIndex: "0" }}>
              Aggregate Disk IOPS/Throughput Limits
            </th>
            <th style={{ fontSize: "14px", width: "25%", zIndex: "0" }}>
              Aggregate Network Bandwidth Limit
            </th>
          </thead>
          <tbody>
            <tr id="list_tablerow">
              <td>{row.num_CPU}</td>
              <td>{row.cpuspeed / 1000 / 1000 / 1000}</td>
              <td>{row.max_Memory / 1024 / 1024 / 1024}</td>
              <td>{row.num_Disks}</td>
              <td>
                {row.aggregateIops}/{row.aggregateThroughput}Mbps
              </td>
              <td>{row.networkbandwidth}Mbps</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  vmSizeColFormater(cell, row) {
    var expTable = document.getElementById(row.basicvm_id + "expTable");
    let classnm = "fa fa-angle-down";
    if (expTable) {
      classnm = "fa fa-angle-up";
    } else {
      classnm = "fa fa-angle-down";
    }
    return (
      <Row style={{ display: "inline-flex" }}>
        <Col sm="10" style={{ padding: "0px" }}>
          <div style={{ display: "inline-flex" }}>
            <div className={window.innerWidth <= 1600 ? "vmsizeTbl" : ""}>
              {row.vmsize_Name}
            </div>
            {row.priceChangeMsg !== null ? (
              <div style={{ textAlign: "right", marginLeft: "10px" }}>
                <i
                  style={{ marginRight: "4px" }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="right"
                  title={row.priceChangeMsg}
                />
              </div>
            ) : null}
          </div>
        </Col>
        <Col sm="2" style={{ padding: "0px", paddingRight: "10px" }}>
          {" "}
          <i
            id={row.basicvm_id + "arr"}
            className={classnm}
            style={{ fontSize: "26px" }}
          ></i>
        </Col>
      </Row>
    );
  }

  componentWillMount() {
    for (var i = 0; i < this.state.VmFilters.length; i++) {
      this.state.filterList.push(this.state.VmFilters[i].label.toUpperCase());
    }
  }
  toggleReset = () => {
    this.setState((prevState) => ({
      modalReset: !prevState.modalReset,
    }));
  };
  togglePerfInfo = () => {
    this.setState((prevState) => ({
      perfInfoModal: !prevState.perfInfoModal,
    }));
  };
  toggleReportInfo = () => {
    this.setState((prevState) => ({
      reportInfoModal: !prevState.reportInfoModal,
    }));
  };
  toggleResetInfo = () => {
    this.setState((prevState) => ({
      resetInfoModal: !prevState.resetInfoModal,
    }));
  };
  toggleManageResizeInfo = () => {
    this.setState((prevState) => ({
      manageResizeInfoModal: !prevState.manageResizeInfoModal,
    }));
  };

  toggleTable = () => {
    this.setState((prevState) => ({
      modalTable: !prevState.modalTable,
    }));
  };

  toggleNext = () => {
    this.setState((prevState) => ({
      togglemodalNext: !prevState.togglemodalNext,
    }));
  };

  async handleVmlistRequest(isFromdidMount) {
    this.props.registerListners(true);
    if (isFromdidMount !== undefined && isFromdidMount === true) {
      await this.setState({ isFromDidMount: isFromdidMount });
    } else {
      await this.setState({ isFromDidMount: false });
    }
    if (this.state.clickResetStatus) {
      return false;
    }

    if (this.state.userList.length <= 0) {
      this.setState({ value: false, modalLoader: false });
      return false;
    }

    const payload = {
      accountList: this.state.userList,
      filterList: this.state.filterList,
      start: this.state.privious,
      max: 2147483647,
      searchStr: this.state.SearchText,
    };
    fetch(Constdata.PARKING_VM_LIST_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          let fetchingDS = false;
          if (
            this.props.dataSourcePayload &&
            this.props.dataSourcePayload.length >= 1 &&
            this.state.getAllConfigData &&
            this.state.getAllConfigData.length <= 0
          ) {
            fetchingDS = true;
          }

          if (responseJson.data === true) {
            this.timeoutId = setTimeout(() => {
              this.handleVmlistRequest(isFromdidMount);
            }, 15000);
          }

          const isFreeTrialAnalyzed = await this.freeTrialAnalysisList(
            responseJson.orgInfo
          );

          await this.setState({
            value: fetchingDS ? true : responseJson.data,
            configId1: responseJson.message,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
            userName: responseJson.orgInfo.userName,
            isParkingFreeTrialSuccess:
              responseJson.orgInfo.isParkingFreeTrialCompleted,
            isFreeTrialAnalysisStarted:
              responseJson.orgInfo.plan === "FREE_TRIAL"
                ? isFreeTrialAnalyzed
                : false,
          });
          let isFetching = false;
          var data = [];
          var notEligible = [];

          Array.isArray(this.state.value) &&
            this.state.value.map(async (key) => {
              if (key.hypervgeneration) {
                key.vmsize_Name =
                  key.vmsize_Name + " (" + key.hypervgeneration + ")";
              }

              let hideIneligibleVmsInMainTbl =
                key.status === "ANALYSIS INELIGIBLE" &&
                (key.metricsPlatform === "Host" ||
                  (key.metricsPlatform === "Host and Guest" &&
                    key.analysisFailReason === null));

              if (
                key.iseligible === true &&
                this.isStaledsVms(key) &&
                !hideIneligibleVmsInMainTbl
              ) {
                //Free paln:- when user logs in and no analysis is done, select all VM which are not analyzed

                if (
                  this.freeTrialPlanSupport() &&
                  this.state.isFreePlanNonAnalyzedSelected
                ) {
                  if (
                    isFreeTrialAnalyzed === key.isVMAnalyzable &&
                    key.status === "NOT ANALYZED"
                  ) {
                    this.setState({
                      result: [...this.state.result, key.basicvm_id],
                    });
                  }
                }

                if (this.state.isUpdated === true) {
                  this.state.result.map((value) => {
                    if (value === key.basicvm_id) {
                      data.push({
                        basicVMId: key.basicvm_id,
                        analysisSeqNum: key.analysisseqnum,
                        bvmState: key.status,
                        accountName: key.accountName,
                        status_change_datetime: key.status_change_datetime,
                        name: key.name,
                        validationkey: key.validationkey,
                        vmResultKeyMap: key.vmResultKeyMap,
                        metricsPlatform: key.metricsPlatform,
                        isVMAnalyzable: key.isVMAnalyzable,
                      });
                      this.setState({ reportData: data });
                      this.isDisabled();
                    }
                  });
                } else {
                  if (
                    this.state.reportData !== undefined &&
                    this.state.reportData.length > 0
                  ) {
                    var Selection = this.state.reportData.reduce(
                      (acc, obj) =>
                        obj.basicVMId === key.basicvm_id ? ++acc : acc,
                      0
                    );
                    if (Selection > 0) {
                      data.push({
                        basicVMId: key.basicvm_id,
                        analysisSeqNum: key.analysisseqnum,
                        bvmState: key.status,
                        accountName: key.accountName,
                        status_change_datetime: key.status_change_datetime,
                        name: key.name,
                        validationkey: key.validationkey,
                        vmResultKeyMap: key.vmResultKeyMap,
                        metricsPlatform: key.metricsPlatform,
                        isVMAnalyzable: key.isVMAnalyzable,
                      });
                    }
                  }
                }

                if (
                  key.status === "ANALYZING" ||
                  key.status === "VALIDATING" ||
                  key.status === "RESETTING"
                ) {
                  isFetching = true;
                }
              } else {
                notEligible.push(key.basicvm_id);
              }
            });

          await this.setState({
            isFreePlanNonAnalyzedSelected: false,
            fetchFlag: isFetching,
            reportData: data,
            notEligibleVms: notEligible,
          });
          if (!this.state.fetchFlag) {
            if (this.state.timerId !== 0) {
              clearInterval(this.state.timerId);
              await this.setState({ timerId: 0 });
            }
          } else {
            if (this.state.timerId === 0) {
              const timer = setInterval(() => {
                this.handleVmlistRequest();
              }, 10000);
              await this.setState({
                timerId: timer,
                fetchFlag: false,
              });
            }
          }

          await this.isDisabled();
          if (isFromdidMount !== undefined && isFromdidMount === true) {
            await this.preSelectVM();
          }
          await this.myFunction();
          if (!this.state.clickResetStatus) {
            await this.setState({ modalLoader: false });
          }
        } else if (
          responseJson.success === false &&
          responseJson.message === "No organization found"
        ) {
          await this.setState({ fetchFlag: false });
          if (this.state.timerId !== 0) {
            clearInterval(this.state.timerId);
            await this.setState({ timerId: 0 });
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          if (this.state.timerId === 0) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.SessionExpiredText,
              isErrorHeader: false,
            });
          } else {
            clearInterval(this.state.timerId);
            await this.setState({ fetchFlag: false, timerId: 0 });
          }
        } else {
          if (this.state.timerId === 0) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.ServerError,
              isErrorHeader: false,
              isRedirect: false,
            });
          } else {
            clearInterval(this.state.timerId);
            await this.setState({ fetchFlag: false, timerId: 0 });
          }
        }
      })
      .catch((error) => {
        if (this.state.timerId !== 0) {
          clearInterval(this.state.timerId);
        }
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }

  async preSelectVM() {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(this.state.value) &&
      this.state.value.map(async (row) => {
        if (
          this.state.result !== undefined &&
          this.state.result.includes(row.basicvm_id)
        ) {
          await this.setState({
            reportData: [
              ...this.state.reportData,
              {
                basicVMId: row.basicvm_id,
                analysisSeqNum: row.analysisseqnum,
                bvmState: row.status,
                accountName: row.accountName,
                status_change_datetime: row.status_change_datetime,
                name: row.name,
                validationkey: row.validationkey,
                vmResultKeyMap: row.vmResultKeyMap,
                metricsPlatform: row.metricsPlatform,
                isVMAnalyzable: row.isVMAnalyzable,
              },
            ],
          });
        }
      });

    await this.setState({ result: [...new Set(this.state.result)] });
    if (this.state.reportData.length > 1) {
      await this.setState({
        reportData: this.removeDuplicates(this.state.reportData, "basicVMId"),
      });
    }
    if (this.state.reportData.length !== 0) {
      await this.isDisabled();
    } else {
      this.setState({ isReport: true });
    }
    //this.checkParentChekcbox();
  }

  async myFunction() {
    var input, filter, table, tr, td, cell, i;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("list_table");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
      tr[i].style.display = "none";
      td = tr[i].getElementsByTagName("td");
      for (var j = 0; j < td.length; j++) {
        cell = tr[i].getElementsByTagName("td")[j];
        if (cell) {
          if (cell.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            break;
          }
        }
      }
    }
  }

  handleReport = () => {
    this.props.history.push({
      pathname: "/parkingReport",
      state: { data: this.state.reportData, listTb: "1" },
    });
  };

  navigateToReport = (ds) => {
    var rptDtd = [];
    rptDtd.push({
      basicVMId: ds.basicvm_id,
      analysisSeqNum: ds.analysisseqnum,
      bvmState: ds.status,
      accountName: ds.accountName,
      status_change_datetime: ds.status_change_datetime,
      name: ds.name,
      validationkey: ds.validationkey,
      vmResultKeyMap: ds.vmResultKeyMap,
      metricsPlatform: ds.metricsPlatform,
    });
    this.props.history.push({
      pathname: "/parkingReport",
      state: { data: rptDtd },
    });
  };

  toggleManageParkingPopup = () => {
    if (!this.state.toggleManageParkingModal) {
      this.setState({ modalLoader: true });
      var payload = {
        duration: "current",
        recList: this.state.reportData,
      };
      fetch(Constdata.PARKING_REPORT_URL, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson.success === true) {
            var manageValData = responseJson.data.resultList;

            manageValData.map((key, i) => {
              this.state.reportData.forEach(function (vm) {
                if (vm.name === key.vmname) {
                  key["id"] = vm.basicVMId;
                }
              });
            });
            await this.setState((prevState) => ({
              manageModalData: manageValData,
              modalLoader: false,
              toggleManageParkingModal: !prevState.toggleManageParkingModal,
            }));
          } else if (
            responseJson.status === 403 ||
            responseJson.status === 401
          ) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.SessionExpiredText,
              isErrorHeader: false,
            });
          } else {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: responseJson.message,
              isErrorHeader: false,
              isRedirect: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.CacheErrorText,
            isErrorHeader: false,
            isRedirect: false,
          });
        });
    } else {
      this.setState((prevState) => ({
        manageModalData: [],
        toggleManageParkingModal: !prevState.toggleManageParkingModal,
      }));
    }
  };

  freeTrialPlan() {
    this.setState({
      freeTrailPopup: true,
      errorPopup: true,
      errormessage: "Only Available in Paid Plan",
      isErrorHeader: true,
    });
  }

  freeTrialPlanSupport() {
    if (this.state.isFreeTrial) {
      if (this.state.userName === "support@serralabs.com") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isStaledsVms(Vm) {
    return (
      Vm.isstaleds === false ||
      (Vm.isstaleds === true && Vm.status === "ANALYZED")
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataSourceResponse !== this.props.dataSourceResponse) {
      this.handleVmlistRequest(true);
    }
  }

  async freeTrialAnalysisList(orgData) {
    if (orgData && orgData.freeTrialAnalysisList !== null) {
      return await orgData.freeTrialAnalysisList.includes("P");
    }
  }

  render() {
    const num = 1000;
    const num2 = 1024;
    const space = `Please confirm to perform parking analysis of the selected VM:  \n ${this.state.reportData.map(
      (Vm) => {
        return Vm.name;
      }
    )}`;
    const ineligibleCount =
      Array.isArray(this.state.value) &&
      this.state.value.reduce(
        (acc, obj) =>
          obj.ineligibility.insufficientMetricNames !== null ||
          obj.ineligibility.otherReason !== null ||
          obj.ineligibility.missingMetricNames !== null ||
          !this.isStaledsVms(obj)
            ? ++acc
            : acc,
        0
      );

    const ineligibleVmList =
      Array.isArray(this.state.value) &&
      this.state.value.filter((Vm) => {
        return (
          !this.isStaledsVms(Vm) ||
          (Vm.status === "ANALYSIS INELIGIBLE" &&
            (Vm.metricsPlatform === "Host" ||
              (Vm.metricsPlatform === "Host and Guest" &&
                Vm.analysisFailReason === null)))
        );
      });

    return (
      <div>
        <div className="container-verf" role="main">
          <div
            className="pageBgWhite vmFilterDiv"
            style={{ textAlign: "left" }}
          >
            <div
              style={{
                padding: "10px 20px",
                alignItem: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "25%" }}>
                <Input
                  className="form-control"
                  id="myInput"
                  placeholder="Search VMs"
                  onKeyUp={this.myFunction}
                  disabled={this.state.refreshFlag}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                  width: "25%",
                }}
              >
                {ineligibleCount > 0 ? (
                  <span>
                    {" "}
                    <label className="labelColor">VM Warning Table</label>
                    <i
                      className="fa fa-warning"
                      onClick={this.toggleTable}
                      style={{
                        fontSize: "20px",
                        color: "#e6da12",
                        paddingLeft: "5px",
                      }}
                      data-toggle="tooltip"
                      data-placement="right"
                      title="View VM Warnings"
                    >
                      {" "}
                    </i>
                  </span>
                ) : null}
              </div>

              <div className="multiSelectFilter">
                <div style={{ paddingRight: "5px" }}>
                  <label className="labelColor">Filter by Status:</label>
                </div>
                <div style={{ width: "75%" }}>
                  <MultiSelectComponent
                    id="checkbox"
                    cssClass={
                      !this.state.refreshFlag
                        ? "multiSelectFilter-input"
                        : "multiSelectFilter-input-disabled"
                    }
                    dataSource={this.state.VmFilters}
                    fields={{ text: "label", value: "value" }}
                    placeholder="Filter by Status"
                    mode="CheckBox"
                    selectAllText="All"
                    unSelectAllText="All"
                    showSelectAll={true}
                    value={[...new Set(this.state.VmFiltersValues)]}
                    closePopupOnSelect={true}
                    enableSelectionOrder={false}
                    showDropDownIcon={true}
                    selectedAll={(value) => this.handleFilter(value)}
                    select={(value) => this.handleFilter(value)}
                    removed={(value) => this.handleFilter(value)}
                    enabled={!this.state.refreshFlag}
                  >
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </div>
              </div>
            </div>
          </div>
          <div className="pageBgWhite vmListDiv">
            <div
              class="btn-toolbar pageBgWhite"
              role="toolbar"
              aria-label="Toolbar with button groups"
              style={{
                display: "flex",
                paddingBottom: "10px",
                paddingTop: "10px",
                position: "sticky",
                top: "61px",
                zIndex: "110",
                justifyContent: "flex-end",
              }}
            >
              <div class="btn-group" role="group" aria-label="First group">
                <Button
                  onClick={this.toggleNext}
                  id={
                    this.state.isPerformAnalysis === true
                      ? "listDisabledBtn"
                      : "listBtn"
                  }
                  disabled={this.state.isPerformAnalysis}
                >
                  Perform Analysis
                </Button>
                <Button
                  className="btn listInfoBtn"
                  onClick={this.togglePerfInfo}
                >
                  <i className="fa fa-info" />
                </Button>
              </div>
              {/* <div class="btn-group" role="group" aria-label="Second group" style={{marginLeft: "30px"}}>
                                    <Button onClick={this.toggleManageParkingPopup} id={this.state.isManageRecomm === true ? "listDisabledBtn" : "listBtn"} disabled={this.state.isManageRecomm}>Manage Parking</Button>
                                    <Button className="btn listInfoBtn info_btn" onClick={this.toggleManageResizeInfo}><i className="fa fa-info" /></Button>
                                </div> */}
              <div
                class="btn-group"
                role="group"
                aria-label="Third group"
                style={{ marginLeft: "10px" }}
              >
                <Button
                  onClick={this.handleReport}
                  id={
                    this.state.isReport === true ? "listDisabledBtn" : "listBtn"
                  }
                  disabled={this.state.isReport}
                >
                  View Report
                </Button>
                <Button
                  className="btn listInfoBtn"
                  onClick={this.toggleReportInfo}
                >
                  <i className="fa fa-info" />
                </Button>
              </div>
              <div
                class="btn-group"
                role="group"
                aria-label="Third group"
                style={{ marginLeft: "10px" }}
              >
                <Button
                  onClick={this.toggleReset}
                  id={
                    this.state.isReset === true ? "listDisabledBtn" : "listBtn"
                  }
                  disabled={this.state.isReset}
                  style={{ width: "130px" }}
                >
                  Reset State
                </Button>
                <Button
                  className="btn listInfoBtn"
                  onClick={this.toggleResetInfo}
                >
                  <i className="fa fa-info" />
                </Button>
              </div>
            </div>

            <BootstrapTable
              bordered={false}
              id="list_table"
              bodyClasses="tbodyColor"
              classes={
                this.state.refreshFlag
                  ? "table-responsive list_table_height list_table_tbody"
                  : "table-responsive list_table_height"
              }
              bootstrap4={true}
              keyField="basicvm_id"
              data={
                Array.isArray(this.state.value)
                  ? this.state.value
                    ? this.state.value.length !==
                      this.state.notEligibleVms.length
                      ? this.state.value
                      : []
                    : []
                  : []
              }
              columns={this.state.columns}
              selectRow={{
                mode: "checkbox",
                clickToSelect: false,
                clickToExpand: true,
                onSelect: this.onChange,
                onSelectAll: this.checkAll,
                selected: this.state.result,
              }}
              hiddenRows={this.state.notEligibleVms}
              expandRow={this.state.expandRow}
              wrapperClasses="table-responsive"
              noDataIndication={() =>
                (this.state.isFromDidMount && this.state.value === true) ||
                (!this.state.isFromDidMount &&
                  this.state.value === true &&
                  !this.state.refreshFlag)
                  ? "Fetching VMs"
                  : (this.state.isFromDidMount &&
                      (this.state.value === false ||
                        this.state.value.length === 0)) ||
                    (!this.state.isFromDidMount &&
                      (this.state.value === false ||
                        this.state.value.length === 0) &&
                      !this.state.refreshFlag) ||
                    this.state.value.length === this.state.notEligibleVms.length
                  ? "No VMs Found"
                  : "   "
              }
              loading={this.state.refreshFlag}
              // overlay={overlayFactory({
              //   spinner: true,
              //   text: "Refreshing VM Table...",
              //   background: "rgb(204, 208, 209,0.3)",
              // })}
            />
          </div>
          <Modal
            toggle={this.toggleNext}
            size="lg"
            isOpen={this.state.togglemodalNext}
            backdrop="static"
          >
            <ModalHeader
              id="modalheader_list"
              cssModule={{ "modal-title": "modalHeader" }}
              toggle={this.toggleNext}
            >
              <div
                className="modalCompanyName"
                dangerouslySetInnerHTML={{
                  __html: message.SerraLabsModalHeader,
                }}
              />
              <div className="modalTitle">
                {" "}
                VM Parking Analysis Confirmation
              </div>
            </ModalHeader>
            <ModalBody>
              <div>
                <div className="box-titel" id="modaldis_list">
                  <div>
                    <p>
                      {" "}
                      {this.state.reportData.length > 1 ? (
                        " Please confirm to perform parking analysis of " +
                        this.state.reportData.length +
                        " selected VMs"
                      ) : (
                        <div className="new-line">{space}</div>
                      )}
                    </p>
                  </div>
                </div>
                <div className="footnotePopup" style={{ marginBottom: "20px" }}>
                  {this.freeTrialPlanSupport() ? (
                    <div
                      style={{ paddingBottom: "7px" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          "In Free Trial, it is highly recommended that all unanalyzed VMs be analyzed so that you can get an overall view of possible savings using parking for your cloud.",
                      }}
                    />
                  ) : null}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: message.PerformAnalysisParkingConfirmNote,
                    }}
                  />
                  {this.freeTrialPlanSupport() &&
                  Array.isArray(this.state.value) &&
                  !this.state.value.some(
                    (vm) =>
                      vm.iseligible === true &&
                      this.isStaledsVms(vm) &&
                      vm.status !== "NOT ANALYZED" &&
                      !(
                        vm.status === "ANALYSIS INELIGIBLE" &&
                        (vm.metricsPlatform === "Host" ||
                          (vm.metricsPlatform === "Host and Guest" &&
                            vm.analysisFailReason === null))
                      )
                  ) ? (
                    <div
                      style={{ paddingTop: "7px" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          "Please note that after starting parking analysis in Free Plan, data sources cannot be changed.",
                      }}
                    />
                  ) : null}
                </div>
                <Form>
                  <FormGroup>
                    <div align="center">
                      <Button
                        disabled={this.state.disabledBtn}
                        id="confirmbtn_list"
                        onClick={this.handleNext}
                      >
                        Yes
                      </Button>
                      <Button
                        outline
                        id="canclebtn_list"
                        onClick={() => {
                          this.toggleNext();
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            size="xl"
            style={{ maxWidth: "1600px", width: "95%", margin: "10px auto" }}
            isOpen={this.state.modalTable}
            toggle={this.toggleTable}
            backdrop={"static"}
          >
            <ModalHeader
              toggle={this.toggleTable}
              id="modalheader_list"
              cssModule={{ "modal-title": "modalHeader" }}
            >
              <div
                className="modalCompanyName"
                dangerouslySetInnerHTML={{
                  __html: message.SerraLabsModalHeader,
                }}
              />
              <div className="modalTitle"> VM Warning Table</div>
            </ModalHeader>
            <ModalBody>
              <IneligibleVMList
                providerName={this.state.providerName}
                vmlist={ineligibleVmList}
                cpoAnalysisType="Parking"
              />
            </ModalBody>
            <ModalFooter>
              <Button
                className="btnBg"
                onClick={() => {
                  this.toggleTable();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.modalReset}
            toggle={this.toggleReset}
            size="lg"
            backdrop={"static"}
          >
            <ModalHeader
              id="modalheader_list"
              cssModule={{ "modal-title": "modalHeader" }}
              toggle={this.toggleReset}
            >
              <div
                className="modalCompanyName"
                dangerouslySetInnerHTML={{
                  __html: message.SerraLabsModalHeader,
                }}
              />
              <div className="modalTitle"> Reset State </div>
            </ModalHeader>
            <ModalBody>
              <div>
                <div className="box-titel" id="modaldis_list">
                  <div>
                    <p style={{ padding: "0px 74px" }}>
                      {this.state.reportData.length > 1
                        ? "Please confirm to reset state of " +
                          this.state.reportData.length +
                          " VMs."
                        : "Please confirm to reset state of the selected VM : " +
                          (this.state.reportData[0] !== undefined
                            ? this.state.reportData[0].name
                            : "")}
                    </p>{" "}
                  </div>
                </div>
                <Form>
                  <FormGroup>
                    <div align="center">
                      <Button id="confirmbtn_list" onClick={this.handelReset}>
                        Yes
                      </Button>
                      <Button
                        outline
                        id="canclebtn_list"
                        onClick={() => {
                          this.toggleReset();
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </ModalBody>
          </Modal>
        </div>
        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        <Modal
          size="lg"
          isOpen={this.state.perfInfoModal}
          toggle={this.togglePerfInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.togglePerfInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Perform Analysis</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.PerformParkingAnalysisInfoText,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.StartAnalysisFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.togglePerfInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.manageResizeInfoModal}
          toggle={this.toggleManageResizeInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleManageResizeInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Manage Resizing</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.ManageParkingInfoText,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.ManageParkingFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleManageResizeInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.reportInfoModal}
          toggle={this.toggleReportInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleReportInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">View Report</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.GenerateParkingReportInfoText0,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{ __html: message.ShowReportFootNote }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleReportInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.resetInfoModal}
          toggle={this.toggleResetInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleResetInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Reset State</div>
          </ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: message.ResetInfoText }} />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{ __html: message.ResetStateFootNote }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleResetInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="xl"
          style={{ maxWidth: "1600px", width: "95%", margin: "10px auto" }}
          isOpen={this.state.toggleManageParkingModal}
          toggle={this.toggleManageParkingPopup}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleManageParkingPopup}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Parking Optimization</div>
          </ModalHeader>
          <ModalBody>
            <ManageParkingRecommendation
              cancelUserParking={this.toggleManageParkingPopup}
              data={this.state.manageModalData}
              handleFetch={this.handleVmlistRequest}
              isFreeTrial={this.state.isFreeTrial}
              userName={this.state.userName}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataSourcePayload: state.dataSourcePayload.payload,
    companyDetails: state.companyDetails.data,
    dataSourceResponse: state.dataSourceRes.dataSourceResponse,
  };
};

export default connect(mapStateToProps, null)(withRouter(VMParkingList));
