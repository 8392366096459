import React, {Component} from 'react';
// import './test.css';
class ResingBenefitTextComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isAsp : (this.props.isAsp !== undefined ? this.props.isAsp : false),
          entityType: this.props.entityType !== undefined ? this.props.entityType : (this.props.isAsp !== undefined && this.props.isAsp === true) ? "asp" : "vm"
        };
      }
    render() {        
        const {data} = this.props;
        const {title} = this.props;
        const {affectString} = this.props;
        const {valResult,renderIcon} = this.props;
        const Y = <img style ={{margin: "16px 0px 0px 5px"}} className="no-box-sizing" src={require('../../images/ValidationResult_Y.png')} title="Good" />;
        const N = <img style ={{margin: "16px 0px 0px 5px"}} className="no-box-sizing" src={require('../../images/ValidationResult_N.png')} title="Poor" />;
        const NA = <img style ={{margin: "16px 0px 0px 5px"}} className="no-box-sizing-na" src={require('../../images/ValidationResult_NA.png')} title="Not Applicable" />;
        const M = <img style ={{margin: "16px 0px 0px 5px"}} className="no-box-sizing" src={require('../../images/ValidationResult_M.png')} title="Fair" />;
        const S = <img style ={{margin: "auto 5px auto 5px"}}  src={require('../../images/ValidationResult_Skipped.png')} title="Skipped" />;
        const Star = <img style ={{margin: "16px 0px 0px 5px"}} className="no-box-sizing" src={require('../../images/ValidationResult_Star.png')} title="Insignificant Data" />;
        let resIcon = "";
        if(renderIcon){
            resIcon =   valResult=== "Y" ? Y :
                        valResult === "N" ? N :
                        valResult === "N/A" ? NA :                                        
                        valResult === "M" ? M:
                        valResult === "*" ? Star : S;
        }
        return(
            <span>
                <div className="div-center-ul">                    
                    <ul className="ul-bullet-inside">
                        <li className="resize-ben-title-text"><span>{title} </span>{resIcon}</li>
                        {data !==undefined ? data.map((key) => {
                            return (
                                    ((key.includes("Due") || key.includes("due")) ?
                                        <li className="li-result-nobullet-bold">{key}</li> :
                                        <li className="li-result-nobullet">{key}</li>)
                                    );
                            }) : null
                        } 
                        {
                            (affectString !== undefined && affectString !== null && affectString !== "") ? 
                                <li className="li-result-nobullet">{affectString}</li> 
                                : null
                        }
                    </ul>									
                </div>                 
            </span>
                );
    }
};
export default ResingBenefitTextComponent;                                
