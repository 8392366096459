import React, { Component } from 'react';
import { Table, Button, Input, Row, Col, Modal, ModalHeader, ModalBody, Form, FormGroup } from 'reactstrap';
import * as Constdata from '../../Constants/ApiConstants';
import SerraLoader from '../CommonComponent/Loader';
import SerraError from '../CommonComponent/Error';
import * as message from '../../Constants/MessageConstant';
import Hashmap from 'hashmap';
import BootstrapTable from 'react-bootstrap-table-next';
import jstz from 'jstz';
import { connect } from 'react-redux';

class ManageUserRecommendation extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.toggleProceedConfirm = this.toggleProceedConfirm.bind(this);
        this.confirmRecommendation = this.confirmRecommendation.bind(this);
        this.setErrorPopupState= this.setErrorPopupState.bind(this);
        this.renderRecommendationCell = this.renderRecommendationCell.bind(this);
        this.thClicked = this.thClicked.bind(this);
        this.renderExpandedRow = this.renderExpandedRow.bind(this);
        this.renderVmStatus = this.renderVmStatus.bind(this);
        this.renderSelect = this.renderSelect.bind(this);
        this.getStringDate = this.getStringDate.bind(this);
        this.confirmResizeChange = this.confirmResizeChange.bind(this);
        this.cancelResizeChange = this.cancelResizeChange.bind(this);
        this.isAnythingChanged = this.isAnythingChanged.bind(this);
        this.resizingCompletedClick = this.resizingCompletedClick.bind(this);
        this.state = {
            value : this.props.data,
            objectiveBvmMap : new Hashmap(),
            initialBvmMap : new Hashmap(),
            confirmStart:false,            
            modalLoader:false,
            errorPopup:false,
            expandKeyValue:new Hashmap(),
            exapanding:[],
            errormessage :"",
            changeResizeConfirm:false,
            changeResizeVm: null,
            changeResizeKey : null,            
            changeResizeSelectedObjective: null,
            toggleProceed: false,
            proceedConfirmText:"",
            imChanged: false,
            isAsp: this.props.isAsp !== undefined ? this.props.isAsp : false,
            entityType : this.props.entityType
        };        
        this.columns = [{
            dataField: (this.props.isAsp ? 'basicASPId': this.props.entityType === "db" ? 'basicentity_id': 'basicVMId'),
            text: 'ID',
            hidden: true
        },
        {
            dataField: 'name',
            text: (this.props.entityType === "db" ? 'Server Name_X_DB Name': 'Name'),
            headerStyle: {
                width: (this.props.entityType === "db" ? '200px': '180px'),
                zIndex:'100',
                wordBreak:"break-word"
            },
            style:{
                pointerEvents:"none",
                wordBreak:"break-word"
            }
        },
        {
            dataField: 'accountName',
            text: 'Metric Data Source',
            headerStyle: {
                width: '180px',
               wordBreak:"break-word",
                zIndex:'100'
            },
            style:{
                pointerEvents:"none",
                wordBreak:"break-word"
            }
        },
        {
            dataField: 'selection',
            isDummyField: true,
            text: 'Benefits & Drawbacks',
            formatter: this.renderRecommendationCell,
            attrs: { name: 'dummyid' },
            headerStyle:{
                zIndex:'100'
            }
        },
        {
            dataField: (this.props.isAsp ? 'aspState' : ((this.props.entityType !== undefined && this.props.entityType === "db") ? 'dbState' : 'bvmState')),
            text: 'Status',
            formatter:this.renderVmStatus,
            headerStyle: {
                padding: '0px !important',
                width: '114px',
                zIndex:'100'
            },
            style:{
                
                wordBreak:"break-word"
            }
        },
        {
            dataField: 'status_change_datetime',
            text: 'When',
            formatter: this.getStringDate,
            headerStyle: {
                width: '100px',
                zIndex:'100'
            },
            style:{
                pointerEvents:"none",
                wordBreak:"break-word"
            }
        },
        {
            dataField: 'action',
            isDummyField: true,
            text: 'Action',
            formatter: this.renderSelect,
            headerStyle: {
                width:'180px',
                zIndex:'100'     
            },
            style:{
                wordBreak:"break-word"
            }
        }];
            
        this.expandRow = {
                showExpandColumn: false,
                onlyOneExpanding: false,
                animation:"grow" ,
                renderer: this.renderExpandedRow,
                expanded: this.state.exapanding
        };
    }
    
    async componentDidMount (){
        var dummies = document.getElementsByName("dummyid");
        for(var i=0 ; i<dummies.length; i++){
            dummies[i].setAttribute('style', 'padding:0px !important');
        }
        var {objectiveBvmMap, initialBvmMap} = this.state;
        this.state.value.map((Vm) => {
            let obj = "";
            obj = Vm.validationPerfType !== null && Vm.validationPerfType !== undefined? Vm.validationPerfType : "";
            let isOnlyValidated = obj !=="";
            if(Vm.resizePerfType !== null && Vm.resizePerfType !== undefined){
                obj = Vm.resizePerfType ;
                isOnlyValidated = false;
            } 
            var idVal = this.state.isAsp ? Vm.basicASPId : Vm.basicVMId;
            if(this.state.entityType === "db"){
                idVal = Vm.basicentity_id;
            }
            if(obj !== ""){
                switch(obj){
                    case "Aggressive":
                         objectiveBvmMap.set(idVal, Vm.vmResultKeyMap.Aggressive);
                        if(!isOnlyValidated){
                            initialBvmMap.set(idVal, Vm.vmResultKeyMap.Aggressive);
                        }
                         document.getElementById(idVal+"agg").checked = true;
                        break;
                    case "Balanced":
                         objectiveBvmMap.set(idVal, Vm.vmResultKeyMap.Balanced);
                        if(!isOnlyValidated){
                           initialBvmMap.set(idVal, Vm.vmResultKeyMap.Aggressive);
                        }
                         document.getElementById(idVal+"bal").checked = true;
                        break;
                    case "Conservative":
                        objectiveBvmMap.set(idVal, Vm.vmResultKeyMap.Conservative);
                        if(!isOnlyValidated){
                            initialBvmMap.set(idVal, Vm.vmResultKeyMap.Aggressive);
                        }
                        document.getElementById(idVal+"con").checked = true;
                        break;
                } 
                if(document.getElementById(idVal+"chk") !== null && document.getElementById(idVal+"chk") !== undefined){
                    document.getElementById(idVal+"chk").disabled = false;
                }
            } else {
                if(document.getElementById(idVal+"chk") !== null && document.getElementById(idVal+"chk") !== undefined){
                    document.getElementById(idVal+"chk").disabled = true;
                }
            }
        });
        await this.setState({objectiveBvmMap : objectiveBvmMap, initialBvmMap:initialBvmMap});   
        await this.isAnythingChanged();
    }
    
    async isAnythingChanged (){
        if(this.state.initialBvmMap.size !== this.state.objectiveBvmMap.size){
            await this.setState({imChanged: true});
        } else if(this.state.objectiveBvmMap.size > 0){
            var initialMap = this.state.initialBvmMap;
            var isChange = false;
            this.state.objectiveBvmMap.forEach(function(value, key) {
                let resizeBy = document.getElementById(key+"sel").value ;
                let completedMark = document.getElementById(key+"chk").checked ;
                let bvm ={basicvmId:key, resultKey:value, resizedBy:resizeBy, markResizingCompleted:completedMark};
                let inibvm = initialMap.get(key);
                if(inibvm !== null && inibvm !== undefined){
                    let initialBvm ={basicvmId:key, resultKey:inibvm, resizedBy:resizeBy, markResizingCompleted:false};
                    let inibvmstr = JSON.stringify(initialBvm);
                    let newbvmstr = JSON.stringify(bvm);
                    if(inibvmstr !== newbvmstr ){
                        isChange = true;
                        return false;
                    }
                } else {
                    isChange = true;
                    return false;
                }                
            });
            await this.setState({imChanged: isChange});
        } else {
            await this.setState({imChanged: false});
        }
    }
    
    resizingCompletedClick = async ()=>{
        await this.isAnythingChanged();
    }
    
    toggleProceedConfirm = async () => {
        
        if(this.state.toggleProceed === false && this.state.objectiveBvmMap.size > 0){
            let proceedConfirmText = "";
            if(this.state.objectiveBvmMap.size === 1){
                let completedMark = false;
                let bvmid = 0;
                this.state.objectiveBvmMap.forEach(function(value, key) {
                    completedMark = document.getElementById(key+"chk").checked ;
                    bvmid = key;
                });
                let isAsp = this.state.isAsp;
                let entityType = this.state.entityType;
                var bvm = this.state.value.filter(function(vm) {
                    let idVal = isAsp ? vm.basicASPId :vm.basicVMId;
                    if(entityType === "db"){
                        idVal = vm.basicentity_id;
                    }
                    return idVal === bvmid;
                })[0];
                proceedConfirmText = bvm.name +" has been selected for resizing ";
                if(completedMark === true && this.state.initialBvmMap.size === 0){
                    proceedConfirmText = proceedConfirmText +" and marked \"Resizing completed\" (which cannot be undone)";
                } else if(completedMark === true && this.state.initialBvmMap.size === 1){
                    proceedConfirmText = bvm.name +" has been marked \"Resizing completed\" (which cannot be undone)";
                }
            } else {
                let anymarkedCompleted = false;
                this.state.objectiveBvmMap.forEach(function(value, key) {
                    anymarkedCompleted = document.getElementById(key+"chk").checked ;
                    if(anymarkedCompleted){
                        return true;
                    }
                });
                if(anymarkedCompleted){
                    if(this.state.isAsp){
                        proceedConfirmText = this.state.objectiveBvmMap.size +" ASPs have been selected for resizing and/or marked \"Resizing completed\" (which cannot be undone)";
                    } else {
                        if(this.state.entityType === "db"){
                            proceedConfirmText = this.state.objectiveBvmMap.size +" DBs have been selected for resizing and/or marked \"Resizing completed\" (which cannot be undone)";
                        } else {
                            proceedConfirmText = this.state.objectiveBvmMap.size +" VMs have been selected for resizing and/or marked \"Resizing completed\" (which cannot be undone)";
                        }
                    }
                } else {
                    if(this.state.isAsp){
                        proceedConfirmText = this.state.objectiveBvmMap.size +" ASPs have been selected for resizing.";
                    } else {
                        if(this.state.entityType === "db"){
                            proceedConfirmText = this.state.objectiveBvmMap.size +" DBs have been selected for resizing.";
                        } else {
                            proceedConfirmText = this.state.objectiveBvmMap.size +" VMs have been selected for resizing.";
                        }
                    }
                }
                
            }
            await this.setState({proceedConfirmText : proceedConfirmText});
        } else {
            await this.setState({proceedConfirmText : ""});
        }
        await this.setState(prevState => ({
          toggleProceed: !prevState.toggleProceed
        }));
    }
    
    renderSelect(cell, Vm){
        let defaultValue = "Manually by User";
        let idVal = this.state.isAsp ? Vm.basicASPId :Vm.basicVMId;
        if(this.state.entityType === "db"){
            idVal = Vm.basicentity_id;
        }
        return (
                <div>
                    <div>
                        <select id={idVal+"sel"} value={defaultValue}>
                            <option value="Manually by User">Manually by User</option>
                            <option value={`Automatic by `+this.props.companyDetails.ServiceName} disabled>Automatic by {this.props.companyDetails.ServiceName}</option>
                        </select>
                    </div>
                    <div style={{display:"inline-flex", marginTop:"20px", alignItems:"center"}}>
                        <div className="chk_container">
                            <Input type="checkbox" className="form-check-input-nontable" id={idVal+"chk"} onChange={(event) => this.resizingCompletedClick(event)}/><span className="custom-checkbox"></span>
                        </div>
                        <label style={{margin:"auto 0px auto 2px"}} for={idVal+"chk"}>Resizing completed</label>
                    </div>
                </div>
            );
    }
    
    renderVmStatus(cell, Vm) {      
        let obj = "";
        let platform = Vm.metricsPlatform === "Host" ? "(Only Host Metrics Used)" : "";
        if(Vm.validationPerfType !== null && Vm.validationPerfType !== undefined){
            switch(Vm.validationPerfType){
                case "Aggressive":
                    obj = "Economical";
                    break;
                case "Balanced":
                    obj = "Balanced";
                    break;
                case "Conservative":
                    obj = "Enhanced";
                    break;
                default: obj = "";
            }
        }
        if(Vm.resizePerfType !== null){
            obj = Vm.resizePerfType === "Aggressive" ? "Economical" : Vm.resizePerfType === "Conservative" ? "Enhanced" : "Balanced";
        }
        var sts = this.state.isAsp ? Vm.aspState : Vm.bvmState;
        if(this.state.entityType === "db"){
            sts = Vm.dbState;
        }
        
        return <div>
            <div className="textfieldCentered">{sts}</div>
            <div className="textfieldCentered">{obj}</div>
            {platform !=="" ? <div style={{fontSize:"12px", textAlign:"center"}}>{platform}</div> : null}
            </div>;
    }

    getStringDate(cell, row) {
        let current_datetime = new Date(row.status_change_datetime);
        var hours = current_datetime.getHours();
        var minutes = current_datetime.getMinutes();
        var month = current_datetime.getMonth() + 1;
        var day = current_datetime.getDate();
        var seconds = current_datetime.getSeconds();

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        var tzAbbr = "";
        var dateObject = new Date(),
        dateString = dateObject + "",
        tzAbbr = (
                dateString.match(/\(([^\)]+)\)$/) ||
                dateString.match(/([A-Z]+) [\d]{4}$/)
                );
        if (tzAbbr) {
tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
}

        return (
                <div style={{textAlign: "center"}}>
                    {current_datetime.getFullYear() + "-" + month + "-" + day + "  " + hours + ':' + minutes+ ':' + seconds+ ' ' + tzAbbr}
                </div>
                );
    }
    
    thClicked =async (bvmid,obj,e)=>{
        //alert(e.target.parentElement.parentElement.parentElement.parentElement.parentElement.tagName);
        //e.target.parentElement.parentElement.parentElement.parentElement.parentElement.click();
        var {expandKeyValue,exapanding} = this.state;
        const index = exapanding.indexOf(bvmid);
        if (index > -1) {
            exapanding.splice(index, 1);
        }
        let isadded = true;
        var curObjico = document.getElementById(bvmid+obj);
        if(expandKeyValue.has(bvmid)){
            if(expandKeyValue.get(bvmid) === obj){
                expandKeyValue.remove(bvmid);  
                if(curObjico !== null) {
                    curObjico.className = "fa fa-angle-down";
                }
            } else {
                isadded = true;
                expandKeyValue.set(bvmid,obj);
                exapanding.push(bvmid);
                if(curObjico !== null) {
                    curObjico.className = "fa fa-angle-up";
                }
            }
        } else {
            isadded = true;
            expandKeyValue.set(bvmid,obj);
            exapanding.push(bvmid);
            if(curObjico !== null) {
                curObjico.className = "fa fa-angle-up";
            }
        }
        if(isadded){
            switch(obj){
                case "aggressive":
                    if(document.getElementById(bvmid+"balanced") !== null){
                        document.getElementById(bvmid+"balanced").className = "fa fa-angle-down";
                    }
                    if(document.getElementById(bvmid+"conservative") !== null){
                        document.getElementById(bvmid+"conservative").className = "fa fa-angle-down";
                    }
                    break;
                case "balanced":
                    if(document.getElementById(bvmid+"aggressive") !== null){
                        document.getElementById(bvmid+"aggressive").className = "fa fa-angle-down";
                    }
                    if(document.getElementById(bvmid+"conservative") !== null){
                        document.getElementById(bvmid+"conservative").className = "fa fa-angle-down";
                    }
                        break;
                case "conservative" :
                    if(document.getElementById(bvmid+"aggressive") !== null){
                        document.getElementById(bvmid+"aggressive").className = "fa fa-angle-down";
                    }
                    if(document.getElementById(bvmid+"balanced") !== null){
                        document.getElementById(bvmid+"balanced").className = "fa fa-angle-down";
                    }
                    break;
            }
        }
        await this.setState({expandKeyValue : expandKeyValue,  exapanding: exapanding});
        if(this.state.isAsp === true && this.state.exapanding.length > 0){
            var tr = document.getElementById(bvmid+"rowTab").parentElement;            
            tr.click();
        }
    }
    
    renderExpandedRow(Vm) {
        var idVal = this.state.isAsp ? Vm.basicASPId : Vm.basicVMId;
        if(this.state.entityType === "db"){
            idVal = Vm.basicentity_id;
        }
        if(this.state.expandKeyValue.has(idVal)){
            let obj = this.state.expandKeyValue.get(idVal);
            let title = obj === "aggressive" ? "Economical" : obj ==="balanced" ? "Balanced" : "Enhanced";
            let Y = <img className="no-box-sizing" src={require('../../images/ValidationResult_Y.png')} title="Good" />;
            let N = <img className="no-box-sizing" src={require('../../images/ValidationResult_N.png')} title="Poor" />;
            let NA = <img className="no-box-sizing-na" src={require('../../images/ValidationResult_NA.png')} title="Not Applicable" />;
            let M = <img className="no-box-sizing" src={require('../../images/ValidationResult_M.png')} title="Fair" />;
            let S = <img className="no-box-sizing" src={require('../../images/ValidationResult_Skipped.png')} title="Skipped" />;
            let Star = <img className="no-box-sizing" src={require('../../images/ValidationResult_Star.png')} title="Insignificant Data" />;
            let renderIcon = false;
            if(Vm.validationPerfType !== undefined && Vm.validationPerfType !== null && obj.toUpperCase() === Vm.validationPerfType.toUpperCase()){
                renderIcon = true;
            }
            let agg_validated = (renderIcon && Vm.validationPerfType === "Aggressive");
            let bal_validated = (renderIcon && Vm.validationPerfType === "Balanced");
            let con_validated = (renderIcon && Vm.validationPerfType === "Enhanced");

            let optimizing = false;
            let state = this.state.isAsp ? Vm.aspState: Vm.bvmState;
            if(this.state.entityType === "db"){
                state = Vm.dbState;
            }
            if(state === "OPTIMIZING"){
                optimizing = true;
            }
            let isResizing = (optimizing && Vm.resizePerfType !== null && Vm.resizePerfType !== undefined && Vm.resizePerfType.toUpperCase() === obj.toUpperCase()) ? true : false;
            
            return(
                    <div>
                    <div style={{textAlign:"center"}}><h4>{title}</h4></div>
                    <Table style={{textAlign:"left"}} className="tableview " id="tableSizing" >
                        <thead style={{textAlign:"left"}}>
                            <tr>
                                <td style={{width:"40px", borderTop:"none", borderLeft:"none"}}></td>
                                <th>Original Spec & Limits</th>
                                <th>Recommended Spec & Limits</th>                            
                                { isResizing ? <th>Most Recent Spec & Limits</th> : <th style={{width:"40px"}}>Cost Changes</th> }
                                { isResizing ? <th style={{width:"50px"}}>Status</th>:<th style={{width:"50px"}}>Performance Changes</th> }


                            </tr>
                        </thead>
                        <tbody>
                            {this.state.entityType === "db" ? 
                                <tr>
                                    <th><span className='labelColor'>vCore</span></th>
                                    <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Num vCore {Vm[obj].vmDTO.current_num_vcpu})</div></td>
                                    <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Num vCore {Vm[obj].vmDTO.rec_num_vcpu})</div></td>
                                    {isResizing ? <td ><div><div>{Vm[obj].vmDTO.applied_vmsize_name}</div><div>(Num vCore {Vm[obj].vmDTO.applied_vmsize_numvcpu})</div></div> </td>
                                    : <td rowSpan={"4"}> {Vm[obj].vmDTO.vm_cost_changes }</td> }
                                    <td rowSpan={"4"}>{isResizing ? Vm[obj].vmDTO.recommendedVMStatus : Vm[obj].vmDTO.vm_cpu_performance_changes}</td>
                                </tr>
                            :
                            <tr>
                                <th><span className='labelColor'>CPU</span></th>
                                <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Num vCPU {Vm[obj].vmDTO.current_num_vcpu}, CPU Speed {Vm[obj].vmDTO.current_cpuspeed})</div></td>
                                <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Num vCPU {Vm[obj].vmDTO.rec_num_vcpu}, CPU Speed {Vm[obj].vmDTO.rec_cpuspeed})</div></td>
                                <td rowSpan={(!isResizing && (this.state.isAsp || this.state.entityType === "db")) ? "4" : isResizing ? "1" :"3"}>
                                {isResizing ? <div><div>{Vm[obj].vmDTO.applied_vmsize_name}</div><div>(Num vCPU {Vm[obj].vmDTO.applied_vmsize_numvcpu}, CPU Speed {Vm[obj].vmDTO.applied_vmsize_vcpuSpeed} GHz)</div></div> : 
                                    renderIcon ?
                                    <Row style={{height:"11em"}}>
                                        <Col lg="9" className="spannedDiv">{Vm[obj].vmDTO.vm_cost_changes}</Col>
                                        <Col lg="3" className="innerTdDivLeftBorder">
                                            <div className="stackedDiv">{Vm[obj].vmDTO.validation_cpu_cost_result === "Y" ? Y :
                                          Vm[obj].vmDTO.validation_cpu_cost_result === "N" ? N :
                                          Vm[obj].vmDTO.validation_cpu_cost_result === "N/A" ? NA :                                        
                                          Vm[obj].vmDTO.validation_cpu_cost_result === "M" ? M:
                                          Vm[obj].vmDTO.validation_cpu_cost_result === "*" ? Star : S}</div>
                                            <div className="stackedDiv">{Vm[obj].vmDTO.validation_memory_cost_result === "Y" ? Y :
                                          Vm[obj].vmDTO.validation_memory_cost_result === "N" ? N :
                                          Vm[obj].vmDTO.validation_memory_cost_result === "N/A" ? NA :                                        
                                          Vm[obj].vmDTO.validation_memory_cost_result === "M" ? M:
                                          Vm[obj].vmDTO.validation_memory_cost_result === "*" ? Star : S}</div>
                                            <div className="stackedDiv">{S}</div>
                                          </Col>
                                    </Row> : Vm[obj].vmDTO.vm_cost_changes}
                                </td>
                                <td rowSpan={(isResizing && (this.state.isAsp || this.state.entityType === "db")) ? "4" : isResizing ? "3": "1"}>
                                    {isResizing ? Vm[obj].vmDTO.recommendedVMStatus : renderIcon ?
                                    <Row>
                                        <Col lg="9" className="spannedDiv">
                                            {Vm[obj].vmDTO.vm_cpu_performance_changes}
                                        </Col>
                                        <Col lg="3" style={{padding:"0px"}}>
                                            {Vm[obj].vmDTO.validation_cpu_perf_result === "Y" ? Y :
                                          Vm[obj].vmDTO.validation_cpu_perf_result === "N" ? N :
                                          Vm[obj].vmDTO.validation_cpu_perf_result === "N/A" ? NA :                                        
                                          Vm[obj].vmDTO.validation_cpu_perf_result === "M" ? M:
                                          Vm[obj].vmDTO.validation_cpu_perf_result === "*" ? Star : S}
                                        </Col>
                                    </Row> : Vm[obj].vmDTO.vm_cpu_performance_changes}
                                </td>
                            </tr> }
                            
                            {this.state.entityType ==="db" ? 
                                    <tr>
                                    <th><span className='labelColor'>Memory</span></th>
                                    <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Size {Vm[obj].osDisk.current_os_diskSizeGB} GB)</div></td>
                                    <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Size {Vm[obj].osDisk.rec_os_diskSizeGB} GB)</div></td>
                                    {isResizing ? <td><div><div>{Vm[obj].vmDTO.applied_vmsize_name}</div><div>(Size {Vm[obj].osDisk.applied_osdisk_memory.toFixed(1)} GB)</div></div></td> :null}
                                </tr>
                                : null }                            
                            
                            {this.state.entityType === "db" ? 
                                <tr>
                                    <th><span className='labelColor'>Data Disk</span></th>
                                    <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.current_memory} GB)</div></td>
                                    <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.rec_memory} GB)</div></td>
                                    {isResizing ? <td><div><div>{Vm[obj].vmDTO.applied_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.applied_vmsize_memory.toFixed(1)} GB)</div></div></td> :null}
                                    
                                </tr> 
                            :<tr>
                                <th><span className='labelColor'>Memory</span></th>
                                <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.current_memory})</div></td>
                                <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.rec_memory})</div></td>
                                {isResizing ? <td><div><div>{Vm[obj].vmDTO.applied_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.applied_vmsize_memory.toFixed(1)} GiB)</div></div></td> :<td>
                                {renderIcon ?
                                    <Row>
                                        <Col lg="9" className="spannedDiv">
                                            {Vm[obj].vmDTO.vm_memory_performance_changes}
                                        </Col>
                                        <Col lg="3" style={{padding:"0px"}}>
                                            {Vm[obj].vmDTO.validation_memory_perf_result === "Y" ? Y :
                                          Vm[obj].vmDTO.validation_memory_perf_result === "N" ? N :
                                          Vm[obj].vmDTO.validation_memory_perf_result === "N/A" ? NA :                                        
                                          Vm[obj].vmDTO.validation_memory_perf_result === "M" ? M:
                                          Vm[obj].vmDTO.validation_memory_perf_result === "*" ? Star : S}
                                        </Col>
                                    </Row> : Vm[obj].vmDTO.vm_memory_performance_changes}
                                </td>}
                            </tr> }
                            {this.state.entityType === "db" ? 
                                <tr>
                                    <th><span className='labelColor'>Log Disk</span></th>
                                    <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.current_networkbandwidth})</div></td>
                                    <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.rec_networkbandwidth})</div></td>
                                    {isResizing ? <td><div><div>{Vm[obj].vmDTO.applied_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.applied_vmsize_networkbandwidth} Mbps)</div></div></td> :null }
                                   
                                </tr> 
                            :<tr>
                                <th><span className='labelColor'>Network</span></th>
                                <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Bandwidth {Vm[obj].vmDTO.current_networkbandwidth})</div></td>
                                <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Bandwidth {Vm[obj].vmDTO.rec_networkbandwidth})</div></td>
                                {isResizing ? <td><div><div>{Vm[obj].vmDTO.applied_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.applied_vmsize_networkbandwidth} Mbps)</div></div></td> :<td>
                                {renderIcon ?
                                    <Row>
                                        <Col lg="9" className="spannedDiv">
                                            {Vm[obj].vmDTO.vm_network_performance_changes}
                                        </Col>
                                        <Col lg="3" style={{padding:"0px"}}>
                                            {S}
                                        </Col>
                                    </Row>: Vm[obj].vmDTO.vm_network_performance_changes}
                                </td> }
                            </tr>}
                            {this.state.entityType !=="db" ? <tr>
                                <th><span className='labelColor'>{this.state.isAsp ? "Storage" : "OS Disk"}</span></th>
                                {this.state.isAsp ? 
                                    <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Size {Vm[obj].osDisk.current_os_diskSizeGB})</div></td>
                                  : <td><div>{Vm[obj].osDisk.current_os_disksize_name} ({Vm[obj].osDisk.current_osdisk_type})</div><div>(Throuput {Vm[obj].osDisk.current_osdisk_bandwidth}, IOPS {Vm[obj].osDisk.current_osdisk_iops}, Size {Vm[obj].osDisk.current_os_diskSizeGB})</div></td>
                                }
                                {this.state.isAsp ? 
                                    <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Size {Vm[obj].osDisk.rec_os_diskSizeGB})</div></td>
                                    :<td><div>{Vm[obj].osDisk.rec_os_disksize_name} ({Vm[obj].osDisk.rec_osdisk_type})</div><div>(Throuput {Vm[obj].osDisk.rec_osdisk_bandwidth}, IOPS {Vm[obj].osDisk.rec_osdisk_iops}, Size {Vm[obj].osDisk.rec_os_diskSizeGB})</div></td>
                                }
                                {(!isResizing && this.state.isAsp) ? "" : <td>
                                {isResizing ? 
                                    ( this.state.isAsp ? 
                                        <div><div>{Vm[obj].vmDTO.applied_vmsize_name}</div><div>(Size {Vm[obj].osDisk.applied_osdisk_memory} GiB)</div></div>
                                        : <div><div>{Vm[obj].osDisk.applied_osdisksize_name} ({Vm[obj].osDisk.applied_osdisk_tier})</div><div>(Throuput {Vm[obj].osDisk.applied_osdisk_bandwidth} MBps, IOPS {Vm[obj].osDisk.applied_osdisk_iops}, Size {Vm[obj].osDisk.applied_osdisk_memory} GiB)</div></div>
                                    )                                
                                :renderIcon ?
                                    <Row>
                                        <Col lg="9" className="spannedDiv">
                                            {Vm[obj].osDisk.osdisk_cost_changes}
                                        </Col>
                                        <Col lg="3" style={{padding:"0px"}}>
                                            {Vm[obj].osDisk.validation_cost_result === "Y" ? Y :
                                          Vm[obj].osDisk.validation_cost_result === "N" ? N :
                                          Vm[obj].osDisk.validation_cost_result === "N/A" ? NA :                                        
                                          Vm[obj].osDisk.validation_cost_result === "M" ? M:
                                          Vm[obj].osDisk.validation_cost_result === "*" ? Star : S}
                                        </Col>
                                    </Row> : (this.state.isAsp? "" :Vm[obj].osDisk.osdisk_cost_changes)}
                                </td>}
                                {(isResizing && this.state.isAsp) ? "" :<td>
                                    {isResizing ? Vm[obj].osDisk.recommendedOSDiskStatus : renderIcon ?
                                    <Row>
                                        <Col lg="9" className="spannedDiv">
                                            {Vm[obj].osDisk.osdisk_performance_changes}
                                        </Col>
                                        <Col lg="3" style={{padding:"0px"}}>
                                            {Vm[obj].osDisk.validation_perf_result === "Y" ? Y :
                                          Vm[obj].osDisk.validation_perf_result === "N" ? N :
                                          Vm[obj].osDisk.validation_perf_result === "N/A" ? NA :                                        
                                          Vm[obj].osDisk.validation_perf_result === "M" ? M:
                                          Vm[obj].osDisk.validation_perf_result === "*" ? Star : S}
                                        </Col>
                                    </Row> : Vm[obj].osDisk.osdisk_performance_changes}
                                </td>}
                            </tr> : null }
                            {this.state.entityType !=="db" ? Object.keys(Vm[obj].dataDiskMap).map((key) => {
                                return (<tr>
                                    <th><span className='labelColor'>Disk {key}</span></th>
                                    <td><div>{Vm[obj].dataDiskMap[key].current_data_disksize_name}({Vm[obj].dataDiskMap[key].current_datadisk_type})</div><div>(Throuput {Vm[obj].dataDiskMap[key].current_datadisk_bandwidth}, IOPS {Vm[obj].dataDiskMap[key].current_datadisk_iops}, Size {Vm[obj].dataDiskMap[key].current_datadisk_sizeGB})</div></td>
                                    <td><div>{Vm[obj].dataDiskMap[key].rec_data_disksize_name}({Vm[obj].dataDiskMap[key].rec_datadisk_type})</div><div>(Throuput {Vm[obj].dataDiskMap[key].rec_datadisk_bandwidth}, IOPS {Vm[obj].dataDiskMap[key].rec_datadisk_iops}, Size {Vm[obj].dataDiskMap[key].rec_datadisk_sizeGB})</div></td>
                                    <td>
                                       {isResizing ? <div><div>{Vm[obj].dataDiskMap[key].applied_data_disksize_name} ({Vm[obj].dataDiskMap[key].applied_data_disk_tier})</div><div>(Throuput {Vm[obj].dataDiskMap[key].applied_datadisk_bandwidth} MBps, IOPS {Vm[obj].dataDiskMap[key].applied_datadisk_iops}, Size {Vm[obj].dataDiskMap[key].applied_datadisk_memory} GiB)</div></div>:renderIcon ?
                                            <Row>
                                                <Col lg="9" className="spannedDiv">
                                                    {Vm[obj].dataDiskMap[key].cost_changes}
                                                </Col>
                                                <Col lg="3" style={{padding:"0px"}}>
                                                    {Vm[obj].dataDiskMap[key].validation_cost_result === "Y" ? Y :
                                                  Vm[obj].dataDiskMap[key].validation_cost_result === "N" ? N :
                                                  Vm[obj].dataDiskMap[key].validation_cost_result === "N/A" ? NA :                                        
                                                  Vm[obj].dataDiskMap[key].validation_cost_result === "M" ? M:
                                                  Vm[obj].dataDiskMap[key].validation_cost_result === "*" ? Star : S}
                                                </Col>
                                        </Row> : Vm[obj].dataDiskMap[key].cost_changes}
                                    </td>
                                    <td>
                                        {isResizing ? Vm[obj].dataDiskMap[key].recommendedDataDiskStatus : renderIcon ?
                                        <Row>
                                        <Col lg="9" className="spannedDiv">
                                                {Vm[obj].dataDiskMap[key].performance_changes}
                                             </Col>
                                            <Col lg="3" style={{padding:"0px"}}>
                                                {Vm[obj].dataDiskMap[key].validation_perf_result === "Y" ? Y :
                                              Vm[obj].dataDiskMap[key].validation_perf_result === "N" ? N :
                                              Vm[obj].dataDiskMap[key].validation_perf_result === "N/A" ? NA :                                        
                                              Vm[obj].dataDiskMap[key].validation_perf_result === "M" ? M:
                                              Vm[obj].dataDiskMap[key].validation_perf_result === "*" ? Star : S}
                                            </Col>
                                        </Row> : Vm[obj].dataDiskMap[key].performance_changes}
                                    </td>
                                </tr>);
                            }) : null}
                        </tbody>
                        </Table></div>);
        }
//        else{
//            var {expandKeyValue,exapanding} = this.state;
//            const index = exapanding.indexOf(idVal);
//            if (index > -1) {
//                exapanding.splice(index, 1);
//            }
//            expandKeyValue.remove(idVal);  
//            this.setState({expandKeyValue : expandKeyValue,  exapanding: exapanding});
//        }
    }
    
    renderRecommendationCell (cell,Vm){
        let Y = <img className="no-box-sizing" src={require('../../images/ValidationResult_Y.png')} title="Good" />;
        let N = <img className="no-box-sizing" src={require('../../images/ValidationResult_N.png')} title="Poor" />;
        let NA = <img className="no-box-sizing-na" src={require('../../images/ValidationResult_NA.png')} title="Not Applicable" />;
        let M = <img className="no-box-sizing" src={require('../../images/ValidationResult_M.png')} title="Fair" />;
        let S = <img className="no-box-sizing" src={require('../../images/ValidationResult_Skipped.png')} title="Skipped" />;
        let Star = <img className="no-box-sizing" src={require('../../images/ValidationResult_Star.png')} title="Insignificant Data" />;
        let renderIcon = false;
        if(Vm.validationPerfType !== undefined && Vm.validationPerfType !== null){
            renderIcon = true;
        }
        let state = this.state.isAsp ? Vm.aspState: Vm.bvmState;
        if(this.state.entityType === "db"){
            state = Vm.dbState;
        }
        let isResizing = (Vm.resizePerfType!== null && state === "OPTIMIZING") ? true : false;
        let isAggResizing = (isResizing && Vm.resizePerfType === "Aggressive") ? true : false;
        let isBalResizing = (isResizing && Vm.resizePerfType === "Balanced") ? true : false;
        let isConResizing = (isResizing && Vm.resizePerfType === "Conservative") ? true : false;
        let resizingObjective = "Balanced";
        if(Vm.resizePerfType!== null){
           resizingObjective = Vm.resizePerfType === "Aggressive" ? "Economical" : Vm.resizePerfType === "Conservative" ? "Enhanced" : "Balanced";
        }
        let isValidated = (Vm.validationPerfType !== null && (state === "VALIDATING" || state === "VALIDATION ERROR")) ? false : true;
        let agg_validated = (isValidated && Vm.validationPerfType === "Aggressive") ? true : false;
        let bal_validated = (isValidated && Vm.validationPerfType === "Balanced") ? true : false;
        let con_validated = (isValidated && Vm.validationPerfType === "Conservative") ? true : false;                  
        
        let agg_costSummaryText = agg_validated ? Vm.aggressive.validation_summary.costSummaryText : Vm.aggressive.summary.costSummaryText;
        let agg_perfAffected = agg_validated ? Vm.aggressive.validation_summary.perfAffected : null;
        let agg_perfSummaryText = agg_validated ? Vm.aggressive.validation_summary.perfSummaryText :Vm.aggressive.summary.perfSummaryText;
        let agg_increasedCost = agg_validated ? Vm.aggressive.validation_summary.increasedCost : null;

        let bal_costSummaryText = bal_validated ? Vm.balanced.validation_summary.costSummaryText : Vm.balanced.summary.costSummaryText;
        let bal_perfAffected = bal_validated ? Vm.balanced.validation_summary.perfAffected : null;
        let bal_perfSummaryText = bal_validated ? Vm.balanced.validation_summary.perfSummaryText :Vm.balanced.summary.perfSummaryText;
        let bal_increasedCost = bal_validated ? Vm.balanced.validation_summary.increasedCost : null;

        let con_costSummaryText = con_validated ? Vm.conservative.validation_summary.costSummaryText : Vm.conservative.summary.costSummaryText;
        let con_perfAffected = con_validated ? Vm.conservative.validation_summary.perfAffected : null;
        let con_perfSummaryText = con_validated ? Vm.conservative.validation_summary.perfSummaryText :Vm.conservative.summary.perfSummaryText;
        let con_increasedCost = con_validated ? Vm.conservative.validation_summary.increasedCost : null;
        let idVal = this.state.isAsp ? Vm.basicASPId : Vm.basicVMId;
        if(this.state.entityType === "db"){
            idVal = Vm.basicentity_id;
        }
        return (
            <table style={{width:"100%"}} id={idVal+"rowTab"}>
            <thead>
                <tr style={{backgroundColor:'#E5E5E5'}}>
                    <th style={{borderLeft: "none"}}>
                        <div>
                            <Input style={{marginRight:"5px"}} type="radio" id={idVal+"agg"} name={Vm.name} 
                            onClick={e =>this.onChange(idVal, Vm.vmResultKeyMap.Aggressive, "Aggressive", Vm, e)} disabled={(isResizing && !isAggResizing)}/>
                            Economical
                        </div>
                    </th>
                    <th>
                        <div>
                            <Input style={{marginRight:"5px"}} type="radio" id={idVal+"bal"} name={Vm.name} 
                            onClick={e =>this.onChange(idVal, Vm.vmResultKeyMap.Balanced, "Balanced",  Vm, e)} disabled={(isResizing &&!isBalResizing)}/>
                            Balanced
                        </div>
                    </th>
                    <th style={{borderRight: "none"}}>
                        <div>
                            <Input style={{marginRight:"5px"}} type="radio" id={idVal+"con"} name={Vm.name} 
                            onClick={e =>this.onChange(idVal, Vm.vmResultKeyMap.Conservative, "Conservative", Vm, e)} disabled={(isResizing &&!isConResizing)}/>
                            Enhanced
                        </div>
                    </th>
                </tr> 
            </thead>
            <tbody>
                <tr>
                    <td className={((isResizing && isAggResizing) ? "textfield thExpandSelector bgColor" : (!isResizing && agg_validated ) ? "textfield thExpandSelector bgColor" : "textfield thExpandSelector")} onClick={() => {this.thClicked(idVal,"aggressive");}} style={{borderLeft: "none", borderBottom: "none"}}>
                        {(agg_costSummaryText !== null || (agg_perfAffected !== null && agg_perfAffected !== "")) ?
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>
                                <Row style={{textAlign:"center"}}>
                                    <Col sm="10" style={{ padding: "0px" }}>
                                        <div>{agg_costSummaryText}</div>
                                        <div>{agg_perfAffected}</div>
                                    </Col>
                                    { (renderIcon && Vm.validationPerfType === "Aggressive") ?
                                        <Col sm="2" style={{alignSelf:"center", padding: "0px"}}>
                                            {
                                                Vm.aggressive.validation_summary.validation_cost_result === "Y" ? Y :
                                                Vm.aggressive.validation_summary.validation_cost_result === "N" ? N :
                                                Vm.aggressive.validation_summary.validation_cost_result === "M" ? M :
                                                Vm.aggressive.validation_summary.validation_cost_result === "NA" ? NA :
                                                Vm.aggressive.validation_summary.validation_cost_result === "*" ? Star : S                                  
                                            }
                                        </Col> : null
                                    }
                                </Row>
                            </td>
                            <td style={{padding:"0px !important", border:"none"}} rowspan="2"> <i id={idVal+"aggressive"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td>
                        </tr> :null}
                        {(agg_perfSummaryText !== null || (agg_increasedCost !== null && agg_increasedCost !== "")) ?
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>
                                <Row style={{textAlign:"center"}}>
                                    <Col sm="10" style={{ padding: "0px" }}>
                                        <div>{agg_perfSummaryText}</div>
                                        <div>{agg_increasedCost}</div>
                                    </Col>
                                    { (renderIcon && Vm.validationPerfType === "Aggressive") ?
                                        <Col sm="2" style={{alignSelf:"center", padding: "0px"}}>
                                        {   Vm.aggressive.validation_summary.validation_perf_result === "Y" ? Y :
                                            Vm.aggressive.validation_summary.validation_perf_result === "N" ? N :
                                            Vm.aggressive.validation_summary.validation_perf_result === "M" ? M :
                                            Vm.aggressive.validation_summary.validation_perf_result === "NA" ? NA :
                                            Vm.aggressive.validation_summary.validation_perf_result === "*" ? Star : S                                 
                                        }
                                    </Col> : null
                                    }
                                </Row>
                            </td>
                            {(agg_costSummaryText === null && (agg_perfAffected === null|| agg_perfAffected ==="")) ? <td style={{padding:"0px !important", border:"none"}}  > <i id={idVal+"aggressive"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td> :""}
                        </tr> :null}
                    </td>
                    <td className={((isResizing && isBalResizing) ? "textfield thExpandSelector bgColor" : (!isResizing && bal_validated) ? "textfield thExpandSelector bgColor" : "textfield thExpandSelector")} onClick={() => {this.thClicked(idVal,"balanced");}} style={{borderLeft: "none", borderBottom: "none"}}>
                        {(bal_costSummaryText !== null || (bal_perfAffected !== null && bal_perfAffected !== ""))?<tr>
                            <td style={{padding:"0px !important", border:"none"}}>
                                <Row style={{textAlign:"center"}}>
                                    <Col sm="10" style={{ padding: "0px" }}>
                                        <div>{bal_costSummaryText}</div>
                                        <div>{bal_perfAffected}</div>
                                    </Col>
                                    { (renderIcon && Vm.validationPerfType === "Balanced") ?
                                    <Col sm="2" style={{alignSelf:"center", padding: "0px"}}>
                                        {Vm.balanced.validation_summary.validation_cost_result === "Y" ? Y :
                                        Vm.balanced.validation_summary.validation_cost_result === "N" ? N :
                                        Vm.balanced.validation_summary.validation_cost_result === "M" ? M :
                                        Vm.balanced.validation_summary.validation_cost_result === "NA" ? NA :
                                        Vm.balanced.validation_summary.validation_cost_result === "*" ? Star : S                                
                                        }
                                    </Col>
                                    : null }
                                </Row>
                            </td>
                            <td style={{padding:"0px !important", border:"none"}} rowSpan="2"> <i id={idVal+"balanced"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td>
                        </tr> :""}
                        {(bal_perfSummaryText !== null || (bal_increasedCost !== null && bal_increasedCost !== "")) ? 
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>
                                <Row style={{textAlign:"center"}}>
                                    <Col sm="10" style={{ padding: "0px" }}>
                                        <div>{bal_perfSummaryText}</div>
                                        <div>{bal_increasedCost}</div>
                                    </Col>
                                    { (renderIcon && Vm.validationPerfType === "Balanced") ?
                                    <Col sm="2" style={{alignSelf:"center", padding: "0px"}}>
                                        {Vm.balanced.validation_summary.validation_perf_result === "Y" ? Y :
                                        Vm.balanced.validation_summary.validation_perf_result === "N" ? N :
                                        Vm.balanced.validation_summary.validation_perf_result === "M" ? M :
                                        Vm.balanced.validation_summary.validation_perf_result === "NA" ? NA :
                                        Vm.balanced.validation_summary.validation_perf_result === "*" ? Star : S                                 
                                        }
                                    </Col>
                                    : null }
                                </Row>
                            </td>
                            {(bal_costSummaryText === null && (bal_perfAffected === null|| bal_perfAffected ===""))? <td style={{padding:"0px !important", border:"none"}}> <i id={idVal+"balanced"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td> :""}
                        </tr> : ""}
                    </td>
                    <td className={((isResizing && isConResizing) ? "textfield thExpandSelector bgColor" : (!isResizing && con_validated) ? "textfield thExpandSelector bgColor" : "textfield thExpandSelector")} onClick={() => {this.thClicked(idVal,"conservative");}} style={{borderLeft: "none", borderBottom: "none", borderRight: "none"}}>
                        {(con_costSummaryText !== null || (con_perfAffected !== null && con_perfAffected !== "")) ? 
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>
                                <Row style={{textAlign:"center"}}>
                                    <Col sm="10" style={{ padding: "0px" }}>
                                        <div>{con_costSummaryText}</div>
                                        <div>{con_perfAffected}</div>
                                    </Col>
                                    { (renderIcon && Vm.validationPerfType === "Conservative") ?
                                    <Col sm="2" style={{alignSelf:"center", padding: "0px"}}>
                                        {Vm.conservative.validation_summary.validation_cost_result === "Y" ? Y :
                                        Vm.conservative.validation_summary.validation_cost_result === "N" ? N :
                                        Vm.conservative.validation_summary.validation_cost_result === "M" ? M :
                                        Vm.conservative.validation_summary.validation_cost_result === "NA" ? NA :
                                        Vm.conservative.validation_summary.validation_cost_result === "*" ? Star : S                                   
                                        }
                                    </Col> : null}
                                </Row>
                            </td>
                            <td style={{padding:"0px !important", border:"none"}} rowSpan="2"> <i id={idVal+"conservative"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td>
                        </tr> :"" }
                        {(con_perfSummaryText !== null || (con_increasedCost !== null && con_increasedCost !== ""))?
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>
                                <Row style={{textAlign:"center"}}>
                                    <Col sm="10" style={{ padding: "0px" }}>
                                        <div>{con_perfSummaryText}</div>
                                        <div>{con_increasedCost}</div>
                                    </Col>
                                    { (renderIcon && Vm.validationPerfType === "Conservative") ?
                                    <Col sm="2" style={{alignSelf:"center", padding: "0px"}}>
                                        {Vm.conservative.validation_summary.validation_perf_result === "Y" ? Y :
                                        Vm.conservative.validation_summary.validation_perf_result === "N" ? N :
                                        Vm.conservative.validation_summary.validation_perf_result === "M" ? M :
                                        Vm.conservative.validation_summary.validation_perf_result === "NA" ? NA :
                                        Vm.conservative.validation_summary.validation_perf_result === "*" ? Star : S                                  
                                        }
                                    </Col>: null}
                                </Row>
                            </td>
                           {(con_costSummaryText === null || (con_perfAffected === null && con_perfAffected === ""))? <td style={{padding:"0px !important", border:"none"}}> <i id={idVal+"conservative"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td> :""}
                        </tr> :""}
                    </td>
                </tr>
            </tbody>
            </table>
        );
    }        
    setErrorPopupState = (event) => {
        this.setState({errorPopup: event});
    }
    
    confirmRecommendation = async() => {
        await this.setState({ modalLoader:true, toggleProceed: false });
        const timezone = jstz.determine();
        const timezoneid = timezone.name();
        var payload = [];
        this.state.objectiveBvmMap.forEach(function(value, key) {
            let resizeBy = document.getElementById(key+"sel").value ;
            let completedMark = document.getElementById(key+"chk").checked ;
            let bvm ={basicvmId:key, resultKey:value, resizedBy:resizeBy, markResizingCompleted:completedMark, userGtmId: timezoneid};
            payload.push(bvm);
        });
        var url = this.state.isAsp ? Constdata.USER_SELECT_RECOMMENDATION_ASP_URL : Constdata.USER_SELECT_RECOMMENDATION_URL;
        if(this.state.entityType === "db"){
            url = Constdata.USER_SELECT_RECOMMENDATION_DB_URL;
        }
        fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
              this.setState({ modalLoader: false });
              this.processResponse(responseJson);
            })
        .catch((error) => {
            this.setState({ modalLoader: false });
        });
        
    }
    processResponse(response) {
        if (response.success === true) {
            this.props.handleFetch();
            this.props.cancelUserResize();
        } else if (response.success === false) {
            this.setState({modalLoader: false, errorPopup: true, errormessage: response.message});
        } else if (response.status === 403 || response.status === 401) {
            this.setState({isRedirect: true, modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false});
        } else {
            this.setState({modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader: false});
        }
    }
    async onChange(basicVmid, objKey, currentSelection, Vm, e) {
        if(Vm.validationPerfType !== undefined && Vm.validationPerfType !== null){
            if(Vm.validationPerfType !== currentSelection){
                await this.setState({changeResizeConfirm : true, changeResizeVm : Vm, changeResizeKey: objKey, changeResizeSelectedObjective : currentSelection});
            } else {
                var {objectiveBvmMap} = this.state;
                objectiveBvmMap.set(basicVmid,objKey);
                await this.setState({objectiveBvmMap : objectiveBvmMap}); 
                if(document.getElementById(basicVmid+"chk") !== null && document.getElementById(basicVmid+"chk") !== undefined){
                    document.getElementById(basicVmid+"chk").disabled = false;
                }
            }
        } else {
            var {objectiveBvmMap} = this.state;
            objectiveBvmMap.set(basicVmid,objKey);
            await this.setState({objectiveBvmMap : objectiveBvmMap}); 
            if(document.getElementById(basicVmid+"chk") !== null && document.getElementById(basicVmid+"chk") !== undefined){
                document.getElementById(basicVmid+"chk").disabled = false;
            }
        }
        await this.isAnythingChanged();
    }
    
    async confirmResizeChange(){
        var {objectiveBvmMap, changeResizeVm, changeResizeKey} = this.state;
        let idVal = this.state.isAsp ? changeResizeVm.basicASPId : changeResizeVm.basicVMId;
        if(this.state.entityType === "db"){
            idVal = changeResizeVm.basicentity_id;
        }
        objectiveBvmMap.set(idVal,changeResizeKey);
        await this.setState({objectiveBvmMap : objectiveBvmMap, changeResizeConfirm : false, changeResizeVm : null, changeResizeKey: null, changeResizeSelectedObjective : null}); 
        if(document.getElementById(idVal+"chk") !== null && document.getElementById(idVal+"chk") !== undefined){
            document.getElementById(idVal+"chk").disabled = false;
        }
        await this.isAnythingChanged();
    }
    
    async cancelResizeChange(){
        var {objectiveBvmMap} = this.state;
        let idVal = this.state.isAsp ? this.state.changeResizeVm.basicASPId : this.state.changeResizeVm.basicVMId;
        if(this.state.entityType === "db"){
            idVal = this.state.changeResizeVm.basicentity_id;
        }
        if(this.state.changeResizeVm.validationPerfType !== null && this.state.changeResizeVm.validationPerfType !== undefined){
            switch(this.state.changeResizeVm.validationPerfType){
                case "Aggressive":
                     objectiveBvmMap.set(idVal, this.state.changeResizeVm.vmResultKeyMap.Aggressive);
                     document.getElementById(idVal+"agg").checked = true;
                    break;
                case "Balanced":
                     objectiveBvmMap.set(idVal, this.state.changeResizeVm.vmResultKeyMap.Balanced);
                     document.getElementById(idVal+"bal").checked = true;
                    break;
                case "Conservative":
                    objectiveBvmMap.set(idVal, this.state.changeResizeVm.vmResultKeyMap.Conservative);
                    document.getElementById(idVal+"con").checked = true;
                    break;
            }
            await this.setState({objectiveBvmMap : objectiveBvmMap});  
        }
        await this.setState({changeResizeConfirm : false, changeResizeVm : null, changeResizeKey: null, changeResizeSelectedObjective : null}); 
    }

    render() {
        return (
                <div>
                    <div style={{textAlign:"center"}}>
                        <BootstrapTable id="list_table"
                            bordered={false}
                            bodyClasses="tbodyColor"
                            classes="table-responsive smallFontTable"
                            bootstrap4={true}
                            keyField={this.state.isAsp ? 'basicASPId': this.state.entityType === "db" ? 'basicentity_id': 'basicVMId'}
                            data={ this.state.value }
                            columns={ this.columns }                            
                            expandRow={ this.expandRow }
                            wrapperClasses="table-responsive" 
                            noDataIndication={ () => 'No VMs Found' } 
                    />
                    </div>
                    <div id="bottom_btn" style={{ display: "flex", justifyContent:"center", width:"100%", bottom:"0"}}>
                        <Button id={this.state.imChanged === false ? "PPU_listdisabled" : "PPU-btn"} onClick={this.toggleProceedConfirm} style={{width:"180px",marginRight:"15px"}} disabled={!this.state.imChanged}>Proceed</Button>
                        <Button className="btn-cancel btn" onClick={this.props.cancelUserResize}>Cancel</Button>                        
                    </div>
                    {(this.state.modalLoader === true) ? <SerraLoader></SerraLoader> :""}
                    {(this.state.errorPopup === true) ?  <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState} isError={this.state.isErrorHeader} ></SerraError> :""}
                    <Modal size="lg" isOpen={this.state.changeResizeConfirm} toggle={this.cancelResizeChange} backdrop="static">
                        <ModalHeader id="modalheader_list" toggle={this.confirmResizeChange} cssModule={{ 'modal-title': 'modalHeader' }}>
                            <div className="modalCompanyName" dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }} />
                            <div className="modalTitle">Change Resize Objective</div>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="box-titel" id="modaldis_list">
                                    <div style={{display:"flex", justifyContent:"center"}}>
                                        <p>
                                            {this.state.changeResizeVm !== null ? this.state.changeResizeVm.name : ""} is validated for {(this.state.changeResizeVm !== null && this.state.changeResizeVm.validationPerfType === "Aggressive") ? "Economical" : (this.state.changeResizeVm !== null && this.state.changeResizeVm.validationPerfType === "Conservative") ? "Enhanced" : "Balanced"} objective.
                                            <div>Are you sure you want to perform resizing for {this.state.changeResizeSelectedObjective === "Aggressive" ? "Economical" : this.state.changeResizeSelectedObjective === "Conservative" ? "Enhanced" : "Balanced"} objective ? </div>
                                        </p>
                                    </div>
                                </div>
                                <Form>
                                    <FormGroup>
                                        <div align="center">
                                            <Button id="confirmbtn_list" onClick={this.confirmResizeChange}>Yes</Button>
                                            <Button id="canclebtn_list" onClick={() => { this.cancelResizeChange(); }}>No</Button>
                                        </div>
                                    </FormGroup>
                                </Form>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal size="lg" isOpen={this.state.toggleProceed} toggle={this.toggleProceedConfirm} backdrop="static">
                        <ModalHeader id="modalheader_list" toggle={this.toggleProceedConfirm} cssModule={{ 'modal-title': 'modalHeader' }}>
                            <div className="modalCompanyName" dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }} />
                            <div className="modalTitle">Resizing Confirmation</div>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="box-titel" id="modaldis_list">
                                    <div style={{display:"flex", justifyContent:"center"}}>
                                        <p dangerouslySetInnerHTML={{__html: this.state.proceedConfirmText}}/>
                                    </div>
                                    <div className="footnotePopup" style={{margin:"20px", fontSize:"16px", textAlign:"left"}}> 
                                        <div dangerouslySetInnerHTML={this.state.isAsp ? { __html: "Note : An email with a report attachment for all ASPs resized will be sent." } : this.state.entityType ==="db" ? { __html: "Note : An email with a report attachment for all DBs resized will be sent." } : { __html: "Note : An email with a report attachment for all VMs resized will be sent." }} />
                                    </div>
                                </div>
                                <Form>
                                    <FormGroup>
                                        <div align="center">
                                            <Button id="confirmbtn_list" onClick={this.confirmRecommendation}>Yes</Button>
                                            <Button id="canclebtn_list" onClick={() => { this.toggleProceedConfirm(); }}>No</Button>
                                        </div>
                                    </FormGroup>
                                </Form>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
                );
    }

}
const mapStateToProps = (state) => {
    return {
        companyDetails: state.companyDetails.data,
    }
  }

export default connect(mapStateToProps, null)(ManageUserRecommendation);

