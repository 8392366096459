/* global fetch */
import React, { Component }from "react";
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { Button, Modal, Form, FormGroup, ModalHeader, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink }
from 'reactstrap';
import Header from '../HeaderComponent/Header';
import VMList from './VMParkingList';
import * as Constdata from '../../Constants/ApiConstants';
import * as message from '../../Constants/MessageConstant';
import SerraLoader from '../CommonComponent/Loader';
import Hashmap from 'hashmap';
import { connect } from "react-redux";

class ManageParking extends Component {
    constructor(props) {
        super(props);
        this.getRefreshStatus = this.getRefreshStatus.bind(this);
        this.doneRefresh = this.doneRefresh.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.startRefresh = this.startRefresh.bind(this);
        this.toggleStartRefresh = this.toggleStartRefresh.bind(this);
        this.state = {
            providerName : "",
            loggedInUser:"",
            resourceIdx: -1,
            modalLoader: false,
            refreshMap: new Hashmap(),
            isFreeTrial: false,
            isVmParkingFreeTrialSuccess: false,
            activeTab: '1'
        };
    }

    componentDidMount() { 
        var pgAc = document.getElementById("pageRef");
        if(pgAc !== undefined && pgAc !== null){
            var header = `<div>
            <div style="display: flex; text-align: center; font-size: 22px; color:#610058;">Function: ${this.props.companyDetails.ServiceName} Parking</div>
        </div>`
            document.getElementById("pageRef").innerHTML = header;
            pgAc.disabled=true;
        }       
        this.getRefreshStatus();                   
    }

    getRefreshStatus = async () => {
        fetch(Constdata.GETALLACCOUNT_URL, {
            method: 'GET',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then((response) => response.json())
                .then(async(responseJson) => {
                    if (responseJson.success === true) {
                        let isFetching = false;
                        
                        this.setState({isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL", providerName : responseJson.orgInfo.providerName, loggedInUser: responseJson.orgInfo.loggedInUser});
                        
                        if (responseJson.orgInfo.providerName === "AWS") {
                            responseJson.data.awsConfigs.map(async(key) => {
                                if (key.status.toLowerCase().includes("fetching") && key.refreshResourceIndex === 1) {
                                    isFetching = true;
                                }
                            });
                        }else{
                            responseJson.data.configs.map(async(key) => {
                                if (key.status.toLowerCase().includes("fetching") && key.refreshResourceIndex === 1) {
                                    isFetching = true;
                                }
                            });
                         }
                            
                        var {refreshMap} = this.state;

                        if (!isFetching) {
                            if (refreshMap.has(1)) {
                                refreshMap.remove(1);
                            }
                            await this.setState({
                                resourceIdx: -1, refreshMap: refreshMap
                            });
                        } else {
                            refreshMap.set(1, 1);
                            await this.setState({
                                resourceIdx: 1, refreshMap: refreshMap
                            });

                        }
                    } else if (responseJson.status === 403 || responseJson.status === 401) {
                        var {refreshMap} = this.state;
                        if (refreshMap.has(1)) {
                            refreshMap.remove(1);
                        }
                        await this.setState({
                            resourceIdx: -1, refreshMap: refreshMap
                        });
                    } else {
                        var {refreshMap} = this.state;
                        if (refreshMap.has(1)) {
                            refreshMap.remove(1);
                        }
                        await this.setState({
                            resourceIdx: -1, refreshMap: refreshMap
                        });
                    }
                }).catch((error) => {

        });
    }

    async toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            await this.setState({
                activeTab: tab
            });
            if (tab === "1") {
                await this.vmTable.handleVmlistRequest();
            }
        }
    }

    checkRefresh = async (idx) => {
        await this.setState({resourceIdx: 1});
        await this.vmTable.getRefreshStatus(false);
    }

    startRefresh = async() => {
        var {refreshMap} = this.state;
        refreshMap.set(this.state.resourceIdx, this.state.resourceIdx);
        await this.setState({toggleStartRefreshModal: false, modalLoader: true, refreshMap: refreshMap});
        await fetch(`${Constdata.STARTALLACCOUNTREFRESH_URL}/${this.state.resourceIdx}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then((response) => response.json())
                .then(async(responseJson) => {
                    if (responseJson.success === true) {
                        await this.setState({modalLoader: false});
                        if (this.state.resourceIdx === 1) {
                            this.vmTable.getRefreshStatus(false);
                        } else if ((this.state.resourceIdx & 2) === 2) {
                            this.vmTable.getRefreshStatus(false);
                        }
                    }
                }).catch((error) => {
            this.setState({modalLoader: false});
            this.vmTable.getRefreshStatus(false);
        });
    }

    doneRefresh = async(idx) => {
        var {refreshMap} = this.state;
        if (refreshMap.has(idx)) {
            refreshMap.remove(idx);
        } else if (refreshMap.has(1) && (idx === 2 || idx === 4 || idx === 8)) {
            refreshMap.remove(1);
        }
        await this.setState({refreshMap: refreshMap});
        if (this.state.refreshMap.size === 0) {
            await this.setState({modalLoader: false});
        }
    }

    toggleStartRefresh = async (ridx) => {
        if (ridx !== undefined) {
            await this.setState({resourceIdx: ridx});
        } else {
            await this.setState({resourceIdx: -1});
        }
        await this.setState(prevState => ({
                toggleStartRefreshModal: !prevState.toggleStartRefreshModal
            }));
    }
    
    

    render() {
        return <div>
            <Header checkRefresh={this.checkRefresh} processLogout={this.props.processLogut} registerListners={this.props.registerListners} providerName={this.state.providerName} loggedInUser={this.state.loggedInUser}></Header>
            <div className="container-verf container-verf-padding" role="main" style={{marginTop:"50px"}}>
                <div className="rowContainer pageBgWhite" style={{width: "100%", margin: "0px 0px 0px 0px", borderRadius: "10px"}}>
                  <div className="container1 boxShadow"> 
                    <Nav tabs className="navClass" id="optTbl">
                        <NavItem style={{width: "100%",marginBottom: "10px"}}>                                    
                            <NavLink style={{display: "flex", justifyContent: "center", alignItems: "center"}} className={classnames({active: this.state.activeTab === '1'})}>
                                <div></div>
                                <div style={{width: "97%"}}>VMs</div>
                                { (this.state.refreshMap.has(2) || this.state.refreshMap.has(1)) ?
                            <div style={{cursor: "not-allowed"}} ><img src={require('../../images/Refresh_Icon1.svg')} title="Refresh VMs" /></div>
                                                    : <div style={{cursor: "pointer"}} outline onClick={() => {
                                                                                                this.toggleStartRefresh(2);
                                                                                                 }}>
                                                        <img src={require('../../images/Refresh_Icon1.svg')} title="Refresh VMs" />
                                                    </div>
                                }
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <VMList onRef={ref => (this.vmTable = ref)} doneRefresh={this.doneRefresh} registerListners={this.props.registerListners}/>
                        </TabPane>
                    </TabContent>
                    </div>
                </div>
            </div>
            {(this.state.modalLoader === true) ? <SerraLoader></SerraLoader> : ""}
            <Modal size="lg" isOpen={this.state.toggleStartRefreshModal} toggle={this.toggleStartRefresh} backdrop="static">
                <ModalHeader id="modalheader_list" toggle={() => {
                                this.toggleStartRefresh();
                                         }} cssModule={{'modal-title': 'modalHeader'}}><div className="modalCompanyName" dangerouslySetInnerHTML={{__html: message.SerraLabsModalHeader}} /> <div className="modalTitle">Start {this.state.resourceIdx === 2 ? "VM" : this.state.resourceIdx === 4 ? "ASP" : "DB"} Table Refresh</div></ModalHeader>
                <ModalBody>
                    <div>
                        <div className="box-titel" id="modaldis_list">
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <p dangerouslySetInnerHTML={{__html: message.StartConifigRefreshText}} ></p>
                            </div>
                        </div>
                        <Form>
                            <FormGroup>
                                <div align="center">
                                    <Button id="confirmbtn_list" onClick={this.startRefresh}>OK</Button>
                                    <Button id="canclebtn_list" onClick={() => {
                                                            this.toggleStartRefresh();
                                                        }}>Cancel</Button>
                                </div>
                            </FormGroup>
                        </Form>
                    </div>
                </ModalBody>
            </Modal>
        </div>

    }
}
const mapStateToProps = (state) => {
    return {
        companyDetails: state.companyDetails.data,
    }
  }

export default connect(mapStateToProps, null)(withRouter(ManageParking));
