import React, {useEffect} from "react";
 
import { useClearCache } from "react-clear-cache";
 
const CacheBuster: React.FC<{}> = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache({duration:60000});
  
   useEffect(() => { 
       if(!isLatestVersion){
            const { caches } = window;
            console.log(caches);
            console.log('Clearing Cache...');
            if(caches !== undefined && caches !== null){
                caches.delete();
            }
            emptyCacheStorage();
            console.log('Cleared Cache...');
        } else {
            console.log('No need to clear cache everything up to date');
        }
   });  
  return (
        <div>
        </div>
  );
};
 
export default CacheBuster;