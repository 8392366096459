import React, { Component } from "react";
import { Table, Row, Col, Button } from "reactstrap";
// import './test.css';
import ColorComponent from "./SvgColorOverview";
import * as message from "../../Constants/MessageConstant";
class ValidationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.iconData,
    };
  }
  render() {
    const { data, valTableData, valStatus } = this.props;
    const PENDING = (
      <img
        src={require("../../images/Pending_01.png")}
        alt=""
        title="Pending"
      />
    );
    const Green = <img src={require("../../images/Green_01.png")} alt="" />;
    const Red = <img src={require("../../images/Red_01.png")} alt="" />;
    const Yellow = <img src={require("../../images/Yellow_01.png")} alt="" />;

    const Y = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_Y.png")}
        title="Good"
      />
    );
    const N = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_N.png")}
        title="Poor"
      />
    );
    const NA = (
      <img
        className="no-box-sizing-na"
        src={require("../../images/ValidationResult_NA.png")}
        title="Not Applicable"
      />
    );
    const M = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_M.png")}
        title="Fair"
      />
    );
    const S = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_Skipped.png")}
        title="Skipped"
      />
    );
    const Star = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_Star.png")}
        title="Insignificant Data"
      />
    );

    let objective =
      this.props.valTableData.perfType === "Aggressive"
        ? "Economical"
        : this.props.valTableData.perfType === "Balanced"
        ? "Balanced"
        : this.props.valTableData.perfType === "Conservative"
        ? "Enhanced"
        : "";
    let simConfidenceSpan =
      data.dataDisk !== null ? 4 + Object.keys(data.dataDisk).length : 5;
    let isValidating = valStatus === "VALIDATING";
    let resultStyle = { width: "20px" };
    return (
      <div>
        <Row>
          <div className="maindivCollapse" style={{ display: "inline-flex" }}>
            <ColorComponent
              data={this.state.data[0].cpu}
              isResize={true}
              isLine={true}
              showInfo={false}
              isIcon={true}
              valStatus={valStatus}
              forValidation={true}
              valObjective={this.props.valObjective}
              valData={valTableData}
              showCostPerfMarker={true}
              fromOverview={true}
            ></ColorComponent>
            <ColorComponent
              data={this.state.data[0].memory}
              isResize={true}
              isLine={true}
              showInfo={false}
              isIcon={true}
              valStatus={valStatus}
              forValidation={true}
              valObjective={this.props.valObjective}
              valData={valTableData}
              fromOverview={true}
            ></ColorComponent>
            <ColorComponent
              data={this.state.data[0].network}
              isResize={true}
              isLine={true}
              showInfo={false}
              isIcon={true}
              valStatus={valStatus}
              forValidation={true}
              valObjective={this.props.valObjective}
              valData={valTableData}
              fromOverview={true}
            ></ColorComponent>
            <ColorComponent
              data={this.state.data[0].disks}
              isResize={true}
              isLine={false}
              showInfo={false}
              isIcon={true}
              valStatus={valStatus}
              forValidation={true}
              valObjective={this.props.valObjective}
              valData={valTableData}
              fromOverview={true}
            ></ColorComponent>
          </div>
        </Row>
        <div className="panel">
          <Row>
            <Col id="ToPDF">
              <Table className="tableview " id="tableSizing" bordered>
                <thead>
                  <tr>
                    <th
                      rowspan="2"
                      style={{
                        borderLeft: "0px !important",
                        borderTop: "0px !important",
                      }}
                    ></th>
                    <th colspan="2">Spec</th>
                    <th colspan="4">Cost</th>
                    <th colspan="4">Performance</th>
                    <th rowspan="2">Validation Confidence</th>
                  </tr>
                  <tr>
                    <th style={{ backgroundColor: "#d8d8d8" }}>Existing</th>
                    <th>{objective}</th>
                    <th>Existing</th>
                    <th>{objective}</th>
                    <th>Validation Result</th>
                    <th>Overall Validation Result</th>
                    <th>Existing</th>
                    <th>{objective}</th>
                    <th>Validation Result</th>
                    <th>Overall Validation Result</th>
                  </tr>
                </thead>
                <tbody>
                  {data.vm.map((key, i) => {
                    let util_result = isValidating
                      ? ""
                      : valTableData.validationVM.sim_cpu_utils_result === "Y"
                      ? Y
                      : valTableData.validationVM.sim_cpu_utils_result === "N"
                      ? N
                      : valTableData.validationVM.sim_cpu_utils_result === "N/A"
                      ? NA
                      : valTableData.validationVM.sim_cpu_utils_result === "M"
                      ? M
                      : valTableData.validationVM.sim_cpu_utils_result === "*"
                      ? Star
                      : S;
                    let health_result = isValidating
                      ? ""
                      : valTableData.validationVM.sim_cpu_health_result === "Y"
                      ? Y
                      : valTableData.validationVM.sim_cpu_health_result === "N"
                      ? N
                      : valTableData.validationVM.sim_cpu_health_result ===
                        "N/A"
                      ? NA
                      : valTableData.validationVM.sim_cpu_health_result === "M"
                      ? M
                      : valTableData.validationVM.sim_cpu_health_result === "*"
                      ? Star
                      : S;
                    let overAllUtilResult =
                      valTableData.overall_validation_util_result === "Y"
                        ? "Good"
                        : valTableData.overall_validation_util_result === "N"
                        ? "Poor"
                        : valTableData.overall_validation_util_result === "M"
                        ? "Fair"
                        : "";
                    let overAllHealthResult =
                      valTableData.overall_validation_health_result === "Y"
                        ? "Good"
                        : valTableData.overall_validation_health_result === "N"
                        ? "Poor"
                        : valTableData.overall_validation_health_result === "M"
                        ? "Fair"
                        : "";
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowspan={data.vm.length}>
                            <div className="CpuClass"> </div>
                            <Col className="tableLeftHead">CPU</Col>
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td rowspan={data.vm.length * 3}>
                            {key.current_vmsize_name}
                            {key.hypervgeneration
                              ? " (" + key.hypervgeneration + ")"
                              : null}
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td rowspan={data.vm.length * 3}>
                            {key.recommended_vmsize_name}
                            {key.resize_hypervgeneration
                              ? " (" + key.resize_hypervgeneration + ")"
                              : null}
                          </td>
                        ) : (
                          ""
                        )}
                        {isValidating ? (
                          <td rowspan="3" colspan="3">
                            {PENDING}
                          </td>
                        ) : valTableData.validationVM.sim_current_cpu_cost ===
                          message.CostHigh ? (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostHighColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM.sim_current_cpu_cost ===
                          message.CostLow ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "#393939" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostLowColor,
                                  color: "#393939",
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{ backgroundColor: message.CostOKColor }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$$
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM.sim_rec_cpu_cost ===
                          message.CostHigh ? (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostHighColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM.sim_rec_cpu_cost ===
                          message.CostLow ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "#393939" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostLowColor,
                                  color: "#393939",
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{ backgroundColor: message.CostOKColor }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$$
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? "" : <td>{util_result}</td>}

                        {i === 0 ? (
                          <td rowspan={simConfidenceSpan}>
                            {isValidating ? PENDING : overAllUtilResult}
                          </td>
                        ) : (
                          <td></td>
                        )}

                        {isValidating ? (
                          <td rowspan="3" colspan="3">
                            {PENDING}
                          </td>
                        ) : valTableData.validationVM.sim_current_cpu_health ===
                          message.HealthGood ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthGoodColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Red}
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM.sim_current_cpu_health ===
                          message.HealthFair ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthFairColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Yellow}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthPoorColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Green}
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM.sim_rec_cpu_health ===
                          message.HealthGood ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthGoodColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Red}
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM.sim_rec_cpu_health ===
                          message.HealthFair ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthFairColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Yellow}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthPoorColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Green}
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : (
                          <td style={resultStyle}>{health_result}</td>
                        )}

                        {i === 0 ? (
                          <td rowspan={simConfidenceSpan}>
                            {isValidating ? PENDING : overAllHealthResult}
                          </td>
                        ) : (
                          <td></td>
                        )}

                        {i === 0 ? (
                          <td rowspan={simConfidenceSpan}>
                            {isValidating ? (
                              PENDING
                            ) : (
                              <Button
                                color="link"
                                onClick={() => {
                                  this.props.showValidationDetails(true);
                                }}
                              >
                                {valTableData.validationConfidence}
                              </Button>
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}
                  {data.vm.map((key, i) => {
                    let util_result = isValidating
                      ? ""
                      : valTableData.validationVM.sim_memory_utils_result ===
                        "Y"
                      ? Y
                      : valTableData.validationVM.sim_memory_utils_result ===
                        "N"
                      ? N
                      : valTableData.validationVM.sim_memory_utils_result ===
                        "N/A"
                      ? NA
                      : valTableData.validationVM.sim_memory_utils_result ===
                        "M"
                      ? M
                      : valTableData.validationVM.sim_memory_utils_result ===
                        "*"
                      ? Star
                      : S;
                    let health_result = isValidating
                      ? ""
                      : valTableData.validationVM.sim_memory_health_result ===
                        "Y"
                      ? Y
                      : valTableData.validationVM.sim_memory_health_result ===
                        "N"
                      ? N
                      : valTableData.validationVM.sim_memory_health_result ===
                        "N/A"
                      ? NA
                      : valTableData.validationVM.sim_memory_health_result ===
                        "M"
                      ? M
                      : valTableData.validationVM.sim_memory_health_result ===
                        "*"
                      ? Star
                      : S;
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowspan={data.vm.length}>
                            <div className="MemoryClass"></div>
                            <Col className="tableLeftHead">MEMORY</Col>
                          </td>
                        ) : (
                          ""
                        )}
                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM
                            .sim_current_memory_cost === message.CostHigh ? (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostHighColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM
                            .sim_current_memory_cost === message.CostLow ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "#393939" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostLowColor,
                                  color: "#393939",
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{ backgroundColor: message.CostOKColor }}
                                className="sizingeHelathColoredTDValidation"
                                tooltip={message.CostOKColor}
                              >
                                $$$
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM.sim_rec_memory_cost ===
                          message.CostHigh ? (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostHighColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM.sim_rec_memory_cost ===
                          message.CostLow ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "#393939" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostLowColor,
                                  color: "#393939",
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{ backgroundColor: message.CostOKColor }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$$
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? "" : <td>{util_result}</td>}
                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM
                            .sim_current_memory_health ===
                          message.HealthGood ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthGoodColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Red}
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM
                            .sim_current_memory_health ===
                          message.HealthFair ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthFairColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Yellow}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthPoorColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Green}
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM.sim_rec_memory_health ===
                          message.HealthGood ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthGoodColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Red}
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM.sim_rec_memory_health ===
                          message.HealthFair ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthFairColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Yellow}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthPoorColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Green}
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? "" : <td>{health_result}</td>}
                      </tr>
                    );
                  })}
                  {data.vm.map((key, i) => {
                    let util_result = isValidating
                      ? ""
                      : valTableData.validationVM.sim_network_utils_result ===
                        "Y"
                      ? Y
                      : valTableData.validationVM.sim_network_utils_result ===
                        "N"
                      ? N
                      : valTableData.validationVM.sim_network_utils_result ===
                        "N/A"
                      ? NA
                      : valTableData.validationVM.sim_network_utils_result ===
                        "M"
                      ? M
                      : valTableData.validationVM.sim_network_utils_result ===
                        "*"
                      ? Star
                      : S;

                    let health_result = isValidating
                      ? ""
                      : valTableData.validationVM.sim_network_health_result ===
                        "Y"
                      ? Y
                      : valTableData.validationVM.sim_network_health_result ===
                        "N"
                      ? N
                      : valTableData.validationVM.sim_network_health_result ===
                        "N/A"
                      ? NA
                      : valTableData.validationVM.sim_network_health_result ===
                        "M"
                      ? M
                      : valTableData.validationVM.sim_network_health_result ===
                        "*"
                      ? Star
                      : S;
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowspan={data.vm.length}>
                            <div className="NetworkClass"></div>
                            <Col className="tableLeftHead">NETWORK</Col>
                          </td>
                        ) : (
                          ""
                        )}
                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM
                            .sim_current_network_cost === message.CostHigh ? (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostHighColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM
                            .sim_current_network_cost === message.CostLow ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "#393939" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostLowColor,
                                  color: "#393939",
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{ backgroundColor: message.CostOKColor }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$$
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM.sim_rec_network_cost ===
                          message.CostHigh ? (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostHighColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM.sim_rec_network_cost ===
                          message.CostLow ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "#393939" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostLowColor,
                                  color: "#393939",
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{ backgroundColor: message.CostOKColor }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$$
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? "" : <td>{util_result}</td>}
                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM
                            .sim_current_network_health ===
                          message.HealthGood ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthGoodColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Red}
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM
                            .sim_current_network_health ===
                          message.HealthFair ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthFairColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Yellow}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthPoorColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Green}
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : valTableData.validationVM.sim_rec_network_health ===
                          message.HealthGood ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthGoodColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Red}
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationVM.sim_rec_network_health ===
                          message.HealthFair ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthFairColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Yellow}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthPoorColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Green}
                              </div>
                            </div>
                          </td>
                        )}
                        {isValidating ? "" : <td>{health_result}</td>}
                      </tr>
                    );
                  })}

                  {data.osdisk.map((key, i) => {
                    let util_result = isValidating
                      ? ""
                      : valTableData.validationOSDisk
                          .sim_osdisk_utils_result === "Y"
                      ? Y
                      : valTableData.validationOSDisk
                          .sim_osdisk_utils_result === "N"
                      ? N
                      : valTableData.validationOSDisk
                          .sim_osdisk_utils_result === "N/A"
                      ? NA
                      : valTableData.validationOSDisk
                          .sim_osdisk_utils_result === "M"
                      ? M
                      : valTableData.validationOSDisk
                          .sim_osdisk_utils_result === "*"
                      ? Star
                      : S;
                    let health_result = isValidating
                      ? ""
                      : valTableData.validationOSDisk
                          .sim_osdisk_health_result === "Y"
                      ? Y
                      : valTableData.validationOSDisk
                          .sim_osdisk_health_result === "N"
                      ? N
                      : valTableData.validationOSDisk
                          .sim_osdisk_health_result === "N/A"
                      ? NA
                      : valTableData.validationOSDisk
                          .sim_osdisk_health_result === "M"
                      ? M
                      : valTableData.validationOSDisk
                          .sim_osdisk_health_result === "*"
                      ? Star
                      : S;
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowspan={data.osdisk.length}>
                            <div className="OsClass"></div>
                            <Col className="tableLeftHead">OS Disk</Col>
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td rowspan={data.osdisk.length}>
                            {key.current_os_disk_name
                              ? key.current_os_disk_name.toUpperCase()
                              : key.current_os_disk_name}
                            {<br />}({key.current_os_disk_tier})
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          {key.recommended_os_disk_name}
                          {<br />}({key.recommended_os_disk_tier})
                        </td>

                        {isValidating ? (
                          <td colspan="3">{PENDING}</td>
                        ) : valTableData.validationOSDisk
                            .sim_current_os_cost === message.CostHigh ? (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostHighColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationOSDisk
                            .sim_current_os_cost === message.CostLow ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "#393939" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostLowColor,
                                  color: "#393939",
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{ backgroundColor: message.CostOKColor }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$$
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : valTableData.validationOSDisk.sim_rec_os_cost ===
                          message.CostHigh ? (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostHighColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationOSDisk.sim_rec_os_cost ===
                          message.CostLow ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "#393939" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.CostLowColor,
                                  color: "#393939",
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td className="textfieldSizing">
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{ backgroundColor: message.CostOKColor }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                $$$
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? "" : <td>{util_result}</td>}
                        {isValidating ? (
                          <td colspan="3">{PENDING}</td>
                        ) : valTableData.validationOSDisk
                            .sim_current_os_health === message.HealthGood ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthGoodColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Red}
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationOSDisk
                            .sim_current_os_health === message.HealthFair ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthFairColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Yellow}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthPoorColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Green}
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? (
                          ""
                        ) : valTableData.validationOSDisk.sim_rec_os_health ===
                          message.HealthGood ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthGoodColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Red}
                              </div>
                            </div>
                          </td>
                        ) : valTableData.validationOSDisk.sim_rec_os_health ===
                          message.HealthFair ? (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthFairColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Yellow}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className="textfieldSizing"
                            style={{ color: "white" }}
                          >
                            <div className="sizingHealthColoredTDCenteredDiv">
                              <div
                                style={{
                                  backgroundColor: message.HealthPoorColor,
                                }}
                                className="sizingeHelathColoredTDValidation"
                              >
                                {Green}
                              </div>
                            </div>
                          </td>
                        )}

                        {isValidating ? "" : <td>{health_result}</td>}
                      </tr>
                    );
                  })}
                  {Object.keys(data.dataDisk).map((key) => {
                    return data.dataDisk[key].map((value, i) => {
                      let util_result = isValidating
                        ? ""
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_utils_result === "Y"
                        ? Y
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_utils_result === "N"
                        ? N
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_utils_result === "N/A"
                        ? NA
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_utils_result === "M"
                        ? M
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_utils_result === "*"
                        ? Star
                        : S;
                      let health_result = isValidating
                        ? ""
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_health_result === "Y"
                        ? Y
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_health_result === "N"
                        ? N
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_health_result === "N/A"
                        ? NA
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_health_result === "M"
                        ? M
                        : valTableData.validationDataDisks[key]
                            .sim_datadisk_health_result === "*"
                        ? Star
                        : S;
                      return (
                        <tr>
                          {i === 0 ? (
                            <td rowspan={data.dataDisk[key].length}>
                              <div className="OsClass" />
                              <Col className="tableLeftHead">DISK {key}</Col>
                            </td>
                          ) : (
                            ""
                          )}
                          <td>
                            {value.current_data_disk_name}
                            {<br />}({value.current_data_disk_tier})
                          </td>
                          <td>
                            {value.recommended_data_disk_name} {<br />}(
                            {value.recommended_data_disk_tier})
                          </td>

                          {isValidating ? (
                            <td colspan="3">{PENDING}</td>
                          ) : valTableData.validationDataDisks[key]
                              .sim_current_datadisk_cost ===
                            message.CostHigh ? (
                            <td className="textfieldSizing">
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.CostHighColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  $
                                </div>
                              </div>
                            </td>
                          ) : valTableData.validationDataDisks[key]
                              .sim_current_datadisk_cost === message.CostLow ? (
                            <td
                              className="textfieldSizing"
                              style={{ color: "#393939" }}
                            >
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.CostLowColor,
                                    color: "#393939",
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  $$
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td className="textfieldSizing">
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.CostOKColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  $$$
                                </div>
                              </div>
                            </td>
                          )}

                          {isValidating ? (
                            ""
                          ) : valTableData.validationDataDisks[key]
                              .sim_rec_datadisk_cost === message.CostHigh ? (
                            <td className="textfieldSizing">
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.CostHighColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  $
                                </div>
                              </div>
                            </td>
                          ) : valTableData.validationDataDisks[key]
                              .sim_rec_datadisk_cost === message.CostLow ? (
                            <td
                              className="textfieldSizing"
                              style={{ color: "#393939" }}
                            >
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.CostLowColor,
                                    color: "#393939",
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  $$
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td className="textfieldSizing">
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.CostOKColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  $$$
                                </div>
                              </div>
                            </td>
                          )}

                          {isValidating ? "" : <td>{util_result}</td>}
                          {isValidating ? (
                            <td colspan="3">{PENDING}</td>
                          ) : valTableData.validationDataDisks[key]
                              .sim_current_datadisk_health ===
                            message.HealthGood ? (
                            <td
                              className="textfieldSizing"
                              style={{ color: "white" }}
                            >
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.HealthGoodColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  {Red}
                                </div>
                              </div>
                            </td>
                          ) : valTableData.validationDataDisks[key]
                              .sim_current_datadisk_health ===
                            message.HealthFair ? (
                            <td
                              className="textfieldSizing"
                              style={{ color: "white" }}
                            >
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.HealthFairColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  {Yellow}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td
                              className="textfieldSizing"
                              style={{ color: "white" }}
                            >
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.HealthPoorColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  {Green}
                                </div>
                              </div>
                            </td>
                          )}

                          {isValidating ? (
                            ""
                          ) : valTableData.validationDataDisks[key]
                              .sim_rec_datadisk_health ===
                            message.HealthGood ? (
                            <td
                              className="textfieldSizing"
                              style={{ color: "white" }}
                            >
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.HealthGoodColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  {Red}
                                </div>
                              </div>
                            </td>
                          ) : valTableData.validationDataDisks[key]
                              .sim_rec_datadisk_health ===
                            message.HealthFair ? (
                            <td
                              className="textfieldSizing"
                              style={{ color: "white" }}
                            >
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.HealthFairColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  {Yellow}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td
                              className="textfieldSizing"
                              style={{ color: "white" }}
                            >
                              <div className="sizingHealthColoredTDCenteredDiv">
                                <div
                                  style={{
                                    backgroundColor: message.HealthPoorColor,
                                  }}
                                  className="sizingeHelathColoredTDValidation"
                                >
                                  {Green}
                                </div>
                              </div>
                            </td>
                          )}

                          {isValidating ? "" : <td>{health_result}</td>}
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default ValidationTable;
