import React, { Component } from 'react';
import { Spinner, Modal, ModalBody } from 'reactstrap';
// import '../AccountComponent/Account.css'
class SerraLoader extends Component {
    render() {
      return this.props.loader ? (
        <Spinner
          id="pwLoader"
          type="grow"
          style={{ width: "50px", height: "50px",position:'absolute',bottom:'50%',left:'40%' }}
          color="danger"
        >
          {"Please Wait,While loading"}
        </Spinner>
      ) : (
        <Modal
          backdrop={"static"}
          className="modalLoader"
          isOpen={true}
          centered={true}
        >
          <ModalBody cssModule={{ "modal-body": "modalCenter" }}>
            <Spinner
              id="pwLoader"
              type="grow"
              style={{ width: "100px", height: "100px" }}
              color="danger"
            >
              {"Please Wait,While loading"}
            </Spinner>
          </ModalBody>
        </Modal>
      );
    }
  }
export default SerraLoader;
