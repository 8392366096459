import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as message from "../../Constants/MessageConstant";

const ComfirmModal = (props) => {

  return (
    <Modal
      style={{ maxWidth: "400px", width: "30%", margin: "100px auto" }}
      backdrop="static"
      size="sm"
      isOpen={props.toggle}
    >
      <ModalHeader
        id="modalheader_list"
        cssModule={{ "modal-title": "modalHeader" }}
      >
        <div
          className="modalCompanyName"
          dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
        />
      </ModalHeader>
      <ModalBody>
        <div className="popup">
          <p>{props.message}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="Yes"
          id="btnaccount_save"
          class="btn btn-secondary"
          onClick={() => props.confirmHandle()}
        >
          Yes
        </Button>
        <Button
          type="No"
          id="btnaccount_cancel"
          class="btn btn-secondary"
          onClick={() => props.cancleHandle()}
        >
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ComfirmModal;
