import { configureStore } from '@reduxjs/toolkit'
import dataSourceResSlice from './reducers/dataSourceResSlice'
import dataSourcePayloadSlice from './reducers/dataSourcePayloadSlice'
import companyDetailsSlice from './reducers/companyDetailsSlice'

export const store = configureStore({
  reducer: {
    dataSourceRes : dataSourceResSlice,
    dataSourcePayload:dataSourcePayloadSlice,
    companyDetails:companyDetailsSlice,
  }
})