import PdfMetrics from "../docs/Azure_VM_Diagnostics_Metric_List.pdf";
import PlansConstants from "../Constants/PlansConstants";
import PdfDSMetricsAWS from "../docs/AWS_Configuration_for_2024-07-22.pdf";
import PdfDSMetricsAzure from "../docs/Azure_Configuration_d_2024-07-15.pdf";
import PdfGuestMetricsAzure from "../docs/Enabling_Guest_Metrics_for_Azure_VMs_07-15-2024.pdf";

function companyDetails() {
  const companyDetails = {
    CompanyName: process.env.REACT_APP_CompanyName,
    Website: process.env.REACT_APP_Website,
    Support: process.env.REACT_APP_Support,
    ServiceName: process.env.REACT_APP_ServiceName,
    ServiceNameFullName: process.env.REACT_APP_ServiceNameFullName,
    TermsAndConditions: process.env.REACT_APP_TermsAndConditions,
    PrivacyPolicy: process.env.REACT_APP_PrivacyPolicy,
  };

  return companyDetails;
}
export const AddAwsAccountText =
  "<p>To add an AWS account, " +
  companyDetails().ServiceName +
  " needs your AWS Metrics Data Source Name, AWS Account Key and AWS Secret Access Key. These can be obtained from the AWS portal. </p>";
export const AddAzureAccountText =
  "<p>To add an Azure account, " +
  companyDetails().ServiceName +
  " needs your Azure Metrics Data Source Name, Tenant (Active Directory) ID, Azure Application (client) ID and the Azure Application (client) Secret. These can be obtained from the Azure portal. </p>";
export const AddAzureAccountText1 =
  "<p> The credentials are only used to read " +
  companyDetails().ServiceName +
  " pertinent information and are not retained after the requisite information is obtained.</p>";
export const AzureClientInstructionText =
  "<p>Kindly refer to <a href = '" +
  PdfDSMetricsAzure +
  "' style='font-size:22px' target = '_blank'>this page</a> for details as to how you can do this. Note that the credentials are only used to read " +
  companyDetails().ServiceName +
  " pertinent information.";
export const AwsClientInstructionText =
  "<p>Kindly refer to <a href = '" +
  PdfDSMetricsAWS +
  "' style='font-size:22px' target = '_blank'>this page</a> for details as to how you can do this. Note that the credentials are only used to read " +
  companyDetails().ServiceName +
  " pertinent information.";
export const AzureClientInstructionMissingText =
  "<p style='font-size:16px; padding:5px;'>Kindly refer to <a href = '" +
  PdfGuestMetricsAzure +
  "' style='font-size:16px; font-weight:600;' target = '_blank'>this page</a> for details as to how you can configure the missing metrics.";
export const AzureVmDiagnosticList =
  "<p style='font-size:20px'>" +
  companyDetails().ServiceName +
  " reads various metrics configured in virtual machine's diagnostics settings, You can <a href = '" +
  PdfMetrics +
  "' style='font-size:22px' target = '_blank'>download metrics list</a>";
export const AddSelectAccountText =
  "<p>You have two ways of importing VM performance metric data into " +
  companyDetails().ServiceName +
  " safely and securely. One is by adding your Azure Account whose VM you want to right size. The other is by uploading a zip file of performance metrics time series</p>";
export const ChoosePlanText =
  "<div><p>" +
  companyDetails().CompanyName +
  " " +
  companyDetails().ServiceNameFullName +
  " (" +
  companyDetails().ServiceName +
  ") is public cloud-based software-as-a-service.  " +
  companyDetails().ServiceName +
  " helps you optimally trade-off your cloud cost with your cloud performance so that you can get the best performance at the lowest cost for your cloud applications, on an ongoing basis.</p></div>";
export const CoosePlanSubDescpription = "Let's Get Started!";
export const FreeTrialText =
  "<p>With the " +
  PlansConstants.free.title +
  ", you can analyze your VMs once and view aggregated cost-savings and performance improvements.  You will also be able to drill down into one VM analysis and view its cost-performance recommendations and benefits.</p>";
export const UploadDataText = "Add Upload file discription";
export const VmlistText = "Add Vmlist Instruction discription";
export const SerraLabsModalHeader =
  companyDetails().CompanyName + " " + companyDetails().ServiceName;
export const SerraLabsHeader =
  companyDetails().CompanyName + " " + companyDetails().ServiceName;
export const SessionExpiredText =
  "User has been logged out because of session inactivity.";
export const SessionExpireWarnText =
  'This session will end shortly unless "Continue" button is clicked';
export const CacheErrorText =
  companyDetails().ServiceName +
  " Service is currently not reachable. Please try again later.";
export const ServerError =
  "Something went wrong, please contact " + companyDetails().Support;
export const StartConifigRefreshText =
  "This may take a up to a few minutes to complete during which no other actions are possible.";
export const StopConfigRefreshText = "Are you sure, you want to stop refresh ?";
export const CostHigh = "Good";
export const CostTooHigh = "Too High";
export const CostLow = "Fair";
export const CostTooLow = "Too Low";
export const CostOK = "Poor";
export const CostHighColor = "#228B22";
export const CostTooHighColor = "#880C25";
export const CostLowColor = "#FFBF00";
export const CostTooLowColor = "#0c171c";
export const CostOKColor = "#E32227";
export const HealthGood = "Normal";
export const HealthPoor = "Abnormal";
export const HealthFair = "Borderline";
export const HealthGoodColor = "#228B22";
export const HealthPoorColor = "#E32227";
export const HealthFairColor = "#FFBF00";
export const Shrinking = "Shrinking";
export const Growing = "Growing";
export const UtilHigh = "High";
export const UtilLow = "Low";
export const UtilMedium = "Medium";
export const AssessmentFailNoMetricData = "Not enough metric data on VM ";
export const AssessmentFail =
  "Analysis failed because of some internal error for VM ";
export const PPUPREQUESTCOFIRMATIONTEXT =
  "Your request has been sent successfully. You will get confirmation email from " +
  companyDetails().Support +
  " soon...";
export const PPUApprovedLogoutMessage =
  "You have been authorized for the Paid Plan plan that you had requested. You will be logged out after you press OK. When you login again, you will be using the Paid Plan.";
export const FreeTrialApprovedLogoutMessage =
  "You have been authorized for the Free Trial plan that you had requested. You will be logged out after you press OK. When you login again, you will be using the Free Trial Plan.";
export const ForceLogoutMessage =
  "This session has been manually terminated by another login to this account.";
export const ResoltionMessage =
  `Sorry, the ` +
  companyDetails().ServiceNameFullName +
  ` User Interface is not usable from your current device whose resolution appears to be ${window.innerWidth} x ${window.innerHeight}.<br/> Please use a device with at least a resolution of 1024 x 768.`;
export const PPUText =
  "<p> With the " +
  PlansConstants.paid.title +
  ", you can analyze all of your VMs whenever you want, view aggregated and specific cost saving and performance improving recommendations and benefits, and apply the recommendations to accrue the benefits.</p>";
export const PerformParkingAnalysisInfoText =
  "<p>For each selected VM, analyze historical data for several usage and health metrics using advanced machine learning techniques to determine thresholds and trends which then are used by parking analysis techniques to determine parking recommendations.</p>";
export const PerformAnalysisInfoText =
  "<p>For each selected VM, analyze historical data for several usage and health metrics using advanced machine learning techniques to determine thresholds and trends which then are used by optimization techniques to determine sizing recommendations for each optimization objective.</p>";
export const PerformASPAnalysisInfoText =
  "<p>For each selected ASP, analyze historical data for several usage and health metrics using advanced machine learning techniques to determine thresholds and trends which then are used by optimization techniques to determine sizing recommendations for each optimization objective.</p>";
export const PerformDBAnalysisInfoText =
  "<p>For each selected DB, analyze historical data for several usage and health metrics using advanced machine learning techniques to determine thresholds and trends which then are used by optimization techniques to determine sizing recommendations for each optimization objective.</p>";
export const PerformCleanupAnalysisInfoText =
  "<p>Resources under each selected Metric Data Source will be checked if they are orphaned and not in use.</p>";
export const GenerateParkingReportInfoText0 =
  "<p>Produce an overall report for all selected VMs with a summary table of parking benefits as well as the parking schedule details of each VM.</p>";
export const GenerateReportInfoText0 =
  "<p>Produce an overall report for all selected VMs with a summary table of recommendation benefits for each optimization objective as well as the ability to drill down into detail reports of each VM.</p>";
export const GenerateASPReportInfoText0 =
  "<p>Produce an overall report for all selected ASPs with a summary table of recommendation benefits for each optimization objective as well as the ability to drill down into detail reports of each ASP.</p>";
export const GenerateDBReportInfoText0 =
  "<p>Produce an overall report for all selected DBs with a summary table of recommendation benefits for each optimization objective as well as the ability to drill down into detail reports of each DB.</p>";
export const GenerateCleanupReportInfoText0 =
  "<p>Produce an overall report for all selected Metric Data Sources with a summary table of cleanup benefits for each resource.</p>";
export const ResetInfoText =
  "<p>Discard prior analysis associated with selected VMs so that they can be analyzed again.</p>";
export const ResetASPInfoText =
  "<p>Discard prior analysis associated with selected ASPs so that they can be analyzed again.</p>";
export const ResetDBInfoText =
  "<p>Discard prior analysis associated with selected DBs so that they can be analyzed again.</p>";
export const ResetCleanupInfoText =
  "<p>Discard prior cleanup analysis associated with selected Metric Data Sources so that they can be analyzed again.</p>";
export const ResetStateFootNote =
  'Not applicable to VMs in "Not Analyzed" state';
export const ResetASPStateFootNote =
  'Not applicable to ASPs in "Not Analyzed" state';
export const ResetDBStateFootNote =
  'Not applicable to DBs in "Not Analyzed" state';
export const ResetCleanupStateFootNote =
  'Not applicable to Metric Data Sources in "Not Analyzed" state';
export const StartAnalysisFootNote =
  'Only applicable to VMs that are in  "Not Analyzed" state';
export const StartASPAnalysisFootNote =
  'Only applicable to ASPs that are in  "Not Analyzed" state';
export const StartDBAnalysisFootNote =
  'Only applicable to DBs that are in  "Not Analyzed" state';
export const StartCleanupAnalysisFootNote =
  'Only applicable to Metric Data Sources that are in  "Not Analyzed" state';
export const ShowReportFootNote =
  'Not applicable to VMs that are in "Not Analyzed" or "Analysis Issue" states';
export const ShowASPReportFootNote =
  'Not applicable to ASPs that are in "Not Analyzed" or "Analysis Issue" states';
export const ShowDBReportFootNote =
  'Not applicable to DBs that are in "Not Analyzed" or "Analysis Issue" states';
export const ShowCleanupReportFootNote =
  'Not applicable to Metric Data Sources that are in "Not Analyzed" or "Analysis Issue" states';
export const PerformAnalysisConfirmFootNote =
  "Each VM Analysis can take anywhere from 15 to 45 minutes depending on the VM complexity";
export const PerformParkingAnalysisConfirmFootNote =
  "Each VM Parking Analysis can take anywhere from 5 to 15 minutes depending on the VM complexity";
export const PerformASPAnalysisConfirmFootNote =
  "Each ASP Analysis can take anywhere from 15 to 30 minutes depending on the ASP complexity";
export const PerformDBAnalysisConfirmFootNote =
  "Each DB Analysis can take anywhere from 15 to 30 minutes depending on the DB complexity";
export const PerformCleanupAnalysisConfirmFootNote =
  "Each Metric Data Source Cleanup Analysis can take anywhere from 1 to 10 minutes depending on the number of resources.";
export const PerformValidationInfoText =
  "<p>Perform validation of VM for the selected optimization objective by simulating the a summary of historical data on recommended VM and Disk sizing.</p>";
export const PerformValidationFootNote =
  '<div>This can take between 75 and 90 minutes to complete per VM although validations can run concurrently after they are created.</div></br><div>Only applicable to VMs that are in  "Analyzed" state.</div>';
export const ManageResizingInfoText =
  "<p>Manage resizing of VM using a selected (usually validated) recommendation.  Currently manual resizing is supported. It requires the user to apply the resizing actions of the selected recommendation, which will be reflected in " +
  companyDetails().ServiceName +
  " UI.</p>";
export const ManageParkingInfoText =
  "<p>Manage parking of VM using a suggested parking schedule resommendation. Currently manual parking is supported. It requires the user to apply the suggested parking schedule resommendation in Azure Portal.</p>";
export const ManageASPResizingInfoText =
  "<p>Manage resizing of ASP using a selected recommendation. Currently manual resizing is supported.  It requires the user to apply the resizing actions of the selected recommendation, which will be reflected in " +
  companyDetails().ServiceName +
  " UI.</p>";
export const ManageDBResizingInfoText =
  "<p>Manage resizing of DB using a selected recommendation. Currently manual resizing is supported.  It requires the user to apply the resizing actions of the selected recommendation, which will be reflected in " +
  companyDetails().ServiceName +
  " UI.</p>";
export const ManageCleanupResizingInfoText =
  "<p>Manage cleanup of orphaned resources in Metric Data Source. Currently manual cleanup is supported. It requires the user to apply the cleanup actions of the suggested orphaned resources, which will be reflected in " +
  companyDetails().ServiceName +
  " UI.</p>";
export const ManageResizingFootNote =
  '<div>This can take as much time as the user needs to complete the resizing.</div></br><div>Only applicable to VMs that are in  "Analyzed" or "Validated" states.</div>';
export const ManageParkingFootNote =
  '<div>This can take as much time as the user needs to complete the parking.</div></br><div>Only applicable to VMs that are in  "Analyzed" state.</div>';
export const ManageASPResizingFootNote =
  '<div>This can take as much time as the user needs to complete the resizing.</div></br><div>Only applicable to ASPs that are in  "Analyzed" state.</div>';
export const ManageDBResizingFootNote =
  '<div>This can take as much time as the user needs to complete the resizing.</div></br><div>Only applicable to DBs that are in  "Analyzed" state.</div>';
export const ManageCleanupResizingFootNote =
  '<div>This can take as much time as the user needs to complete the cleanup.</div></br><div>Only applicable to Metric Data Sources that are in  "Analyzed" state.</div>';
export const RefreshVMInfoText =
  "<p>Refresh VM & ASP Tables retrieves the latest list of VMs and ASPs from all the configured accounts and updates the VM & ASP tables.</p>";
export const RefreshVMFootNote =
  "This may take a up to a few minutes to complete during which no other actions are possible.";
export const ResizeProceedConrfirmText =
  " have been selected for resizing or have been marked as resizing complete. For those that are marked as resizing complete, the actions are irreversible.";
export const VMRersourceChangedFLagInfoTextAnalyzed =
  "The analysis and recommendation of this VM is no longer current as the VM sizing has changed.";
export const ASPRersourceChangedFLagInfoTextAnalyzed =
  "The analysis and recommendation of this ASP is no longer current as the ASP sizing has changed.";
export const DBRersourceChangedFLagInfoTextAnalyzed =
  "The analysis and recommendation of this Database is no longer current as the Databse sizing has changed.";
export const VMRersourceChangedFLagInfoTextValidated =
  "The analysis, recommendation and validation of this VM is no longer current as the VM sizing has changed.";
export const VMResourceChngedFlagInfoTextSummaryAnalyzed =
  "VM sizing has changed post analysis and recommendation, reset and analyze to make analysis and recommendation current. ";
export const ASPResourceChngedFlagInfoTextSummaryAnalyzed =
  "ASP sizing has changed post analysis and recommendation, reset and analyze to make analysis and recommendation current. ";
export const DBResourceChngedFlagInfoTextSummaryAnalyzed =
  "DB sizing has changed post analysis and recommendation, reset and analyze to make analysis and recommendation current. ";
export const VMResourceChngedFlagInfoTextSummaryValidated =
  "VM and related sizing has changed post analysis, recommendation, and validation; reset and analyze to make analysis and recommendation current. ";
export const VMResourceCHangeFlagInfoTextSummaryOptimizing =
  "VM resizing per optimization recommendations has been initiated;  analysis, recommendation and validation above are based on previous VM and related size. ";
export const VMResourceCHangeFlagInfoTextSummaryOptimized =
  "VM resizing per optimization recommendations has been applied; analysis, recommendation and validation above are based on previous VM and related size. ";
export const ASPResourceCHangeFlagInfoTextSummaryOptimizing =
  "ASP resizing per optimization recommendations has been initiated;  analysis and recommendation above are based on previous ASP and related size. ";
export const ASPResourceCHangeFlagInfoTextSummaryOptimized =
  "ASP resizing per optimization recommendations has been applied; analysis and recommendation above are based on previous ASP and related size. ";
export const DBResourceCHangeFlagInfoTextSummaryOptimizing =
  "DB resizing per optimization recommendations has been initiated;  analysis and recommendation above are based on previous DB and related size. ";
export const DBResourceCHangeFlagInfoTextSummaryOptimized =
  "DB resizing per optimization recommendations has been applied; analysis and recommendation above are based on previous DB and related size. ";
export const AzureTestErrorMsg =
  "Role assignments of the Microsoft Entra ID app (with the above-specified application ID) may be incorrect or missing. Ensure that correct role assignments for this app, as shown in <a href = '" +
  PdfDSMetricsAzure +
  "' style='font-size:16px; font-weight:600;' target = '_blank'>this page</a>";
export const AwsTestErrorMsg =
  "Role assignments of the Microsoft Entra ID app (with the above-specified application ID) may be incorrect or missing.  Ensure that correct role assignments for this app, as shown in <a href = '" +
  PdfDSMetricsAWS +
  "' style='font-size:16px; font-weight:600;' target = '_blank'>this page</a>";
export const RecommendationFootNote =
  "<span style='vertical-align: sub'>*</span>Only non-reserved VM and Disk costs included; assuming one month = 730 hrs";
export const CostHealthFootNote =
  "<span style='vertical-align: sub'>*</span>Only non-reserved VM and Disk costs included; assuming one month = 730 hrs";
export const AzureCalculatorPricAnalyzed =
  "Analysis performed using Azure Calculator Pricing as Subscription Pricing was not yet available.  Recommend that analysis be performed again when Subscription Pricing is available.";
export const PerformAnalysisHostMetricsFootNote =
  "Analysis is limited for this VM because it has host-only metrics. Recommend enabling guest metrics as shown in the VM Warning Table.";
export const PerformAnalysisMultipleHostMetricsFootNote =
  "Analysis is limited for some VMs because they have host-only metrics. Recommend enabling guest metrics as shown in the VM Warning Table.";
export const RecommendationPreferenceMsg =
  "This Azure only preference determines whether or not the recommended VM Size needs to support the same VM generation (v1 or v2) as the analyzed VM Size.  When the VM generations of the recommended VM Size and the analyzed VM Size are different, manual steps are necessary to complete the recommended resizing whereas when the VM generations are the same, the recommended resizing is easy to do.";
export const AnalyzedPreferenceMsg =
  "Note that this analysis is obsolete because of pricing change or recommendation preference change";
export const VMsRightsizingBenefitsMsg =
  "Only VM and Disk costs included, VM is unreserved, disks are LRS, one month has 730 hrs";
export const ASPsRightsizingBenefitsMsg =
  "ASP is unreserved and one month has 730 hrs";
export const DBaasRightsizingBenefitsMsg =
  "DBaaS is unreserved and one month has 730 hrs";
export const RecommendationPreferenceMsgAWS =
  "This AWS only preference determines whether or not the recommended VM Instance Type needs to support the same VM generation (Current or Older) as the analyzed VM Instance Type.  When the generations of the recommended VM Instance Type and the analyzed VM Instance Type are different, manual steps are necessary to complete the recommended resizing whereas when the VM Instance Type generations are the same, the recommended resizing is easy to do.";
export const MeasurementId = "GT-TQSVKWH";
export const OrgStatusDetailsInfo =
  "Details button is disabled because there is no datasource successfully configured and enabled for the user.";
export const MetricDataIntervalInfo =
  "Best fidelity is the highest resolution of data that is expected to be retrieved for rightsizing analysis. Metric collection fidelity is determined by how each VM is configured.  In addition for 1 minute fidelity, detailed monitoring needs to be enabled for each VM.";
export const MetricDataIntervalInfoAzure =
  "Best fidelity is the highest resolution of data that is expected to be retrieved for rightsizing analysis. Using 5 minute metric collection fidelity means lower cost associated with metric generation/storage than when using 1 minute metric collection fidelity.";
export const MetricDataDurationInfoAzure =
  "Maximum duration is the most amount of data at the best fidelity that is expected to be retrieved for rightsizing analysis. Ensure that each storage account associated with each VM is configured to store at least the number of days specified as metric duration";
export const MetricDataDurationInfo =
  "Maximum duration is the most amount of data at the best fidelity that is expected to be retrieved for rightsizing analysis. AWS metric values can be retrieved at 1 min fidelity (if generated) for the past 15 days, 5 min fidelity for the past 63 days, and 1 hour fidelity for the past 455 days.  There is cost associated with generating metric values using CloudWatch depending on fidelity and metric type.";
export const ChangedMatrics =
  "Available Metrics has changed from Host Metrics to Host+Guest Metrics (or Host+Guest Metrics to Host Metrics).";
export const PerformAnalysisConfirmNote =
  "Note that successful analysis can take anywhere from a few minutes to tens of minutes depending on duration and fidelity settings.";
export const PerformAnalysisConfirmNoteFreeTrial =
  "Successful VM analysis can take anywhere from a few minutes to tens of minutes depending on duration and fidelity settings which for Free Trial is set at 7  days duration and 5 minute fidelity.";
export const PerformAnalysisParkingConfirmNote =
  "Successful parking analysis of a VM can take 15-30 mins to complete after analysis has been started.";
export const IsStaledsMsg = "Cannot access associated Data Source";
export const CacheErrorTextPdf =
  "<p>PDF generation failed - please retry or contact " +
  companyDetails().Support +
  "<p>";
