import React, { Component } from 'react';
import { Input, Button, InputGroup, InputGroupText, InputGroupAddon, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as Constdata from '../../Constants/ApiConstants';
import Header from '../HeaderComponent/Header';
import SerraLoader from '../CommonComponent/Loader';
import SerraError from '../CommonComponent/Error';
import * as message from '../../Constants/MessageConstant';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
class AccountCreation extends Component {
  constructor(props) {
    super(props);
    this.handleSumbit = this.handleSumbit.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.toggle = this.toggle.bind(this);
    this.IsDisabled=this.IsDisabled.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.processTestResponse = this.processTestResponse.bind(this);
    this.testConfig = this.testConfig.bind(this);
    this.setErrorPopupState= this.setErrorPopupState.bind(this);
    this.toggleShow=this.toggleShow.bind(this);
    this.handleCancelAccount=this.handleCancelAccount.bind(this);
    this.state = {
      id: 1,
      orgId: '',
      fields: {},
      errors: {},
      modal: false,
      provider: '',
      modalLoader:false,
      errormessage:'',
      errorPopup:false,
      hidden:true,
      isRedirect:false,
      configIdupdate:this.props.location !== "undefined"?this.props.location.state.configIdUpdate:"",
      isErrorHeader:true
    };
  }
  componentDidMount(){
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Account Creation" });
    this.props.registerListners(true);
   if(this.props.location.state.key!==false){
       this.setState({ modalLoader:true });
   }
   return this.props.location.state.key===true?
    fetch(`${Constdata.GETAZURECONFIG_URL}${this.state.configIdupdate}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).then((response) => response.json())
      .then((responseJson) => {
       console.log(responseJson);
       if (responseJson.success === true){
      var prefieldData={"accountname" : responseJson.data.accountname,
                            "tenantId":responseJson.data.tenantId,
                            "clientSecret":responseJson.data.clientSecret,
                          "clientId":responseJson.data.clientId,
                            "storeSecret": responseJson.data.storeSecret};
       this.setState({fields:prefieldData , orgId:this.props.location.state.orgIdEdit, modalLoader: false});
      }else if(responseJson.status === 403 || responseJson.status === 401 ){
        this.setState({isRedirect:true, modalLoader: false, errorPopup: true, errormessage:message.SessionExpiredText, isErrorHeader:false});
    }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ modalLoader: false, errorPopup: true, errormessage:message.CacheErrorText, isErrorHeader:false});
      })
    :"";
  }
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!fields["tenantId"]) {
      formIsValid = false;
      errors["tenantId"] = "Azure Account cannot be empty";
    }
    //clientSecret
    if (!fields["clientSecret"]) {
      formIsValid = false;
      errors["clientSecret"] = "Client Secret cannot be empty";
    }
    //ClientId
    if (!fields["clientId"]) {
      formIsValid = false;
      errors["clientId"] = "Client Id cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleStoreSecret(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.checked;
    this.setState({ fields });
  }
  handleSumbit = (e) => {
    this.setState({ modalLoader:true });
    e.preventDefault();
    const { accountname, tenantId, clientSecret, clientId } = this.state.fields;
    const payload = {
      accountname : accountname,
      tenantId: tenantId,
      clientSecret: clientSecret,
      clientId: clientId,
      storeSecret : true,
      isEdit:this.props.location.state.key
    };
    if (!this.handleValidation()) {
      return;
    }
    fetch(Constdata.ADDUPDATE_AZURE_CONFIG_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({ modalLoader: false });
        this.processResponse(responseJson);
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  processResponse(response) {
    if (response.success === true) {
      this.setState(function () {
        return ({ orgId: response.data.orgId ,configId: response.data.configId});
      });
      this.props.history.push({ pathname: '/vmlist', state: { key: this.state.orgId, configId:this.state.configId } });
    }
    else  if (response.success === false) {
      this.setState({ modalLoader: false ,errorPopup:true, errormessage:response.message});
      for (var i = 0; i < response.errorFields.length; i++) {
        if (response.errorFields[i].fieldName !== "") {
          this.setState({ error: response.errorFields[i].fieldName });
          this.state.errors[response.errorFields[i].fieldName] = response.errorFields[i].error;
        }
      }
    }
      else if(response.status === 403 || response.status === 401 ){
        this.setState({isRedirect:true, modalLoader: false, errorPopup: true, errormessage:message.SessionExpiredText, isErrorHeader:false});
    }else{
      this.setState({  modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader:false});
  }
      }
  testConfig = () => {
    this.setState({ modalLoader: true });
    const { tenantId, clientSecret, clientId } = this.state.fields;
    const payload = {
      orgId: 1,
      tenantId: tenantId,
      clientSecret: clientSecret,
      clientId: clientId
    };
    if (!this.handleValidation()) {
      return;
    }
    fetch(Constdata.TEST_AZURE_CONFIG_URL, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        this.processTestResponse(responseJson);
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ modalLoader: false, errorPopup: true, errormessage:message.CacheErrorText, isErrorHeader:false});
      });
  }
  processTestResponse(response) {
    if(response.success === true){
    this.setState({ modalLoader: false ,errorPopup:true, errormessage:response.message});
    }else if(response.success === false){
      this.setState({ modalLoader: false ,errorPopup:true, errormessage:response.message});
    }
    else if(response.status === 403 || response.status === 401 ){
      this.setState({isRedirect: true,  modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText,isErrorHeader:false});
  }else{
    this.setState({  modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader:false});
}
  }
  IsDisabled =()=>{
    const { tenantId, clientSecret, clientId } = this.state.fields;
    return !tenantId || !clientSecret || !clientId || this.state.modalLoader;
  }
  setErrorPopupState = (event) =>{
    this.setState({errorPopup: event});
    if(this.state.isRedirect){
    this.props.history.push('/');
    }
  }
  handleCancelAccount= ()=>{
    this.props.location.state.key === true ? this.props.history.push({pathname:"/vmlist",  state: { key: this.state.orgId, configId:this.state.configIdupdate }}):this.props.history.push({pathname:"/select"});
  }
  render() {
    return (
      <div style={{overflow:"hidden"}}>
       <Header processLogout={this.props.processLogut} registerListners={this.props.registerListners} ></Header>
        <div className="container-verf" role="main">
        <div className="brand-container account-container-plan">
        <div style={{margin:"auto", fontSize:"36px"}}><b>{this.props.companyDetails.CompanyName+' '+this.props.companyDetails.ServiceName}</b>
            <div  style={{fontSize:"32px", fontWeight:"300"}}>Add Azure Account</div></div></div>
          <div className="account_container">
            <form >
              <div className="form-group">
                <label htmlFor="accountname">Name:</label>
                <Input placeholder="Name for azure configuration" type="text" id="accountname" name="accountname" value={this.state.fields["accountname"]} onChange={this.handleChange.bind(this, "accountname")} />
                <span style={{ color: "red" }}>{this.state.errors["accountname"]}</span>
              </div>
              <div className="form-group">
                <label htmlFor="tenantId">Tenant (Active Directory) ID:</label>
                <Input placeholder="Tenant (Active Directory) ID" type="text" id="tenantId" name="tenantId" value={this.state.fields["tenantId"]} onChange={this.handleChange.bind(this, "tenantId")} />
                <span style={{ color: "red" }}>{this.state.errors["tenantId"]}</span>
              </div>
              <div className="form-group">
                <label htmlFor="clientId">Application (client) ID: <span>&nbsp;</span><span className="info-icon" onClick={this.toggle}><i className="fa fa-info-circle"/>  </span></label> 
                <Input placeholder="Application (client) ID" type="text" id="clientId" name="clientId" value={this.state.fields["clientId"]} onChange={this.handleChange.bind(this, "clientId")} />
                <span style={{ color: "red" }}>{this.state.errors["clientId"]}</span>
              </div>
              <div className="form-group">
                <label htmlFor="clientSecret">Application (client) Secret:</label>
                <InputGroup>
                <Input placeholder="Application (client) Secret" type={this.state.hidden ? "password" : "text"} id="clientSecret" name="clientSecret" value={this.state.fields["clientSecret"]} onChange={this.handleChange.bind(this, "clientSecret")} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-eye-slash" onClick={this.toggleShow}></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                <span style={{ color: "red" }}>{this.state.errors["clientSecret"]}</span>
              </div>
{ /*             <div className="checkbox">                
                <Input type="checkbox" id="storeSecret" name="storeSecret" defaultChecked={this.state.fields["storeSecret"]}  value={this.state.fields["storeSecret"]} onChange={this.handleStoreSecret.bind(this, "storeSecret")} />
                <label htmlFor="storeSecret"><span>&nbsp;&nbsp;</span>Store Credentials</label> 
                <span style={{ color: "red" }}>{this.state.errors["storeSecret"]}</span>
              </div> */}
              <div className="prim_But1">
                <Button id={(this.IsDisabled())? "btn_disabled":"btnaccount_test"} disabled={this.IsDisabled()} onClick={(() => this.testConfig())} >Test</Button>
              </div>
              <div className="prim_But" >
                <Button id={(this.IsDisabled())? "btn_disabled":"btnaccount_save"}  type="submit" disabled={this.IsDisabled()} onClick={this.handleSumbit}>Next
                          </Button>
               <Button id={this.state.modalLoader?"btncancel_disabled":"btnaccount_cancel"} onClick={this.handleCancelAccount} disabled={this.state.modalLoader}>Cancel</Button>
              </div>
            </form>
          </div>
          {(this.state.modalLoader === true) ? <SerraLoader></SerraLoader> :""}
          {(this.state.errorPopup === true) ?  <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState} isError={this.state.isErrorHeader}></SerraError> :""}
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
              <ModalHeader id="modalheader_list" toggle={this.toggle}  cssModule={{'modal-title': 'modalHeader'}}><div className="modalCompanyName" dangerouslySetInnerHTML={{__html: message.SerraLabsModalHeader}} /> </ModalHeader>
              <ModalBody>
                  <div  dangerouslySetInnerHTML={{__html: message.AzureClientInstructionText}}/>
                  <div  dangerouslySetInnerHTML={{__html: message.AzureVmDiagnosticList}}/>
              </ModalBody>
              <ModalFooter id="knowMoreFooter">
                  <Button id="confirmbtn_list" onClick={this.toggle}>OK</Button>
              </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      companyDetails: state.companyDetails.data,
  }
}

export default connect(mapStateToProps, null)(withRouter(AccountCreation));
