import React, { Component } from "react";
import {
  Row,
  Col,
  ButtonGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import classnames from "classnames";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  DataLabel,
  ColumnSeries,
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationDataLabel,
  AccumulationLegend,
} from "@syncfusion/ej2-react-charts";
import Header from "../HeaderComponent/Header";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraError from "../CommonComponent/Error";
import ReactGA from "react-ga4";
import SerraLoader from "../CommonComponent/Loader";
import domtoimage from "dom-to-image";
import jstz from "jstz";
import { getStringDate, priceDisplay } from "../../Constants/CommonConstant";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setDataSourceRes } from "../../reducers/dataSourceResSlice";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.getDashData = this.getDashData.bind(this);
    this.getPdf = this.getPdf.bind(this);
    this.refreshDash = this.refreshDash.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleFunc = this.toggleFunc.bind(this);
    this.legendRenderTop = this.legendRenderTop.bind(this);
    this.legendRenderVm = this.legendRenderVm.bind(this);
    this.legendRenderAsp = this.legendRenderAsp.bind(this);
    this.legendRenderDb = this.legendRenderDb.bind(this);
    this.legendRenderRight = this.legendRenderRight.bind(this);
    this.legendRenderParking = this.legendRenderParking.bind(this);
    this.legendRenderCleanup = this.legendRenderCleanup.bind(this);
    this.template = this.chartTemplate;
    this.toggleVmsStatusModal = this.toggleVmsStatusModal.bind(this);
    this.checkRefresh = this.checkRefresh.bind(this);
    this.isDataSourceExists = this.isDataSourceExists.bind(this);
    this.isEmptyChart = this.isEmptyChart.bind(this);

    this.state = {
      innerWidth: window.innerWidth,
      chartWidth: String(parseInt(window.innerWidth / 2.3)),
      providerName: "",
      loggedInUser: "",
      resizeTimer: -1,
      activeTab: "1",
      activeFuncTab: "1",
      modalLoader: false,
      errormessage: "",
      errorPopup: false,
      isFreeTrial: false,
      isErrorHeader: true,
      isRenderAnyResource: false,
      isRenderVMs: false,
      isRenderASPs: false,
      isRenderDBs: false,
      isRenderCleanup: false,
      refreshTimer: -1,
      costPerfPalette: ["#FF9B29", "#84CD59", "#B2BB47", "#E0A835", "#008080"],
      functionPalette: ["#FF9B29", "#E0A835", "#B2BB47", "#84CD59", "#008080"],
      resourceTypePalette: ["#B1D4FF", "#3A93FF", "#0058C1", "#004392"],
      legendSettings: {
        visible: true,
        position: "Bottom",
        margin: { top: 5 },
        padding: 10,
        alignment: "Center",
        toggleVisibility: false,
        textStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
      },
      costPerfMarker: {
        dataLabel: {
          template: this.template,
          position: "Outer",
          visible: true,
          font: {
            color: "#393939",
            size: "16px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "500",
          },
        },
      },
      chartTitleStyle: {
        color: "#393939",
        fontWeight: "500",
        size:
          window.innerWidth >= 1530
            ? "20px"
            : window.innerWidth >= 1400
            ? "18px"
            : window.innerWidth >= 1250
            ? "16px"
            : "15px",
        fontFamily: "Poppins, sans-serif",
        whiteSpace: "normal",
      },
      costxAxis: {
        valueType: "Category",
        labelStyle: {
          size: "14px",
          fontWeight: "500",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
      },
      perfxAxis: {
        valueType: "Category",
        labelStyle: {
          size: "14px",
          fontWeight: "500",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
      },
      costyAxis: {
        labelFormat: "${value}",
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Saving",
            zIndex: "Over",
            start: 100,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Spend",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      costyAxisParking: {
        labelFormat: "${value}",
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Saving",
            zIndex: "Over",
            start: 100,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Spend",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      costyAxisCleanup: {
        labelFormat: "${value}",
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Saving",
            zIndex: "Over",
            start: 100,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Spend",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      perfyAxis: {
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Decline",
            zIndex: "Over",
            start: 0,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Improve",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      costyAxisVm: {
        labelFormat: "${value}",
        labelStyle: {
          size: "16px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Saving",
            zIndex: "Over",
            start: 100,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Spend",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      perfyAxisVm: {
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Decline",
            zIndex: "Over",
            start: 0,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Improve",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      costyAxisAsp: {
        labelFormat: "${value}",
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Saving",
            zIndex: "Over",
            start: 100,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Spend",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      perfyAxisAsp: {
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Decline",
            zIndex: "Over",
            start: 0,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Improve",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      costyAxisDb: {
        labelFormat: "${value}",
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Saving",
            zIndex: "Over",
            start: 100,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Spend",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      perfyAxisDb: {
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Decline",
            zIndex: "Over",
            start: 0,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Improve",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      costyAxisRight: {
        labelFormat: "${value}",
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Saving",
            zIndex: "Over",
            start: 100,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Spend",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      perfyAxisRight: {
        labelStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        stripLines: [
          {
            text: "Decline",
            zIndex: "Over",
            start: 0,
            size: 0,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "Start",
            horizontalAlignment: "Start",
          },
          {
            text: "Improve",
            start: 100,
            size: 2,
            sizeType: "Pixel",
            dashArray: "10,5",
            color: "#FF9B29",
            verticalAlignment: "End",
            horizontalAlignment: "Start",
          },
        ],
      },
      datalabel: {
        visible: true,
        position: "Outside",
        connectorStyle: { length: "10px" },
        font: {
          color: "#393939",
          size: "14px",
          fontWeight: "600",
          fontFamily: "Poppins, sans-serif",
        },
      },
      pieLegendSettings: {
        position: "Bottom",
        padding: 5,
        visible: true,
        toggleVisibility: false,
        textStyle: {
          fontFamily: "Poppins, sans-serif",
          size: "14px",
          alignment: "center",
        },
      },
      pieLegendSettingsAll: {
        position: "Bottom",
        padding: 5,
        visible: true,
        toggleVisibility: false,
        textStyle: {
          fontFamily: "Poppins, sans-serif",
          size: "14px",
        },
      },
      vmsStatusModal: false,
      vmsCurrentStatus: null,
      isFetchingStatus: false,
      timerId: -1,
      startRefresh: false,
      isPdfClick: false,
      dsFetching: false,
    };
  }

  async componentDidMount() {
    // if (window.innerWidth < 1650 && window.innerWidth > 1400) {
    //   await this.setState({ chartWidth: "595" });
    // } else if (window.innerWidth < 1400 && window.innerWidth > 1100) {
    //   await this.setState({ chartWidth: "595" });
    // } else if (window.innerWidth < 1100) {
    //   await this.setState({ chartWidth: "400" });
    // }

    if (this.state.innerWidth !== window.innerWidth) {
      this.setState({ innerWidth: window.innerWidth });
      let width = String(parseInt(window.innerWidth / 2.3));
      this.setState({ chartWidth: width });
      window.dispatchEvent(new Event("resize"));
    }

    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Dashboard",
    });
    this.props.registerListners();
    await this.refreshDash(true, true);
    clearTimeout(this.state.resizeTimer);
    this.fetchAllConfig();
  }

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.processLogut();
      this.props.history.push("/");
    }
  };

  getDashData() {
    this.setState({ modalLoader: true });
    this.props.registerListners(true);
    fetch(Constdata.GET_DASHBOARD_DATA_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (
          responseJson.success === true &&
          responseJson.data !== undefined &&
          responseJson.data !== null
        ) {
          await this.setState({
            providerName: responseJson.orgInfo.providerName,
            loggedInUser: responseJson.orgInfo.loggedInUser,
            vmsCurrentStatus: responseJson.data.currentStatus,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
          });

          let maxVal =
            responseJson.data.costGraph.costGraphList.length > 0
              ? Math.max(
                  responseJson.data.costGraph.costGraphList[0].currentPrice,
                  responseJson.data.costGraph.costGraphList[0].selectedPrice,
                  responseJson.data.costGraph.costGraphList[0].aggressivePrice,
                  responseJson.data.costGraph.costGraphList[0].balancedPrice,
                  responseJson.data.costGraph.costGraphList[0].conservativePrice
                )
              : 0;
          let interval = Math.round(maxVal / 4);
          let maxValPerf =
            responseJson.data.perfGraph.perfDTOList.length > 0
              ? Math.max(
                  responseJson.data.perfGraph.perfDTOList[0].resizedPerf,
                  responseJson.data.perfGraph.perfDTOList[0].currentPerf,
                  responseJson.data.perfGraph.perfDTOList[0].resizedPerf,
                  responseJson.data.perfGraph.perfDTOList[0].aggressivePerf,
                  responseJson.data.perfGraph.perfDTOList[0].balancedPerf,
                  responseJson.data.perfGraph.perfDTOList[0].conservativePerf
                )
              : 0;
          let intervalPerf = Math.round(maxValPerf / 4);
          if (intervalPerf === 0) {
            intervalPerf = 0.25;
          }
          if (maxValPerf === 0) {
            maxValPerf = 1;
          }
          await this.setState({
            costyAxis: {
              minimum: 0,
              maximum: maxVal + interval,
              interval: interval,
            },
            perfyAxis: {
              minimum: 0,
              maximum: maxValPerf + intervalPerf,
              interval: intervalPerf,
            },
          });
          //VM

          let maxValVm =
            responseJson.data.resourceDashboardMap.VM.costGraph.costGraphList
              .length > 0
              ? Math.max(
                  responseJson.data.resourceDashboardMap.VM.costGraph
                    .costGraphList[0].currentPrice,
                  responseJson.data.resourceDashboardMap.VM.costGraph
                    .costGraphList[0].selectedPrice,
                  responseJson.data.resourceDashboardMap.VM.costGraph
                    .costGraphList[0].aggressivePrice,
                  responseJson.data.resourceDashboardMap.VM.costGraph
                    .costGraphList[0].balancedPrice,
                  responseJson.data.resourceDashboardMap.VM.costGraph
                    .costGraphList[0].conservativePrice
                )
              : 0;

          let intervalVm = Math.round(maxValVm / 4);
          let maxValPerfVm =
            responseJson.data.resourceDashboardMap.VM.perfGraph.perfDTOList
              .length > 0
              ? Math.max(
                  responseJson.data.resourceDashboardMap.VM.perfGraph
                    .perfDTOList[0].resizedPerf,
                  responseJson.data.resourceDashboardMap.VM.perfGraph
                    .perfDTOList[0].currentPerf,
                  responseJson.data.resourceDashboardMap.VM.perfGraph
                    .perfDTOList[0].aggressivePerf,
                  responseJson.data.resourceDashboardMap.VM.perfGraph
                    .perfDTOList[0].balancedPerf,
                  responseJson.data.resourceDashboardMap.VM.perfGraph
                    .perfDTOList[0].conservativePerf
                )
              : 0;
          let intervalPerfVm = Math.round(maxValPerfVm / 4);
          if (intervalPerfVm === 0) {
            intervalPerfVm = 0.25;
          }
          if (maxValPerfVm === 0) {
            maxValPerfVm = 1;
          }
          await this.setState({
            costyAxisVm: {
              minimum: 0,
              maximum: maxValVm + intervalVm,
              interval: intervalVm,
            },
            perfyAxisVm: {
              minimum: 0,
              maximum: maxValPerfVm + intervalPerfVm,
              interval: intervalPerfVm,
            },
          });
          //ASP
          let maxValAsp =
            responseJson.data.resourceDashboardMap.ASP.costGraph.costGraphList
              .length > 0
              ? Math.max(
                  responseJson.data.resourceDashboardMap.ASP.costGraph
                    .costGraphList[0].currentPrice,
                  responseJson.data.resourceDashboardMap.ASP.costGraph
                    .costGraphList[0].aggressivePrice,
                  responseJson.data.resourceDashboardMap.ASP.costGraph
                    .costGraphList[0].selectedPrice,
                  responseJson.data.resourceDashboardMap.ASP.costGraph
                    .costGraphList[0].balancedPrice,
                  responseJson.data.resourceDashboardMap.ASP.costGraph
                    .costGraphList[0].conservativePrice
                )
              : 0;
          let intervalAsp = Math.round(maxValAsp / 4);
          let maxValPerfAsp =
            responseJson.data.resourceDashboardMap.ASP.perfGraph.perfDTOList
              .length > 0
              ? Math.max(
                  responseJson.data.resourceDashboardMap.ASP.perfGraph
                    .perfDTOList[0].aggressivePerf,
                  responseJson.data.resourceDashboardMap.ASP.perfGraph
                    .perfDTOList[0].currentPerf,
                  responseJson.data.resourceDashboardMap.ASP.perfGraph
                    .perfDTOList[0].balancedPerf,
                  responseJson.data.resourceDashboardMap.ASP.perfGraph
                    .perfDTOList[0].conservativePerf,
                  responseJson.data.resourceDashboardMap.ASP.perfGraph
                    .perfDTOList[0].resizedPerf
                )
              : 0;
          let intervalPerfAsp = Math.round(maxValPerfAsp / 4);
          if (intervalPerfAsp === 0) {
            intervalPerfAsp = 0.25;
          }
          if (maxValPerfAsp === 0) {
            maxValPerfAsp = 1;
          }
          await this.setState({
            costyAxisAsp: {
              minimum: 0,
              maximum: maxValAsp + intervalAsp,
              interval: intervalAsp,
            },
            perfyAxisAsp: {
              minimum: 0,
              maximum: maxValPerfAsp + intervalPerfAsp,
              interval: intervalPerfAsp,
            },
          });
          //DB
          let maxValDb =
            responseJson.data.resourceDashboardMap.DB.costGraph.costGraphList
              .length > 0
              ? Math.max(
                  responseJson.data.resourceDashboardMap.DB.costGraph
                    .costGraphList[0].currentPrice,
                  responseJson.data.resourceDashboardMap.DB.costGraph
                    .costGraphList[0].selectedPrice,
                  responseJson.data.resourceDashboardMap.DB.costGraph
                    .costGraphList[0].aggressivePrice,
                  responseJson.data.resourceDashboardMap.DB.costGraph
                    .costGraphList[0].balancedPrice,
                  responseJson.data.resourceDashboardMap.DB.costGraph
                    .costGraphList[0].conservativePrice
                )
              : 0;
          let intervalDb = Math.round(maxValDb / 4);
          let maxValPerfDb =
            responseJson.data.resourceDashboardMap.DB.perfGraph.perfDTOList
              .length > 0
              ? Math.max(
                  responseJson.data.resourceDashboardMap.DB.perfGraph
                    .perfDTOList[0].resizedPerf,
                  responseJson.data.resourceDashboardMap.DB.perfGraph
                    .perfDTOList[0].currentPerf,
                  responseJson.data.resourceDashboardMap.DB.perfGraph
                    .perfDTOList[0].aggressivePerf,
                  responseJson.data.resourceDashboardMap.DB.perfGraph
                    .perfDTOList[0].balancedPerf,
                  responseJson.data.resourceDashboardMap.DB.perfGraph
                    .perfDTOList[0].conservativePerf
                )
              : 0;
          let intervalPerfDb = Math.round(maxValPerfDb / 4);
          if (intervalPerfDb === 0) {
            intervalPerfDb = 0.25;
          }
          if (maxValPerfDb === 0) {
            maxValPerfDb = 1;
          }
          await this.setState({
            costyAxisDb: {
              minimum: 0,
              maximum: maxValDb + intervalDb,
              interval: intervalDb,
            },
            perfyAxisDb: {
              minimum: 0,
              maximum: maxValPerfDb + intervalPerfDb,
              interval: intervalPerfDb,
            },
          });
          //Rightsizing
          let maxValRight =
            responseJson.data.functionDashboardMap.RIGHTSIZE.costGraph
              .costGraphList.length > 0
              ? Math.max(
                  responseJson.data.functionDashboardMap.RIGHTSIZE.costGraph
                    .costGraphList[0].currentPrice,
                  responseJson.data.functionDashboardMap.RIGHTSIZE.costGraph
                    .costGraphList[0].selectedPrice,
                  responseJson.data.functionDashboardMap.RIGHTSIZE.costGraph
                    .costGraphList[0].aggressivePrice,
                  responseJson.data.functionDashboardMap.RIGHTSIZE.costGraph
                    .costGraphList[0].balancedPrice,
                  responseJson.data.functionDashboardMap.RIGHTSIZE.costGraph
                    .costGraphList[0].conservativePrice
                )
              : 0;
          let intervalRight = Math.round(maxValRight / 4);
          let maxValPerfRight =
            responseJson.data.functionDashboardMap.RIGHTSIZE.perfGraph
              .perfDTOList.length > 0
              ? Math.max(
                  responseJson.data.functionDashboardMap.RIGHTSIZE.perfGraph
                    .perfDTOList[0].resizedPerf,
                  responseJson.data.functionDashboardMap.RIGHTSIZE.perfGraph
                    .perfDTOList[0].currentPerf,
                  responseJson.data.functionDashboardMap.RIGHTSIZE.perfGraph
                    .perfDTOList[0].aggressivePerf,
                  responseJson.data.functionDashboardMap.RIGHTSIZE.perfGraph
                    .perfDTOList[0].balancedPerf,
                  responseJson.data.functionDashboardMap.RIGHTSIZE.perfGraph
                    .perfDTOList[0].conservativePerf
                )
              : 0;
          let intervalPerfRight = Math.round(maxValPerfRight / 4);
          if (intervalPerfRight === 0) {
            intervalPerfRight = 0.25;
          }
          if (maxValPerfRight === 0) {
            maxValPerfRight = 1;
          }
          await this.setState({
            costyAxisRight: {
              minimum: 0,
              maximum: maxValRight + intervalRight,
              interval: intervalRight,
            },
            perfyAxisRight: {
              minimum: 0,
              maximum: maxValPerfRight + intervalPerfRight,
              interval: intervalPerfRight,
            },
          });
          //Parking
          let maxValParking =
            responseJson.data.functionDashboardMap.VM_PARKING.costGraph
              .costGraphList.length > 0
              ? Math.max(
                  responseJson.data.functionDashboardMap.VM_PARKING.costGraph
                    .costGraphList[0].currentPrice,
                  responseJson.data.functionDashboardMap.VM_PARKING.costGraph
                    .costGraphList[0].aggressivePrice
                )
              : 0;
          let intervalParking = Math.round(maxValParking / 4);
          await this.setState({
            costyAxisParking: {
              minimum: 0,
              maximum: maxValParking + intervalParking,
              interval: intervalParking,
            },
          });
          //Cleanup
          let maxValCleanup =
            responseJson.data.functionDashboardMap.CLEANUP.costGraph
              .costGraphList.length > 0
              ? Math.max(
                  responseJson.data.functionDashboardMap.CLEANUP.costGraph
                    .costGraphList[0].currentPrice,
                  responseJson.data.functionDashboardMap.CLEANUP.costGraph
                    .costGraphList[0].aggressivePrice
                )
              : 0;
          let intervalCleanup = Math.round(maxValCleanup / 4);
          await this.setState({
            costyAxisCleanup: {
              minimum: 0,
              maximum: maxValCleanup + intervalCleanup,
              interval: intervalCleanup,
            },
          });
          let maxValTopPieChart = Math.max.apply(
            Math,
            responseJson.data.topPieChartList.map(function (o) {
              return o.y;
            })
          );
          let isRenderAnyResource = maxValTopPieChart === 0 ? false : true;
          // console.log("Top : " + maxValTopPieChart + " " + isRenderAnyResource);
          let maxValVmPieChart = Math.max.apply(
            Math,
            responseJson.data.resourceDashboardMap.VM.pieChartList.map(
              function (o) {
                return o.y;
              }
            )
          );
          let isRenderVmResource = maxValVmPieChart === 0 ? false : true;
          // console.log("VM : " + maxValVmPieChart + " " + isRenderVmResource);
          let maxValAspPieChart = Math.max.apply(
            Math,
            responseJson.data.resourceDashboardMap.ASP.pieChartList.map(
              function (o) {
                return o.y;
              }
            )
          );
          let isRenderAspResource = maxValAspPieChart === 0 ? false : true;
          // console.log("ASP : " + maxValAspPieChart + " " + isRenderAspResource);
          let maxValDbPieChart = Math.max.apply(
            Math,
            responseJson.data.resourceDashboardMap.DB.pieChartList.map(
              function (o) {
                return o.y;
              }
            )
          );
          let isRenderDbResource = maxValDbPieChart === 0 ? false : true;
          // console.log("DB : " + maxValDbPieChart + " " + isRenderDbResource);
          let maxValCleanupPieChart = Math.max.apply(
            Math,
            responseJson.data.functionDashboardMap.CLEANUP.pieChartList.map(
              function (o) {
                return o.y;
              }
            )
          );
          let isRenderCleanupResource =
            maxValCleanupPieChart === 0 ? false : true;
          // console.log(
          //   "Cleanup : " + maxValCleanupPieChart + " " + isRenderCleanupResource
          // );
          await this.setState({
            isRenderAnyResource: isRenderAnyResource,
            isRenderVMs: isRenderVmResource,
            isRenderASPs: isRenderAspResource,
            isRenderDBs: isRenderDbResource,
            isRenderCleanup: isRenderCleanupResource,
          });

          await this.setState({ value: responseJson.data });
          var timerId = setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 1);
          await this.setState({ resizeTimer: timerId });
          await this.setState({ modalLoader: false });
        } else if (responseJson.data === undefined) {
          // console.log(responseJson);
          this.setState({ modalLoader: false });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          //this.setState({ modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false });
          await clearInterval(this.state.refreshTimer);
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
          await clearInterval(this.state.refreshTimer);
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  }

  isEmptyChart = (obj) => {
    if (obj !== undefined && obj.length !== 0) {
      const data = obj[0];
      for (let key in data) {
        if (key !== "type" && parseFloat(data[key]) !== 0) {
          return true;
        }
      }
      return false;
    }
  };

  getPdfOld = async () => {
    this.setState({ modalLoader: true, isPdfClick: true });
    var timer = setInterval(() => {
      document.getElementById("costArrowIconStr").click();
      document.getElementById("costChartStr").click();
      document.getElementById("perfArrowIconStr").click();
      document.getElementById("overAllProgressStr").click();
      document.getElementById("perfChartStr").click();
      if (this.state.isRenderAnyResource) {
        if (this.state.isRenderVMs) {
          document.getElementById("vmCharts").click();
        }
        if (this.state.isRenderASPs) {
          document.getElementById("aspCharts").click();
        }
        if (this.state.isRenderDBs) {
          document.getElementById("dbCharts").click();
        }
        if (this.state.isRenderAnyResource) {
          document.getElementById("rightsizingCharts").click();
        }
        if (this.state.isRenderAnyResource) {
          document.getElementById("parkingCharts").click();
        }
        if (this.state.isRenderCleanup) {
          document.getElementById("cleanupCharts").click();
        }
      }
    }, 5000);
    let filename = "";
    const timezone = jstz.determine();
    const timezoneid = timezone.name();
    var costArrowIconStr = "";
    await domtoimage
      .toPng(document.getElementById("costArrowIconStr"), { quality: 1 })
      .then(function (dataUrl) {
        costArrowIconStr = dataUrl;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
    var perfArrowIconStr = "";
    await domtoimage
      .toPng(document.getElementById("perfArrowIconStr"), { quality: 1 })
      .then(function (dataUrl) {
        perfArrowIconStr = dataUrl;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
    var overAllProgressStr = "";
    await domtoimage
      .toPng(document.getElementById("overAllProgressStr"), { quality: 1 })
      .then(function (dataUrl) {
        overAllProgressStr = dataUrl;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });

    var costChartStr = "";
    if (this.isEmptyChart(this.state.value.costGraph.costGraphList)) {
      await domtoimage
        .toPng(document.getElementById("costChartStr"))
        .then(function (dataUrl) {
          costChartStr = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }

    var perfChartStr = "";
    if (this.isEmptyChart(this.state.value.perfGraph.perfDTOList)) {
      await domtoimage
        .toPng(document.getElementById("perfChartStr"))
        .then(function (dataUrl) {
          perfChartStr = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }

    var styleChange = false;
    var vmCharts = "";
    var tab = "";
    if (
      this.state.isRenderVMs &&
      (this.isEmptyChart(
        this.state.value.resourceDashboardMap.VM.costGraph.costGraphList
      ) ||
        this.isEmptyChart(
          this.state.value.resourceDashboardMap.VM.perfGraph.perfDTOList
        ) ||
        this.isEmptyChart(
          this.state.value.resourceDashboardMap.VM.pieChartList
        ))
    ) {
      tab = document.getElementById("vmChartsTab");
      if (tab.className !== "tab-pane active") {
        tab.className = "tab-pane active";
        styleChange = true;
      }
      await domtoimage
        .toPng(document.getElementById("vmCharts"))
        .then(function (dataUrl) {
          vmCharts = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      if (styleChange) {
        tab.className = "tab-pane";
        styleChange = false;
      }
    }

    var aspCharts = "";
    if (
      this.state.isRenderASPs &&
      (this.isEmptyChart(
        this.state.value.resourceDashboardMap.ASP.costGraph.costGraphList
      ) ||
        this.isEmptyChart(
          this.state.value.resourceDashboardMap.ASP.perfGraph.perfDTOList
        ) ||
        this.isEmptyChart(
          this.state.value.resourceDashboardMap.ASP.pieChartList
        ))
    ) {
      tab = document.getElementById("aspChartsTab");
      if (tab.className !== "tab-pane active") {
        tab.className = "tab-pane active";
        styleChange = true;
      }
      await domtoimage
        .toPng(document.getElementById("aspCharts"))
        .then(function (dataUrl) {
          aspCharts = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      if (styleChange) {
        tab.className = "tab-pane";
        styleChange = false;
      }
    }

    var dbCharts = "";
    if (
      this.state.isRenderDBs &&
      (this.isEmptyChart(
        this.state.value.resourceDashboardMap.DB.costGraph.costGraphList
      ) ||
        this.isEmptyChart(
          this.state.value.resourceDashboardMap.DB.perfGraph.perfDTOList
        ) ||
        this.isEmptyChart(
          this.state.value.resourceDashboardMap.DB.pieChartList
        ))
    ) {
      tab = document.getElementById("dbChartsTab");
      if (tab.className !== "tab-pane active") {
        tab.className = "tab-pane active";
        styleChange = true;
      }
      await domtoimage
        .toPng(document.getElementById("dbCharts"))
        .then(function (dataUrl) {
          dbCharts = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      if (styleChange) {
        tab.className = "tab-pane";
        styleChange = false;
      }
    }
    var rightsizingCharts = "";
    if (
      this.state.isRenderAnyResource &&
      (this.isEmptyChart(
        this.state.value.functionDashboardMap.RIGHTSIZE.costGraph.costGraphList
      ) ||
        this.isEmptyChart(
          this.state.value.functionDashboardMap.RIGHTSIZE.perfGraph.perfDTOList
        ) ||
        this.isEmptyChart(
          this.state.value.functionDashboardMap.RIGHTSIZE.pieChartList
        ))
    ) {
      tab = document.getElementById("rightsizingChartsTab");
      if (tab.className !== "tab-pane active") {
        tab.className = "tab-pane active";
        styleChange = true;
      }
      await domtoimage
        .toPng(document.getElementById("rightsizingCharts"))
        .then(function (dataUrl) {
          rightsizingCharts = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      if (styleChange) {
        tab.className = "tab-pane";
        styleChange = false;
      }
    }
    var parkingCharts = "";
    if (
      this.state.isRenderAnyResource &&
      (this.isEmptyChart(
        this.state.value.functionDashboardMap.VM_PARKING.costGraph.costGraphList
      ) ||
        this.isEmptyChart(
          this.state.value.functionDashboardMap.VM_PARKING.pieChartList
        ))
    ) {
      tab = document.getElementById("parkingChartsTab");
      if (tab.className !== "tab-pane active") {
        tab.className = "tab-pane active";
        styleChange = true;
      }
      await domtoimage
        .toPng(document.getElementById("parkingCharts"))
        .then(function (dataUrl) {
          parkingCharts = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      if (styleChange) {
        tab.className = "tab-pane";
        styleChange = false;
      }
    }

    var cleanupCharts = "";
    if (
      this.state.isRenderCleanup &&
      (this.isEmptyChart(
        this.state.value.functionDashboardMap.CLEANUP.costGraph.costGraphList
      ) ||
        this.isEmptyChart(
          this.state.value.functionDashboardMap.CLEANUP.pieChartList
        ))
    ) {
      tab = document.getElementById("cleanupChartsTab");
      if (tab.className !== "tab-pane active") {
        tab.className = "tab-pane active";
        styleChange = true;
      }
      await domtoimage
        .toPng(document.getElementById("cleanupCharts"))
        .then(function (dataUrl) {
          cleanupCharts = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      if (styleChange) {
        tab.className = "tab-pane";
        styleChange = false;
      }
    }

    const payload = {
      userGmtId: timezoneid,
      costArrowIconStr,
      costChartStr,
      perfArrowIconStr,
      overAllProgressStr,
      perfChartStr,
      resourceGraphs: {
        vmCharts: vmCharts,
        aspCharts: aspCharts,
        dbCharts: dbCharts,
      },
      functionGraphs: {
        rightsizingCharts: rightsizingCharts,
        parkingCharts: parkingCharts,
        cleanupCharts: cleanupCharts,
      },
    };

    const blobData = new Blob([JSON.stringify(payload)], {
      type: "application/json",
    });
    const formData = new FormData();
    formData.append("file", blobData, "DashboardPayload.json");

    let response = await fetch(Constdata.GETDASHBOARDPDF_URL, {
      method: "POST",
      credentials: "include",
      body: formData,
    }).catch((error) => {
      console.log("Dashboard pdf Error:--", error);
      clearInterval(timer);
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorTextPdf,
        isErrorHeader: false,
        isRedirect: false,
        isPdfClick: false,
      });
    });

    console.log("Pdf response  :- ", response);

    if (response && response.status !== 500) {
      if (response.headers.get("Content-Disposition") === null) {
        clearInterval(timer);
        let js = await response.json();

        console.log("Pdf js :- ", js);
        if (js.success === false) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: js.message,
            isErrorHeader: false,
            isRedirect: false,
            isPdfClick: false,
          });
        }
      } else {
        filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        let blob = await response.blob();
        if (blob !== undefined && blob !== null) {
          let url = window.URL.createObjectURL(blob);
          let a = document.getElementById("pdfid");
          a.href = url;
          a.download = filename;
          a.click();
        }
        clearInterval(timer);
        this.setState({ modalLoader: false, isPdfClick: false });
      }
    } else {
      clearInterval(timer);
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorTextPdf,
        isErrorHeader: false,
        isRedirect: false,
        isPdfClick: false,
      });
    }
  };

  getPdf = async () => {
    try {
      this.setState({ modalLoader: true, isPdfClick: true });

      // Click logic
      const clickLogic = async () => {
        document.getElementById("costArrowIconStr").click();
        document.getElementById("costChartStr").click();
        document.getElementById("perfArrowIconStr").click();
        document.getElementById("overAllProgressStr").click();
        document.getElementById("perfChartStr").click();

        if (this.state.isRenderAnyResource) {
          if (this.state.isRenderVMs) {
            document.getElementById("vmCharts").click();
          }
          if (this.state.isRenderASPs) {
            document.getElementById("aspCharts").click();
          }
          if (this.state.isRenderDBs) {
            document.getElementById("dbCharts").click();
          }
          if (this.state.isRenderAnyResource) {
            document.getElementById("rightsizingCharts").click();
          }
          if (this.state.isRenderAnyResource) {
            document.getElementById("parkingCharts").click();
          }
          if (this.state.isRenderCleanup) {
            document.getElementById("cleanupCharts").click();
          }
        }
      };

      const timer = setInterval(clickLogic, 5000);

      // Promise domtoimage
      const toPng = async (elementId) => {
        return await new Promise(async (resolve, reject) => {
          domtoimage
            .toPng(await document.getElementById(elementId), { quality: 1 })
            .then(resolve)
            .catch((error) => {
              console.log(`Error For ${elementId} Image ======>`, error);
              reject(error);
            });
        });
      };

      // Get images
      const costArrowIconStr = await toPng("costArrowIconStr");
      const perfArrowIconStr = await toPng("perfArrowIconStr");
      const overAllProgressStr = await toPng("overAllProgressStr");
      const costChartStr = this.isEmptyChart(
        this.state.value.costGraph.costGraphList
      )
        ? await toPng("costChartStr")
        : "";
      const perfChartStr = this.isEmptyChart(
        this.state.value.perfGraph.perfDTOList
      )
        ? await toPng("perfChartStr")
        : "";

      var styleChange = false;
      var tab = "";
      var vmCharts = "";
      if (
        this.state.isRenderVMs &&
        (this.isEmptyChart(
          this.state.value.resourceDashboardMap.VM.costGraph.costGraphList
        ) ||
          this.isEmptyChart(
            this.state.value.resourceDashboardMap.VM.perfGraph.perfDTOList
          ) ||
          this.isEmptyChart(
            this.state.value.resourceDashboardMap.VM.pieChartList
          ))
      ) {
        tab = document.getElementById("vmChartsTab");
        if (tab.className !== "tab-pane active") {
          tab.className = "tab-pane active";
          styleChange = true;
        }
        vmCharts = await toPng("vmCharts");
        if (styleChange) {
          tab.className = "tab-pane";
          styleChange = false;
        }
      }

      var aspCharts = "";
      if (
        this.state.isRenderASPs &&
        (this.isEmptyChart(
          this.state.value.resourceDashboardMap.ASP.costGraph.costGraphList
        ) ||
          this.isEmptyChart(
            this.state.value.resourceDashboardMap.ASP.perfGraph.perfDTOList
          ) ||
          this.isEmptyChart(
            this.state.value.resourceDashboardMap.ASP.pieChartList
          ))
      ) {
        tab = document.getElementById("aspChartsTab");
        if (tab.className !== "tab-pane active") {
          tab.className = "tab-pane active";
          styleChange = true;
        }
        aspCharts = await toPng("aspCharts");
        if (styleChange) {
          tab.className = "tab-pane";
          styleChange = false;
        }
      }

      var dbCharts = "";
      if (
        this.state.isRenderDBs &&
        (this.isEmptyChart(
          this.state.value.resourceDashboardMap.DB.costGraph.costGraphList
        ) ||
          this.isEmptyChart(
            this.state.value.resourceDashboardMap.DB.perfGraph.perfDTOList
          ) ||
          this.isEmptyChart(
            this.state.value.resourceDashboardMap.DB.pieChartList
          ))
      ) {
        tab = document.getElementById("dbChartsTab");
        if (tab.className !== "tab-pane active") {
          tab.className = "tab-pane active";
          styleChange = true;
        }
        dbCharts = await toPng("dbCharts");
        if (styleChange) {
          tab.className = "tab-pane";
          styleChange = false;
        }
      }
      var rightsizingCharts = "";
      if (
        this.state.isRenderAnyResource &&
        (this.isEmptyChart(
          this.state.value.functionDashboardMap.RIGHTSIZE.costGraph
            .costGraphList
        ) ||
          this.isEmptyChart(
            this.state.value.functionDashboardMap.RIGHTSIZE.perfGraph
              .perfDTOList
          ) ||
          this.isEmptyChart(
            this.state.value.functionDashboardMap.RIGHTSIZE.pieChartList
          ))
      ) {
        tab = document.getElementById("rightsizingChartsTab");
        if (tab.className !== "tab-pane active") {
          tab.className = "tab-pane active";
          styleChange = true;
        }
        rightsizingCharts = await toPng("rightsizingCharts");
        if (styleChange) {
          tab.className = "tab-pane";
          styleChange = false;
        }
      }
      var parkingCharts = "";
      if (
        this.state.isRenderAnyResource &&
        (this.isEmptyChart(
          this.state.value.functionDashboardMap.VM_PARKING.costGraph
            .costGraphList
        ) ||
          this.isEmptyChart(
            this.state.value.functionDashboardMap.VM_PARKING.pieChartList
          ))
      ) {
        tab = document.getElementById("parkingChartsTab");
        if (tab.className !== "tab-pane active") {
          tab.className = "tab-pane active";
          styleChange = true;
        }
        parkingCharts = await toPng("parkingCharts");
        if (styleChange) {
          tab.className = "tab-pane";
          styleChange = false;
        }
      }

      var cleanupCharts = "";
      if (
        this.state.isRenderCleanup &&
        (this.isEmptyChart(
          this.state.value.functionDashboardMap.CLEANUP.costGraph.costGraphList
        ) ||
          this.isEmptyChart(
            this.state.value.functionDashboardMap.CLEANUP.pieChartList
          ))
      ) {
        tab = document.getElementById("cleanupChartsTab");
        if (tab.className !== "tab-pane active") {
          tab.className = "tab-pane active";
          styleChange = true;
        }
        cleanupCharts = await toPng("cleanupCharts");
        if (styleChange) {
          tab.className = "tab-pane";
          styleChange = false;
        }
      }

      clearInterval(timer);

      // Construct payload
      const payload = {
        userGmtId: jstz.determine().name(),
        costArrowIconStr,
        costChartStr,
        perfArrowIconStr,
        overAllProgressStr,
        perfChartStr,
        resourceGraphs: {
          vmCharts: vmCharts,
          aspCharts: aspCharts,
          dbCharts: dbCharts,
        },
        functionGraphs: {
          rightsizingCharts: rightsizingCharts,
          parkingCharts: parkingCharts,
          cleanupCharts: cleanupCharts,
        },
      };

      console.log("JSON Payload ============>", payload);

      // Post payload
      const formData = new FormData();

      await formData.append(
        "file",
        await new Blob([JSON.stringify(payload)], { type: "application/json" }),
        "DashboardPayload.json"
      );

      for (let [key, value] of formData.entries()) {
        if (value instanceof Blob) {
          console.log(
            "FormData Payload ============>",
            `${key} : ${value.size} bytes`
          );
        } else {
          console.log("FormData Payload ============>", `${key} : ${value}`);
        }
      }

      const response = await fetch(Constdata.GETDASHBOARDPDF_URL, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      console.log("Response Payload ============>", response);

      if (response.ok) {
        const disposition = response.headers.get("Content-Disposition");

        console.log("Disposition ============> ", disposition);

        if (disposition) {
          const filename = disposition.split("filename=")[1];

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          console.log("Blob ============>", blob);
          console.log("Blob URL ============>", url);

          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        } else {
          throw new Error("PDF response is missing Content-Disposition header");
        }
      } else {
        throw new Error("PDF request failed with status: " + response.status);
      }

      this.setState({ modalLoader: false, isPdfClick: false });
    } catch (error) {
      console.error("Error fetching PDF:", error);
      // clearInterval(timer);
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorTextPdf,
        isErrorHeader: false,
        isRedirect: false,
        isPdfClick: false,
      });
    }
  };

  refreshDash = async (forceRefresh, fromDidMount) => {
    this.setState({ timerId: -1 });
    if (forceRefresh) {
      await this.getDashData();
      // if (fromDidMount) {
      //   const timer = setInterval(async () => {
      //     await this.getDashData();
      //   }, 300000);
      //   await this.setState({ refreshTimer: timer });
      // }
    }
  };

  async componentWillUnmount() {
    // console.log(this.state.refreshTimer);
    await clearInterval(this.state.refreshTimer);
  }

  axisLabelRender = (args) => {
    //console.log(args);
    if (!isNaN(args.value) && !isNaN(args.axis.endLabel)) {
      args.text = "";
    }
  };

  chartTemplate(args) {
    //        if (args.point.y == 0) {
    //            args.cancel = true;
    //        } else {
    var text = "";
    var seriesText = "";
    var ret = "";
    if (args.point.x === "COST") {
      var prop = args.series.properties.yName + "Percent";
      var series = args.series.properties.dataSource[0][prop];
      args.point.y = parseFloat(args.point.y).toFixed(2);
      if (args.series.properties.yName === "currentPrice") {
        text = priceDisplay(args.point.y);
      } else {
        text = priceDisplay(args.point.y);
        seriesText = "(" + series + "%)";
      }
      ret = (
        <div style={{ color: "#393939", textAlign: "center" }}>
          <div>{text}</div>
          <div>{seriesText}</div>
        </div>
      );
    } else if (args.point.x === "PERFORMANCE") {
      var prop = args.series.properties.yName + "Percent";
      var series = args.series.properties.dataSource[0][prop];
      if (args.series.properties.yName === "currentPerf") {
        text = parseFloat(args.point.y).toFixed(2);
      } else {
        text = parseFloat(args.point.y).toFixed(2);
        seriesText = "(" + series + "%)";
      }
      ret = (
        <div style={{ color: "#393939", textAlign: "center" }}>
          <div>{text}</div>
          <div>{seriesText}</div>
        </div>
      );
    }
    return ret;
    //}
  }

  axisPointRender = (args) => {
    //        if (args.point.y == 0) {
    //            args.cancel = true;
    //        } else {
    // console.log(args);
    if (args.point.x === "COST") {
      var prop = args.series.properties.yName + "Percent";
      var series = args.series.properties.dataSource[0][prop];
      args.point.y = parseFloat(args.point.y).toFixed(2);
      if (
        args.series.properties.yName === "currentPrice" ||
        args.series.properties.yName === "aggressivePrice" ||
        args.series.properties.yName === "balancedPrice" ||
        args.series.properties.yName === "conservativePrice"
      ) {
        args.point.text = priceDisplay(args.point.y);
      } else {
        args.point.text = priceDisplay(args.point.y) + " (" + series + "%)";
      }
    } else if (args.point.x === "PERFORMANCE") {
      var prop = args.series.properties.yName + "Percent";
      var series = args.series.properties.dataSource[0][prop];
      if (
        args.series.properties.yName === "currentPerf" ||
        args.series.properties.yName === "aggressivePerf" ||
        args.series.properties.yName === "balancedPerf" ||
        args.series.properties.yName === "conservativePerf"
      ) {
        args.point.text = parseFloat(args.point.y).toFixed(2);
      } else {
        args.point.text =
          parseFloat(args.point.y).toFixed(2) + " (" + series + "%)";
      }
    }
    //}
  };

  textRender = (args) => {
    if (args.point.y === "0.0") {
      args.cancel = true;
    } else {
      var prop = args.point.x;
      var result = args.series.resultData.filter((obj) => {
        return obj.x === prop;
      });
      args.text = result[0].actual_y + "%";
    }
  };
  legendRenderTop = (args) => {
    if (this.state.value !== undefined) {
      var list = this.state.value.topPieChartList;
      var result = list.filter((obj) => {
        return obj.x === args.text;
      });
      if (result !== undefined && result.length > 0) {
        args.text = result[0].text;
      }
    }
  };
  legendRenderVm = (args) => {
    if (this.state.value !== undefined) {
      var list = this.state.value.resourceDashboardMap.VM.pieChartList;
      var result = list.filter((obj) => {
        return obj.x === args.text;
      });
      if (result !== undefined && result.length > 0) {
        args.text = result[0].text;
      }
    }
  };
  legendRenderAsp = (args) => {
    if (this.state.value !== undefined) {
      var list = this.state.value.resourceDashboardMap.ASP.pieChartList;
      var result = list.filter((obj) => {
        return obj.x === args.text;
      });
      if (result !== undefined && result.length > 0) {
        args.text = result[0].text;
      }
    }
  };
  legendRenderDb = (args) => {
    if (this.state.value !== undefined) {
      var list = this.state.value.resourceDashboardMap.DB.pieChartList;
      var result = list.filter((obj) => {
        return obj.x === args.text;
      });
      if (result !== undefined && result.length > 0) {
        args.text = result[0].text;
      }
    }
  };
  legendRenderRight = (args) => {
    if (this.state.value !== undefined) {
      var list = this.state.value.functionDashboardMap.RIGHTSIZE.pieChartList;
      var result = list.filter((obj) => {
        return obj.x === args.text;
      });
      if (result !== undefined && result.length > 0) {
        args.text = result[0].text;
      }
    }
  };
  legendRenderParking = (args) => {
    if (this.state.value !== undefined) {
      var list = this.state.value.functionDashboardMap.VM_PARKING.pieChartList;
      var result = list.filter((obj) => {
        return obj.x === args.text;
      });
      if (result !== undefined && result.length > 0) {
        args.text = result[0].text;
      }
    }
  };
  legendRenderCleanup = (args) => {
    if (this.state.value !== undefined) {
      var list = this.state.value.functionDashboardMap.CLEANUP.pieChartList;
      var result = list.filter((obj) => {
        return obj.x === args.text;
      });
      if (result !== undefined && result.length > 0) {
        args.text = result[0].text;
      }
    }
  };

  async toggle(tab) {
    if (this.state.activeTab !== tab) {
      await this.setState({
        activeTab: tab,
      });
      window.dispatchEvent(new Event("resize"));
      if (tab == 1) {
        if (document.getElementById("vmcost_Secondary_Element")) {
          document.getElementById("vmcost_Secondary_Element").style.display =
            "none";
        }
        if (document.getElementById("vmperf_Secondary_Element")) {
          document.getElementById("vmperf_Secondary_Element").style.display =
            "none";
        }
      } else if (tab == 2) {
        if (document.getElementById("aspcost_Secondary_Element")) {
          document.getElementById("aspcost_Secondary_Element").style.display =
            "none";
        }
        if (document.getElementById("aspperf_Secondary_Element")) {
          document.getElementById("aspperf_Secondary_Element").style.display =
            "none";
        }
      } else if (tab == 3) {
        if (document.getElementById("dbcost_Secondary_Element")) {
          document.getElementById("dbcost_Secondary_Element").style.display =
            "none";
        }
        if (document.getElementById("dbperf_Secondary_Element")) {
          document.getElementById("dbperf_Secondary_Element").style.display =
            "none";
        }
      }
    }
  }

  async toggleFunc(tab) {
    if (this.state.activeFuncTab !== tab) {
      await this.setState({
        activeFuncTab: tab,
      });

      window.dispatchEvent(new Event("resize"));
      if (tab == 1) {
        document.getElementById("rightcost_Secondary_Element").style.display =
          "none";
        document.getElementById("rightperf_Secondary_Element").style.display =
          "none";
      } else if (tab == 2) {
        document.getElementById("parkcost_Secondary_Element").style.display =
          "none";
      } else {
        document.getElementById("cleanupcost_Secondary_Element").style.display =
          "none";
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.innerWidth !== window.innerWidth) {
      this.setState({ innerWidth: window.innerWidth });
      let width = String(parseInt(window.innerWidth / 2.3));
      this.setState({ chartWidth: width });
      window.dispatchEvent(new Event("resize"));
    }

    if (prevState.timerId !== this.state.timerId) {
      if (this.state.timerId === -1) {
        this.setState({ startRefresh: false });
        await this.refreshDash(true, true);
      }
    }

    if (this.props.dataSourceResponse !== prevProps.dataSourceResponse) {
      var response = prevProps.dataSourceResponse;
      if (
        response &&
        response.success === true &&
        response.testConfig !== true &&
        this.state.isFetchingStatus !== true
      ) {
        await this.refreshDash(true, true);
      }
    }

    if (this.props.dataSourcePayload !== prevProps.dataSourcePayload) {
      this.setState({
        dsFetching: this.props.dataSourcePayload.length > 0 ? true : false,
      });
    }
  }

  toggleVmsStatusModal = () => {
    this.setState((prevState) => ({
      vmsStatusModal: !prevState.vmsStatusModal,
    }));
  };

  fetchAllConfig = async () => {
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          let value = [];
          if (responseJson.orgInfo.providerName === "AWS") {
            value = responseJson.data.awsConfigs;
          } else {
            value = responseJson.data.configs;
          }

          let isFetching = false;

          value.map(async (key) => {
            let isAnyPricFetching = false;
            if (responseJson.orgInfo.providerName === "AWS") {
              isAnyPricFetching = false;
            } else {
              if (key.azureSubscriptions.length > 0) {
                key.azureSubscriptions.map((Sb, i) => {
                  if (
                    Sb.priceThreadStatus !== null &&
                    Sb.priceThreadStatus.toLowerCase().includes("fetching")
                  ) {
                    isAnyPricFetching = true;
                  }
                });
              }
            }
            if (
              key.status.toLowerCase().includes("fetching") ||
              isAnyPricFetching
            ) {
              isFetching = true;
              this.setState({ isFetchingStatus: true });
            }
          });
          if (!isFetching) {
            if (this.state.timerId !== -1) {
              clearInterval(this.state.timerId);
              this.setState({ timerId: -1, isFetchingStatus: false });
            }
          } else {
            if (this.state.timerId === -1) {
              const timer = setTimeout(() => {
                this.fetchAllConfig();
              }, 5000);
              this.setState({
                timerId: timer,
              });
            }
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            isRedirect: false,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        if (this.state.timerId !== -1) {
          clearInterval(this.state.timerId);
          this.setState({ timerId: -1 });
        }
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          isRedirect: false,
          isErrorHeader: false,
        });
      });
  };

  checkRefresh = async (type) => {
    this.setState({ startRefresh: true });

    this.fetchAllConfig();

    if (type === "DisableDS" || type === "EnableDS" || type === "DeleteDS") {
      await this.refreshDash(true, true);
    }
  };

  isDataSourceExists = () => {
    if (this.state.vmsCurrentStatus !== null) {
      if (
        this.state.vmsCurrentStatus[0] &&
        this.state.vmsCurrentStatus[0].includes("None")
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  render() {
    const costIcon =
      this.state.value !== undefined &&
      this.state.value.totalAnalysisCost < 0 ? (
        <img
          className="arrowImg"
          style={{ height: "136px", width: "136px" }}
          src={require("../../images/HighCost_02.svg")}
          alt="High Cost"
        />
      ) : this.state.value !== undefined &&
        this.state.value.totalAnalysisCost > 0 ? (
        <img
          className="arrowImg"
          style={{ height: "136px", width: "136px" }}
          src={require("../../images/LowCost_02.svg")}
          alt="Low Cost"
        />
      ) : (
        <img
          className="arrowImg"
          style={{ height: "136px", width: "136px" }}
          src={require("../../images/EqualCost_02.svg")}
          alt="No Change in Cost"
        />
      );
    const perfIcon =
      this.state.value !== undefined &&
      this.state.value.totalAnalysisPerfPercent < 0 ? (
        <img
          className="arrowImg"
          style={{ height: "136px", width: "136px" }}
          src={require("../../images/LowPerformance_02.svg")}
          alt="Low Performance"
        />
      ) : this.state.value !== undefined &&
        this.state.value.totalAnalysisPerfPercent > 0 ? (
        <img
          className="arrowImg"
          style={{ height: "136px", width: "136px" }}
          src={require("../../images/HighPerformance_02.svg")}
          alt="High Performance"
        />
      ) : (
        <img
          className="arrowImg"
          style={{ height: "136px", width: "136px" }}
          src={require("../../images/EqualPerformance_02.svg")}
          alt="No Change in Performance"
        />
      );

    return (
      <div style={{ overflow: "hidden" }}>
        <Header
          history={this.props.history}
          processLogout={this.props.processLogut}
          registerListners={this.props.registerListners}
          providerName={this.state.providerName}
          loggedInUser={this.state.loggedInUser}
          checkRefresh={this.checkRefresh}
        ></Header>

        <div
          className="container-verf container-verf-padding"
          role="main"
          style={{ marginTop: "50px" }}
        >
          {this.state.isFreeTrial ? (
            <Button
              className="btnBg"
              onClick={this.toggleVmsStatusModal}
              style={{
                float: "right",
                marginTop: this.state.isFreeTrial
                  ? this.state.innerWidth < "1025"
                    ? "-50px"
                    : "-65px"
                  : "-10px",
                marginBottom: "20px",
                position: "absolute",
                right: "60px",
              }}
            >
              Current Status
            </Button>
          ) : (
            <div style={{ textAlign: "right" }}>
              <Button
                className="btnBg"
                onClick={this.toggleVmsStatusModal}
                style={{
                  marginTop: this.state.isFreeTrial
                    ? this.state.innerWidth < "1025"
                      ? "-50px"
                      : "-65px"
                    : "-10px",
                  marginBottom: "20px",
                  marginRight: "-1px",
                }}
              >
                Current Status
              </Button>
            </div>
          )}

          <div className="pageBgWhite" style={{ borderRadius: "8px" }}>
            <Row className="pageBgHeader">
              <Col sm="12" style={{ display: "flex", alignItems: "center" }}>
                <div id="spaceFiller" style={{ width: "10%" }}></div>
                <div
                  style={{
                    width: "80%",
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  As of{" "}
                  {this.state.value !== undefined &&
                  this.state.value.currentDateTime !== undefined
                    ? getStringDate(this.state.value.currentDateTime)
                    : ""}
                </div>
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    outline="true"
                    onClick={() => {
                      this.refreshDash(true, false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      id="RefreshIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 27.975 26"
                    >
                      <g
                        id="Group_1680"
                        data-name="Group 1680"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Path_1655"
                          data-name="Path 1655"
                          d="M25.14,14.717a.764.764,0,0,0-.9.6A11.471,11.471,0,1,1,23.594,8.6L18.075,6.137a.856.856,0,0,0-1.131.434.674.674,0,0,0,.341.889L24.1,10.5a.974.974,0,0,0,1.312-.559l2.517-6.968a.73.73,0,0,0-.437-.934.8.8,0,0,0-1.026.481L24.716,7.372a12.994,12.994,0,1,0,1.019,8.247.764.764,0,0,0-.6-.9"
                          transform="translate(0 0)"
                          fill="#ffffff"
                        />
                      </g>
                    </svg>
                  </div>
                  {this.state.isRenderAnyResource ? (
                    <div
                      outline="true"
                      onClick={this.getPdf}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    >
                      <svg
                        id="ExportPDF"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.538"
                        height="24"
                        viewBox="0 0 22.538 24"
                      >
                        <path
                          id="Path_1540"
                          data-name="Path 1540"
                          d="M486.276,502.261a1.238,1.238,0,0,1,.346.049.661.661,0,0,1,.247.148.63.63,0,0,1,.148.226.924.924,0,0,1,.042.29.677.677,0,0,1-.784.756h-.565v-1.469Z"
                          transform="translate(-481.386 -489.062)"
                          fill="#fff"
                        />
                        <path
                          id="Path_1541"
                          data-name="Path 1541"
                          d="M495.127,503.169a2.151,2.151,0,0,1,0,1.243,1.327,1.327,0,0,1-.254.473,1.058,1.058,0,0,1-.41.29,1.369,1.369,0,0,1-.551.106H493.2V502.3h.713a1.368,1.368,0,0,1,.551.106,1.155,1.155,0,0,1,.41.3A1.239,1.239,0,0,1,495.127,503.169Z"
                          transform="translate(-484.989 -489.121)"
                          fill="#fff"
                        />
                        <path
                          id="Path_1542"
                          data-name="Path 1542"
                          d="M494.413,476.9H481.657a2.071,2.071,0,0,0-2.066,2.066l0,9.5h-1.584a.606.606,0,0,0-.61.6v5.087a.608.608,0,0,0,.61.61H479.6l0,4.068a2.071,2.071,0,0,0,2.066,2.066h16.216a2.071,2.071,0,0,0,2.066-2.066V482.422Zm.308,1.779,3.438,3.438h-2.416a1.024,1.024,0,0,1-1.023-1.023Zm4.174,20.155a1.024,1.024,0,0,1-1.023,1.023H481.657a1.024,1.024,0,0,1-1.023-1.023l-.014-4.068h15.346a.608.608,0,0,0,.61-.61v-5.087a.607.607,0,0,0-.61-.6H480.528l.106-9.5a1.024,1.024,0,0,1,1.023-1.023h12.021v3.151a2.071,2.071,0,0,0,2.066,2.066H498.9Zm-18.189-5.024v-4.449h1.574a2.572,2.572,0,0,1,.807.115,1.622,1.622,0,0,1,.556.305,1.2,1.2,0,0,1,.319.461,1.584,1.584,0,0,1,.1.577,1.735,1.735,0,0,1-.1.6,1.254,1.254,0,0,1-.332.482,1.5,1.5,0,0,1-.556.305,2.511,2.511,0,0,1-.794.115h-.543v1.485Zm3.9,0v-4.449h1.723a2.657,2.657,0,0,1,.95.17,2.287,2.287,0,0,1,.726.461,2.07,2.07,0,0,1,.468.705,2.506,2.506,0,0,1,0,1.777,2.007,2.007,0,0,1-.468.705,2.174,2.174,0,0,1-.726.461,2.518,2.518,0,0,1-.95.17Zm7.081-2.53v.8h-1.519v1.73H489.13v-4.449h2.862v.794h-1.825v1.126Z"
                          transform="translate(-477.401 -476.897)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row
              className="boxShadow"
              // id="costArrowIconStr"
              style={{ alignItems: "center", paddingBottom: "30px" }}
            >
              <Col
                id="costArrowIconStr"
                sm={window.innerWidth < 1650 ? "3" : "4"}
              >
                <Row style={{ textAlign: "center", display: "block" }}>
                  <Col>{costIcon}</Col>
                  {this.state.value !== undefined &&
                  this.state.value.totalAnalysisCost < 0 ? (
                    <Col className="dashText">
                      Up to{" "}
                      {priceDisplay(
                        Math.abs(this.state.value.totalAnalysisCost)
                      )}{" "}
                      (
                      {parseFloat(
                        Math.abs(this.state.value.totalAnalysisCostPercent)
                      ).toFixed(2)}
                      %) Aggregate Monthly Cost Spend
                    </Col>
                  ) : this.state.value !== undefined &&
                    this.state.value.totalAnalysisCost > 0 ? (
                    <Col className="dashText">
                      Up to {priceDisplay(this.state.value.totalAnalysisCost)} (
                      {parseFloat(
                        this.state.value.totalAnalysisCostPercent
                      ).toFixed(2)}
                      %) Aggregate Monthly Cost Saving
                    </Col>
                  ) : (
                    <Col className="dashText">No Change in Cost</Col>
                  )}
                </Row>
              </Col>
              <Col
                id="overAllProgressStr"
                sm={window.innerWidth < 1650 ? "6" : "4"}
              >
                {this.state.value !== undefined &&
                this.isEmptyChart(this.state.value.topPieChartList) ? (
                  this.state.isRenderAnyResource ? (
                    <>
                      <AccumulationChartComponent
                        title="Overall Progress"
                        id="progressPie"
                        height="350"
                        titleStyle={{
                          ...this.state.chartTitleStyle,
                          color: "white",
                        }}
                        legendSettings={this.state.pieLegendSettings}
                        enableSmartLabels="true"
                        textRender={this.textRender}
                        legendRender={this.legendRenderTop}
                      >
                        <Inject
                          services={[AccumulationDataLabel, AccumulationLegend]}
                        />
                        <AccumulationSeriesCollectionDirective>
                          <AccumulationSeriesDirective
                            dataSource={
                              this.state.value !== undefined
                                ? this.state.value.topPieChartList
                                : []
                            }
                            xName="x"
                            yName="y"
                            pointColorMapping="fill"
                            dataLabel={this.state.datalabel}
                            legendShape="Rectangle"
                          ></AccumulationSeriesDirective>
                        </AccumulationSeriesCollectionDirective>
                      </AccumulationChartComponent>
                      <div className="dashText">Overall Progress</div>
                    </>
                  ) : null
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "rgb(33, 37, 41)",
                    }}
                  >
                    {this.state.dsFetching
                      ? "Configuration Is In Progress"
                      : "No Data Source Successfully Configured"}
                  </div>
                )}
              </Col>
              <Col
                id="perfArrowIconStr"
                sm={window.innerWidth < 1650 ? "3" : "4"}
              >
                <Row style={{ textAlign: "center", display: "block" }}>
                  <Col>{perfIcon}</Col>
                  {this.state.value !== undefined &&
                  this.state.value.totalAnalysisPerfPercent < 0 ? (
                    <Col className="dashText">
                      Up to{" "}
                      {parseFloat(
                        Math.abs(this.state.value.totalAnalysisPerfPercent)
                      ).toFixed(2)}
                      % Average Performance degradation
                    </Col>
                  ) : this.state.value !== undefined &&
                    this.state.value.totalAnalysisPerfPercent > 0 ? (
                    <Col className="dashText">
                      Up to{" "}
                      {parseFloat(
                        this.state.value.totalAnalysisPerfPercent
                      ).toFixed(2)}
                      % Average Performance Improvement
                    </Col>
                  ) : (
                    <Col className="dashText">No Change in Performance</Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="boxShadow" style={{ paddingTop: "20px" }}>
              <Col sm="6" id="costChartStr">
                {this.state.value !== undefined &&
                (this.isEmptyChart(this.state.value.costGraph.costGraphList) ||
                  !this.state.isPdfClick) ? (
                  <ChartComponent
                    id="cost"
                    primaryXAxis={this.state.costxAxis}
                    pointRender={this.axisPointRender}
                    axisLabelRender={this.axisLabelRender}
                    primaryYAxis={this.state.costyAxis}
                    title="Aggregate Monthly Cost Saving by Rightsizing, Parking & Cleanup"
                    titleStyle={this.state.chartTitleStyle}
                    palettes={this.state.costPerfPalette}
                    legendSettings={this.state.legendSettings}
                  >
                    <Inject
                      services={[ColumnSeries, Legend, DataLabel, Category]}
                    />
                    <SeriesCollectionDirective>
                      <SeriesDirective
                        dataSource={
                          this.state.value !== undefined &&
                          this.state.value.costGraph !== undefined &&
                          this.state.value.costGraph.costGraphList.length > 0
                            ? this.state.value.costGraph.costGraphList
                            : []
                        }
                        xName="type"
                        yName="currentPrice"
                        name="Current"
                        type="Column"
                        legendShape="Rectangle"
                        marker={this.state.costPerfMarker}
                        columnSpacing="0.1"
                        columnWidth="1"
                      ></SeriesDirective>
                      {this.state.value !== undefined &&
                      this.state.value.costGraph !== undefined &&
                      this.state.value.costGraph.costGraphList.length > 0 &&
                      parseFloat(
                        this.state.value.costGraph.costGraphList[0]
                          .selectedPrice
                      ) > 0 ? (
                        <SeriesDirective
                          dataSource={this.state.value.costGraph.costGraphList}
                          xName="type"
                          yName="selectedPrice"
                          name="Lowest"
                          type="Column"
                          legendShape="Rectangle"
                          marker={this.state.costPerfMarker}
                          columnSpacing="0.1"
                          columnWidth="1"
                        ></SeriesDirective>
                      ) : null}
                      <SeriesDirective
                        dataSource={
                          this.state.value !== undefined &&
                          this.state.value.costGraph !== undefined
                            ? this.state.value.costGraph.costGraphList
                            : []
                        }
                        xName="type"
                        yName="aggressivePrice"
                        name="Economical"
                        type="Column"
                        legendShape="Rectangle"
                        marker={this.state.costPerfMarker}
                        columnSpacing="0.1"
                        columnWidth="1"
                      ></SeriesDirective>
                      <SeriesDirective
                        dataSource={
                          this.state.value !== undefined &&
                          this.state.value.costGraph !== undefined
                            ? this.state.value.costGraph.costGraphList
                            : []
                        }
                        xName="type"
                        yName="balancedPrice"
                        name="Balanced"
                        type="Column"
                        legendShape="Rectangle"
                        marker={this.state.costPerfMarker}
                        columnSpacing="0.1"
                        columnWidth="1"
                      ></SeriesDirective>
                      <SeriesDirective
                        dataSource={
                          this.state.value !== undefined &&
                          this.state.value.costGraph !== undefined
                            ? this.state.value.costGraph.costGraphList
                            : []
                        }
                        xName="type"
                        yName="conservativePrice"
                        name="Enhanced"
                        type="Column"
                        legendShape="Rectangle"
                        marker={this.state.costPerfMarker}
                        columnSpacing="0.1"
                        columnWidth="1"
                      ></SeriesDirective>
                    </SeriesCollectionDirective>
                  </ChartComponent>
                ) : null}
              </Col>
              <Col sm="6" id="perfChartStr">
                {this.state.value !== undefined &&
                (this.isEmptyChart(this.state.value.perfGraph.perfDTOList) ||
                  !this.state.isPdfClick) ? (
                  <ChartComponent
                    id="perf"
                    primaryXAxis={this.state.perfxAxis}
                    pointRender={this.axisPointRender}
                    axisLabelRender={this.axisLabelRender}
                    primaryYAxis={this.state.perfyAxis}
                    title="Average Relative Performance Improvement by Rightsizing"
                    titleStyle={this.state.chartTitleStyle}
                    palettes={this.state.costPerfPalette}
                    legendSettings={this.state.legendSettings}
                  >
                    <Inject
                      services={[ColumnSeries, Legend, DataLabel, Category]}
                    />
                    <SeriesCollectionDirective>
                      <SeriesDirective
                        dataSource={
                          this.state.value !== undefined &&
                          this.state.value.perfGraph !== undefined &&
                          this.state.value.perfGraph.perfDTOList.length > 0
                            ? this.state.value.perfGraph.perfDTOList
                            : []
                        }
                        xName="type"
                        yName="currentPerf"
                        name="Current"
                        type="Column"
                        legendShape="Rectangle"
                        marker={this.state.costPerfMarker}
                        columnSpacing="0.1"
                        columnWidth="1"
                      ></SeriesDirective>
                      {this.state.value !== undefined &&
                      this.state.value.perfGraph !== undefined &&
                      this.state.value.perfGraph.perfDTOList.length > 0 &&
                      parseFloat(
                        this.state.value.perfGraph.perfDTOList[0].resizedPerf
                      ) > 0 ? (
                        <SeriesDirective
                          dataSource={
                            this.state.value !== undefined &&
                            this.state.value.perfGraph !== undefined
                              ? this.state.value.perfGraph.perfDTOList
                              : []
                          }
                          xName="type"
                          yName="resizedPerf"
                          name="Best"
                          type="Column"
                          legendShape="Rectangle"
                          marker={this.state.costPerfMarker}
                          columnSpacing="0.1"
                          columnWidth="1"
                        ></SeriesDirective>
                      ) : null}
                      <SeriesDirective
                        dataSource={
                          this.state.value !== undefined &&
                          this.state.value.perfGraph !== undefined
                            ? this.state.value.perfGraph.perfDTOList
                            : []
                        }
                        xName="type"
                        yName="aggressivePerf"
                        name="Economical"
                        type="Column"
                        legendShape="Rectangle"
                        marker={this.state.costPerfMarker}
                        columnSpacing="0.1"
                        columnWidth="1"
                      ></SeriesDirective>
                      <SeriesDirective
                        dataSource={
                          this.state.value !== undefined &&
                          this.state.value.perfGraph !== undefined
                            ? this.state.value.perfGraph.perfDTOList
                            : []
                        }
                        xName="type"
                        yName="balancedPerf"
                        name="Balanced"
                        type="Column"
                        legendShape="Rectangle"
                        marker={this.state.costPerfMarker}
                        columnSpacing="0.1"
                        columnWidth="1"
                      ></SeriesDirective>
                      <SeriesDirective
                        dataSource={
                          this.state.value !== undefined &&
                          this.state.value.perfGraph !== undefined
                            ? this.state.value.perfGraph.perfDTOList
                            : []
                        }
                        xName="type"
                        yName="conservativePerf"
                        name="Enhanced"
                        type="Column"
                        legendShape="Rectangle"
                        marker={this.state.costPerfMarker}
                        columnSpacing="0.1"
                        columnWidth="1"
                      ></SeriesDirective>
                    </SeriesCollectionDirective>
                  </ChartComponent>
                ) : null}
              </Col>
            </Row>
            <Row
              className="boxShadow"
              id="tabRow"
              style={{
                paddingTop: "20px",
                display: this.state.isRenderAnyResource ? "flex" : "none",
              }}
            >
              <Col sm="6">
                <Nav tabs className="navClass" id="dashtabs">
                  {this.state.isRenderVMs ? (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        VMs
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {this.state.isRenderASPs &&
                  this.state.providerName === "AZURE" ? (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        ASPs
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                  {this.state.isRenderDBs &&
                  this.state.providerName === "AZURE" ? (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        DBaaS
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                </Nav>
                <TabContent
                  id="dashtabcontent"
                  activeTab={this.state.activeTab}
                >
                  {this.state.isRenderVMs ? (
                    <TabPane tabId="1" id="vmChartsTab">
                      <Row
                        className="rmpaddingChart"
                        style={{ textAlign: "center" }}
                        id="vmCharts"
                      >
                        <Col sm="12" className="rmpaddingChart">
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value.resourceDashboardMap.VM.costGraph
                              .costGraphList
                          ) ||
                            !this.state.isPdfClick) ? (
                            <ChartComponent
                              id="vmcost"
                              primaryXAxis={this.state.costxAxis}
                              pointRender={this.axisPointRender}
                              axisLabelRender={this.axisLabelRender}
                              primaryYAxis={this.state.costyAxisVm}
                              title="Aggregate VM Monthly Cost Saving By Rightsizing and Parking"
                              titleStyle={this.state.chartTitleStyle}
                              palettes={this.state.costPerfPalette}
                              legendSettings={this.state.legendSettings}
                              width={this.state.chartWidth}
                            >
                              <Inject
                                services={[
                                  ColumnSeries,
                                  Legend,
                                  DataLabel,
                                  Category,
                                ]}
                              />
                              <SeriesCollectionDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.VM
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="currentPrice"
                                  name="Current"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                  animation={{
                                    enable: true,
                                    duration: 1200,
                                    delay: 100,
                                  }}
                                ></SeriesDirective>
                                {this.state.value.resourceDashboardMap.VM
                                  .costGraph.costGraphList.length > 0 &&
                                parseFloat(
                                  this.state.value.resourceDashboardMap.VM
                                    .costGraph.costGraphList[0].selectedPrice
                                ) > 0 ? (
                                  <SeriesDirective
                                    dataSource={
                                      this.state.value.resourceDashboardMap.VM
                                        .costGraph.costGraphList
                                    }
                                    xName="type"
                                    yName="selectedPrice"
                                    name="Optimally Sized/Parked"
                                    type="Column"
                                    legendShape="Rectangle"
                                    marker={this.state.costPerfMarker}
                                    columnSpacing="0.1"
                                    columnWidth="1"
                                  ></SeriesDirective>
                                ) : null}
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.VM
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="aggressivePrice"
                                  name="Economical"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.VM
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="balancedPrice"
                                  name="Balanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.VM
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="conservativePrice"
                                  name="Enhanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                              </SeriesCollectionDirective>
                            </ChartComponent>
                          ) : null}
                        </Col>
                        <Col
                          sm="12"
                          className="rmpaddingChart"
                          style={{ marginTop: "20px" }}
                        >
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value.resourceDashboardMap.VM.perfGraph
                              .perfDTOList
                          ) ||
                            !this.state.isPdfClick) ? (
                            <ChartComponent
                              id="vmperf"
                              primaryXAxis={this.state.perfxAxis}
                              pointRender={this.axisPointRender}
                              axisLabelRender={this.axisLabelRender}
                              primaryYAxis={this.state.perfyAxisVm}
                              title="Average VM Relative Performance By Rightsizing"
                              titleStyle={this.state.chartTitleStyle}
                              palettes={this.state.costPerfPalette}
                              legendSettings={this.state.legendSettings}
                              // height="500"
                              width={this.state.chartWidth}
                            >
                              <Inject
                                services={[
                                  ColumnSeries,
                                  Legend,
                                  DataLabel,
                                  Category,
                                ]}
                              />
                              <SeriesCollectionDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.VM
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="currentPerf"
                                  name="Current"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                {this.state.value.resourceDashboardMap.VM
                                  .perfGraph.perfDTOList.length > 0 &&
                                parseFloat(
                                  this.state.value.resourceDashboardMap.VM
                                    .perfGraph.perfDTOList[0].resizedPerf
                                ) > 0 ? (
                                  <SeriesDirective
                                    dataSource={
                                      this.state.value.resourceDashboardMap.VM
                                        .perfGraph.perfDTOList
                                    }
                                    xName="type"
                                    yName="resizedPerf"
                                    name="Optimally Sized"
                                    type="Column"
                                    legendShape="Rectangle"
                                    marker={this.state.costPerfMarker}
                                    columnSpacing="0.1"
                                    columnWidth="1"
                                  ></SeriesDirective>
                                ) : null}
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.VM
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="aggressivePerf"
                                  name="Economical"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.VM
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="balancedPerf"
                                  name="Balanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.VM
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="conservativePerf"
                                  name="Enhanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                              </SeriesCollectionDirective>
                            </ChartComponent>
                          ) : null}
                        </Col>
                        <Col
                          sm="12"
                          className="rmpaddingChart"
                          style={{ textAlign: "center", marginTop: "20px" }}
                        >
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value.resourceDashboardMap.VM
                              .pieChartList
                          ) ||
                            !this.state.isPdfClick) ? (
                            this.state.value.resourceDashboardMap.VM
                              .pieChartList &&
                            this.state.value.resourceDashboardMap.VM
                              .pieChartList.length > 0 ? (
                              <AccumulationChartComponent
                                id="vmProgressPie"
                                title="Progress"
                                height="350"
                                width="565"
                                titleStyle={this.state.chartTitleStyle}
                                textRender={this.textRender}
                                legendSettings={this.state.pieLegendSettingsAll}
                                legendRender={this.legendRenderVm}
                              >
                                <Inject
                                  services={[
                                    AccumulationDataLabel,
                                    AccumulationLegend,
                                  ]}
                                />
                                <AccumulationSeriesCollectionDirective>
                                  <AccumulationSeriesDirective
                                    dataSource={
                                      this.state.value !== undefined
                                        ? this.state.value.resourceDashboardMap
                                            .VM.pieChartList
                                        : []
                                    }
                                    xName="x"
                                    yName="y"
                                    pointColorMapping="fill"
                                    dataLabel={this.state.datalabel}
                                    legendShape="Rectangle"
                                  ></AccumulationSeriesDirective>
                                </AccumulationSeriesCollectionDirective>
                              </AccumulationChartComponent>
                            ) : (
                              <div className="dashboardPieChartEmpty">
                                <div className="emptyDivChartTitle">
                                  Progress
                                </div>
                                <h6>No VMs Qualify For Rightsizing Analysis</h6>
                              </div>
                            )
                          ) : null}
                        </Col>
                      </Row>
                    </TabPane>
                  ) : null}
                  {this.state.isRenderASPs ? (
                    <TabPane tabId="2" id="aspChartsTab">
                      <Row
                        className="rmpaddingChart"
                        style={{ textAlign: "center" }}
                        id="aspCharts"
                      >
                        <Col sm="12" className="rmpaddingChart">
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value.resourceDashboardMap.ASP.costGraph
                              .costGraphList
                          ) ||
                            !this.state.isPdfClick) ? (
                            <ChartComponent
                              id="aspcost"
                              primaryXAxis={this.state.costxAxis}
                              pointRender={this.axisPointRender}
                              axisLabelRender={this.axisLabelRender}
                              primaryYAxis={this.state.costyAxisAsp}
                              title="Aggregate ASP Monthly Cost Saving By Rightsizing"
                              titleStyle={this.state.chartTitleStyle}
                              palettes={this.state.costPerfPalette}
                              legendSettings={this.state.legendSettings}
                              // height="500"
                              width={this.state.chartWidth}
                            >
                              <Inject
                                services={[
                                  ColumnSeries,
                                  Legend,
                                  DataLabel,
                                  Category,
                                ]}
                              />
                              <SeriesCollectionDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.ASP
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="currentPrice"
                                  name="Current"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                {this.state.value.resourceDashboardMap.ASP
                                  .costGraph.costGraphList.length > 0 &&
                                parseFloat(
                                  this.state.value.resourceDashboardMap.ASP
                                    .costGraph.costGraphList[0].selectedPrice
                                ) > 0 ? (
                                  <SeriesDirective
                                    dataSource={
                                      this.state.value.resourceDashboardMap.ASP
                                        .costGraph.costGraphList
                                    }
                                    xName="type"
                                    yName="selectedPrice"
                                    name="Lowest"
                                    type="Column"
                                    legendShape="Rectangle"
                                    marker={this.state.costPerfMarker}
                                    columnSpacing="0.1"
                                    columnWidth="1"
                                  ></SeriesDirective>
                                ) : null}
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.ASP
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="aggressivePrice"
                                  name="Economical"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.ASP
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="balancedPrice"
                                  name="Balanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.ASP
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="conservativePrice"
                                  name="Enhanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                              </SeriesCollectionDirective>
                            </ChartComponent>
                          ) : null}
                        </Col>
                        <Col
                          sm="12"
                          className="rmpaddingChart"
                          style={{ marginTop: "20px" }}
                        >
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value.resourceDashboardMap.ASP.perfGraph
                              .perfDTOList
                          ) ||
                            !this.state.isPdfClick) ? (
                            <ChartComponent
                              id="aspperf"
                              primaryXAxis={this.state.perfxAxis}
                              pointRender={this.axisPointRender}
                              axisLabelRender={this.axisLabelRender}
                              primaryYAxis={this.state.perfyAxisAsp}
                              title="Average ASP Relative Performance By Rightsizing"
                              titleStyle={this.state.chartTitleStyle}
                              palettes={this.state.costPerfPalette}
                              legendSettings={this.state.legendSettings}
                              // height="500"
                              width={this.state.chartWidth}
                            >
                              <Inject
                                services={[
                                  ColumnSeries,
                                  Legend,
                                  DataLabel,
                                  Category,
                                ]}
                              />
                              <SeriesCollectionDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.ASP
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="currentPerf"
                                  name="Current"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                {this.state.value.resourceDashboardMap.ASP
                                  .perfGraph.perfDTOList.length > 0 &&
                                parseFloat(
                                  this.state.value.resourceDashboardMap.ASP
                                    .perfGraph.perfDTOList[0].resizedPerf
                                ) > 0 ? (
                                  <SeriesDirective
                                    dataSource={
                                      this.state.value.resourceDashboardMap.ASP
                                        .perfGraph.perfDTOList
                                    }
                                    xName="type"
                                    yName="resizedPerf"
                                    name="Optimally Sized"
                                    type="Column"
                                    legendShape="Rectangle"
                                    marker={this.state.costPerfMarker}
                                    columnSpacing="0.1"
                                    columnWidth="1"
                                  ></SeriesDirective>
                                ) : null}
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.ASP
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="aggressivePerf"
                                  name="Economical"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.ASP
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="balancedPerf"
                                  name="Balanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.ASP
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="conservativePerf"
                                  name="Enhanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                              </SeriesCollectionDirective>
                            </ChartComponent>
                          ) : null}
                        </Col>
                        <Col
                          sm="12"
                          className="rmpaddingChart"
                          style={{ textAlign: "center", marginTop: "20px" }}
                        >
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value.resourceDashboardMap.ASP
                              .pieChartList
                          ) ||
                            !this.state.isPdfClick) ? (
                            this.state.value.resourceDashboardMap.ASP
                              .pieChartList &&
                            this.state.value.resourceDashboardMap.ASP
                              .pieChartList.length > 0 ? (
                              <AccumulationChartComponent
                                id="aspprogressPie"
                                title="Progress"
                                height="350"
                                width="565"
                                titleStyle={this.state.chartTitleStyle}
                                enableSmartLabels="true"
                                textRender={this.textRender}
                                legendSettings={this.state.pieLegendSettingsAll}
                                legendRender={this.legendRenderAsp}
                              >
                                <Inject
                                  services={[
                                    AccumulationDataLabel,
                                    AccumulationLegend,
                                  ]}
                                />
                                <AccumulationSeriesCollectionDirective>
                                  <AccumulationSeriesDirective
                                    dataSource={
                                      this.state.value !== undefined
                                        ? this.state.value.resourceDashboardMap
                                            .ASP.pieChartList
                                        : []
                                    }
                                    xName="x"
                                    yName="y"
                                    pointColorMapping="fill"
                                    dataLabel={this.state.datalabel}
                                    legendShape="Rectangle"
                                  ></AccumulationSeriesDirective>
                                </AccumulationSeriesCollectionDirective>
                              </AccumulationChartComponent>
                            ) : (
                              <div className="dashboardPieChartEmpty">
                                <div className="emptyDivChartTitle">
                                  Progress
                                </div>
                                <h6>
                                  No ASPs Qualify For Rightsizing Analysis
                                </h6>
                              </div>
                            )
                          ) : null}
                        </Col>
                      </Row>
                    </TabPane>
                  ) : null}
                  {this.state.isRenderDBs ? (
                    <TabPane tabId="3" id="dbChartsTab">
                      <Row
                        className="rmpaddingChart"
                        style={{ textAlign: "center" }}
                        id="dbCharts"
                      >
                        <Col sm="12" className="rmpaddingChart">
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value?.resourceDashboardMap?.DB
                              ?.costGraph?.costGraphList
                          ) ||
                            !this.state.isPdfClick) ? (
                            <ChartComponent
                              id="dbcost"
                              primaryXAxis={this.state.costxAxis}
                              pointRender={this.axisPointRender}
                              axisLabelRender={this.axisLabelRender}
                              primaryYAxis={this.state.costyAxisDb}
                              title="Aggregate DBaaS Monthly Cost Saving By Rightsizing"
                              titleStyle={this.state.chartTitleStyle}
                              palettes={this.state.costPerfPalette}
                              legendSettings={this.state.legendSettings}
                              // height="500"
                              width={this.state.chartWidth}
                            >
                              <Inject
                                services={[
                                  ColumnSeries,
                                  Legend,
                                  DataLabel,
                                  Category,
                                ]}
                              />
                              <SeriesCollectionDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.DB
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="currentPrice"
                                  name="Current"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                {this.state.value.resourceDashboardMap.DB
                                  .costGraph.costGraphList.length > 0 &&
                                parseFloat(
                                  this.state.value.resourceDashboardMap.DB
                                    .costGraph.costGraphList[0].selectedPrice
                                ) > 0 ? (
                                  <SeriesDirective
                                    dataSource={
                                      this.state.value.resourceDashboardMap.DB
                                        .costGraph.costGraphList
                                    }
                                    xName="type"
                                    yName="selectedPrice"
                                    name="Optimally Sized"
                                    type="Column"
                                    legendShape="Rectangle"
                                    marker={this.state.costPerfMarker}
                                    columnSpacing="0.1"
                                    columnWidth="1"
                                  ></SeriesDirective>
                                ) : null}
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.DB
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="aggressivePrice"
                                  name="Economical"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.DB
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="balancedPrice"
                                  name="Balanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.DB
                                      .costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="conservativePrice"
                                  name="Enhanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                              </SeriesCollectionDirective>
                            </ChartComponent>
                          ) : null}
                        </Col>
                        <Col
                          sm="12"
                          className="rmpaddingChart"
                          style={{ marginTop: "20px" }}
                        >
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value?.resourceDashboardMap?.DB
                              ?.perfGraph?.perfDTOList
                          ) ||
                            !this.state.isPdfClick) ? (
                            <ChartComponent
                              id="dbperf"
                              primaryXAxis={this.state.perfxAxis}
                              pointRender={this.axisPointRender}
                              axisLabelRender={this.axisLabelRender}
                              primaryYAxis={this.state.perfyAxisDb}
                              title="Average DBaaS Relative Performance By Rightsizing"
                              titleStyle={this.state.chartTitleStyle}
                              palettes={this.state.costPerfPalette}
                              legendSettings={this.state.legendSettings}
                              // height="500"
                              width={this.state.chartWidth}
                            >
                              <Inject
                                services={[
                                  ColumnSeries,
                                  Legend,
                                  DataLabel,
                                  Category,
                                ]}
                              />
                              <SeriesCollectionDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.DB
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="currentPerf"
                                  name="Current"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                {this.state.value.resourceDashboardMap.DB
                                  .perfGraph.perfDTOList.length > 0 &&
                                parseFloat(
                                  this.state.value.resourceDashboardMap.DB
                                    .perfGraph.perfDTOList[0].resizedPerf
                                ) > 0 ? (
                                  <SeriesDirective
                                    dataSource={
                                      this.state.value.resourceDashboardMap.DB
                                        .perfGraph.perfDTOList
                                    }
                                    xName="type"
                                    yName="resizedPerf"
                                    name="Optimally Sized"
                                    type="Column"
                                    legendShape="Rectangle"
                                    marker={this.state.costPerfMarker}
                                    columnSpacing="0.1"
                                    columnWidth="1"
                                  ></SeriesDirective>
                                ) : null}
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.DB
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="aggressivePerf"
                                  name="Economical"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.DB
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="balancedPerf"
                                  name="Balanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.resourceDashboardMap.DB
                                      .perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="conservativePerf"
                                  name="Enhanced"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                              </SeriesCollectionDirective>
                            </ChartComponent>
                          ) : null}
                        </Col>
                        <Col
                          sm="12"
                          className="rmpaddingChart"
                          style={{ textAlign: "center", marginTop: "20px" }}
                        >
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value?.resourceDashboardMap?.DB
                              ?.pieChartList
                          ) ||
                            !this.state.isPdfClick) ? (
                            this.state.value.resourceDashboardMap.DB
                              .pieChartList &&
                            this.state.value.resourceDashboardMap.DB
                              .pieChartList.length > 0 ? (
                              <AccumulationChartComponent
                                id="dbprogressPie"
                                title="Progress"
                                height="350"
                                width="565"
                                titleStyle={this.state.chartTitleStyle}
                                enableSmartLabels="true"
                                textRender={this.textRender}
                                legendSettings={this.state.pieLegendSettingsAll}
                                legendRender={this.legendRenderDb}
                              >
                                <Inject
                                  services={[
                                    AccumulationDataLabel,
                                    AccumulationLegend,
                                  ]}
                                />

                                <AccumulationSeriesCollectionDirective>
                                  <AccumulationSeriesDirective
                                    dataSource={
                                      this.state.value !== undefined
                                        ? this.state.value.resourceDashboardMap
                                            .DB.pieChartList
                                        : []
                                    }
                                    xName="x"
                                    yName="y"
                                    pointColorMapping="fill"
                                    dataLabel={this.state.datalabel}
                                    legendShape="Rectangle"
                                  ></AccumulationSeriesDirective>
                                </AccumulationSeriesCollectionDirective>
                              </AccumulationChartComponent>
                            ) : (
                              <div className="dashboardPieChartEmpty">
                                <div className="emptyDivChartTitle">
                                  Progress
                                </div>
                                <h6>
                                  No DBaaS Qualify For Rightsizing Analysis
                                </h6>
                              </div>
                            )
                          ) : null}
                        </Col>
                      </Row>
                    </TabPane>
                  ) : null}
                </TabContent>
              </Col>
              <Col sm="6">
                <Nav tabs className="navClass" id="dashtabs">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeFuncTab === "1",
                      })}
                      onClick={() => {
                        this.toggleFunc("1");
                      }}
                    >
                      Rightsizing
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeFuncTab === "2",
                      })}
                      onClick={() => {
                        this.toggleFunc("2");
                      }}
                    >
                      Parking
                    </NavLink>
                  </NavItem>
                  {this.state.isRenderCleanup ? (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeFuncTab === "3",
                        })}
                        onClick={() => {
                          this.toggleFunc("3");
                        }}
                      >
                        Cleanup
                      </NavLink>
                    </NavItem>
                  ) : null}
                </Nav>
                <TabContent
                  id="dashtabcontent"
                  activeTab={this.state.activeFuncTab}
                >
                  <TabPane tabId="1" id="rightsizingChartsTab">
                    <Row
                      className="rmpaddingChart"
                      style={{ textAlign: "center" }}
                      id="rightsizingCharts"
                    >
                      <Col sm="12" className="rmpaddingChart">
                        {this.state.value !== undefined &&
                        (this.isEmptyChart(
                          this.state.value.functionDashboardMap.RIGHTSIZE
                            .costGraph.costGraphList
                        ) ||
                          !this.state.isPdfClick) ? (
                          <ChartComponent
                            id="rightcost"
                            primaryXAxis={this.state.costxAxis}
                            axisLabelRender={this.axisLabelRender}
                            primaryYAxis={this.state.costyAxisRight}
                            title="Aggregate Cost Saving"
                            titleStyle={this.state.chartTitleStyle}
                            palettes={this.state.costPerfPalette}
                            legendSettings={this.state.legendSettings}
                            // height="500"
                            width={this.state.chartWidth}
                          >
                            <Inject
                              services={[
                                ColumnSeries,
                                Legend,
                                DataLabel,
                                Category,
                              ]}
                            />
                            <SeriesCollectionDirective>
                              <SeriesDirective
                                dataSource={
                                  this.state.value.functionDashboardMap
                                    .RIGHTSIZE.costGraph.costGraphList
                                }
                                xName="type"
                                yName="currentPrice"
                                name="Current"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                              {this.state.value.functionDashboardMap.RIGHTSIZE
                                .costGraph.costGraphList.length > 0 &&
                              parseFloat(
                                this.state.value.functionDashboardMap.RIGHTSIZE
                                  .costGraph.costGraphList[0].selectedPrice
                              ) > 0 ? (
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.functionDashboardMap
                                      .RIGHTSIZE.costGraph.costGraphList
                                  }
                                  xName="type"
                                  yName="selectedPrice"
                                  name="Optimally Sized"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                              ) : null}
                              <SeriesDirective
                                dataSource={
                                  this.state.value.functionDashboardMap
                                    .RIGHTSIZE.costGraph.costGraphList
                                }
                                xName="type"
                                yName="aggressivePrice"
                                name="Economical"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                              <SeriesDirective
                                dataSource={
                                  this.state.value.functionDashboardMap
                                    .RIGHTSIZE.costGraph.costGraphList
                                }
                                xName="type"
                                yName="balancedPrice"
                                name="Balanced"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                              <SeriesDirective
                                dataSource={
                                  this.state.value.functionDashboardMap
                                    .RIGHTSIZE.costGraph.costGraphList
                                }
                                xName="type"
                                yName="conservativePrice"
                                name="Enhanced"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                            </SeriesCollectionDirective>
                          </ChartComponent>
                        ) : null}
                      </Col>
                      <Col
                        sm="12"
                        className="rmpaddingChart"
                        style={{ marginTop: "20px" }}
                      >
                        {this.state.value !== undefined &&
                        (this.isEmptyChart(
                          this.state.value.functionDashboardMap.RIGHTSIZE
                            .perfGraph.perfDTOList
                        ) ||
                          !this.state.isPdfClick) ? (
                          <ChartComponent
                            id="rightperf"
                            primaryXAxis={this.state.perfxAxis}
                            pointRender={this.axisPointRender}
                            axisLabelRender={this.axisLabelRender}
                            primaryYAxis={this.state.perfyAxisRight}
                            title="Average Relative Performance"
                            titleStyle={this.state.chartTitleStyle}
                            palettes={this.state.costPerfPalette}
                            legendSettings={this.state.legendSettings}
                            // height="500"
                            width={this.state.chartWidth}
                          >
                            <Inject
                              services={[
                                ColumnSeries,
                                Legend,
                                DataLabel,
                                Category,
                              ]}
                            />
                            <SeriesCollectionDirective>
                              <SeriesDirective
                                dataSource={
                                  this.state.value.functionDashboardMap
                                    .RIGHTSIZE.perfGraph.perfDTOList
                                }
                                xName="type"
                                yName="currentPerf"
                                name="Current"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                              {this.state.value.functionDashboardMap.RIGHTSIZE
                                .perfGraph.perfDTOList.length > 0 &&
                              parseFloat(
                                this.state.value.functionDashboardMap.RIGHTSIZE
                                  .perfGraph.perfDTOList[0].resizedPerf
                              ) > 0 ? (
                                <SeriesDirective
                                  dataSource={
                                    this.state.value.functionDashboardMap
                                      .RIGHTSIZE.perfGraph.perfDTOList
                                  }
                                  xName="type"
                                  yName="resizedPerf"
                                  name="Optimally Sized"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                              ) : null}
                              <SeriesDirective
                                dataSource={
                                  this.state.value.functionDashboardMap
                                    .RIGHTSIZE.perfGraph.perfDTOList
                                }
                                xName="type"
                                yName="aggressivePerf"
                                name="Economical"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                              <SeriesDirective
                                dataSource={
                                  this.state.value.functionDashboardMap
                                    .RIGHTSIZE.perfGraph.perfDTOList
                                }
                                xName="type"
                                yName="balancedPerf"
                                name="Balanced"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                              <SeriesDirective
                                dataSource={
                                  this.state.value.functionDashboardMap
                                    .RIGHTSIZE.perfGraph.perfDTOList
                                }
                                xName="type"
                                yName="conservativePerf"
                                name="Enhanced"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                            </SeriesCollectionDirective>
                          </ChartComponent>
                        ) : null}
                      </Col>
                      <Col
                        sm="12"
                        className="rmpaddingChart"
                        style={{ textAlign: "center", marginTop: "20px" }}
                      >
                        {this.state.value !== undefined &&
                        (this.isEmptyChart(
                          this.state.value.functionDashboardMap.RIGHTSIZE
                            .pieChartList
                        ) ||
                          !this.state.isPdfClick) ? (
                          this.state.value.functionDashboardMap.RIGHTSIZE
                            .pieChartList &&
                          this.state.value.functionDashboardMap.RIGHTSIZE
                            .pieChartList.length > 0 ? (
                            <AccumulationChartComponent
                              id="rightprogressPie"
                              title="Progress"
                              height="350"
                              width="565"
                              titleStyle={this.state.chartTitleStyle}
                              enableSmartLabels="true"
                              textRender={this.textRender}
                              legendSettings={this.state.pieLegendSettingsAll}
                              legendRender={this.legendRenderRight}
                            >
                              <Inject
                                services={[
                                  AccumulationDataLabel,
                                  AccumulationLegend,
                                ]}
                              />
                              <AccumulationSeriesCollectionDirective>
                                <AccumulationSeriesDirective
                                  dataSource={
                                    this.state.value !== undefined
                                      ? this.state.value.functionDashboardMap
                                          .RIGHTSIZE.pieChartList
                                      : []
                                  }
                                  xName="x"
                                  yName="y"
                                  pointColorMapping="fill"
                                  dataLabel={this.state.datalabel}
                                  legendShape="Rectangle"
                                ></AccumulationSeriesDirective>
                              </AccumulationSeriesCollectionDirective>
                            </AccumulationChartComponent>
                          ) : (
                            <div className="dashboardPieChartEmpty">
                              <div className="emptyDivChartTitle">Progress</div>
                              <h6>No VMs Qualify For Rightsizing Analysis</h6>
                            </div>
                          )
                        ) : null}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" id="parkingChartsTab">
                    <Row
                      className="rmpaddingChart"
                      style={{ textAlign: "center" }}
                      id="parkingCharts"
                    >
                      <Col sm="12" className="rmpaddingChart">
                        {this.state.value !== undefined &&
                        (this.isEmptyChart(
                          this.state.value.functionDashboardMap.VM_PARKING
                            .costGraph.costGraphList
                        ) ||
                          !this.state.isPdfClick) ? (
                          <ChartComponent
                            id="parkcost"
                            primaryXAxis={this.state.costxAxis}
                            pointRender={this.axisPointRender}
                            axisLabelRender={this.axisLabelRender}
                            primaryYAxis={this.state.costyAxisParking}
                            title="Aggregate Cost Saving"
                            titleStyle={this.state.chartTitleStyle}
                            palettes={this.state.costPerfPalette}
                            legendSettings={this.state.legendSettings}
                            // height="500"
                            width={this.state.chartWidth}
                          >
                            <Inject
                              services={[
                                ColumnSeries,
                                Legend,
                                DataLabel,
                                Category,
                              ]}
                            />
                            <SeriesCollectionDirective>
                              <SeriesDirective
                                dataSource={
                                  this.state.value !== undefined
                                    ? this.state.value.functionDashboardMap
                                        .VM_PARKING.costGraph.costGraphList
                                    : []
                                }
                                xName="type"
                                yName="currentPrice"
                                name="Current"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                              <SeriesDirective
                                dataSource={
                                  this.state.value !== undefined
                                    ? this.state.value.functionDashboardMap
                                        .VM_PARKING.costGraph.costGraphList
                                    : []
                                }
                                xName="type"
                                yName="aggressivePrice"
                                name="Lowest"
                                type="Column"
                                legendShape="Rectangle"
                                marker={this.state.costPerfMarker}
                                columnSpacing="0.1"
                                columnWidth="1"
                              ></SeriesDirective>
                              {/*<SeriesDirective dataSource={this.state.value !== undefined ? this.state.value.functionDashboardMap.VM_PARKING.costGraph.costGraphList : []} xName='type' yName='selectedPrice' name='Optimally Parked' type='Column' legendShape='Rectangle' marker={this.state.costPerfMarker} >
                                         </SeriesDirective>*/}
                            </SeriesCollectionDirective>
                          </ChartComponent>
                        ) : null}
                      </Col>
                      <Col
                        sm="12"
                        className="rmpaddingChart"
                        style={{ textAlign: "center", marginTop: "20px" }}
                      >
                        {this.state.value !== undefined &&
                        (this.isEmptyChart(
                          this.state.value.functionDashboardMap.VM_PARKING
                            .pieChartList
                        ) ||
                          !this.state.isPdfClick) ? (
                          this.state.value.functionDashboardMap.VM_PARKING
                            .pieChartList &&
                          this.state.value.functionDashboardMap.VM_PARKING
                            .pieChartList.length > 0 ? (
                            <AccumulationChartComponent
                              id="parkprogressPie"
                              title="Progress"
                              height="350"
                              width="565"
                              titleStyle={this.state.chartTitleStyle}
                              enableSmartLabels="true"
                              textRender={this.textRender}
                              legendSettings={this.state.pieLegendSettingsAll}
                              legendRender={this.legendRenderParking}
                            >
                              <Inject
                                services={[
                                  AccumulationDataLabel,
                                  AccumulationLegend,
                                ]}
                              />

                              <AccumulationSeriesCollectionDirective>
                                <AccumulationSeriesDirective
                                  dataSource={
                                    this.state.value !== undefined
                                      ? this.state.value.functionDashboardMap
                                          .VM_PARKING.pieChartList
                                      : []
                                  }
                                  xName="x"
                                  yName="y"
                                  pointColorMapping="fill"
                                  dataLabel={this.state.datalabel}
                                  legendShape="Rectangle"
                                ></AccumulationSeriesDirective>
                              </AccumulationSeriesCollectionDirective>
                            </AccumulationChartComponent>
                          ) : (
                            <div className="dashboardPieChartEmpty">
                              <div className="emptyDivChartTitle">Progress</div>
                              <h6>No VMs Qualify For Parking Analysis</h6>
                            </div>
                          )
                        ) : null}
                      </Col>
                    </Row>
                  </TabPane>
                  {this.state.isRenderCleanup ? (
                    <TabPane tabId="3" id="cleanupChartsTab">
                      <Row
                        className="rmpaddingChart"
                        style={{ textAlign: "center" }}
                        id="cleanupCharts"
                      >
                        <Col sm="12" className="rmpaddingChart">
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value.functionDashboardMap.CLEANUP
                              .costGraph.costGraphList
                          ) ||
                            !this.state.isPdfClick) ? (
                            <ChartComponent
                              id="cleanupcost"
                              primaryXAxis={this.state.costxAxis}
                              pointRender={this.axisPointRender}
                              axisLabelRender={this.axisLabelRender}
                              primaryYAxis={this.state.costyAxisCleanup}
                              title="Aggregate Cost Saving by Metric Data Source Cleanup"
                              titleStyle={this.state.chartTitleStyle}
                              palettes={this.state.costPerfPalette}
                              legendSettings={this.state.legendSettings}
                              // height="500"
                              width={this.state.chartWidth}
                            >
                              <Inject
                                services={[
                                  ColumnSeries,
                                  Legend,
                                  DataLabel,
                                  Category,
                                ]}
                              />
                              <SeriesCollectionDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value !== undefined
                                      ? this.state.value.functionDashboardMap
                                          .CLEANUP.costGraph.costGraphList
                                      : []
                                  }
                                  xName="type"
                                  yName="currentPrice"
                                  name="Current"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                <SeriesDirective
                                  dataSource={
                                    this.state.value !== undefined
                                      ? this.state.value.functionDashboardMap
                                          .CLEANUP.costGraph.costGraphList
                                      : []
                                  }
                                  xName="type"
                                  yName="aggressivePrice"
                                  name="Lowest"
                                  type="Column"
                                  legendShape="Rectangle"
                                  marker={this.state.costPerfMarker}
                                  columnSpacing="0.1"
                                  columnWidth="1"
                                ></SeriesDirective>
                                {/*<SeriesDirective dataSource={this.state.value !== undefined ? this.state.value.functionDashboardMap.CLEANUP.costGraph.costGraphList : []} xName='type' yName='selectedPrice' name='Optimally Cleaned' type='Column' legendShape='Rectangle' marker={this.state.costPerfMarker} >
                                         </SeriesDirective>*/}
                              </SeriesCollectionDirective>
                            </ChartComponent>
                          ) : null}
                        </Col>

                        <Col
                          sm="12"
                          className="rmpaddingChart"
                          style={{ textAlign: "center", marginTop: "20px" }}
                        >
                          {this.state.value !== undefined &&
                          (this.isEmptyChart(
                            this.state.value.functionDashboardMap.CLEANUP
                              .pieChartList
                          ) ||
                            !this.state.isPdfClick) ? (
                            this.state.value.functionDashboardMap.CLEANUP
                              .pieChartList &&
                            this.state.value.functionDashboardMap.CLEANUP
                              .pieChartList.length > 0 ? (
                              <AccumulationChartComponent
                                id="cleanupProgressPie"
                                title="Progress"
                                height="350"
                                width="565"
                                titleStyle={this.state.chartTitleStyle}
                                enableSmartLabels="true"
                                textRender={this.textRender}
                                legendSettings={this.state.pieLegendSettingsAll}
                                legendRender={this.legendRenderCleanup}
                              >
                                <Inject
                                  services={[
                                    AccumulationDataLabel,
                                    AccumulationLegend,
                                  ]}
                                />
                                <AccumulationSeriesCollectionDirective>
                                  <AccumulationSeriesDirective
                                    dataSource={
                                      this.state.value !== undefined
                                        ? this.state.value.functionDashboardMap
                                            .CLEANUP.pieChartList
                                        : []
                                    }
                                    xName="x"
                                    yName="y"
                                    pointColorMapping="fill"
                                    dataLabel={this.state.datalabel}
                                    legendShape="Rectangle"
                                  ></AccumulationSeriesDirective>
                                </AccumulationSeriesCollectionDirective>
                              </AccumulationChartComponent>
                            ) : (
                              <div className="dashboardPieChartEmpty">
                                <div className="emptyDivChartTitle">
                                  Progress
                                </div>
                                <h6>
                                  No Metric Data Source Qualify For Cleanup
                                  Analysis
                                </h6>
                              </div>
                            )
                          ) : null}
                        </Col>
                      </Row>
                    </TabPane>
                  ) : null}
                </TabContent>
              </Col>
            </Row>
          </div>
          <a id="pdfid" />
        </div>

        <Modal
          isOpen={this.state.vmsStatusModal}
          toggle={this.toggleVmsStatusModal}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">
              {" "}
              Status as of {getStringDate(new Date())}
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">
                <ul>
                  {this.state.vmsCurrentStatus &&
                    Object.keys(this.state.vmsCurrentStatus).map((key) => (
                      <li style={{ textAlign: "left" }}>
                        <pre style={{ fontSize: "16px" }}>
                          {this.state.vmsCurrentStatus[key]}
                        </pre>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button
              id="confirmbtn_list"
              onClick={(e) => {
                this.setState({ vmsStatusModal: false });
              }}
            >
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDataSourceRes: (data) => dispatch(setDataSourceRes(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    dataSourceResponse: state.dataSourceRes.dataSourceResponse,
    companyDetails: state.companyDetails.data,
    dataSourcePayload: state.dataSourcePayload.payload,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
