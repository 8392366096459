/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import {Button, Table, Label} from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";

class VMParkingPreferences extends Component{
    constructor(props) {
        super(props);
        this.keyPress = this.keyPress.bind(this);
        this.setErrorPopupState = this.setErrorPopupState.bind(this);
        this.changeDuration = this.changeDuration.bind(this);
        this.savePref = this.savePref.bind(this);
        //this.manageAction = this.manageAction.bind(this);
        this.state = {
            cpuThreshold : 10,
            memThreshold : 10,
            diskReadThreshold : 10,
            diskWriteThreshold : 10,
            netTXThreshold : 10,
            netRXThreshold : 10,
            enableSave: false,
            errorPopup: false,
            modalLoader: false,
            isErrorHeader: true,
            errormessage: '',
            isRedirect: false
        };
    }
    
     setErrorPopupState = (event) => {
        this.setState({errorPopup: event});
        if (this.state.isRedirect) {
            this.props.processLogut();
            this.props.history.push('/');
        }
    }
    
     keyPress(e) {
         if (e.keyCode >=65 && e.keyCode <= 90) {
            e.preventDefault();
        }
    }
    
    async changeDuration(e,thresholdname) {
        
        const value = e.target.value.replace(/[^\d\.]/, '');
        console.log("set thresholdname: ",e+":"+ value);
        if (value === '' || (parseInt(value) !== 0 && parseInt(value) > 0 && parseInt(value) < 100)) {
            
            switch(thresholdname){
                case 'EffCpuUsed_Aggr':
                    await this.setState({cpuThreshold:value});
                    break;        
                case 'MemUsed': 
                    await this.setState({memThreshold: value});
                    break;    
                case 'DiskReadThroughput_Aggr': 
                    await this.setState({diskReadThreshold:value});
                    break;   
                case 'DiskWriteThroughput_Aggr': 
                    await this.setState({diskWriteThreshold:value});
                    break;   
                case 'NetRXBandwidth_Aggr': 
                    await this.setState({netRXThreshold:value});
                    break;   
                case 'NetTXBandwidth_Aggr': 
                    await this.setState({netTXThreshold:value});
                    break;   
            }
            await this.setState({enableSave: true});
        }else{
            await this.setState({enableSave: false});
        }

    }

    async savePref(){
        this.setState({modalLoader: true});
        const payload = {
            effCpuUsed_Aggr:parseFloat(this.state.cpuThreshold),
            memUsed:parseFloat(this.state.memThreshold),
            diskReadThroughput_Aggr:parseFloat(this.state.diskReadThreshold),
            diskWriteThroughput_Aggr:parseFloat(this.state.diskWriteThreshold),
            netRXBandwidth_Aggr:parseFloat(this.state.netRXThreshold),
            netTXBandwidth_Aggr:parseFloat(this.state.netTXThreshold),
            InactiveResiduals:"PartiallySmoothedData"
        };
        console.log("save payload: " + payload );
        fetch(Constdata.SET_VM_PARKING_PREFERENCES, {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(payload)
        }).then((response) => response.json())
                .then((responseJson) => {
                    this.setState({modalLoader: false});
                    if (responseJson.success === true) {
                        this.props.toggle();
                    } else if (responseJson.success === false) {
                        this.setState({modalLoader: false, errorPopup: true, errormessage: responseJson.message});
                    } else if (responseJson.status === 403 || responseJson.status === 401) {
                        this.setState({isRedirect: true, modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false});
                    } else {
                        this.setState({modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader: false});
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
    }


    async componentDidMount() {
        await this.setState({modalLoader: true});
        this.props.registerListners(true);
        fetch(Constdata.GET_VM_PARKING_PREFERENCES, {
            method: 'GET',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.success === true) {
                        console.log(responseJson);
                        this.setState({modalLoader: false, cpuThreshold: parseInt(responseJson.data.inactiveMetricThresholds.effCpuUsed_Aggr *100),
                        memThreshold:parseInt(responseJson.data.inactiveMetricThresholds.memUsed *100), 
                        diskReadThreshold: parseInt(responseJson.data.inactiveMetricThresholds.diskReadThroughput_Aggr *100),
                        diskWriteThreshold: parseInt(responseJson.data.inactiveMetricThresholds.diskWriteThroughput_Aggr*100), 
                        netTXThreshold:parseInt(responseJson.data.inactiveMetricThresholds.netTXBandwidth_Aggr*100),
                        netRXThreshold:parseInt(responseJson.data.inactiveMetricThresholds.netRXBandwidth_Aggr*100)
                    });
                
                    } else if (responseJson.status === 403 || responseJson.status === 401) {
                        this.setState({modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false});
                    } else {
                        this.setState({modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader: false, isRedirect: false});
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({modalLoader: false, errorPopup: true, errormessage: message.CacheErrorText, isErrorHeader: false, isRedirect: false});
                });
    }

    render() {
        return (
                <div>
                    <div className="container-verf container-verf-margin pageBgWhite" style={{borderRadius: "10px"}} role="main">
                        <div className="frm-container">
                        <Table>
                        <tbody>
                        <tr>
                        <td><Label for="duration">EffCpuUsed_Aggr Threshold   </Label></td>
                        <td>
                        <div>
                              <input type="number" placeholder="Threshold" id="cpu" step="1" min="10" max="100" value={this.state.cpuThreshold} onChange={(e)=>{this.changeDuration(e,"EffCpuUsed_Aggr")}} onKeyDown={this.keyPress}/>
                        </div>
                        </td>
                        </tr>
                        <tr>
                        <td><Label for="unit" >MemUsed Threshold   </Label></td>
                        <td>
                        <div>
                              <input type="number" placeholder="Threshold" id="mem" step="1" min="10" max="100"   value={this.state.memThreshold} onChange={(e)=>{this.changeDuration(e,"MemUsed")}} onKeyDown={this.keyPress}/>
                        </div></td>
                        </tr>
                        <tr>
                        <td><Label for="unit" >DiskReadThroughput_Aggr Threshold   </Label></td>
                        <td>
                        <div>
                             <input type="number" placeholder="Threshold" id="diskread" step="1" min="10" max="100"  value={this.state.diskReadThreshold} onChange={(e)=>{this.changeDuration(e,"DiskReadThroughput_Aggr")}} onKeyDown={this.keyPress}/>
                        </div></td>
                        </tr>
                        <tr>
                        <td><Label for="unit" >DiskWriteThroughput_Aggr Threshold   </Label></td>
                        <td>
                        <div>
                              <input type="number" placeholder="Threshold" id="diskwrite" step="1" min="10" max="100"  value={this.state.diskWriteThreshold} onChange={(e)=>{this.changeDuration(e,"DiskWriteThroughput_Aggr")}} onKeyDown={this.keyPress}/>
                        </div></td>
                        </tr>
                        <tr>
                        <td><Label for="unit" >NetRXBandwidth_Aggr Threshold   </Label></td>
                        <td>
                        <div>
                              <input type="number" placeholder="Threshold" id="netrx" step="1" min="10" max="100"  value={this.state.netRXThreshold} onChange={(e)=>{this.changeDuration(e,"NetRXBandwidth_Aggr")}} onKeyDown={this.keyPress}/>
                        </div></td>
                        </tr>
                         <tr>
                        <td><Label for="unit" >NetTXBandwidth_Aggr Threshold   </Label></td>
                        <td>
                        <div>
                              <input type="number" placeholder="Threshold" id="nettx" step="1" min="10" max="100"  value={this.state.netTXThreshold} onChange={(e)=>{this.changeDuration(e,"NetTXBandwidth_Aggr")}} onKeyDown={this.keyPress}/>
                        </div></td>
                        </tr>
                        </tbody>
                        </Table>
                            <div class="btn-toolbar pageBgWhite" role="toolbar" aria-label="Toolbar with button groups" style={{display: "flex", padding: "20px", marginTop: "20px", justifyContent: "flex-end", borderRadius: "0px 0px 10px 10px"}}>
                                <div class="mr-2" role="group" aria-label="Third group">
                                    <Button id={!this.state.enableSave ? "GOTO_listdisabled" : "confirmbtn_list"} onClick={this.savePref} className="btn-detils" disabled={ !this.state.enableSave}>Save</Button>
                                </div>
                                <div role="group" aria-label="First group">
                                    <Button className="btn-cancel btn btn-light" onClick={this.props.toggle}>Close</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.state.errorPopup === true) ? <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState} isError={this.state.isErrorHeader}></SerraError> : ""}
                    {(this.state.modalLoader === true) ? <SerraLoader></SerraLoader> : ""}
                </div>
                );
    }
}
export default withRouter(VMParkingPreferences);
