/* global fetch */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Input,
  Button,
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../HeaderComponent/Header";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import ManageCleanupRecommendation from "./ManageCleanupRecommendation";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import jstz from "jstz";
import { connect } from "react-redux";
import WarningTblCleanup from "./WarningTblCleanup";

class ManageCleanup extends Component {
  constructor(props) {
    super(props);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.handleCleanuplistRequest = this.handleCleanuplistRequest.bind(this);
    this.checkRefresh = this.checkRefresh.bind(this);
    this.toggleManageCleanupInfo = this.toggleManageCleanupInfo.bind(this);
    this.togglePerfInfo = this.togglePerfInfo.bind(this);
    this.toggleReportInfo = this.toggleReportInfo.bind(this);
    this.toggleResetInfo = this.toggleResetInfo.bind(this);
    this.navigateToReport = this.navigateToReport.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.toggleReset = this.toggleReset.bind(this);
    this.handelReset = this.handelReset.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.onChange = this.onChange.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.toggleNext = this.toggleNext.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.responseProcess = this.responseProcess.bind(this);
    this.toggleManageCleanupPopup = this.toggleManageCleanupPopup.bind(this);
    this.renderDbStatus = this.renderDbStatus.bind(this);
    this.myFunctionSelect = this.myFunctionSelect.bind(this);
    this.removeDuplicates = this.removeDuplicates.bind(this);
    this.getRefreshStatus = this.getRefreshStatus.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.freeTrialPlan = this.freeTrialPlan.bind(this);
    this.freeTrialPlanSupport = this.freeTrialPlanSupport.bind(this);
    this.toggleTable = this.toggleTable.bind(this);
    this.isStaledsVms = this.isStaledsVms.bind(this);
    this.freeTrialAnalysisList = this.freeTrialAnalysisList.bind(this);
    this.state = {
      providerName: "",
      loggedInUser: "",
      value: true,
      result: [],
      reportData: [],
      nonSelectable: [],
      isUpdated: false,
      modalLoader: false,
      refreshFlag: false,
      isFromDidMount: false,
      isPerformAnalysis: true,
      isManageResize: true,
      isReport: true,
      isReset: true,
      togglemodalNext: false,
      toggleManageCleanupModal: false,
      manageCleanupInfoModal: false,
      perfInfoModal: false,
      reportInfoModal: false,
      resetInfoModal: false,
      modalReset: false,
      errorPopup: false,
      errormessage: "",
      isErrorHeader: false,
      isFreeTrial: false,
      isCleanupTrialSuccess: false,
      isRedirect: false,
      timerId: 0,
      refreshTimerId: -1,
      fetchFlag: false,
      getAllConfigData: [],
      clickResetStatus: false,
      freeTrailPopup: false,
      userName: false,
      notEligibleList: [],
      ineligibleDsList: [],
      modalTable: false,
      StatusFilters: [
        { label: "Not Analyzed", value: "1" },
        { label: "Analyzing", value: "2" },
        { label: "Analyzed", value: "3" },
        { label: "Analysis Issue", value: "4" },
        { label: "Optimizing", value: "5" },
        { label: "Optimally Cleaned", value: "6" },
        { label: "Custom Cleaned", value: "7" },
        { label: "Cleaned", value: "8" },
        { label: "Resetting", value: "9" },
        { label: "Pending", value: "10" },
      ],
      StatusFiltersValues: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      StatusFiltersLabels: [
        "Not Analyzed",
        "Analyzing",
        "Analyzed",
        "Analysis Issue",
        "Optimizing",
        "Optimally Cleaned",
        "Custom Cleaned",
        "Resetting",
        "Pending",
      ],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "metric_datasource_name",
          text: "Metric Data Source",
          sort: true,
          headerStyle: {
            minWidth: "60px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
          },
        },
        {
          dataField: "status",
          text: "Status",
          formatter: this.renderDbStatus,
          sort: true,
          style: {
            padding: "0px ",
            fontWeight: "600",
          },
          headerStyle: {
            padding: "0px !important",
            minWidth: "30%",
          },
        },
        {
          dataField: "statusdatetime",
          text: "When",
          formatter: this.getStringDate,
          headerStyle: {
            minWidth: "30%",
          },
          style: {
            pointerEvents: "none",
          },
        },
      ],
      isFreePlanNonAnalyzedSelected: false,
      isFreeTrialAnalysisStarted: false,
    };
  }
  timeoutId = null;

  async componentDidMount() {
    var pgAc = document.getElementById("pageRef");
    if (pgAc !== undefined && pgAc !== null) {
      var header = `<div>
        <div style="display: flex; text-align: center; font-size: 22px; color:#610058;">Function: ${this.props.companyDetails.ServiceName} Cleanup</div>
    </div>`;
      document.getElementById("pageRef").innerHTML = header;
      pgAc.disabled = true;
    }

    this.props.registerListners();
    fetch(Constdata.GETPRESELECTEDFILTERS_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        await this.setState({
          viewPriviousdata: responseJson.data
            ? responseJson.data.lastReport
            : [],
          result:
            responseJson.orgInfo.plan === "FREE_TRIAL" &&
            responseJson.orgInfo.userName !== "support@serralabs.com"
              ? []
              : responseJson.data
              ? responseJson.data.selectedCleanupIds
              : [],
          isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
          userName: responseJson.orgInfo.userName,
          isParkingFreeTrialSuccess:
            responseJson.orgInfo.isParkingFreeTrialCompleted,
          isFreePlanNonAnalyzedSelected: true,
        });
        await this.fillupAccountFilter();
        await this.getRefreshStatus(true);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
    // await this.handleCleanuplistRequest(true);
  }

  async componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Clear timeout on component unmount
    }
    await clearInterval(this.state.timerId);
    await clearInterval(this.state.refreshTimerId);
  }

  toggleManageCleanupInfo = () => {
    this.setState((prevState) => ({
      manageCleanupInfoModal: !prevState.manageCleanupInfoModal,
    }));
  };

  getRefreshStatus = async (fromDidMount) => {
    if (fromDidMount !== undefined && fromDidMount === false) {
      await this.fillupAccountFilter();
      //await this.populatePreselectRows(fromDidMount);
    }
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          let isFetching = false;

          if (responseJson.orgInfo.providerName === "AWS") {
            if (
              this.props.dataSourcePayload &&
              this.props.dataSourcePayload.length >= 1 &&
              responseJson.data.awsConfigs.length <= 0
            ) {
              this.timeoutId = setTimeout(() => {
                this.getRefreshStatus(fromDidMount);
              }, 15000);
            }
            await responseJson.data.awsConfigs.map(async (key) => {
              if (
                key.status.toLowerCase().includes("fetching") &&
                (key.refreshResourceIndex === 1 ||
                  (key.refreshResourceIndex & 2) === 2)
              ) {
                isFetching = true;
              }
            });
            this.setState({ getAllConfigData: responseJson.data.awsConfigs });
          } else {
            if (
              this.props.dataSourcePayload &&
              this.props.dataSourcePayload.length >= 1 &&
              responseJson.data.configs.length <= 0
            ) {
              this.timeoutId = setTimeout(() => {
                this.getRefreshStatus(fromDidMount);
              }, 15000);
            }
            await responseJson.data.configs.map(async (key) => {
              if (
                key.status.toLowerCase().includes("fetching") &&
                (key.refreshResourceIndex === 1 ||
                  (key.refreshResourceIndex & 2) === 2)
              ) {
                isFetching = true;
              }
            });
            this.setState({ getAllConfigData: responseJson.data.configs });
          }
          // this.handleCleanuplistRequest(fromDidMount);
          if (!isFetching) {
            await this.setState({
              refreshFlag: false,
              selectRow: { ...this.state.selectRow, hideSelectAll: false },
            });
            if (this.state.refreshTimerId !== -1) {
              clearInterval(this.state.refreshTimerId);
              await this.setState({ refreshTimerId: -1 });
            }
          } else {
            this.setState({
              refreshFlag: true,
              selectRow: { ...this.state.selectRow, hideSelectAll: true },
            });
            if (this.state.refreshTimerId === -1) {
              const timer = setInterval(() => {
                this.getRefreshStatus(fromDidMount);
                this.handleCleanuplistRequest(fromDidMount);
              }, 10000);
              await this.setState({
                refreshTimerId: timer,
              });
            }
          }
          this.isDisabled();
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          if (this.state.refreshTimerId !== -1) {
            clearInterval(this.state.refreshTimerId);
          }
          await this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            isRedirect: true,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
        } else {
          if (this.state.refreshTimerId !== -1) {
            clearInterval(this.state.refreshTimerId);
          }
          await this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            isRedirect: true,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
        }
      })
      .catch((error) => {
        if (this.state.refreshTimerId !== -1) {
          clearInterval(this.state.refreshTimerId);
          this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
        }
        console.error(error);
        this.setState({
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  fillupAccountFilter = async () => {
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          let accountlist = [];
          let accountselValList = [];
          let accNameLst = [];
          if (responseJson.orgInfo.providerName === "AWS") {
            this.setState({ providerName: "AWS" });
            for (var i = 0; i < responseJson.data.awsConfigs.length; i++) {
              accountlist.push({
                label: responseJson.data.awsConfigs[i].accountname,
                value: "" + i,
              });
              accountselValList.push("" + i);
              accNameLst.push(responseJson.data.awsConfigs[i].accountname);
            }
          } else if (responseJson.orgInfo.providerName === "AZURE") {
            this.setState({ providerName: "AZURE" });
            for (var i = 0; i < responseJson.data.configs.length; i++) {
              accountlist.push({
                label: responseJson.data.configs[i].accountname,
                value: "" + i,
              });
              accountselValList.push("" + i);
              accNameLst.push(responseJson.data.configs[i].accountname);
            }
          }
          this.setState({
            accountList: accountlist,
            accountValList: accountselValList,
            userList: accNameLst,
          });
          this.handleCleanuplistRequest(true);
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  async handleCleanuplistRequest(isFromdidMount) {
    this.props.registerListners(true);
    if (isFromdidMount !== undefined && isFromdidMount === true) {
      await this.setState({ isFromDidMount: isFromdidMount });
    } else {
      await this.setState({ isFromDidMount: false });
    }
    if (this.state.clickResetStatus) {
      return false;
    }
    fetch(Constdata.CLEANUP_LIST_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          let fetchingDS = false;
          if (
            this.props.dataSourcePayload &&
            this.props.dataSourcePayload.length >= 1 &&
            this.state.getAllConfigData &&
            this.state.getAllConfigData.length <= 0
          ) {
            fetchingDS = true;
          }

          if (responseJson.data === true) {
            this.timeoutId = setTimeout(() => {
              this.handleCleanuplistRequest(isFromdidMount);
            }, 15000);
          }

          const isFreeTrialAnalyzed = await this.freeTrialAnalysisList(
            responseJson.orgInfo
          );

          await this.setState({
            value: fetchingDS ? true : responseJson.data,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
            userName: responseJson.orgInfo.userName,
            isCleanupTrialSuccess:
              responseJson.orgInfo.isCleanupFreeTrailCompleted,
            providerName: responseJson.orgInfo.providerName,
            loggedInUser: responseJson.orgInfo.loggedInUser,
            isFreeTrialAnalysisStarted:
              responseJson.orgInfo.plan === "FREE_TRIAL"
                ? isFreeTrialAnalyzed
                : false,
          });

          let isFetching = false;
          var data = [];
          var notEligible = [];
          var notEligibleList1 = [];

          Array.isArray(this.state.value) &&
            this.state.value.map(async (key) => {
              if (this.isStaledsVms(key)) {
                //Free paln:- when user logs in and no analysis is done, select all VM which are not analyzed
                if (
                  this.freeTrialPlanSupport() &&
                  this.state.isFreePlanNonAnalyzedSelected
                ) {
                  if (
                    isFreeTrialAnalyzed === key.isdsanalyzable &&
                    key.status === "NOT ANALYZED"
                  ) {
                    this.setState({
                      result: [...this.state.result, key.id],
                    });
                  }
                }

                if (this.state.isUpdated === true) {
                  this.state.result.map((value) => {
                    if (value === key.id) {
                      data.push({
                        id: key.id,
                        status: key.status,
                        metric_datasource_name: key.metric_datasource_name,
                        statusdatetime: key.statusdatetime,
                        isdsanalyzable: key.isdsanalyzable,
                      });
                      this.setState({ reportData: data });
                      this.isDisabled();
                    }
                  });
                } else {
                  if (
                    this.state.reportData !== undefined &&
                    this.state.reportData.length > 0
                  ) {
                    var Selection = this.state.reportData.reduce(
                      (acc, obj) => (obj.id === key.id ? ++acc : acc),
                      0
                    );
                    if (Selection > 0) {
                      data.push({
                        id: key.id,
                        status: key.status,
                        metric_datasource_name: key.metric_datasource_name,
                        statusdatetime: key.statusdatetime,
                        isdsanalyzable: key.isdsanalyzable,
                      });
                    }
                  }
                }

                if (
                  key.status === "ANALYZING" ||
                  key.status.toUpperCase() === "PENDING" ||
                  key.status === "RESETTING"
                ) {
                  isFetching = true;
                }
              } else {
                notEligible.push(key.id);
                notEligibleList1.push(key);
              }
            });

          await this.setState({
            isFreePlanNonAnalyzedSelected: false,
            fetchFlag: isFetching,
            reportData: data,
            notEligibleList: notEligible,
            ineligibleDsList: notEligibleList1,
            // refreshFlag: anyRefreshing,
          });
          if (!this.state.fetchFlag) {
            if (this.state.timerId !== 0) {
              clearInterval(this.state.timerId);
              await this.setState({ timerId: 0 });
            }
          } else {
            if (this.state.timerId === 0) {
              const timer = setInterval(() => {
                this.handleCleanuplistRequest();
              }, 10000);
              await this.setState({
                timerId: timer,
                fetchFlag: false,
              });
            }
          }

          await this.isDisabled();
          if (isFromdidMount !== undefined && isFromdidMount === true) {
            await this.preSelectConfig();
          }
          await this.myFunction();
          await this.myFunctionSelect();
          if (!this.state.clickResetStatus) {
            await this.setState({ modalLoader: false });
          }
        } else if (
          responseJson.success === false &&
          responseJson.message === "No organization found"
        ) {
          await this.setState({ fetchFlag: false });
          if (this.state.timerId !== 0) {
            clearInterval(this.state.timerId);
            await this.setState({ timerId: 0 });
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          if (this.state.timerId === 0) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.SessionExpiredText,
              isErrorHeader: false,
            });
          } else {
            clearInterval(this.state.timerId);
            await this.setState({ fetchFlag: false, timerId: 0 });
          }
        } else {
          if (this.state.timerId === 0) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.ServerError,
              isErrorHeader: false,
              isRedirect: false,
            });
          } else {
            clearInterval(this.state.timerId);
            await this.setState({ fetchFlag: false, timerId: 0 });
          }
        }
      })
      .catch((error) => {
        if (this.state.timerId !== 0) {
          clearInterval(this.state.timerId);
        }
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }

  async checkAll(isSelect, rows, e) {
    var data = [];
    var basicDbData = [];
    if (isSelect) {
      Array.isArray(this.state.value) &&
        this.state.value.map((db) => {
          if (this.isStaledsVms(db)) {
            data.push({
              id: db.id,
              status: db.status,
              metric_datasource_name: db.metric_datasource_name,
              statusdatetime: db.statusdatetime,
              isdsanalyzable: db.isdsanalyzable,
            });
            basicDbData.push(db.id);
          }
        });
      await this.setState({
        reportData: data,
        result: basicDbData,
        isChecked: true,
      });
    } else {
      await this.setState({
        result: [],
        reportData: [],
        isChecked: false,
      });
    }
    await this.isDisabled();
    await this.myFunction();
    await this.myFunctionSelect();
  }

  async onChange(row, isSelect, rowIndex, e) {
    if (isSelect) {
      await this.setState({
        result: [...this.state.result, row.id],
        reportData: [
          ...this.state.reportData,
          {
            id: row.id,
            status: row.status,
            metric_datasource_name: row.metric_datasource_name,
            statusdatetime: row.statusdatetime,
            isdsanalyzable: row.isdsanalyzable,
          },
        ],
      });
    } else {
      let remove = this.state.result.indexOf(row.id);
      await this.setState({
        result: this.state.result.filter((_, key) => key !== remove),
        reportData: this.state.reportData.filter((key) => key.id !== row.id),
        isChecked: false,
      });
    }
    await this.setState({ result: [...new Set(this.state.result)] });
    await this.isDisabled();
    await this.myFunction();
    await this.myFunctionSelect();
  }

  async isDisabled() {
    const { reportData, isFreeTrialAnalysisStarted } = this.state;
    let isViewReportDisabled = false;
    let isAnalysisDisabled = false;
    let isResetDisabled = false;
    let recommendationDisabled = false;
    if (reportData.length === 0) {
      isViewReportDisabled = true;
      isAnalysisDisabled = true;
      isResetDisabled = true;
      recommendationDisabled = true;
    } else {
      reportData.map((key) => {
        switch (key.status.toUpperCase()) {
          case "NOT ANALYZED":
            isViewReportDisabled = true;
            isResetDisabled = true;
            recommendationDisabled = true;
            break;
          case "ANALYZING":
          case "PENDING":
          case "ANALYSIS ISSUE":
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            recommendationDisabled = true;
            break;
          case "ANALYZED":
            isAnalysisDisabled = true;
            break;
          case "OPTIMIZING":
            isAnalysisDisabled = true;
            break;
          case "OPTIMALLY CLEANED":
          case "CUSTOM CLEANED":
          case "CLEANED":
            recommendationDisabled = true;
            isAnalysisDisabled = true;
            break;
          case "RESETTING":
            recommendationDisabled = true;
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            isResetDisabled = true;
            break;
          default:
            break;
        }
      });
    }

    //Free Trial:- Only allowed resets for analysis issue and analysis failure VM states

    if (this.freeTrialPlanSupport()) {
      var disabledResetBtn = reportData.some(
        (e) =>
          e.status !== "ANALYSIS ISSUE" && e.status !== "ANALYSIS INELIGIBLE"
      );
      if (disabledResetBtn) {
        isResetDisabled = true;
      }

      var disabledAnalysisBtn = reportData.some(
        (key) => key.isdsanalyzable !== isFreeTrialAnalysisStarted
      );

      if (disabledAnalysisBtn) {
        isAnalysisDisabled = true;
      }
    }

    await this.setState({
      isReport: this.freeTrialPlanSupport() ? true : isViewReportDisabled,
      isPerformAnalysis: isAnalysisDisabled,
      isReset: isResetDisabled,
      isManageResize: this.freeTrialPlanSupport()
        ? true
        : recommendationDisabled,
    });
  }

  async preSelectConfig() {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(this.state.value) &&
      this.state.value.map(async (row) => {
        if (
          this.state.result !== undefined &&
          this.state.result.includes(row.id)
        ) {
          await this.setState({
            reportData: [
              ...this.state.reportData,
              {
                id: row.id,
                status: row.status,
                metric_datasource_name: row.metric_datasource_name,
                statusdatetime: row.statusdatetime,
                isdsanalyzable: row.isdsanalyzable,
              },
            ],
          });
        }
      });

    await this.setState({ result: [...new Set(this.state.result)] });
    if (this.state.reportData.length > 1) {
      await this.setState({
        reportData: this.removeDuplicates(this.state.reportData, "id"),
      });
    }

    if (this.state.reportData.length !== 0) {
      await this.isDisabled();
    } else {
      this.setState({ isReport: true });
    }
    //this.checkParentChekcbox();
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event, freeTrailPopup: false });
    if (this.state.isRedirect && !this.state.freeTrailPopup) {
      this.props.processLogut();
      this.props.history.push("/");
    }
  };

  checkRefresh = async (idx) => {};

  handleNext = async () => {
    const { reportData } = this.state;
    const timezone = jstz.determine();
    const timezoneid = timezone.name();
    let resultPayload = reportData.map((vm) => vm.id);
    await this.setState({ modalLoader: true });
    const payload = {
      userGtmId: timezoneid,
      basicASPIDList: resultPayload,
    };
    fetch(Constdata.CLEANUP_START_ANALYSIS_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.responseProcess(responseJson);
      })
      .catch((error) => {
        if (this.state.timerId !== 0) {
          clearInterval(this.state.timerId);
        }
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  responseProcess = async (response) => {
    if (response.success === true) {
      await this.setState({
        togglemodalNext: false,
        isReport: false,
        isCleanupTrialSuccess: response.orgInfo.isCleanupFreeTrailCompleted,
      });
      await this.handleCleanuplistRequest();
      if (this.freeTrialPlanSupport()) {
        await this.isDisabled();
        await this.preSelectConfig();
      }
    } else if (response.success === false) {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: response.message,
        isErrorHeader: false,
        isRedirect: false,
      });
    } else if (response.status === 403 || response.status === 401) {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: message.SessionExpiredText,
        isErrorHeader: false,
      });
    } else {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    }
  };

  handelReset = async () => {
    this.setState({
      modalLoader: true,
      modalReset: false,
      clickResetStatus: true,
    });
    var seqs = [];
    this.state.reportData.map(async (key) => {
      seqs.push(key.id);
    });
    fetch(Constdata.CLEANUP_RESET_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(seqs),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          await this.setState({
            clickResetStatus: false,
            isUpdated: true,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
            userName: responseJson.orgInfo.userName,
            isCleanupTrialSuccess:
              responseJson.orgInfo.isCleanupFreeTrailCompleted,
          });
          await this.handleCleanuplistRequest();
          if (this.freeTrialPlanSupport()) {
            await this.isDisabled();
            await this.preSelectConfig();
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: false,
            modalReset: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  toggleManageCleanupPopup = () => {
    if (!this.state.toggleManageCleanupModal) {
      this.setState({ modalLoader: true });
      var seqs = [];
      this.state.reportData.map(async (key) => {
        seqs.push(key.id);
      });
      fetch(Constdata.CLEANUP_REPORT_URL, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(seqs),
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson.success === true) {
            var manageValData = responseJson.data.configResultList;

            manageValData.map((key, i) => {
              this.state.reportData.forEach(function (vm) {
                if (vm.metric_datasource_name === key.metricDataSourceName) {
                  key["id"] = vm.id;
                }
              });
            });
            await this.setState((prevState) => ({
              manageModalData: manageValData,
              modalLoader: false,
              toggleManageCleanupModal: !prevState.toggleManageCleanupModal,
            }));
          } else if (
            responseJson.status === 403 ||
            responseJson.status === 401
          ) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.SessionExpiredText,
              isErrorHeader: false,
            });
          } else {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: responseJson.message,
              isErrorHeader: false,
              isRedirect: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.CacheErrorText,
            isErrorHeader: false,
            isRedirect: false,
          });
        });
    } else {
      this.setState((prevState) => ({
        manageModalData: [],
        toggleManageCleanupModal: !prevState.toggleManageCleanupModal,
      }));
    }
  };

  toggleTable = () => {
    this.setState((prevState) => ({
      modalTable: !prevState.modalTable,
    }));
  };

  renderDbStatus(cell, ds) {
    switch (ds.status.toUpperCase()) {
      case "ANALYZING":
      case "PENDING":
        return (
          <div className="textfieldCentered bgColor">
            <div>
              <i
                className="fa fa-circle-o-notch fa-spin"
                aria-hidden="true"
                data-toggle="tooltip"
                data-placement="right"
                title={ds.status}
              />
            </div>
            <div>{ds.status}</div>
          </div>
        );
      case "ANALYSIS ISSUE":
        return (
          <div className="textfieldCentered">
            <div>{ds.status}</div>
          </div>
        );

      case "RESETTING":
        return (
          <div className="textfieldCentered bgColor">
            <label>
              <i
                className="fa fa-circle-o-notch fa-spin"
                aria-hidden="true"
                data-toggle="tooltip"
                data-placement="right"
                title={ds.status}
              />
              <br></br>
              {ds.status}
            </label>{" "}
          </div>
        );
      case "OPTIMALLY CLEANED":
      case "CUSTOM CLEANED":
      case "CLEANED":
      case "OPTIMIZING":
        return (
          <div className="textfieldCentered">
            <a
              href="#"
              style={{ fontSize: "14px" }}
              onClick={(e) => {
                e.preventDefault();
                if (this.freeTrialPlanSupport()) {
                  this.freeTrialPlan();
                } else {
                  this.navigateToReport(ds);
                }
              }}
            >
              <div className="textfieldCentered">
                <div>{ds.status}</div>
              </div>
            </a>
          </div>
        );
      case "ANALYZED":
        return (
          <div className="textfieldCentered">
            <a
              href="#"
              style={{ fontSize: "14px" }}
              onClick={(e) => {
                e.preventDefault();
                if (this.freeTrialPlanSupport()) {
                  this.freeTrialPlan();
                } else {
                  this.navigateToReport(ds);
                }
              }}
            >
              {ds.status}
            </a>
            {ds.isstaleds === true ? (
              <i
                style={{
                  position: "relative",
                  right: "-20px",
                }}
                className="fa fa-info-circle"
                aria-hidden="true"
                data-toggle="tooltip"
                data-placement="right"
                title={message.IsStaledsMsg}
              />
            ) : null}
          </div>
        );
      default:
        return <div className="textfieldCentered">{ds.status}</div>;
    }
  }

  navigateToReport = (ds) => {
    var rptDtd = [];
    rptDtd.push(ds.id);
    this.props.history.push({
      pathname: "/cleanupReport",
      state: { data: rptDtd },
    });
  };

  handleReport = () => {
    var rptDtd = [];
    console.log(this.state.reportData);
    this.state.reportData.map(async (key) => {
      rptDtd.push(key.id);
    });
    this.props.history.push({
      pathname: "/cleanupReport",
      state: { data: rptDtd },
    });
  };

  getStringDate(cell, row) {
    let current_datetime = new Date(new Date(row.statusdatetime).toGMTString());
    var hours = current_datetime.getHours();
    var minutes = current_datetime.getMinutes();
    var month = current_datetime.getMonth() + 1;
    var day = current_datetime.getDate();
    var seconds = current_datetime.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    var tzAbbr = "";
    var dateObject = new Date(),
      dateString = dateObject + "",
      tzAbbr =
        dateString.match(/\(([^\)]+)\)$/) ||
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
      tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
    }

    return (
      <div style={{ textAlign: "center" }}>
        {current_datetime.getFullYear() +
          "-" +
          month +
          "-" +
          day +
          "  " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds +
          " " +
          tzAbbr}
      </div>
    );
  }

  componentWillUnmount() {
    if (this.state.timerId !== 0) {
      clearInterval(this.state.timerId);
    }
  }

  componentWillMount() {}

  async handleFilter(key) {
    let filterValList = [];
    let filterLabelList = [];
    var isChanged = false;
    if (key !== null && key !== undefined) {
      if (key.name === "selectedAll") {
        if (key.isChecked === true) {
          this.state.StatusFilters.map((data) => {
            filterValList.push(data.value);
            filterLabelList.push(data.label);
          });
        }
        isChanged = true;
      } else {
        filterValList = this.state.StatusFiltersValues.slice();
        filterLabelList = this.state.StatusFiltersLabels.slice();
        if (
          key.name === "select" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterVal = key.itemData.value;
          let filterLabel = key.itemData.label;
          if (!filterValList.includes(filterVal)) {
            await filterValList.push(filterVal);
            await filterLabelList.push(filterLabel);
            isChanged = true;
          }
        } else if (
          key.name === "removed" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterVal = key.itemData.value;
          let filterLabel = key.itemData.label;
          if (filterValList.includes(filterVal)) {
            let idx = filterValList.indexOf(filterVal);
            filterValList.splice(idx, 1);
            idx = filterLabelList.indexOf(filterLabel);
            filterLabelList.splice(idx, 1);
            isChanged = true;
          }
        }
      }
      if (isChanged) {
        await this.setState({
          StatusFiltersValues: filterValList,
          StatusFiltersLabels: filterLabelList,
        });
        await this.myFunctionSelect();
      }
    }
  }

  toggleManageResizeInfo = () => {
    this.setState((prevState) => ({
      manageResizeInfoModal: !prevState.manageResizeInfoModal,
    }));
  };

  togglePerfInfo = () => {
    this.setState((prevState) => ({
      perfInfoModal: !prevState.perfInfoModal,
    }));
  };

  toggleReportInfo = () => {
    this.setState((prevState) => ({
      reportInfoModal: !prevState.reportInfoModal,
    }));
  };

  toggleResetInfo = () => {
    this.setState((prevState) => ({
      resetInfoModal: !prevState.resetInfoModal,
    }));
  };

  toggleReset = () => {
    this.setState((prevState) => ({
      modalReset: !prevState.modalReset,
    }));
  };

  toggleNext = () => {
    this.setState((prevState) => ({
      togglemodalNext: !prevState.togglemodalNext,
    }));
  };

  async myFunction() {
    // this.setState({ SearchText: e.target.value });
    var input, filter, table, tr, td, cell, i;
    input = document.getElementById("myCleanupInput");
    filter = input.value.toUpperCase();
    table = document.getElementsByClassName("cleanupTbl")[0];
    tr = table.getElementsByTagName("tr");
    let trs = [];
    for (i = 1; i < tr.length; i++) {
      // Hide the row initially.
      tr[i].style.display = "none";
      trs.push(tr[i]);
      td = tr[i].getElementsByTagName("td");
      for (var j = 0; j < td.length; j++) {
        cell = tr[i].getElementsByTagName("td")[j];
        if (cell) {
          if (cell.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            let idx = trs.indexOf(tr[i]);
            trs.splice(idx, 1);
            break;
          }
        }
      }
    }
    for (var c = 0; c < trs.length; c++) {
      var chks = trs[c].getElementsByTagName("input");
      if (chks !== undefined && chks.length === 1) {
        if (chks[0].checked === true) {
          chks[0].click();
        }
      }
    }
  }

  async myFunctionSelect() {
    var filter, table, tr, i, cell, ck;
    //document.getElementById("myCleanupInput").value="";
    table = document.getElementsByClassName("cleanupTbl")[0];
    tr = table.getElementsByTagName("tr");
    let trs = [];
    for (i = 1; i < tr.length; i++) {
      // Hide the row initially.
      tr[i].style.display = "none";
      trs.push(tr[i]);
      for (var s = 0; s < this.state.StatusFiltersLabels.length; s++) {
        filter = this.state.StatusFiltersLabels[s].toUpperCase();
        cell = tr[i].getElementsByTagName("td")[2];
        if (cell) {
          if (cell.innerHTML.indexOf(filter) > -1) {
            tr[i].style.display = "";
            let idx = trs.indexOf(tr[i]);
            trs.splice(idx, 1);
            break;
          }
        } else {
          cell = tr[i].getElementsByTagName("td")[0];
          if (
            cell &&
            cell.innerHTML.indexOf("No Metric Data Source Found") > -1
          ) {
            tr[i].style.display = "";
          }
        }
      }
    }
    for (var c = 0; c < trs.length; c++) {
      var chks = trs[c].getElementsByTagName("input");
      if (chks !== undefined && chks.length === 1) {
        if (chks[0].checked === true) {
          chks[0].click();
        }
      }
    }
  }

  freeTrialPlan() {
    this.setState({
      freeTrailPopup: true,
      errorPopup: true,
      errormessage: "Only Available in Paid Plan",
      isErrorHeader: true,
    });
  }
  freeTrialPlanSupport() {
    if (this.state.isFreeTrial) {
      if (this.state.userName === "support@serralabs.com") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  isStaledsVms(Vm) {
    return (
      Vm.isstaleds === false ||
      (Vm.isstaleds === true && Vm.status === "ANALYZED")
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataSourceResponse !== this.props.dataSourceResponse) {
      this.handleCleanuplistRequest(true);
    }
  }

  async freeTrialAnalysisList(orgData) {
    if (orgData && orgData.freeTrialAnalysisList !== null) {
      return await orgData.freeTrialAnalysisList.includes("C");
    }
  }

  render() {
    let ineligibleCount = this.state.ineligibleDsList.length;
    const analysisConfirmStr = `Please confirm to perform cleanup analysis of the selected Metric Data Sources :  \n ${this.state.reportData.map(
      (db) => {
        return db.metric_datasource_name;
      }
    )}`;

    return (
      <div>
        <Header
          processLogout={this.props.processLogut}
          registerListners={this.props.registerListners}
          providerName={this.state.providerName}
          loggedInUser={this.state.loggedInUser}
        ></Header>
        <div
          className="container-verf container-verf-padding"
          role="main"
          style={{ marginTop: "50px" }}
        >
          <div className="container1 boxShadow">
            <div
              className="rowContainer pageBgWhite"
              style={{
                width: "100%",
                margin: "0px 0px 0px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                className="pageBgWhite"
                style={{ textAlign: "left", borderRadius: "5px" }}
              >
                <div
                  style={{
                    padding: "10px 20px",
                    alignItem: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "25%" }}>
                    <Input
                      className="form-control"
                      id="myCleanupInput"
                      placeholder="Search Metric Data Sources"
                      onKeyUp={this.myFunction}
                      disabled={this.state.refreshFlag}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      width: "25%",
                    }}
                  >
                    {ineligibleCount > 0 ? (
                      <span>
                        {" "}
                        <label className="labelColor">DS Warning Table</label>
                        <i
                          className="fa fa-warning"
                          onClick={this.toggleTable}
                          style={{
                            fontSize: "20px",
                            color: "#e6da12",
                            paddingLeft: "5px",
                          }}
                          data-toggle="tooltip"
                          data-placement="right"
                          title="View VM Warnings"
                        >
                          {" "}
                        </i>
                      </span>
                    ) : null}
                  </div>
                  <div className="multiSelectFilter">
                    <div style={{ paddingRight: "5px" }}>
                      <label className="labelColor">Filter by Status:</label>
                    </div>
                    <div style={{ width: "75%" }}>
                      <MultiSelectComponent
                        id="checkbox"
                        cssClass={
                          !this.state.refreshFlag
                            ? "multiSelectFilter-input"
                            : "multiSelectFilter-input-disabled"
                        }
                        dataSource={this.state.StatusFilters}
                        fields={{ text: "label", value: "value" }}
                        placeholder="Filter by Status"
                        mode="CheckBox"
                        selectAllText="All"
                        unSelectAllText="All"
                        showSelectAll={true}
                        value={[...new Set(this.state.StatusFiltersValues)]}
                        closePopupOnSelect={true}
                        enableSelectionOrder={false}
                        showDropDownIcon={true}
                        selectedAll={(value) => this.handleFilter(value)}
                        select={(value) => this.handleFilter(value)}
                        removed={(value) => this.handleFilter(value)}
                        enabled={!this.state.refreshFlag}
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pageBgWhite vmListDiv">
                <div
                  class="btn-toolbar pageBgWhite"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                  style={{
                    display: "flex",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    position: "sticky",
                    top: "61px",
                    zIndex: "110",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    <Button
                      onClick={this.toggleNext}
                      id={
                        this.state.isPerformAnalysis === true
                          ? "listDisabledBtn"
                          : "listBtn"
                      }
                      disabled={this.state.isPerformAnalysis}
                    >
                      Perform Analysis
                    </Button>
                    <Button
                      className="btn listInfoBtn"
                      onClick={this.togglePerfInfo}
                    >
                      <i className="fa fa-info" />
                    </Button>
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Second group"
                    style={{ marginLeft: "10px" }}
                  >
                    <Button
                      onClick={this.toggleManageCleanupPopup}
                      id={
                        this.state.isManageResize === true
                          ? "listDisabledBtn"
                          : "listBtn"
                      }
                      disabled={this.state.isManageResize}
                    >
                      Manage Cleanup
                    </Button>
                    <Button
                      className="btn listInfoBtn"
                      onClick={this.toggleManageCleanupInfo}
                    >
                      <i className="fa fa-info" />
                    </Button>
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Third group"
                    style={{ marginLeft: "10px" }}
                  >
                    <Button
                      onClick={this.handleReport}
                      id={
                        this.state.isReport === true
                          ? "listDisabledBtn"
                          : "listBtn"
                      }
                      disabled={this.state.isReport}
                    >
                      View Report
                    </Button>
                    <Button
                      className="btn listInfoBtn"
                      onClick={this.toggleReportInfo}
                    >
                      <i className="fa fa-info" />
                    </Button>
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Fourth group"
                    style={{ marginLeft: "10px" }}
                  >
                    <Button
                      onClick={this.toggleReset}
                      id={
                        this.state.isReset === true
                          ? "listDisabledBtn"
                          : "listBtn"
                      }
                      disabled={this.state.isReset}
                      style={{ width: "130px" }}
                    >
                      Reset State
                    </Button>
                    <Button
                      className="btn listInfoBtn"
                      onClick={this.toggleResetInfo}
                    >
                      <i className="fa fa-info" />
                    </Button>
                  </div>
                </div>

                <BootstrapTable
                  bordered={false}
                  id="list_table"
                  bodyClasses="tbodyColor"
                  classes="table-responsive list_table_height cleanupTbl"
                  bootstrap4={true}
                  keyField="id"
                  data={Array.isArray(this.state.value) ? this.state.value : []}
                  columns={this.state.columns}
                  selectRow={{
                    mode: "checkbox",
                    clickToSelect: false,
                    clickToExpand: false,
                    onSelect: this.onChange,
                    onSelectAll: this.checkAll,
                    selected: this.state.result,
                  }}
                  hiddenRows={this.state.notEligibleList}
                  expandRow={this.state.expandRow}
                  wrapperClasses="table-responsive text-center"
                  noDataIndication={() =>
                    (this.state.isFromDidMount && this.state.value == true) ||
                    (!this.state.isFromDidMount &&
                      this.state.value == true &&
                      !this.state.refreshFlag)
                      ? "Fetching Metric Data Source"
                      : (this.state.isFromDidMount &&
                          (this.state.value === false ||
                            this.state.value.length === 0)) ||
                        (!this.state.isFromDidMount &&
                          (this.state.value === false ||
                            this.state.value.length === 0) &&
                          !this.state.refreshFlag)
                      ? "No Metric Data Source Found"
                      : "   "
                  }
                  loading={this.state.refreshFlag}
                  // overlay={overlayFactory({
                  //   spinner: true,
                  //   text: "Refreshing Cleanup Table...",
                  //   background: "rgb(204, 208, 209,0.3)",
                  // })}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        <Modal
          toggle={this.toggleNext}
          size="xl"
          style={{ maxWidth: "1600px", width: "45%", margin: "10px auto" }}
          isOpen={this.state.togglemodalNext}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
            toggle={this.toggleNext}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Cleanup Analysis Confirmation</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel" id="modaldis_list">
                <div>
                  <p>
                    {" "}
                    {this.state.reportData.length > 1 ? (
                      " Please confirm to perform analysis of " +
                      this.state.reportData.length +
                      " selected Metric Data Sources"
                    ) : (
                      <div className="new-line">{analysisConfirmStr}</div>
                    )}
                  </p>
                </div>
              </div>
              <div className="footnotePopup" style={{ marginBottom: "20px" }}>
                {this.freeTrialPlanSupport() ? (
                  <div
                    style={{ paddingBottom: "7px" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        "In Free Trial, it is highly recommended that all unanalyzed data sources be analyzed so that you can get an overall view of possible savings using cleanup for your cloud.",
                    }}
                  />
                ) : null}
                <div
                  dangerouslySetInnerHTML={{
                    __html: `Cleanup analysis identifies specific resources: ${
                      this.state.providerName === "AZURE"
                        ? "(Disks, ASPs, Public IPs, Storage Accounts)"
                        : "(Disks, Public IPs)"
                    }  
                    that are no longer needed and calculates the possible cost savings by deleting them.`,
                  }}
                />
                <div
                  style={{ paddingTop: "7px" }}
                  dangerouslySetInnerHTML={{
                    __html: message.PerformCleanupAnalysisConfirmFootNote,
                  }}
                />
                {this.freeTrialPlanSupport() &&
                Array.isArray(this.state.value) &&
                !this.state.value.some(
                  (vm) => this.isStaledsVms(vm) && vm.status !== "NOT ANALYZED"
                ) ? (
                  <div
                    style={{ paddingTop: "7px" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        "Please note that after starting cleanup analysis in Free Trial, data sources cannot be changed.",
                    }}
                  />
                ) : null}
              </div>
              <Form>
                <FormGroup>
                  <div align="center">
                    <Button id="confirmbtn_list" onClick={this.handleNext}>
                      Yes
                    </Button>
                    <Button
                      id="canclebtn_list"
                      onClick={() => {
                        this.toggleNext();
                      }}
                    >
                      No
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modalReset}
          toggle={this.toggleReset}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
            toggle={this.toggleReset}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle"> Reset State </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel" id="modaldis_list">
                <div>
                  <p style={{ padding: "0px 74px" }}>
                    {this.state.reportData.length > 1
                      ? "Please confirm to reset state of " +
                        this.state.reportData.length +
                        " Data Source."
                      : "Please confirm to reset state of the selected Data Source : " +
                        (this.state.reportData[0] !== undefined
                          ? this.state.reportData[0].metric_datasource_name
                          : "")}
                  </p>{" "}
                </div>
              </div>
              <Form>
                <FormGroup>
                  <div align="center">
                    <Button id="confirmbtn_list" onClick={this.handelReset}>
                      Yes
                    </Button>
                    <Button
                      id="canclebtn_list"
                      onClick={() => {
                        this.toggleReset();
                      }}
                    >
                      No
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="xl"
          style={{ maxWidth: "1600px", width: "95%", margin: "10px auto" }}
          isOpen={this.state.toggleManageCleanupModal}
          toggle={this.toggleManageCleanupPopup}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleManageCleanupPopup}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Cleanup Optimization</div>
          </ModalHeader>
          <ModalBody>
            <ManageCleanupRecommendation
              cancelUserCleanup={this.toggleManageCleanupPopup}
              data={this.state.manageModalData}
              handleFetch={this.handleCleanuplistRequest}
              isFreeTrial={this.state.isFreeTrial}
              userName={this.state.userName}
            />
          </ModalBody>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.perfInfoModal}
          toggle={this.togglePerfInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.togglePerfInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Perform Analysis</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.PerformCleanupAnalysisInfoText,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.StartCleanupAnalysisFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.togglePerfInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.manageCleanupInfoModal}
          toggle={this.toggleManageCleanupInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleManageCleanupInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Manage Cleanup</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.ManageCleanupResizingInfoText,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.ManageCleanupResizingFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleManageCleanupInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.reportInfoModal}
          toggle={this.toggleReportInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleReportInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">View Report</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.GenerateCleanupReportInfoText0,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.ShowCleanupReportFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleReportInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.resetInfoModal}
          toggle={this.toggleResetInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleResetInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Reset State</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{ __html: message.ResetCleanupInfoText }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.ResetCleanupStateFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleResetInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="xl"
          style={{ maxWidth: "1600px", width: "95%", margin: "10px auto" }}
          isOpen={this.state.modalTable}
          toggle={this.toggleTable}
          backdrop={"static"}
        >
          <ModalHeader
            toggle={this.toggleTable}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{
                __html: message.SerraLabsModalHeader,
              }}
            />
            <div className="modalTitle"> Metric Data Source Warning Table</div>
          </ModalHeader>
          <ModalBody>
            <WarningTblCleanup ineligibleDsList={this.state.ineligibleDsList} />
          </ModalBody>
          <ModalFooter>
            <Button
              className="btnBg"
              onClick={() => {
                this.toggleTable();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyDetails: state.companyDetails.data,
    dataSourcePayload: state.dataSourcePayload.payload,
    dataSourceResponse: state.dataSourceRes.dataSourceResponse,
  };
};

export default connect(mapStateToProps, null)(withRouter(ManageCleanup));
