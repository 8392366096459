/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
/* global fetch */
import React, { Component } from "react";
import { Input, Button, Row, FormGroup, Label, Tooltip } from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.changeUnit = this.changeUnit.bind(this);
    this.changeDuration = this.changeDuration.bind(this);
    this.changeParkingUnit = this.changeParkingUnit.bind(this);
    this.changeParkingDuration = this.changeParkingDuration.bind(this);
    this.manageAction = this.manageAction.bind(this);
    this.savePref = this.savePref.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.changeInterval = this.changeInterval.bind(this);
    this.toggleTooltips = this.toggleTooltips.bind(this);
    this.freeTrialPlanSupport = this.freeTrialPlanSupport.bind(this);
    this.state = {
      unit: "days",
      duration: 7,
      parkingDuration: 17,
      parkingUnit: "days",
      enableSave: false,
      errorPopup: false,
      modalLoader: false,
      isErrorHeader: true,
      errormessage: "",
      isRedirect: false,
      max: 60 * 24,
      interval: 5,
      provider: props.providerName,
      tooltipOpen: false,
      tooltipIntervalOpen: false,
      userName: props.userName,
      isFreeTrial: props.isFreeTrial,
    };
  }

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.history.push("/");
    }
  };

  async changeUnit(event) {
    await this.setState({ unit: event.target.value });
    if (this.state.unit === "hours") {
      document.getElementById("duration").setAttribute("max", 60 * 24);
    } else {
      if (this.state.duration > 60) {
        document.getElementById("duration").setAttribute("max", 60);
        await this.setState({ duration: 60 });
      }
    }

    if (this.state.interval === 5 && this.state.unit === "hours") {
      if (this.state.duration < 6) {
        this.setState({ duration: 5 });
      }
    }
    this.manageAction();
  }

  changeParkingUnit(event) {
    this.setState({ unit: event.target.value });
    this.manageAction();
  }

  keyPress(e) {
    if (e.keyCode === 190 || e.keyCode === 110) {
      e.preventDefault();
    }
  }
  async changeDuration(e) {
    const value = e.target.value.replace(/[^\d]/, "");
    var upperLimit = 61;
    if (this.state.unit === "hours") {
      upperLimit = 60 * 24 + 1;
    }
    let lowerLimit = 0;
    if (
      this.state.interval === 5 &&
      this.state.unit === "hours" &&
      parseInt(value) < 6
    ) {
      lowerLimit = 5;
      if (
        value === "" ||
        (parseInt(value) !== 0 &&
          parseInt(value) > 0 &&
          parseInt(value) < upperLimit &&
          parseInt(value) >= lowerLimit)
      ) {
        await this.setState({ duration: value });
        await this.manageAction();
      }
    } else {
      if (
        value === "" ||
        (parseInt(value) !== 0 &&
          parseInt(value) > 0 &&
          parseInt(value) < upperLimit)
      ) {
        await this.setState({ duration: value });
        await this.manageAction();
      }
    }
  }

  async changeParkingDuration(e) {
    const value = e.target.value.replace(/[^\d]/, "");
    if (value === "" || (parseInt(value) > 0 && parseInt(value) < 18)) {
      await this.setState({ parkingDuration: value });
      await this.manageAction();
    }
  }

  async manageAction() {
    var upperLimit = 61;
    if (this.state.unit === "hours") {
      upperLimit = 60 * 24 + 1;
    }
    if (
      this.state.duration > 0 &&
      this.state.duration < upperLimit &&
      this.state.parkingDuration >= 14 &&
      this.state.parkingDuration < 18
    ) {
      await this.setState({ enableSave: true });
    } else {
      await this.setState({ enableSave: false });
    }
  }

  savePref() {
    this.setState({ modalLoader: true });
    const payload = {
      commonMetricQueryDuration: this.state.duration,
      commonMetricQueryDurationUnit: this.state.unit,
      parkingMetricQueryDuration: this.state.parkingDuration,
      parkingMetricQueryDurationUnit: "days", //this.state.parkingUnit
      interval: this.state.interval,
      providerName: this.state.provider,
    };

    fetch(Constdata.SET_METRIC_FETCH_DURATION, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ modalLoader: false });
        this.props.handleRecommendationDurationPreference(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async componentDidMount() {
    await this.setState({ modalLoader: true });
    this.props.registerListners(true);
    fetch(Constdata.GET_METRIC_FETCH_DURATION, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          console.log(responseJson);
          await this.setState({
            modalLoader: false,
            duration: responseJson.data.commonMetricQueryDuration,
            unit: responseJson.data.commonMetricQueryDurationUnit,
            parkingDuration: 17,
            parkingUnit: responseJson.data.parkingMetricQueryDurationUnit,
            interval:
              responseJson.data.interval === 0
                ? this.state.interval
                : responseJson.data.interval,
          });
          //await this.setState({modalLoader: false, duration: responseJson.data.commonMetricQueryDuration, unit: responseJson.data.commonMetricQueryDurationUnit, parkingDuration: responseJson.data.parkingMetricQueryDuration, parkingUnit: responseJson.data.parkingMetricQueryDurationUnit});
          if (this.state.unit === "hours") {
            document.getElementById("duration").setAttribute("max", 60 * 24);
          } else {
            document.getElementById("duration").setAttribute("max", 60);
          }

          if (this.state.unit === "") {
            this.setState({ unit: "hours", max: 60 * 24 });
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          await this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          await this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }
  changeInterval(e) {
    const value = e.target.value;

    if (value === "5" && this.state.unit === "hours") {
      if (this.state.duration < 6) {
        this.setState({ duration: 5 });
      }
    }

    this.setState({ interval: parseInt(value) });
    this.manageAction();
  }

  toggleTooltips = () =>
    this.setState({ tooltipOpen: !this.state.tooltipOpen });

  toggleTooltipsInterval = () =>
    this.setState({ tooltipIntervalOpen: !this.state.tooltipIntervalOpen });

  freeTrialPlanSupport() {
    if (this.state.isFreeTrial) {
      if (this.state.userName === "support@serralabs.com") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  render() {
    return (
      <div>
        <div
          className="container-verf container-verf-margin pageBgWhite"
          style={{ borderRadius: "10px" }}
          role="main"
        >
          <div className="frm-container">
            <Row>
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  fontSize: "20px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                <Label>Rightsizing Duration</Label>
              </div>
            </Row>
            <Row
              style={{
                justifyContent: "center",
                borderBottom: "1px solid #c9c9c9",
                paddingBottom: "30px",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                <FormGroup>
                  <div style={{ display: "flex" }}>
                    <Label for="duration" style={{ width: "80px" }}>
                      Maximum Duration
                    </Label>
                    <div>
                      <i
                        id="Tooltip-Duration"
                        style={{ marginLeft: "7px", fontSize: "18px" }}
                        className="fa fa-info-circle"
                      ></i>
                      <Tooltip
                        placement="bottom"
                        isOpen={this.state.tooltipOpen}
                        target={"Tooltip-Duration"}
                        toggle={this.toggleTooltips}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid black",
                          textAlign: "left",
                          minWidth:
                            window.innerWidth >= 1702
                              ? "55%"
                              : window.innerWidth >= 1561
                              ? "60%"
                              : window.innerWidth >= 1379
                              ? "68%"
                              : "75%",
                        }}
                      >
                        <div style={{ lineHeight: 1.1 }}>
                          {" "}
                          {this.state.provider === "AZURE"
                            ? message.MetricDataDurationInfoAzure
                            : message.MetricDataDurationInfo}
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <div>
                    <Input
                      type="number"
                      pattern="[1-9]"
                      step="1"
                      min="1"
                      max={this.state.max}
                      name="duration"
                      id="duration"
                      placeholder="Duration"
                      value={this.state.duration}
                      onChange={this.changeDuration}
                      onKeyDown={this.keyPress}
                    />
                  </div>
                </FormGroup>
              </div>
              <div style={{ marginRight: "20px" }}>
                <FormGroup>
                  <Label for="unit" style={{ paddingTop: "24px" }}>
                    Unit
                  </Label>
                  <div>
                    <select
                      value={this.state.unit}
                      onChange={this.changeUnit}
                      style={{ height: "38px" }}
                    >
                      <option value="hours">Hour(s)</option>
                      <option value="days">Day(s)</option>
                    </select>
                  </div>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <div style={{ display: "flex" }}>
                    <Label for="unit" style={{ width: "50px" }}>
                      Best Fidelity
                    </Label>
                    <div>
                      <i
                        id="Tooltip-Interval"
                        style={{ marginLeft: "7px", fontSize: "18px" }}
                        className="fa fa-info-circle"
                      />
                      <Tooltip
                        placement="bottom"
                        isOpen={this.state.tooltipIntervalOpen}
                        target={"Tooltip-Interval"}
                        toggle={this.toggleTooltipsInterval}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid black",
                          textAlign: "left",
                          minWidth:
                            window.innerWidth >= 1768
                              ? "55%"
                              : window.innerWidth >= 1621
                              ? "60%"
                              : window.innerWidth >= 1496
                              ? "65%"
                              : window.innerWidth >= 1354
                              ? "72%"
                              : "75%",
                        }}
                      >
                        <div style={{ lineHeight: 1.1 }}>
                          {this.state.provider === "AZURE"
                            ? message.MetricDataIntervalInfoAzure
                            : message.MetricDataIntervalInfo}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div>
                    <select
                      value={this.state.interval}
                      onChange={this.changeInterval}
                      style={{ height: "38px" }}
                    >
                      <option value="1">1 Min</option>
                      <option value="5">5 Min</option>
                    </select>
                  </div>
                </FormGroup>
              </div>
            </Row>
            {/* <Row>
                                <div style={{textAlign: 'center', width: "100%", fontSize: "20px", fontWeight: '600', marginTop: '30px', marginBottom: '20px'}}>
                                    <Label>Parking Metric Data Duration Preference</Label> 
                                </div>
                                <Row style={{justifyContent: "center", borderBottom: "1px solid #c9c9c9", paddingBottom: '30px'}}>
                
                                    <div style={{marginRight: "20px"}}>
                                        <FormGroup>
                                            <Label for="parkingDuration">Duration</Label>
                                            <div>
                                                <Input type="number" pattern="[1-9]" step="1" min="14" max="17" name="parkingDuration" id="parkingDuration" placeholder="Duration" value={this.state.parkingDuration} onChange={this.changeParkingDuration} onKeyDown={this.keyPress} />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup>
                                            <Label for="unit" >Unit</Label>
                                            <div>
                                                <select value={this.state.parkingUnit} onChange={this.changeParkingUnit} style={{height: "38px"}}>
                                                    <option value="days">Day(s)</option>
                                                </select>
                                            </div>
                                        </FormGroup>
                                    </div>                                
                                </Row>
                            </Row> */}
            <div
              class="btn-toolbar pageBgWhite"
              role="toolbar"
              aria-label="Toolbar with button groups"
              style={{
                display: "flex",
                padding: "20px",
                marginTop: "20px",
                justifyContent: "flex-end",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <div class="mr-2" role="group" aria-label="Third group">
                <Button
                  id={
                    this.freeTrialPlanSupport() || !this.state.enableSave
                      ? "GOTO_listdisabled"
                      : "confirmbtn_list"
                  }
                  onClick={this.savePref}
                  className="btn-detils"
                  disabled={
                    this.freeTrialPlanSupport() || !this.state.enableSave
                  }
                >
                  Save
                </Button>
              </div>
              <div role="group" aria-label="First group">
                <Button
                  className="btn-cancel btn btn-light"
                  onClick={this.props.toggle}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      </div>
    );
  }
}

export default Preferences;
