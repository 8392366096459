import { createSlice } from '@reduxjs/toolkit'
// THUNK

const initialState = {
    dataSourceResponse: null
}

// SLICE
export const dataSourceResSlice = createSlice({
  name: 'dataSourceRes',
  initialState,
  reducers: {
    setDataSourceRes: (state, action) => {
      state.dataSourceResponse = action.payload
    },
    reset: () => initialState
  }
})

// ACTION
export const { reset, setDataSourceRes } = dataSourceResSlice.actions

// REDUCER
export default dataSourceResSlice.reducer