/* global fetch */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Input,
  Button,
  Table,
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import ManageUserRecommendation from "./ManageUserRecommendation";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import jstz from "jstz";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

class ManageAspOptimizations extends Component {
  constructor(props) {
    super(props);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.handleAsplistRequest = this.handleAsplistRequest.bind(this);
    this.handleAccount = this.handleAccount.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.toggleTable = this.toggleTable.bind(this);
    this.toggleNext = this.toggleNext.bind(this);
    this.toggleManageResizePopup = this.toggleManageResizePopup.bind(this);
    this.toggleManageResizeInfo = this.toggleManageResizeInfo.bind(this);
    this.togglePerfInfo = this.togglePerfInfo.bind(this);
    this.toggleReportInfo = this.toggleReportInfo.bind(this);
    this.toggleResetInfo = this.toggleResetInfo.bind(this);
    this.fillupAccountFilter = this.fillupAccountFilter.bind(this);
    this.vmSizeColFormater = this.vmSizeColFormater.bind(this);
    this.preSelectVM = this.preSelectVM.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.onChange = this.onChange.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.getRefreshStatus = this.getRefreshStatus.bind(this);
    this.responseProcess = this.responseProcess.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.toggleReset = this.toggleReset.bind(this);
    this.handelReset = this.handelReset.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.renderVmStatus = this.renderVmStatus.bind(this);
    this.navigateToSummary = this.navigateToSummary.bind(this);
    this.toggleReason = this.toggleReason.bind(this);
    this.removeDuplicates = this.removeDuplicates.bind(this);
    this.populatePreselectRows = this.populatePreselectRows.bind(this);
    this.freeTrialPlan = this.freeTrialPlan.bind(this);
    this.freeTrialPlanSupport = this.freeTrialPlanSupport.bind(this);
    this.isStaledsVms = this.isStaledsVms.bind(this);
    this.freeTrialAnalysisList = this.freeTrialAnalysisList.bind(this);
    this.state = {
      value: true,
      result: [],
      reportData: [],
      manageModalData: [],
      modalTable: false,
      refreshFlag: false,
      isPerformAnalysis: true,
      isManageResize: true,
      isReport: true,
      isReset: true,
      togglemodalNext: false,
      toggleManageResizeModal: false,
      manageResizeInfoModal: false,
      perfInfoModal: false,
      reportInfoModal: false,
      resetInfoModal: false,
      modalLoader: false,
      errorPopup: false,
      errormessage: "",
      isErrorHeader: false,
      isFreeTrial: false,
      isAspFreeTrialSuccess: false,
      isRedirect: false,
      isFromDidMount: false,
      modalReset: false,
      next: 6,
      privious: 0,
      SearchText: "",
      loadingState: false,
      fetchFlag: false,
      timerId: 0,
      refreshTimerId: -1,
      nonSelectable: [],
      accountList: [],
      accountValList: [],
      accountNameList: [],
      filterList: [],
      selectedBasicAspId: [],
      notEligibleAsp: [],
      curIneligibleAsp: null,
      notEligibleList: [],
      getAllConfigData: [],
      clickResetStatus: false,
      freeTrailPopup: false,
      userName: false,
      AspFilters: [
        { label: "Not Analyzed", value: "1" },
        { label: "Analyzing", value: "2" },
        { label: "Analyzed", value: "3" },
        { label: "Analysis Issue", value: "4" },
        { label: "Optimizing", value: "5" },
        { label: "Optimally Sized", value: "6" },
        { label: "Custom-Sized", value: "7" },
        { label: "Resetting", value: "8" },
      ],
      AspFiltersValues: ["1", "2", "3", "4", "5", "6", "7", "8"],
      columns: [
        {
          dataField: "basicAspId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "name",
          text: "ASP Name",
          sort: true,
          headerStyle: {
            minWidth: "60px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
            fontWeight: "600",
            color: "#393939",
          },
        },
        {
          dataField: "accountName",
          text: "Metric Data Source",
          sort: true,
          headerStyle: {
            minWidth: "60px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
          },
        },
        {
          dataField: "priceSource",
          text: "Pricing Model",
          sort: true,
          headerStyle: {
            width: "140px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
          },
        },
        {
          dataField: "osType",
          text: "OS Type",
          sort: true,
          headerStyle: {
            width: "140px",
          },
          style: {
            pointerEvents: "none",
          },
        },
        {
          dataField: "pricingTier",
          text: "Pricing Tier",
          sort: true,
          formatter: this.vmSizeColFormater,
          classes: (cell, row, rowIndex, colIndex) => {
            return "thExpandSelector";
          },
        },
        {
          dataField: "region",
          text: "Region",
          sort: true,
          headerStyle: {
            width: "140px",
          },
          style: {
            pointerEvents: "none",
          },
        },
        {
          dataField: "status",
          text: "Status",
          formatter: this.renderVmStatus,
          sort: true,
          style: {
            padding: "0px ",
            fontWeight: "600",
            color: "#393939",
          },
          headerStyle: {
            padding: "0px !important",
            width: "230px",
          },
        },
        {
          dataField: "status_change_datetime",
          text: "When",
          sort: true,
          formatter: this.getStringDate,
          headerStyle: {
            width: "120px",
          },
          style: {
            pointerEvents: "none",
          },
        },
      ],
      expandRow: {
        showExpandColumn: false,
        onlyOneExpanding: false,
        animation: "grow",
        renderer: this.expandeRowRenderer,
        onExpand: (row, isExpand, rowIndex, e) => {
          if (isExpand) {
            if (document.getElementById(row.basicAspId + "arrAsp") !== null) {
              document.getElementById(row.basicAspId + "arrAsp").className =
                "fa fa-angle-up";
            }
          } else {
            if (document.getElementById(row.basicAspId + "arrAsp") !== null) {
              document.getElementById(row.basicAspId + "arrAsp").className =
                "fa fa-angle-down";
            }
          }
        },
      },
      isFreePlanNonAnalyzedSelected: false,
      isFreeTrialAnalysisStarted: false,
    };
  }
  timeoutId = null;

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event, freeTrailPopup: false });
    if (this.state.isRedirect && !this.state.freeTrailPopup) {
      this.props.processLogut();
      this.props.history.push("/");
    }
  };

  navigateToSummary(Vm, landOn) {
    const data = {
      vmname: Vm.name,
      basicvmid: Vm.basicAspId,
      analysisseqnum: Vm.analysisseqnum,
      progressnum: 0,
      providername: "Azure",
      orgname: "",
      accountName: Vm.accountName,
      region: Vm.region,
      analysisdatetimestamp: Vm.status_change_datetime,
      status_change_datetime: Vm.status_change_datetime,
    };
    this.props.history.push({
      pathname: "/AspSummary",
      state: {
        data: data,
        oldData: this.state.reportData,
        lastView: "/manageOptimizations",
        key: Vm.accountName,
        landOn: landOn,
        isAsp: true,
        listTb: "2",
      },
    });
  }

  renderVmStatus(cell, Vm) {
    let resizeObjective = "";
    if (Vm.resizeperftype !== null && Vm.resizeperftype !== undefined) {
      resizeObjective =
        Vm.resizeperftype === "Aggressive"
          ? "Economical"
          : Vm.resizeperftype === "Conservative"
          ? "Enhanced"
          : "Balanced";
    }
    switch (Vm.status) {
      case "ANALYZING":
        return (
          <div className="textfieldCentered bgColor">
            <div>
              <i
                className="fa fa-circle-o-notch fa-spin"
                aria-hidden="true"
                data-toggle="tooltip"
                data-placement="right"
                title={Vm.status}
              />
            </div>
            <div>{Vm.status}</div>
            <div style={{ fontSize: "11px" }}>{Vm.analysisSubState}</div>
          </div>
        );
      case "ANALYSIS ISSUE":
        return (
          <div className="textfieldCentered">
            <Row>
              <Col sm="12" style={{ padding: "0px" }}>
                <div>{Vm.status}</div>
                {Vm.analysisFailReason ? (
                  <i
                    style={{ position: "absolute", right: "10px", top: "0px", }}
                    className="fa fa-question-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={Vm.analysisFailReason}
                  />
                ) : null}
              </Col>
            </Row>
          </div>
        );

      case "RESETTING":
        return (
          <div className="textfieldCentered bgColor">
            <label>
              <i
                className="fa fa-circle-o-notch fa-spin"
                aria-hidden="true"
                data-toggle="tooltip"
                data-placement="right"
                title={Vm.status}
              />
              <br></br>
              {Vm.status}
            </label>{" "}
          </div>
        );
      case "OPTIMALLY SIZED":
      case "CUSTOM-SIZED":
      case "OPTIMIZING":
        return (
          <div className="textfieldCentered">
            <Row>
              <Col sm="12" style={{ padding: "0px" }}>
                <a
                  href="#"
                  style={{ fontSize: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.freeTrialPlanSupport()) {
                      this.freeTrialPlan();
                    } else {
                      this.navigateToSummary(Vm, "resize");
                    }
                  }}
                >
                  <div className="textfieldCentered">
                    <div>{Vm.status}</div> <div>{resizeObjective}</div>
                  </div>
                </a>
                {Vm.isResourceChanged === true ? (
                  <i
                    style={{ position: "absolute", right: "10px", top: "0px", }}
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={message.ASPRersourceChangedFLagInfoTextAnalyzed}
                  />
                ) : null}
                {Vm.metricdatadurationused !== null ? (
                  <div style={{ fontSize: "11px" }}>
                    ({Vm.metricdatadurationused})
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        );
      case "ANALYZED":
        return (
          <div className="textfieldCentered">
            <Row>
              <Col sm="12" style={{ padding: "0px" }}>
                <a
                  href="#"
                  style={{ fontSize: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.freeTrialPlanSupport()) {
                      this.freeTrialPlan();
                    } else {
                      this.navigateToSummary(Vm, "analysis");
                    }
                  }}
                >
                  <div className="textfieldCentered">
                    <div>{Vm.status}</div>
                  </div>
                </a>
                {Vm.isResourceChanged === true || Vm.isstaleds === true ? (
                  <i
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "0px",
                    }}
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={`${
                      Vm.isResourceChanged === true
                        ? message.ASPRersourceChangedFLagInfoTextAnalyzed
                        : ""
                    }\n${Vm.isstaleds === true ? message.IsStaledsMsg : ""}`}
                  />
                ) : null}

                {Vm.metricdatadurationused !== "" ? (
                  <div style={{ fontSize: "11px" }}>
                    ({Vm.metricdatadurationused})
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        );
      default:
        return <div className="textfieldCentered">{Vm.status}</div>;
    }
  }

  toggleReset = () => {
    this.setState((prevState) => ({
      modalReset: !prevState.modalReset,
    }));
  };

  async onChange(row, isSelect, rowIndex, e) {
    if (isSelect) {
      await this.setState({
        result: [...this.state.result, row.basicAspId],
        reportData: [
          ...this.state.reportData,
          {
            basicASPId: row.basicAspId,
            analysisSeqNum: row.analysisseqnum,
            aspState: row.status,
            accountName: row.accountName,
            status_change_datetime: row.status_change_datetime,
            name: row.name,
            vmResultKeyMap: row.aspResultKeyMap,
            resizePerfType: row.resizeperftype,
            isaspanalyzable: row.isaspanalyzable,
          },
        ],
      });
    } else {
      let remove = this.state.result.indexOf(row.basicAspId);
      await this.setState({
        result: this.state.result.filter((_, key) => key !== remove),
        reportData: this.state.reportData.filter(
          (key) => key.basicASPId !== row.basicAspId
        ),
        isChecked: false,
      });
    }
    await this.setState({ result: [...new Set(this.state.result)] });
    await this.isDisabled();
    await this.myFunction();
  }

  getStringDate(cell, row) {
    let current_datetime = new Date(
      new Date(row.status_change_datetime).toGMTString()
    );
    var hours = current_datetime.getHours();
    var minutes = current_datetime.getMinutes();
    var month = current_datetime.getMonth() + 1;
    var day = current_datetime.getDate();
    var seconds = current_datetime.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    var tzAbbr = "";
    var dateObject = new Date(),
      dateString = dateObject + "",
      tzAbbr =
        dateString.match(/\(([^\)]+)\)$/) ||
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
      tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
    }

    return (
      <div style={{ textAlign: "center" }}>
        {current_datetime.getFullYear() +
          "-" +
          month +
          "-" +
          day +
          "  " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds +
          " " +
          tzAbbr}
      </div>
    );
  }

  vmSizeColFormater(cell, row) {
    var expTable = document.getElementById(row.basicAspId + "expTableAsp");
    let classnm = "fa fa-angle-down";
    if (expTable) {
      classnm = "fa fa-angle-up";
    } else {
      classnm = "fa fa-angle-down";
    }
    return (
      <Row style={{ display: "inline-flex" }}>
        <Col sm="10" style={{ padding: "0px" }}>
          <div style={{ display: "inline-flex" }}>
            <div>{row.pricingTier}</div>
            {row.priceChangeMsg !== null ? (
              <div style={{ textAlign: "right", marginLeft: "10px" }}>
                <i
                  style={{ marginRight: "4px" }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="right"
                  title={row.priceChangeMsg}
                />
              </div>
            ) : null}
          </div>
        </Col>
        <Col sm="2" style={{ padding: "0px", textAlign: "end" }}>
          {" "}
          <i
            id={row.basicAspId + "arrAsp"}
            className={classnm}
            style={{ fontSize: "26px" }}
          ></i>
        </Col>
      </Row>
    );
  }

  expandeRowRenderer(row) {
    return (
      <div
        style={{ textAlign: "center", display: "block ruby" }}
        id={row.basicAspId + "expTableAsp"}
      >
        <Table
          id="list_table"
          className="list_table_inner"
          style={{ width: "80%", position: "relative" }}
          align="center"
        >
          <thead>
            <th style={{ fontSize: "14px", zIndex: "0" }}>Apps</th>
            <th style={{ fontSize: "14px", zIndex: "0" }}>CPU ACUs </th>
            <th style={{ fontSize: "14px", zIndex: "0" }}>Memory (GB)</th>
            <th style={{ fontSize: "14px", zIndex: "0" }}>Max Storage (GB)</th>
            <th style={{ fontSize: "14px", width: "26%", zIndex: "0" }}>
              Aggregate Disk IOPS/Throughput Limits
            </th>
            <th style={{ fontSize: "14px", width: "25%", zIndex: "0" }}>
              Aggregate Network Bandwidth Limit
            </th>
          </thead>
          <tbody>
            <tr id="list_tablerow">
              <td>{row.numApps}</td>
              <td>{row.cpuAcus}</td>
              <td>{row.maxMemoryGiB} GiB</td>
              <td>{row.maxStorageGiB} GiB</td>
              <td>
                {row.diskIOPSCount}/{row.diskThroughputMBps} Mbps
              </td>
              <td>{row.networkBandwidthMbps} Mbps</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  componentWillMount() {
    for (var i = 0; i < this.state.AspFilters.length; i++) {
      this.state.filterList.push(this.state.AspFilters[i].label.toUpperCase());
    }
  }

  async componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Clear timeout on component unmount
    }
    await clearInterval(this.state.refreshTimerId);
    await clearInterval(this.state.timerId);
  }

  async componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "CPO Rightsizing",
    });
    this.props.registerListners();
    this.props.onRef(this);
    fetch(Constdata.GETPRESELECTEDFILTERS_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        await this.setState({
          selectedBasicAspId: responseJson.data
            ? responseJson.data.selectedASPIds
            : [],
          result:
            responseJson.orgInfo.plan === "FREE_TRIAL" &&
            responseJson.orgInfo.userName !== "support@serralabs.com"
              ? []
              : responseJson.data
              ? responseJson.data.selectedASPIds
              : [],
          isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
          isAspFreeTrialSuccess: responseJson.orgInfo.isAspFreeTrialCompleted,
          userName: responseJson.orgInfo.userName,
          isFreePlanNonAnalyzedSelected: true,
        });
        await this.fillupAccountFilter();
        await this.getRefreshStatus(true);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }

  populatePreselectRows(fromDidMount) {
    fetch(Constdata.GETPRESELECTEDFILTERS_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        await this.setState({
          selectedBasicAspId: responseJson.data
            ? responseJson.data.selectedASPIds
            : [],
          result: this.freeTrialPlanSupport()
            ? this.state.result
            : responseJson.data
            ? responseJson.data.selectedBVMIds
            : [],
          isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
          isAspFreeTrialSuccess: responseJson.orgInfo.isAspFreeTrialCompleted,
          userName: responseJson.orgInfo.userName,
        });
        if (fromDidMount !== undefined && fromDidMount === true) {
          await this.preSelectVM();
          await this.getRefreshStatus(fromDidMount);
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }

  getRefreshStatus = async (fromDidMount) => {
    if (fromDidMount !== undefined && fromDidMount === false) {
      await this.fillupAccountFilter();
      await this.populatePreselectRows(fromDidMount);
    }
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          var isFetching = false;

          if (
            this.props.dataSourcePayload &&
            this.props.dataSourcePayload.length >= 1 &&
            responseJson.data.configs.length <= 0
          ) {
            this.timeoutId = setTimeout(() => {
              this.getRefreshStatus(fromDidMount);
            }, 15000);
          }

          await responseJson.data.configs.map((key) => {
            if (
              key.status.toLowerCase().includes("fetching") &&
              (key.refreshResourceIndex === 1 ||
                (key.refreshResourceIndex & 4) === 4)
            ) {
              isFetching = true;
            }
          });
          this.setState({ getAllConfigData: responseJson.data.configs });

          // this.handleAsplistRequest(fromDidMount);
          if (!isFetching) {
            await this.setState({
              refreshFlag: false,
              selectRow: { ...this.state.selectRow, hideSelectAll: false },
            });
            if (this.state.refreshTimerId !== -1) {
              clearInterval(this.state.refreshTimerId);
              await this.setState({
                refreshTimerId: -1,
                toggleStopRefreshModal: false,
              });
            }
            await this.props.doneRefresh(4);
          } else {
            await this.setState({
              refreshFlag: true,
              selectRow: { ...this.state.selectRow, hideSelectAll: true },
            });
            if (this.state.refreshTimerId === -1) {
              const timer = setInterval(() => {
                this.getRefreshStatus(fromDidMount);
                this.handleAsplistRequest(fromDidMount);
              }, 10000);
              await this.setState({
                refreshTimerId: timer,
              });
            }
          }
          this.isDisabled();
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          if (this.state.refreshTimerId !== -1) {
            clearInterval(this.state.refreshTimerId);
          }
          await this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            isRedirect: true,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
          await this.props.doneRefresh(4);
        } else {
          if (this.state.refreshTimerId !== -1) {
            clearInterval(this.state.refreshTimerId);
          }
          await this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            isRedirect: true,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
          await this.props.doneRefresh(4);
        }
      })
      .catch((error) => {
        if (this.state.refreshTimerId !== -1) {
          clearInterval(this.state.refreshTimerId);
          this.setState({
            refreshTimerId: -1,
            refreshFlag: false,
            selectRow: { ...this.state.selectRow, hideSelectAll: false },
          });
          this.props.doneRefresh(4);
        }
        console.error(error);
        this.setState({
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  async isDisabled() {
    const { reportData, isFreeTrialAnalysisStarted } = this.state;
    let isViewReportDisabled = false;
    let isAnalysisDisabled = false;
    let isResetDisabled = false;
    let recommendationDisabled = false;
    if (reportData.length === 0) {
      isViewReportDisabled = true;
      isAnalysisDisabled = true;
      isResetDisabled = true;
      recommendationDisabled = true;
    } else {
      reportData.map((key) => {
        var en =
          Array.isArray(this.state.value) &&
          this.state.value.find((e) => e.basicAspId === key.basicASPId);
        switch (key.aspState) {
          case "NOT ANALYZED":
            isViewReportDisabled = true;
            isResetDisabled = true;
            recommendationDisabled = true;
            break;
          case "ANALYZING":
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            recommendationDisabled = true;
            //                        if (en.analysisSubState !== null && en.analysisSubState !== undefined && en.analysisSubState !== "" && en.analysisSubState.toLowerCase().includes("retrieving")) {
            //                            isResetDisabled = true;
            //                        }
            break;
          case "ANALYSIS ISSUE":
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            recommendationDisabled = true;
            break;
          case "ANALYZED":
            isAnalysisDisabled = true;
            break;
          case "OPTIMIZING":
            isAnalysisDisabled = true;
            break;
          case "OPTIMALLY SIZED":
          case "CUSTOM-SIZED":
            recommendationDisabled = true;
            isAnalysisDisabled = true;
            break;
          case "RESETTING":
            recommendationDisabled = true;
            isAnalysisDisabled = true;
            isViewReportDisabled = true;
            isResetDisabled = true;
        }
      });
    }

    //Free Trial:- Only allowed resets for analysis issue and analysis failure VM states

    if (this.freeTrialPlanSupport()) {
      var disabledResetBtn = reportData.some(
        (e) =>
          e.aspState !== "ANALYSIS ISSUE" &&
          e.aspState !== "ANALYSIS INELIGIBLE"
      );
      if (disabledResetBtn) {
        isResetDisabled = true;
      }

      var disabledAnalysisBtn = reportData.some(
        (key) => key.isaspanalyzable !== isFreeTrialAnalysisStarted
      );

      if (disabledAnalysisBtn) {
        isAnalysisDisabled = true;
      }
    }

    await this.setState({
      isReport: this.freeTrialPlanSupport() ? true : isViewReportDisabled,
      isPerformAnalysis: isAnalysisDisabled,
      isReset: isResetDisabled,
      isManageResize: this.freeTrialPlanSupport()
        ? true
        : recommendationDisabled,
    });

    if (this.state.refreshFlag) {
      reportData.map((key) => {
        if (key.aspState === "ANALYZED") {
          this.setState({ isReport: false });
        }
      });
    }
  }

  async preSelectVM() {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(this.state.value) &&
      this.state.value.map(async (asp) => {
        if (
          this.state.result !== undefined &&
          this.state.result.includes(asp.basicAspId)
        ) {
          await this.setState({
            result: [...this.state.result, asp.basicAspId],
            reportData: [
              ...this.state.reportData,
              {
                basicASPId: asp.basicAspId,
                analysisSeqNum: asp.analysisseqnum,
                aspState: asp.status,
                accountName: asp.accountName,
                status_change_datetime: asp.status_change_datetime,
                name: asp.name,
                vmResultKeyMap: asp.aspResultKeyMap,
                resizePerfType: asp.resizeperftype,
                isaspanalyzable: asp.isaspanalyzable,
              },
            ],
          });
        }
      });

    await this.setState({ result: [...new Set(this.state.result)] });
    if (this.state.reportData.length > 1) {
      await this.setState({
        reportData: this.removeDuplicates(this.state.reportData, "basicASPId"),
      });
    }
    if (this.state.reportData.length !== 0) {
      await this.isDisabled();
    } else {
      this.setState({ isReport: true });
    }
  }
  fillupAccountFilter = () => {
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          let accountlist = [];
          let accountselValList = [];
          let accNameLst = [];
          for (var i = 0; i < responseJson.data.configs.length; i++) {
            accountlist.push({
              label: responseJson.data.configs[i].accountname,
              value: "" + i,
            });
            accountselValList.push("" + i);
            accNameLst.push(responseJson.data.configs[i].accountname);
          }
          this.setState({
            accountList: accountlist,
            accountValList: accountselValList,
            accountNameList: accNameLst,
          });
          this.handleAsplistRequest(true);
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
  async handleAccount(key) {
    let accNameList = [];
    let accountselValList = [];
    if (key !== null && key !== undefined) {
      if (key.name === "selectedAll") {
        if (key.isChecked === true) {
          this.state.accountList.map((data) => {
            if (!accNameList.includes(data.label)) {
              accNameList.push(data.label);
            }
            if (!accountselValList.includes(data.value)) {
              accountselValList.push(data.value);
            }
          });
        }
      } else {
        accNameList = this.state.accountNameList.slice();
        accountselValList = this.state.accountValList.slice();
        if (
          key.name === "select" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterNm = key.itemData.label;
          let filterVal = key.itemData.value;
          if (!accNameList.includes(filterNm)) {
            await accNameList.push(filterNm);
          }
          if (!accountselValList.includes(filterVal)) {
            await accountselValList.push(filterVal);
          }
        } else if (
          key.name === "removed" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterNm = key.itemData.label;
          let filterVal = key.itemData.value;
          if (accNameList.includes(filterNm)) {
            let idx1 = accNameList.indexOf(filterNm);
            accNameList.splice(idx1, 1);
          }
          if (accountselValList.includes(filterVal)) {
            let idx = accountselValList.indexOf(filterVal);
            accountselValList.splice(idx, 1);
          }
        }
      }
      await this.setState({
        accountNameList: accNameList,
        accountValList: accountselValList,
        modalLoader: true,
      });
      this.handleAsplistRequest();
    }
  }

  async handleFilter(key) {
    let filterlist = [];
    let filterValList = [];
    if (key !== null && key !== undefined) {
      if (key.name === "selectedAll") {
        if (key.isChecked === true) {
          this.state.AspFilters.map((data) => {
            filterlist.push(data.label.toUpperCase());
            filterValList.push(data.value);
          });
        }
      } else {
        filterlist = this.state.filterList.slice();
        filterValList = this.state.AspFiltersValues.slice();
        if (
          key.name === "select" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterNm = key.itemData.label.toUpperCase();
          let filterVal = key.itemData.value;
          if (!filterlist.includes(filterNm)) {
            await filterlist.push(filterNm);
          }
          if (!filterValList.includes(filterVal)) {
            await filterValList.push(filterVal);
          }
        } else if (
          key.name === "removed" &&
          key.e !== null &&
          key.e.type === "mouseup"
        ) {
          let filterNm = key.itemData.label.toUpperCase();
          let filterVal = key.itemData.value;
          if (filterlist.includes(filterNm)) {
            let idx1 = filterlist.indexOf(filterNm);
            filterlist.splice(idx1, 1);
          }
          if (filterValList.includes(filterVal)) {
            let idx = filterValList.indexOf(filterVal);
            filterValList.splice(idx, 1);
          }
        }
      }
      await this.setState({
        filterList: filterlist,
        AspFiltersValues: filterValList,
        modalLoader: true,
      });
      this.handleAsplistRequest();
    }
  }

  toggleTable = () => {
    this.setState((prevState) => ({
      modalTable: !prevState.modalTable,
    }));
  };

  toggleNext = () => {
    this.setState((prevState) => ({
      togglemodalNext: !prevState.togglemodalNext,
    }));
  };

  toggleManageResizePopup = () => {
    if (!this.state.toggleManageResizeModal) {
      this.setState({ modalLoader: true });
      var seqs = [];
      this.state.reportData.map(async (key) => {
        seqs.push(key.analysisSeqNum);
      });
      fetch(Constdata.ASP_ANALYSIS_COMPACT_DETAILD, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(seqs),
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson.success === true) {
            var manageValData = this.state.reportData;
            Object.keys(responseJson.data).map((key, i) => {
              manageValData.forEach(function (vm) {
                if (vm.basicASPId == key) {
                  vm["aggressive"] = responseJson.data[key].aggressive;
                  vm["balanced"] = responseJson.data[key].balanced;
                  vm["conservative"] = responseJson.data[key].conservative;
                  vm["validationPerfType"] = null;
                  vm["resizePerfType"] = vm.resizePerfType;
                  vm["isresizecomplete"] =
                    responseJson.data[key].isresizecomplete;
                }
              });
            });
            await this.setState((prevState) => ({
              manageModalData: manageValData,
              modalLoader: false,
              toggleManageResizeModal: !prevState.toggleManageResizeModal,
            }));
          } else if (
            responseJson.status === 403 ||
            responseJson.status === 401
          ) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.SessionExpiredText,
              isErrorHeader: false,
            });
          } else {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: responseJson.message,
              isErrorHeader: false,
              isRedirect: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.CacheErrorText,
            isErrorHeader: false,
            isRedirect: false,
          });
        });
    } else {
      this.setState((prevState) => ({
        manageModalData: [],
        toggleManageResizeModal: !prevState.toggleManageResizeModal,
      }));
    }
  };

  async toggleReason(Vm) {
    if (Vm.basicAspId === undefined) {
      await this.setState({ curIneligibleAsp: null });
    } else {
      await this.setState({ curIneligibleAsp: Vm });
    }
    this.setState((prevState) => ({
      modalReason: !prevState.modalReason,
    }));
  }

  handleReport = () => {
    var rptDtd = [];
    this.state.reportData.map(async (key) => {
      rptDtd.push({
        basicVMId: key.basicASPId,
        analysisSeqNum: key.analysisSeqNum,
        status: key.aspState,
        accountName: key.accountName,
        status_change_datetime: key.status_change_datetime,
        name: key.name,
      });
    });
    this.props.history.push({
      pathname: "/report",
      state: { data: rptDtd, isAsp: true, entityType: "asp", listTb: "2" },
    });
  };

  toggleManageResizeInfo = () => {
    this.setState((prevState) => ({
      manageResizeInfoModal: !prevState.manageResizeInfoModal,
    }));
  };

  togglePerfInfo = () => {
    this.setState((prevState) => ({
      perfInfoModal: !prevState.perfInfoModal,
    }));
  };

  toggleReportInfo = () => {
    this.setState((prevState) => ({
      reportInfoModal: !prevState.reportInfoModal,
    }));
  };

  toggleResetInfo = () => {
    this.setState((prevState) => ({
      resetInfoModal: !prevState.resetInfoModal,
    }));
  };

  async handleAsplistRequest(isFromdidMount) {
    this.props.registerListners(true);
    if (isFromdidMount !== undefined && isFromdidMount === true) {
      await this.setState({ isFromDidMount: isFromdidMount });
    } else {
      await this.setState({ isFromDidMount: false });
    }
    if (this.state.clickResetStatus) {
      return false;
    }

    if (this.state.accountNameList.length <= 0) {
      this.setState({ value: false, modalLoader: false });
      return false;
    }

    const payload = {
      accountList: this.state.accountNameList,
      filterList: this.state.filterList,
      start: this.state.privious,
      max: 2147483647,
      searchStr: this.state.SearchText,
    };
    fetch(Constdata.GET_ASP_LIST, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          let fetchingDS = false;
          if (
            this.props.dataSourcePayload &&
            this.props.dataSourcePayload.length >= 1 &&
            this.state.getAllConfigData &&
            this.state.getAllConfigData.length <= 0
          ) {
            fetchingDS = true;
          }

          if (responseJson.data === true) {
            this.timeoutId = setTimeout(() => {
              this.handleAsplistRequest(isFromdidMount);
            }, 15000);
          }

          const isFreeTrialAnalyzed = await this.freeTrialAnalysisList(
            responseJson.orgInfo
          );

          await this.setState({
            value: fetchingDS ? true : responseJson.data,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
            isAspFreeTrialSuccess: responseJson.orgInfo.isAspFreeTrialCompleted,
            userName: responseJson.orgInfo.userName,
            isFreeTrialAnalysisStarted:
              responseJson.orgInfo.plan === "FREE_TRIAL"
                ? isFreeTrialAnalyzed
                : false,
          });
          let isFetching = false;
          var data = [];
          var notEligible = [];
          var notEligibleList1 = [];

          Array.isArray(this.state.value) &&
            this.state.value.map(async (key) => {
              if (key.iseligible === true && this.isStaledsVms(key)) {
                //Free paln:- when user logs in and no analysis is done, select all VM which are not analyzed

                if (
                  this.freeTrialPlanSupport() &&
                  this.state.isFreePlanNonAnalyzedSelected
                ) {
                  if (
                    isFreeTrialAnalyzed === key.isaspanalyzable &&
                    key.status === "NOT ANALYZED"
                  ) {
                    this.setState({
                      result: [...this.state.result, key.basicAspId],
                    });
                  }
                }

                if (this.state.isUpdated === true) {
                  this.state.result.map((value) => {
                    if (value === key.basicAspId) {
                      data.push({
                        basicASPId: key.basicAspId,
                        analysisSeqNum: key.analysisseqnum,
                        aspState: key.status,
                        accountName: key.accountName,
                        status_change_datetime: key.status_change_datetime,
                        name: key.name,
                        vmResultKeyMap: key.aspResultKeyMap,
                        resizePerfType: key.resizeperftype,
                        isaspanalyzable: key.isaspanalyzable,
                      });
                      this.setState({ reportData: data });
                      this.isDisabled();
                    }
                  });
                } else {
                  if (
                    this.state.reportData !== undefined &&
                    this.state.reportData.length > 0
                  ) {
                    var Selection = this.state.reportData.reduce(
                      (acc, obj) =>
                        obj.basicASPId === key.basicAspId ? ++acc : acc,
                      0
                    );
                    if (Selection > 0) {
                      data.push({
                        basicASPId: key.basicAspId,
                        analysisSeqNum: key.analysisseqnum,
                        aspState: key.status,
                        accountName: key.accountName,
                        status_change_datetime: key.status_change_datetime,
                        name: key.name,
                        vmResultKeyMap: key.aspResultKeyMap,
                        resizePerfType: key.resizeperftype,
                        isaspanalyzable: key.isaspanalyzable,
                      });
                    }
                  }
                }

                if (key.status === "ANALYZING" || key.status === "RESETTING") {
                  isFetching = true;
                }
              } else {
                notEligible.push(key.basicAspId);
                notEligibleList1.push(key);
                this.state.value.splice(key, 1);
              }
            });

          await this.setState({
            isFreePlanNonAnalyzedSelected: false,
            fetchFlag: isFetching,
            reportData: data,
            notEligibleAsp: notEligible,
            notEligibleList: notEligibleList1,
          });
          if (!this.state.fetchFlag) {
            if (this.state.timerId !== 0) {
              clearInterval(this.state.timerId);
              await this.setState({ timerId: 0 });
            }
          } else {
            if (this.state.timerId === 0) {
              const timer = setInterval(() => {
                this.handleAsplistRequest();
              }, 10000);
              await this.setState({
                timerId: timer,
                fetchFlag: false,
              });
            }
          }

          await this.isDisabled();
          if (isFromdidMount !== undefined && isFromdidMount === true) {
            await this.preSelectVM();
          }
          await this.myFunction();
          if (!this.state.clickResetStatus) {
            await this.setState({ modalLoader: false });
          }
        } else if (
          responseJson.success === false &&
          responseJson.message === "No organization found"
        ) {
          await this.setState({ fetchFlag: false });
          if (this.state.timerId !== 0) {
            clearInterval(this.state.timerId);
            await this.setState({ timerId: 0 });
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          if (this.state.timerId === 0) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.SessionExpiredText,
              isErrorHeader: false,
            });
          } else {
            clearInterval(this.state.timerId);
            await this.setState({ fetchFlag: false, timerId: 0 });
          }
        } else {
          if (this.state.timerId === 0) {
            this.setState({
              modalLoader: false,
              errorPopup: true,
              errormessage: message.ServerError,
              isErrorHeader: false,
              isRedirect: false,
            });
          } else {
            clearInterval(this.state.timerId);
            await this.setState({ fetchFlag: false, timerId: 0 });
          }
        }
      })
      .catch((error) => {
        if (this.state.timerId !== 0) {
          clearInterval(this.state.timerId);
        }
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }

  async checkAll(isSelect, rows, e) {
    var data = [];
    var basicAspData = [];
    if (isSelect) {
      Array.isArray(this.state.value) &&
        this.state.value.map((asp) => {
          if (asp.iseligible === true && this.isStaledsVms(asp)) {
            data.push({
              basicASPId: asp.basicAspId,
              analysisSeqNum: asp.analysisseqnum,
              aspState: asp.status,
              accountName: asp.accountName,
              status_change_datetime: asp.status_change_datetime,
              name: asp.name,
              vmResultKeyMap: asp.aspResultKeyMap,
              resizePerfType: asp.resizeperftype,
              isaspanalyzable: asp.isaspanalyzable,
            });
            basicAspData.push(asp.basicAspId);
          }
        });
      await this.setState({
        reportData: data,
        result: basicAspData,
        isChecked: true,
      });
    } else {
      await this.setState({
        result: [],
        reportData: [],
        isChecked: false,
      });
    }
    await this.isDisabled();
    await this.myFunction();
  }

  async myFunction() {
    // this.setState({ SearchText: e.target.value });
    var input, filter, table, tr, td, cell, i;
    input = document.getElementById("myAspInput");
    if (input !== null && input !== undefined) {
      filter = input.value.toUpperCase();
      table = document.getElementsByClassName("aspTbl")[0];
      tr = table.getElementsByTagName("tr");

      for (i = 1; i < tr.length; i++) {
        // Hide the row initially.
        tr[i].style.display = "none";

        td = tr[i].getElementsByTagName("td");
        for (var j = 0; j < td.length; j++) {
          cell = tr[i].getElementsByTagName("td")[j];

          if (cell) {
            if (cell.innerHTML.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
              break;
            }
          }
        }
      }
    }
  }

  responseProcess = async (response) => {
    if (response.success === true) {
      await this.setState({
        togglemodalNext: false,
        isAspFreeTrialSuccess: response.orgInfo.isAspFreeTrialCompleted,
      });
      await this.handleAsplistRequest();
      if (this.freeTrialPlanSupport()) {
        await this.isDisabled();
        await this.preSelectVM();
      }
    } else if (response.success === false) {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: response.message,
        isErrorHeader: false,
        isRedirect: false,
      });
    } else if (response.status === 403 || response.status === 401) {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: message.SessionExpiredText,
        isErrorHeader: false,
      });
    } else {
      await this.setState({
        togglemodalNext: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    }
  };

  handleNext = async () => {
    const { reportData } = this.state;
    const timezone = jstz.determine();
    const timezoneid = timezone.name();
    let resultPayload = reportData.map((asp) => asp.basicASPId);
    await this.setState({ modalLoader: true });
    const payload = {
      userGtmId: timezoneid,
      basicASPIDList: resultPayload,
    };
    fetch(Constdata.START_ASP_ANALYSIS_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.responseProcess(responseJson);
      })
      .catch((error) => {
        if (this.state.timerId !== 0) {
          clearInterval(this.state.timerId);
        }
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  handelReset = async () => {
    this.setState({
      modalLoader: true,
      modalReset: false,
      clickResetStatus: true,
    });
    var seqs = [];
    this.state.reportData.map(async (key) => {
      seqs.push({
        basicASPId: key.basicASPId,
        uniqueSeqNum: key.analysisSeqNum,
        aspState: key.aspState,
        accountName: key.accountName,
        status_change_datetime: key.status_change_datetime,
        name: key.name,
        vmResultKeyMap: key.aspResultKeyMap,
        resizePerfType: key.resizeperftype,
      });
    });
    fetch(Constdata.RESET_ASP_ANALYSIS_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(seqs),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          await this.setState({
            clickResetStatus: false,
            isUpdated: true,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
            isAspFreeTrialSuccess: responseJson.orgInfo.isAspFreeTrialCompleted,
            userName: responseJson.orgInfo.userName,
          });
          await this.handleAsplistRequest();
          if (this.freeTrialPlanSupport()) {
            await this.isDisabled();
            await this.preSelectVM();
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: false,
            modalReset: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };
  freeTrialPlan() {
    this.setState({
      freeTrailPopup: true,
      errorPopup: true,
      errormessage: "Only Available in Paid Plan",
      isErrorHeader: true,
    });
  }

  freeTrialPlanSupport() {
    if (this.state.isFreeTrial) {
      if (this.state.userName === "support@serralabs.com") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isStaledsVms(asp) {
    return (
      asp.isstaleds === false ||
      (asp.isstaleds === true && asp.status === "ANALYZED")
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataSourceResponse !== this.props.dataSourceResponse) {
      this.handleAsplistRequest(true);
    }
  }
  async freeTrialAnalysisList(orgData) {
    if (orgData && orgData.freeTrialAnalysisList !== null) {
      return await orgData.freeTrialAnalysisList.includes("A");
    }
  }
  render() {
    const num = 1000;
    const num2 = 1024;
    let ineligibleCount = this.state.notEligibleAsp.length;
    const analysisConfirmStr = `Please confirm to perform analysis of the selected ASP :  \n ${this.state.reportData.map(
      (asp) => {
        return asp.name;
      }
    )}`;
    return (
      <div>
        <div className="container-verf" role="main">
          <div
            className="pageBgWhite vmFilterDiv"
            style={{ textAlign: "left" }}
          >
            <div
              style={{
                padding: "10px 20px",
                alignItem: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "25%" }}>
                <Input
                  className="form-control"
                  id="myAspInput"
                  placeholder="Search ASPs"
                  onKeyUp={this.myFunction}
                  disabled={this.state.refreshFlag}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {ineligibleCount > 0 ? (
                  <span>
                    {" "}
                    <label>
                      <b>ASP Table</b>
                    </label>
                    <i
                      className="fa fa-warning"
                      onClick={this.toggleTable}
                      style={{
                        fontSize: "20px",
                        color: "#e6da12",
                        paddingLeft: "5px",
                      }}
                      data-toggle="tooltip"
                      data-placement="right"
                      title="View ineligible App Service Plans"
                    >
                      {" "}
                    </i>
                  </span>
                ) : null}
              </div>

              <div className="multiSelectFilter">
                <div style={{ paddingRight: "5px" }}>
                  <label className="labelColor">Filter by Status:</label>
                </div>
                <div style={{ width: "75%" }}>
                  <MultiSelectComponent
                    id="checkbox"
                    cssClass={
                      !this.state.refreshFlag
                        ? "multiSelectFilter-input"
                        : "multiSelectFilter-input-disabled"
                    }
                    dataSource={this.state.AspFilters}
                    fields={{ text: "label", value: "value" }}
                    placeholder="Filter by Status"
                    mode="CheckBox"
                    selectAllText="All"
                    unSelectAllText="All"
                    showSelectAll={true}
                    value={[...new Set(this.state.AspFiltersValues)]}
                    closePopupOnSelect={true}
                    enableSelectionOrder={false}
                    showDropDownIcon={true}
                    selectedAll={(value) => this.handleFilter(value)}
                    select={(value) => this.handleFilter(value)}
                    removed={(value) => this.handleFilter(value)}
                    enabled={!this.state.refreshFlag}
                  >
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </div>
              </div>
            </div>
          </div>
          <div className="pageBgWhite vmListDiv">
            <div
              class="btn-toolbar pageBgWhite"
              role="toolbar"
              aria-label="Toolbar with button groups"
              style={{
                display: "flex",
                paddingBottom: "10px",
                paddingTop: "10px",
                position: "sticky",
                top: "61px",
                zIndex: "110",
                justifyContent: "flex-end",
              }}
            >
              <div class="btn-group" role="group" aria-label="First group">
                <Button
                  onClick={this.toggleNext}
                  id={
                    this.state.isPerformAnalysis === true
                      ? "listDisabledBtn"
                      : "listBtn"
                  }
                  disabled={this.state.isPerformAnalysis}
                >
                  Perform Analysis
                </Button>
                <Button
                  className="btn listInfoBtn"
                  onClick={this.togglePerfInfo}
                >
                  <i className="fa fa-info" />
                </Button>
              </div>
              <div
                class="btn-group"
                role="group"
                aria-label="Second group"
                style={{ marginLeft: "10px" }}
              >
                <Button
                  onClick={this.toggleManageResizePopup}
                  id={
                    this.state.isManageResize === true
                      ? "listDisabledBtn"
                      : "listBtn"
                  }
                  disabled={this.state.isManageResize}
                >
                  Manage Resizing
                </Button>
                <Button
                  className="btn listInfoBtn"
                  onClick={this.toggleManageResizeInfo}
                >
                  <i className="fa fa-info" />
                </Button>
              </div>
              <div
                class="btn-group"
                role="group"
                aria-label="Third group"
                style={{ marginLeft: "10px" }}
              >
                <Button
                  onClick={this.handleReport}
                  id={
                    this.state.isReport === true ? "listDisabledBtn" : "listBtn"
                  }
                  disabled={this.state.isReport}
                >
                  View Report
                </Button>
                <Button
                  className="btn listInfoBtn"
                  onClick={this.toggleReportInfo}
                >
                  <i className="fa fa-info" />
                </Button>
              </div>
              <div
                class="btn-group"
                role="group"
                aria-label="Fourth group"
                style={{ marginLeft: "10px" }}
              >
                <Button
                  onClick={this.toggleReset}
                  id={
                    this.state.isReset === true ? "listDisabledBtn" : "listBtn"
                  }
                  disabled={this.state.isReset}
                  style={{ width: "130px" }}
                >
                  Reset State
                </Button>
                <Button
                  className="btn listInfoBtn"
                  onClick={this.toggleResetInfo}
                >
                  <i className="fa fa-info" />
                </Button>
              </div>
            </div>

            <BootstrapTable
              bordered={false}
              id="list_table"
              bodyClasses="tbodyColor"
              classes="table-responsive list_table_height aspTbl"
              bootstrap4={true}
              keyField="basicAspId"
              data={Array.isArray(this.state.value) ? this.state.value : []}
              columns={this.state.columns}
              selectRow={{
                mode: "checkbox",
                clickToSelect: false,
                clickToExpand: true,
                // hideSelectAll: this.state.refreshFlag,
                onSelect: this.onChange,
                onSelectAll: this.checkAll,
                selected: this.state.result,
              }}
              hiddenRows={this.state.notEligibleAsp}
              expandRow={this.state.expandRow}
              wrapperClasses="table-responsive"
              noDataIndication={() =>
                (this.state.isFromDidMount && this.state.value === true) ||
                (!this.state.isFromDidMount &&
                  this.state.value === true &&
                  !this.state.refreshFlag)
                  ? "Fetching ASPs"
                  : (this.state.isFromDidMount &&
                      (this.state.value === false ||
                        this.state.value.length === 0)) ||
                    (!this.state.isFromDidMount &&
                      (this.state.value === false ||
                        this.state.value.length === 0) &&
                      !this.state.refreshFlag)
                  ? "No ASPs Found"
                  : "   "
              }
              loading={this.state.refreshFlag}
              // overlay={overlayFactory({
              //   spinner: true,
              //   text: "Refreshing ASP Table...",
              //   background: "rgb(204, 208, 209,0.3)",
              // })}
            />
          </div>
        </div>

        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        <Modal
          isOpen={this.state.modalTable}
          toggle={this.toggleTable}
          size="xl"
          backdrop={"static"}
        >
          <ModalHeader
            toggle={this.toggleTable}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">
              List of ineligible ASPs for Analysis
            </div>
          </ModalHeader>
          <ModalBody>
            <Table id="list_table" bordered>
              <thead>
                <tr>
                  <th>ASP Name</th>
                  <th style={{ width: "140px" }}>Metric Data Source</th>
                  <th>OS Type</th>
                  <th>Pricing Tier</th>
                  <th>Region</th>
                  <th>Apps</th>
                  <th>CPU ACUs</th>
                  <th>Memory (GB)</th>
                  <th>Max. Storage (GB)</th>
                  <th style={{ fontSize: "14px", width: "164px" }}>
                    Aggregate Disk IOPS/Throughput Limits
                  </th>
                  <th style={{ width: "150px" }}>
                    Aggregate Network Bandwidth Limit
                  </th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {this.state.notEligibleList &&
                  this.state.notEligibleList.map((asp, i) => {
                    return (
                      <tr id="list_tablerow">
                        <td className="textfield" key={asp.basicAspId}>
                          {asp.name}
                        </td>
                        <td className="textfield">{asp.accountName}</td>
                        <td className="textfield">{asp.osType}</td>
                        <td className="textfield">{asp.pricingTier}</td>
                        <td className="textfield">{asp.region}</td>
                        <td className="numberfield">{asp.numApps}</td>
                        <td className="numberfield">{asp.cpuAcus}</td>
                        <td className="numberfield">{asp.maxMemoryGiB} GiB</td>
                        <td className="numberfield">{asp.maxStorageGiB} GiB</td>
                        <td className="numberfield">
                          {asp.diskIOPSCount}/{asp.diskThroughputMBps} MBps
                        </td>
                        <td className="numberfield">
                          {asp.networkBandwidthMbps}Mbps
                        </td>
                        <td className="numberfield">
                          {asp.isstaleds === true ? (
                            <div>{message.IsStaledsMsg}</div>
                          ) : (
                            <Button
                              color="link"
                              onClick={() => this.toggleReason(asp)}
                            >
                              View
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btnBg"
              onClick={() => {
                this.toggleTable();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalReason}
          toggle={this.toggleReason}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
            toggle={this.toggleReason}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle"> Ineligibility Reasons </div>
          </ModalHeader>
          <ModalBody>
            <Table id="list_table" bordered>
              <thead>
                <tr>
                  <th>Reasons</th>
                </tr>
              </thead>
              <tbody>
                {this.state.curIneligibleAsp !== undefined &&
                this.state.curIneligibleAsp !== null &&
                this.state.curIneligibleAsp.basicAspId !== undefined &&
                this.state.curIneligibleAsp.numApps === 0 ? (
                  <tr>
                    <td style={{ padding: "15px" }}>
                      <div>
                        <div>
                          <b>No Apps found under this app service plan</b>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {this.state.curIneligibleAsp !== undefined &&
                this.state.curIneligibleAsp !== null &&
                this.state.curIneligibleAsp.basicAspId !== undefined &&
                (this.state.curIneligibleAsp.pricingTier.includes("Free") ||
                  this.state.curIneligibleAsp.pricingTier.includes(
                    "Shared"
                  )) ? (
                  <tr>
                    <td style={{ padding: "15px" }}>
                      <b>
                        App Service Plans with Free and Shared pricing tiers are
                        not eligible for analysis
                      </b>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          </ModalBody>
        </Modal>
        <Modal
          toggle={this.toggleNext}
          size="lg"
          isOpen={this.state.togglemodalNext}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
            toggle={this.toggleNext}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">ASP Analysis Confirmation</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel" id="modaldis_list">
                <div>
                  <p>
                    {" "}
                    {this.state.reportData.length > 1 ? (
                      " Please confirm to perform analysis of " +
                      this.state.reportData.length +
                      " selected ASPs"
                    ) : (
                      <div className="new-line">{analysisConfirmStr}</div>
                    )}
                  </p>
                </div>
              </div>
              <div className="footnotePopup" style={{ marginBottom: "20px" }}>
                {this.freeTrialPlanSupport() ? (
                  <div
                    style={{ paddingBottom: "7px" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        "In Free Trial, it is highly recommended that all unanalyzed ASP be rightsize analyzed so that you can get an overall view of possible savings and performance improvement using rightsizing for your cloud.",
                    }}
                  />
                ) : null}
                <div
                  dangerouslySetInnerHTML={{
                    __html: message.PerformAnalysisConfirmNote,
                  }}
                />
              </div>
              <Form>
                <FormGroup>
                  <div align="center">
                    <Button id="confirmbtn_list" onClick={this.handleNext}>
                      Yes
                    </Button>
                    <Button
                      outline
                      id="canclebtn_list"
                      onClick={() => {
                        this.toggleNext();
                      }}
                    >
                      No
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size="xl"
          style={{ maxWidth: "1600px", width: "95%", margin: "10px auto" }}
          isOpen={this.state.toggleManageResizeModal}
          toggle={this.toggleManageResizePopup}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleManageResizePopup}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">
              Select Optimization Objective for Resizing
            </div>
          </ModalHeader>
          <ModalBody>
            <ManageUserRecommendation
              cancelUserResize={this.toggleManageResizePopup}
              data={this.state.manageModalData}
              handleFetch={this.handleAsplistRequest}
              isAsp={true}
              entityType={"asp"}
            />
          </ModalBody>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.perfInfoModal}
          toggle={this.togglePerfInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.togglePerfInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Perform Analysis</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.PerformASPAnalysisInfoText,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.StartASPAnalysisFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.togglePerfInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.manageResizeInfoModal}
          toggle={this.toggleManageResizeInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleManageResizeInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Manage Resizing</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.ManageASPResizingInfoText,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.ManageASPResizingFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleManageResizeInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.reportInfoModal}
          toggle={this.toggleReportInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleReportInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">View Report</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: message.GenerateASPReportInfoText0,
              }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.ShowASPReportFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleReportInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.resetInfoModal}
          toggle={this.toggleResetInfo}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleResetInfo}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Reset State</div>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{ __html: message.ResetASPInfoText }}
            />
            <div className="footnotePopup">
              <div
                dangerouslySetInnerHTML={{
                  __html: message.ResetASPStateFootNote,
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button id="confirmbtn_list" onClick={this.toggleResetInfo}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalReset}
          toggle={this.toggleReset}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
            toggle={this.toggleReset}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle"> Reset State </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel" id="modaldis_list">
                <div>
                  <p style={{ padding: "0px 74px" }}>
                    {this.state.reportData.length > 1
                      ? "Please confirm to reset state of " +
                        this.state.reportData.length +
                        " ASPs."
                      : "Please confirm to reset state of the selected ASP : " +
                        (this.state.reportData[0] !== undefined
                          ? this.state.reportData[0].name
                          : "")}
                  </p>{" "}
                </div>
              </div>
              <Form>
                <FormGroup>
                  <div align="center">
                    <Button id="confirmbtn_list" onClick={this.handelReset}>
                      Yes
                    </Button>
                    <Button
                      id="canclebtn_list"
                      onClick={() => {
                        this.toggleReset();
                      }}
                    >
                      No
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataSourcePayload: state.dataSourcePayload.payload,
    companyDetails: state.companyDetails.data,
    dataSourceResponse: state.dataSourceRes.dataSourceResponse,
  };
};
export default connect(
  mapStateToProps,
  null
)(withRouter(ManageAspOptimizations));
