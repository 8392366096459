/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';
import { Input, Button, Row, Label} from 'reactstrap';
import * as Constdata from '../../Constants/ApiConstants';
import SerraLoader from '../CommonComponent/Loader';
import SerraError from '../CommonComponent/Error';
import * as message from '../../Constants/MessageConstant';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jstz from 'jstz';

class OnDemandMetrics extends Component {
    constructor(props) {
        super(props);
        this.initiateODMetrics = this.initiateODMetrics.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.toggle = this.toggle.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.changeObjValue = this.changeObjValue.bind(this);
        this.handleVmlistRequest = this.handleVmlistRequest.bind(this);
        this.state = {
            vmlist: [],
            startDate: null,
            endDate: null,
            minEndDate: null,
            dropdownOpen: false,
            setDropdownOpen: false,
            basicvmId: 0,
            obj:"a",
            modalLoader: false,
            errorPopup: false,
            errormessage: "",
            isErrorHeader: false,
            filterList: [],
            VmFilters: [{label: 'Analyzed', value: "1"},
                {label: 'Optimally Sized', value: "2"},
                {label: 'Not Analyzed', value: "3"},
                {label: 'Analyzing', value: "4"},
                {label: 'Analysis Issue', value: "5"},
                {label: 'Optimizing', value: "6"},
                {label: 'Custom-Sized', value: "7"}
            ],
            objectives: [{label: 'Economical', value: "a"},
                {label: 'Balanced', value: "b"},
                {label: 'Enhanced', value: "c"}
            ],
            accountlist: [],
            closeODModal: false
        };
    }

    componentWillMount() {
        for (var i = 0; i < this.state.VmFilters.length; i++) {
            this.state.filterList.push(this.state.VmFilters[i].label.toUpperCase());
        }
    }

    componentDidMount() {
        fetch(Constdata.GETALLACCOUNT_URL, {
            method: 'GET',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then((response) => response.json())
                .then(async (responseJson) => {
                    if (responseJson.success === true) {
                        let accountlist = [];
                        for (var i = 0; i < responseJson.data.configs.length; i++) {
                            accountlist.push(responseJson.data.configs[i].accountname);
                        }
                        await this.setState({accountList: accountlist});

                        await this.handleVmlistRequest();
                    } else if (responseJson.status === 403 || responseJson.status === 401) {
                        this.setState({isRedirect: true, modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false});
                    }
                })
                .catch((error) => {
                    this.setState({modalLoader: false, isErrorHeader: false, isRedirect: false});
                });
    }

    setStartDate = async (date) => {
        if (date !== null) {
            date.setSeconds(0);
            date.setMilliseconds(0);
            await this.setState({startDate: date});
        }
    }
    setEndDate = async (date) => {
        if (date !== null) {
            date.setSeconds(0);
            date.setMilliseconds(0);
            this.setState({endDate: date});
        }
    }

    initiateODMetrics = async() => {
        if(this.state.startDate.getTime() >= this.state.endDate.getTime()){
            await this.setState({modalLoader: false, errorPopup: true, errormessage: "Start date/time can not be greater than or equals to End date/time", isErrorHeader: false, isRedirect: true});
            return;
        }
        this.setState({modalLoader: true});
        const timezone = jstz.determine();
        const userGtmId = timezone.name();
        let vmn = "";
        this.state.vmlist.map((e, i) => {
            if(e.basicvm_id === this.state.basicvmId){
                vmn = e.name;
            }
        });
        const payload = {
            basicvmId: this.state.basicvmId,
            analyzedBasicVmId: this.props.analysisStatus.basicvmid,
            vmName: vmn,
            analysisSeqNum: this.props.analysisStatus.analysisseqnum,
            userGMTId: userGtmId,
            startDate: this.state.startDate.getTime(),
            endDate: this.state.endDate.getTime(),
            objective: this.state.obj
        };
        fetch(Constdata.TEST_ANALYSIS, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(payload)
        }).then((response) => response.json())
                .then(async (responseJson) => {
                    if (responseJson.success === true) {
                        await this.setState({modalLoader: false, closeODModal: true, errorPopup: true, errormessage: responseJson.message, isErrorHeader: true, isRedirect: true});
                    } else if (responseJson.status === 403 || responseJson.status === 401) {
                        await this.setState({modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false, isRedirect: true});
                    } else {
                        await this.setState({modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader: false, isRedirect: false});
                    }
                }).catch((error) => {
            this.setState({modalLoader: false, errorPopup: true, errormessage: message.CacheErrorText, isErrorHeader: false, isRedirect: false});
        });
    }

    changeValue = (e) => {
        let id = e.target.value;
        this.setState({basicvmId: id});
    }

    changeObjValue = (e) => {
        let ob = e.target.value;
        this.setState({obj: ob});
    }

    toggle = () => {
        this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen
            }));
    }

    async handleVmlistRequest() {
        const payload = {
            accountList: this.state.accountList,
            filterList: this.state.filterList,
            start: 0,
            max: 2147483647,
            searchStr: ""
        };
        fetch(Constdata.PPUVMLIST_URL, {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(payload)
        }).then((response) => response.json())
                .then(async (responseJson) => {
                    if (responseJson.success === true) {
                        await this.setState({vmlist: responseJson.data, modalLoader: false});
                    } else if (responseJson.status === 403 || responseJson.status === 401) {
                        await this.setState({modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false, isRedirect: true});
                    } else {
                        await this.setState({modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader: false, isRedirect: false});
                    }
                }).catch((error) => {
            this.setState({modalLoader: false, errorPopup: true, errormessage: message.CacheErrorText, isErrorHeader: false, isRedirect: false});
        });

    }

    setErrorPopupState = (event) => {
        this.setState({errorPopup: event});
        if (this.state.closeODModal === true) {
            this.props.cancelOnDemand();
        }
    }

    render() {
        return (
                <div>
                    <Row>
                        <div style={{width: "100%", marginBottom: "15px", display: "flex"}}>
                            <div style={{width: "20%"}}>
                                <Label for="vmSelect">Select Objective : </Label>
                            </div>
                            <div style={{width: "80%"}}>
                                <Input type="select" name="select" id="objSelect" onChange={this.changeObjValue} value={this.state.obj}>
                                
                                {this.state.objectives.map((e, i) => {
                                        return <option value={e.value} >{e.label}</option>;
                                    })}
                                </Input>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div style={{width: "100%", marginBottom: "15px", display: "flex"}}>
                            <div style={{width: "20%"}}>
                                <Label for="vmSelect">Select Simultor VM : </Label>
                            </div>
                            <div style={{width: "80%"}}>
                                <Input type="select" name="select" id="vmSelect" onChange={this.changeValue} value={this.state.basicvmId}>
                                <option value={0} >---Select VM---</option>;
                                {this.state.vmlist.map((e, i) => {
                                        if (e.iseligible === true) {
                                            return <option value={e.basicvm_id} >{e.name}</option>;
                                        }
                                    })}
                                </Input>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div style={{width: "50%"}}>
                            <div style={{display: "inline-flex", width: "100%"}}>
                                <div style={{width: "40%"}}>
                                    <Label for="vmSelect">Start Date/Time : </Label>
                                </div>
                
                                <div style={{width: "60%"}}>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={date => this.setStartDate(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="MM/dd/yyyy hh:mm aa"     
                                        placeholderText="Select Start Date/Time"
                                        />
                                </div>
                            </div>
                        </div>
                        <div style={{width: "50%"}}>
                            <div style={{display: "inline-flex", width: "100%"}}>
                                <div style={{width: "40%", display: "flex", justifyContent: "center"}}>
                                    <Label>End Date/Time : </Label>
                                </div>
                
                                <div style={{width: "60%"}} >
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onChange={date => this.setEndDate(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="MM/dd/yyyy hh:mm aa"  
                                        placeholderText="Select End Date/Time"
                                        minDate={this.state.startDate}
                                        />
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="prim_But" style={{width: "100%"}} >
                            <Button style={{height: "auto"}}  id={(this.state.startDate !== null && this.state.endDate !== null && this.state.basicvmId !== 0) ? "primaryBtn" : "PPU_listdisabled" } onClick={this.initiateODMetrics} disabled={(this.state.startDate !== null && this.state.endDate !== null && this.state.basicvmId !== 0) ? false : true}>Initiate</Button>
                            <Button onClick={this.props.cancelOnDemand} id="canclebtn_list">Cancel</Button>
                        </div>
                    </Row>
                                    {(this.state.errorPopup === true) ? <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState} isError={this.state.isErrorHeader}></SerraError> : ""}
                                    {(this.state.modalLoader === true) ? <SerraLoader></SerraLoader> : ""}
                </div>
                );
    }

}
export default OnDemandMetrics;

