import React, { Component } from "react";
import { Table, Row, Col } from "reactstrap";
// import './test.css';
import ColorComponent from "./SvgColorOverview";
class RecommendationStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.iconData,
      isAsp: this.props.isAsp !== undefined ? this.props.isAsp : false,
      hostOnly: this.props.data.metricsPlatform === "Host",
      entityType:
        this.props.entityType !== undefined
          ? this.props.entityType
          : this.props.isAsp !== undefined && this.props.isAsp === true
          ? "asp"
          : "vm",
    };
  }
  render() {
    const { data } = this.props;
    return (
      <div>
        <Row>
          <div className="maindivCollapse" style={{ display: "inline-flex" }}>
            <ColorComponent
              data={this.state.data[0].cpu}
              isResize={this.props.isResize}
              isIcon={this.props.isIcon}
              isLine={this.props.isLine}
              isAsp={this.state.isAsp}
              entityType={this.state.entityType}
              showCostPerfMarker={true}
              fromOverview={true}
            >
              {" "}
            </ColorComponent>
            {this.state.entityType === "db" ? (
              <ColorComponent
                data={this.state.data[0].disks}
                isResize={this.props.isResize}
                isIcon={this.props.isIcon}
                isLine={this.props.isLine}
                isAsp={this.state.isAsp}
                entityType={this.state.entityType}
                fromOverview={true}
              ></ColorComponent>
            ) : null}
            <ColorComponent
              data={this.state.data[0].memory}
              isResize={this.props.isResize}
              isIcon={this.props.isIcon}
              isLine={this.props.isLine}
              isAsp={this.state.isAsp}
              hostOnly={this.state.hostOnly}
              entityType={this.state.entityType}
              fromOverview={true}
            ></ColorComponent>
            <ColorComponent
              data={this.state.data[0].network}
              isResize={this.props.isResize}
              isIcon={this.props.isIcon}
              isLine={
                this.state.entityType === "db" ? false : this.props.isLine
              }
              isAsp={this.state.isAsp}
              entityType={this.state.entityType}
              fromOverview={true}
            ></ColorComponent>
            {this.state.entityType !== "db" ? (
              <ColorComponent
                data={this.state.data[0].disks}
                isResize={this.props.isResize}
                isIcon={this.props.isIcon}
                isLine={false}
                isAsp={this.state.isAsp}
                entityType={this.state.entityType}
                fromOverview={true}
              ></ColorComponent>
            ) : null}
          </div>
        </Row>
        <div className="panel">
          <Row>
            <Col id="ToPDF">
              <Table id="tableSizing" bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>Original Spec</th>
                    <th>Recommended Spec</th>
                    <th>Most Recent Spec</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowSpan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbCpuClass"
                                  : "CpuClass"
                              }
                            >
                              {" "}
                              <Col className="tableLeftHead">
                                {this.state.entityType === "db"
                                  ? "vCORE"
                                  : "CPU"}
                              </Col>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td
                            rowSpan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            {key.current_vmsize_name}
                            {key.hypervgeneration
                              ? " (" + key.hypervgeneration + ")"
                              : null}
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td
                            rowSpan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            {key.recommended_vmsize_name}
                            {key.resize_hypervgeneration
                              ? " (" + key.resize_hypervgeneration + ")"
                              : null}
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td
                            rowSpan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            {key.applied_vmsize_name}
                          </td>
                        ) : (
                          ""
                        )}
                        {this.state.isAsp ? (
                          i === 0 ? (
                            <td
                              rowSpan={
                                data.vm.length * (this.state.isAsp ? 4 : 3)
                              }
                            >
                              {key.recommendedVMStatus}
                            </td>
                          ) : (
                            ""
                          )
                        ) : (
                          <td
                            rowSpan={
                              data.vm.length *
                              (this.state.entityType === "db" ? 4 : 3)
                            }
                          >
                            {key.recommendedVMStatus}
                          </td>
                        )}
                      </tr>
                    );
                  })}

                  {this.state.entityType === "db"
                    ? data.osdisk.map((key, i) => {
                        return (
                          <tr>
                            {i === 0 ? (
                              <td rowSpan={data.osdisk.length}>
                                <div className="DbOsClass"></div>
                                <Col className="tableLeftHead">MEMORY</Col>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                    : null}

                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowSpan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbMemoryClass"
                                  : "MemoryClass"
                              }
                            ></div>
                            <Col className="tableLeftHead">
                              {this.state.entityType === "db"
                                ? "DATA DISK"
                                : "MEMORY"}
                            </Col>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowSpan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbNetworkClass"
                                  : "NetworkClass"
                              }
                            ></div>
                            <Col className="tableLeftHead">
                              {this.state.entityType === "db"
                                ? "LOG DISK"
                                : "NETWORK"}
                            </Col>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}

                  {this.state.entityType !== "db"
                    ? data.osdisk.map((key, i) => {
                        return (
                          <tr>
                            {i === 0 ? (
                              <td rowSpan={data.osdisk.length}>
                                <div className="OsClass"></div>
                                <Col className="tableLeftHead">
                                  {this.state.isAsp ? "STORAGE" : "OS Disk"}
                                </Col>
                              </td>
                            ) : (
                              ""
                            )}
                            {this.state.isAsp ? (
                              ""
                            ) : i === 0 ? (
                              <td rowspan={data.osdisk.length}>
                                {key.current_os_disk_name
                                  ? key.current_os_disk_name.toUpperCase()
                                  : key.current_os_disk_name}
                                {<br />}({key.current_os_disk_tier})
                              </td>
                            ) : (
                              ""
                            )}
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td>
                                {key.recommended_os_disk_name}
                                {<br />}({key.recommended_os_disk_tier})
                              </td>
                            )}

                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td>
                                {key.applied_osdisksize_name}
                                {<br />}({key.applied_osdisk_tier})
                              </td>
                            )}
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td>{key.recommendedOSDiskStatus}</td>
                            )}
                          </tr>
                        );
                      })
                    : null}
                  {this.state.entityType !== "db"
                    ? Object.keys(data.dataDisk).map((key) => {
                        return data.dataDisk[key].map((value, i) => {
                          return (
                            <tr>
                              {i === 0 ? (
                                <td rowspan={data.dataDisk[key].length}>
                                  <div className="OsClass" />
                                  <Col className="tableLeftHead">
                                    DISK {key}
                                  </Col>
                                </td>
                              ) : (
                                ""
                              )}
                              <td>
                                {value.current_data_disk_name
                                  ? value.current_data_disk_name.toUpperCase()
                                  : value.current_data_disk_name}
                                {<br />}({value.current_data_disk_tier})
                              </td>
                              <td>
                                {value.recommended_data_disk_name} {<br />}(
                                {value.recommended_data_disk_tier})
                              </td>

                              <td>
                                {value.applied_disksize_name}
                                {<br />}({value.applied_datadisk_tier})
                              </td>
                              <td>{value.recommendedDataDiskStatus}</td>
                            </tr>
                          );
                        });
                      })
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default RecommendationStatus;
