import React, { Component } from 'react';
import { Table, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as message from '../../Constants/MessageConstant';
import ResingBenefitTextComponent from './ResingBenefitTextComponent';
class ValidationDetailsTable extends Component {
  constructor(props) {
    super(props);
    this.viewGraph = this.viewGraph.bind(this);
    this.state = {
        graphModal : false,
        graphData : undefined,
        titleString: ''
    };
  }
  
  viewGraph =(imgDt, title) =>{
      this.setState(prevState => ({
      graphModal: !prevState.graphModal,
      graphData : imgDt,
      titleString: title
    }));
  }
  render() {
    const {data, valSummary} = this.props;
    const Green = <img src={require('../../images/Green_01.png')} title="Abnormal" />;
    const Red = <img src={require('../../images/Red_01.png')} title="Normal" />;
    const Yellow = <img src={require('../../images/Yellow_01.png')} title="Borderline" />;
    const higherArrow = <img id="ArrowImage" src={require('../../images/High_01.png')} title="Higher" />;
    const mHigherArrow = <img id="ArrowImage" src={require('../../images/High_01.png')} title="Marginally Higher" />;
    const betterArrow = <img id="ArrowImage" src={require('../../images/High_01.png')} title="Better" />;
    const mBetterArrow = <img id="ArrowImage" src={require('../../images/High_01.png')} title="Marginally Better" />;
    const worseArrow = <img id="ArrowImage" src={require('../../images/Low_01.png')}  title="Worse"/>;
    const mWorseArrow = <img id="ArrowImage" src={require('../../images/Low_01.png')}  title="Marginally Worse"/>;
    const lowerArrow = <img id="ArrowImage" src={require('../../images/Low_01.png')}  title="Lower"/>;
    const mLowerArrow = <img id="ArrowImage" src={require('../../images/Low_01.png')}  title="Marginally Lower"/>;
    const flatArrow = <img id="ArrowImage" src={require('../../images/Normal_01.png')} title="Same" />;
    const highCost = <label title="Poor">$$$</label>;
    const fairCost = <label title="Fair">$$</label>;
    const lowCost = <label title="Good">$</label>;
    const S = <img className="no-box-sizing" src={require('../../images/ValidationResult_Skipped.png')} title="Skipped" />;
    const Star = <img className="no-box-sizing" src={require('../../images/ValidationResult_Star.png')} title="Insignificant" />;
    const NA = <img className="no-box-sizing-na" src={require('../../images/ValidationResult_NA.png')} title="Not Applicable" />;
    let objective = data.perfType === "Aggressive" ? "Economical"
                    : data.perfType ==="Balanced" ? "Balanced"
                    : data.perfType ==="Conservative" ? "Enhanced" :"";
    return (
      <div>
        {this.props.isValidationGraphTable === false ?
        <div className="panel">
        {valSummary !== undefined ?
            <Row>
                <div className="overviewTextCompParentExpanded">
                    {(valSummary.costSummaryText !== undefined && valSummary.costSummaryText !== null) ?
                            <div className="maindiv-text-overview maindiv-text-overview-grad">
                                    <ResingBenefitTextComponent title={valSummary.costSummaryText} data={valSummary.costSummaryDescription} affectString={valSummary.perfAffected}  valResult={valSummary.validation_cost_result} renderIcon={true }/>
                            </div>
                            : ""}
                    {(valSummary.perfSummaryText !== undefined && valSummary.perfSummaryText !== null) ?
                            <div className="maindiv-text-overview maindiv-text-overview-grad">
                                    <ResingBenefitTextComponent data={valSummary.perfSummaryDescription} title={valSummary.perfSummaryText} affectString={valSummary.increasedCost} valResult={valSummary.validation_perf_result} renderIcon={true}/>
                            </div>
                    : null}
                </div>
            </Row> : null}
            
            <Row><Col>
                <Table className="tableview" id="tableSizing" bordered >
                    <thead>
                        <tr>
                            <td rowspan="2" style={{borderRight: "2px solid #8e8e8e", borderBottom:"0px", borderLeft:"0px", borderTop:"0px"}}></td>
                            <td colspan="4" style={{borderRight: "2px solid #8e8e8e"}}><b>Utilization Validation</b></td>
                            <td colspan="4"><b>Health Validation</b></td>
                        </tr>
                        <tr>
                            <th style={{backgroundColor:"#d8d8d8"}}>Existing</th>
                            <th>Expected Effects</th>
                            <th>Recommended</th>
                            <th style={{borderRight: "2px solid #8e8e8e"}}>Observed Effects</th>
                            <th style={{backgroundColor:"#d8d8d8"}}>Existing</th>
                            <th>Expected Effects</th>
                            <th>Recommended</th>
                            <th>Observed Effects</th>
                        </tr>                                               
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{borderRight: "2px solid #8e8e8e"}}><b>CPU</b></td>
                            
                            <td className="textfieldSizing" >{data.validationVM.sim_current_cpu_util}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationVM.sim_cpu_utils_expected_effects}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_cpu_utils_expected_effects === "Higher" ? higherArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Better" ? betterArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Lower" ? lowerArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Worse" ? worseArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Same" ? flatArrow :
                                          data.validationVM.sim_cpu_utils_expected_effects === "Insignificant" ? Star : 
                                          data.validationVM.sim_cpu_utils_expected_effects === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing" >{data.validationVM.sim_rec_cpu_util}</td>
                            
                            <td style={{borderRight: "2px solid #8e8e8e"}}>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationVM.sim_cpu_util_changes}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_cpu_util_changes === "Higher" ? higherArrow :
                                          data.validationVM.sim_cpu_util_changes === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_cpu_util_changes === "Better" ? betterArrow :
                                          data.validationVM.sim_cpu_util_changes === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_cpu_util_changes === "Lower" ? lowerArrow :
                                          data.validationVM.sim_cpu_util_changes === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_cpu_util_changes === "Worse" ? worseArrow :
                                          data.validationVM.sim_cpu_util_changes === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_cpu_util_changes === "Same" ? flatArrow :
                                          data.validationVM.sim_cpu_util_changes === "Insignificant" ? Star : 
                                          data.validationVM.sim_cpu_util_changes === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing">{data.validationVM.sim_current_cpu_health}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationVM.sim_cpu_health_expected_effects}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_cpu_health_expected_effects === "Higher" ? higherArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Better" ? betterArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Lower" ? lowerArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Worse" ? worseArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Same" ? flatArrow :
                                          data.validationVM.sim_cpu_health_expected_effects === "Insignificant" ? Star : 
                                          data.validationVM.sim_cpu_health_expected_effects === "N/A" ? NA :
                                          data.validationVM.sim_cpu_health_expected_effects === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing">{data.validationVM.sim_rec_cpu_health}</td>
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationVM.sim_cpu_health_changes}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_cpu_health_changes === "Higher" ? higherArrow :
                                          data.validationVM.sim_cpu_health_changes === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_cpu_health_changes === "Better" ? betterArrow :
                                          data.validationVM.sim_cpu_health_changes === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_cpu_health_changes === "Lower" ? lowerArrow :
                                          data.validationVM.sim_cpu_health_changes === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_cpu_health_changes === "Worse" ? worseArrow :
                                          data.validationVM.sim_cpu_health_changes === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_cpu_health_changes === "Same" ? flatArrow :
                                          data.validationVM.sim_cpu_health_changes === "Insignificant" ? Star : 
                                          data.validationVM.sim_cpu_health_changes === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>                              
                        </tr>
                        <tr>
                            <td style={{borderRight: "2px solid #8e8e8e"}}><b>Memory</b></td>
                            
                            <td className="textfieldSizing" >{data.validationVM.sim_current_memory_util}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationVM.sim_memory_utils_expected_effects}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_memory_utils_expected_effects === "Higher" ? higherArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Better" ? betterArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Lower" ? lowerArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Worse" ? worseArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Same" ? flatArrow :
                                          data.validationVM.sim_memory_utils_expected_effects === "Insignificant" ? Star : 
                                          data.validationVM.sim_memory_utils_expected_effects === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing" >{data.validationVM.sim_rec_memory_util}</td>
                            
                            <td style={{borderRight: "2px solid #8e8e8e"}}>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationVM.sim_memory_util_changes}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_memory_util_changes === "Higher" ? higherArrow :
                                          data.validationVM.sim_memory_util_changes === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_memory_util_changes === "Better" ? betterArrow :
                                          data.validationVM.sim_memory_util_changes === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_memory_util_changes === "Lower" ? lowerArrow :
                                          data.validationVM.sim_memory_util_changes === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_memory_util_changes === "Worse" ? worseArrow :
                                          data.validationVM.sim_memory_util_changes === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_memory_util_changes === "Same" ? flatArrow :
                                          data.validationVM.sim_memory_util_changes === "Insignificant" ? Star : 
                                          data.validationVM.sim_memory_util_changes === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                           <td className="textfieldSizing">{data.validationVM.sim_current_memory_health}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationVM.sim_memory_health_expected_effects}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_memory_health_expected_effects === "Higher" ? higherArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Better" ? betterArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Lower" ? lowerArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Worse" ? worseArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Same" ? flatArrow :
                                          data.validationVM.sim_memory_health_expected_effects === "Insignificant" ? Star : 
                                          data.validationVM.sim_memory_health_expected_effects === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing">{data.validationVM.sim_rec_memory_health}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationVM.sim_memory_health_changes}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_memory_health_changes === "Higher" ? higherArrow :
                                          data.validationVM.sim_memory_health_changes === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_memory_health_changes === "Better" ? betterArrow :
                                          data.validationVM.sim_memory_health_changes === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_memory_health_changes === "Lower" ? lowerArrow :
                                          data.validationVM.sim_memory_health_changes === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_memory_health_changes === "Worse" ? worseArrow :
                                          data.validationVM.sim_memory_health_changes === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_memory_health_changes === "Same" ? flatArrow :
                                          data.validationVM.sim_memory_health_changes === "Insignificant" ? Star : 
                                          data.validationVM.sim_memory_health_changes === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>                               
                        </tr>
                        <tr>
                            <td style={{borderRight: "2px solid #8e8e8e"}}><b>Network</b></td>
                            
                            <td className="textfieldSizing" >{data.validationVM.sim_current_network_util}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>Skipped</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_network_utils_expected_effects === "Higher" ? higherArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Better" ? betterArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Lower" ? lowerArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Worse" ? worseArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Same" ? flatArrow :
                                          data.validationVM.sim_network_utils_expected_effects === "Insignificant" ? Star : 
                                          data.validationVM.sim_network_utils_expected_effects === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing" >{data.validationVM.sim_rec_network_util}</td>
                            
                            <td style={{borderRight: "2px solid #8e8e8e"}}>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>Skipped</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_network_util_changes === "Higher" ? higherArrow :
                                          data.validationVM.sim_network_util_changes === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_network_util_changes === "Better" ? betterArrow :
                                          data.validationVM.sim_network_util_changes === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_network_util_changes === "Lower" ? lowerArrow :
                                          data.validationVM.sim_network_util_changes === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_network_util_changes === "Worse" ? worseArrow :
                                          data.validationVM.sim_network_util_changes === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_network_util_changes === "Same" ? flatArrow :
                                          data.validationVM.sim_network_util_changes === "Insignificant" ? Star : 
                                          data.validationVM.sim_network_util_changes === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing">{data.validationVM.sim_current_network_health}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>Skipped</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_network_health_expected_effects === "Higher" ? higherArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Better" ? betterArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Lower" ? lowerArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Worse" ? worseArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Same" ? flatArrow :
                                          data.validationVM.sim_network_health_expected_effects === "Insignificant" ? Star : 
                                          data.validationVM.sim_network_health_expected_effects === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing">{data.validationVM.sim_rec_network_health}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>Skipped</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationVM.sim_network_health_changes === "Higher" ? higherArrow :
                                          data.validationVM.sim_network_health_changes === "Marginally Higher" ? mHigherArrow :
                                          data.validationVM.sim_network_health_changes === "Better" ? betterArrow :
                                          data.validationVM.sim_network_health_changes === "Marginally Better" ? mBetterArrow :
                                          data.validationVM.sim_network_health_changes === "Lower" ? lowerArrow :
                                          data.validationVM.sim_network_health_changes === "Marginally Lower" ? mLowerArrow :
                                          data.validationVM.sim_network_health_changes === "Worse" ? worseArrow :
                                          data.validationVM.sim_network_health_changes === "Marginally Worse" ? mWorseArrow :
                                          data.validationVM.sim_network_health_changes === "Same" ? flatArrow :
                                          data.validationVM.sim_network_health_changes === "Insignificant" ? Star : 
                                          data.validationVM.sim_network_health_changes === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>                                 
                        </tr>
                        <tr>
                            <td style={{borderRight: "2px solid #8e8e8e"}}><b>OS Disk</b></td>
                            
                            <td className="textfieldSizing" >{data.validationOSDisk.sim_current_os_util}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationOSDisk.sim_osdisk_utils_expected_effects}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Higher" ? higherArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Marginally Higher" ? mHigherArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Better" ? betterArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Marginally Better" ? mBetterArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Lower" ? lowerArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Marginally Lower" ? mLowerArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Worse" ? worseArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Marginally Worse" ? mWorseArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Same" ? flatArrow :
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "Insignificant" ? Star : 
                                          data.validationOSDisk.sim_osdisk_utils_expected_effects === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing" >{data.validationOSDisk.sim_rec_os_util}</td>
                            
                            <td style={{borderRight: "2px solid #8e8e8e"}}>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationOSDisk.sim_os_disk_util_changes}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationOSDisk.sim_os_disk_util_changes === "Higher" ? higherArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Marginally Higher" ? mHigherArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Better" ? betterArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Marginally Better" ? mBetterArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Lower" ? lowerArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Marginally Lower" ? mLowerArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Worse" ? worseArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Marginally Worse" ? mWorseArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Same" ? flatArrow :
                                          data.validationOSDisk.sim_os_disk_util_changes === "Insignificant" ? Star : 
                                          data.validationOSDisk.sim_os_disk_util_changes === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing">{data.validationOSDisk.sim_current_os_health}</td>
                                                        
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationOSDisk.sim_osdisk_health_expected_effects}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Higher" ? higherArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Marginally Higher" ? mHigherArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Better" ? betterArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Marginally Better" ? mBetterArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Lower" ? lowerArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Marginally Lower" ? mLowerArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Worse" ? worseArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Marginally Worse" ? mWorseArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Same" ? flatArrow :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "Insignificant" ? Star :
                                          data.validationOSDisk.sim_osdisk_health_expected_effects === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td>
                            
                            <td className="textfieldSizing">{data.validationOSDisk.sim_rec_os_health}</td>
                            
                            <td>
                                <Row style={{display:"inline-flex"}}>
                                    <Col sm="10" style={{padding:"0px"}}>{data.validationOSDisk.sim_os_disk_health_changes}</Col>
                                    <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                        { 
                                          data.validationOSDisk.sim_os_disk_health_changes === "Higher" ? higherArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Marginally Higher" ? mHigherArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Better" ? betterArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Marginally Better" ? mBetterArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Lower" ? lowerArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Marginally Lower" ? mLowerArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Worse" ? worseArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Marginally Worse" ? mWorseArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Same" ? flatArrow :
                                          data.validationOSDisk.sim_os_disk_health_changes === "Insignificant" ? Star :
                                          data.validationOSDisk.sim_os_disk_health_changes === "N/A" ? NA : S
                                        }
                                    </Col>
                                </Row>
                            </td> 
                        </tr>
                      {Object.keys(data.validationDataDisks).map((key, i) => {
                          return (
                            <tr>
                                <td style={{borderRight: "2px solid #8e8e8e"}}><b>Data Disk {i}</b></td>
                                
                                <td className="textfieldSizing">{data.validationDataDisks[key].sim_current_datadisk_util}</td>
                                
                                <td>
                                    <Row style={{display:"inline-flex"}}>
                                        <Col sm="10" style={{padding:"0px"}}>{data.validationDataDisks[key].sim_datadisk_utils_expected_effects}</Col>
                                        <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                            { 
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Higher" ? higherArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Marginally Higher" ? mHigherArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Better" ? betterArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Marginally Better" ? mBetterArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Lower" ? lowerArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Marginally Lower" ? mLowerArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Worse" ? worseArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Marginally Worse" ? mWorseArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Same" ? flatArrow :
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "Insignificant" ? Star : 
                                              data.validationDataDisks[key].sim_datadisk_utils_expected_effects === "N/A" ? NA : S
                                            }
                                        </Col>
                                    </Row>
                                </td>
                                
                                <td className="textfieldSizing" >{data.validationDataDisks[key].sim_rec_datadisk_util}</td>
                                
                                <td style={{borderRight: "2px solid #8e8e8e"}}>
                                    <Row style={{display:"inline-flex"}}>
                                        <Col sm="10" style={{padding:"0px"}}>{data.validationDataDisks[key].sim_data_disk_utils_changes}</Col>
                                        <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                            { 
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Higher" ? higherArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Marginally Higher" ? mHigherArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Better" ? betterArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Marginally Better" ? mBetterArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Lower" ? lowerArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Marginally Lower" ? mLowerArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Worse" ? worseArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Marginally Worse" ? mWorseArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Same" ? flatArrow :
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "Insignificant" ? Star : 
                                              data.validationDataDisks[key].sim_data_disk_utils_changes === "N/A" ? NA : S
                                            }
                                        </Col>
                                    </Row>
                                </td>
                                
                                <td className="textfieldSizing" >{data.validationDataDisks[key].sim_current_datadisk_health}</td>
                                
                                <td>
                                    <Row style={{display:"inline-flex"}}>
                                        <Col sm="10" style={{padding:"0px"}}>{data.validationDataDisks[key].sim_datadisk_health_expected_effects}</Col>
                                        <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                            { 
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Higher" ? higherArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Marginally Higher" ? mHigherArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Better" ? betterArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Marginally Better" ? mBetterArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Lower" ? lowerArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Marginally Lower" ? mLowerArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Worse" ? worseArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Marginally Worse" ? mWorseArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Same" ? flatArrow :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "Insignificant" ? Star :
                                              data.validationDataDisks[key].sim_datadisk_health_expected_effects === "N/A" ? NA : S
                                            }
                                        </Col>
                                    </Row>
                                </td>
                                
                                <td className="textfieldSizing">{data.validationDataDisks[key].sim_rec_datadisk_health}</td>
                                
                                <td>
                                    <Row style={{display:"inline-flex"}}>
                                        <Col sm="10" style={{padding:"0px"}}>{data.validationDataDisks[key].sim_data_disk_health_changes}</Col>
                                        <Col sm="2" style={{padding:"0px", textAlign:"end", display:"flex"}}>
                                            { 
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Higher" ? higherArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Marginally Higher" ? mHigherArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Better" ? betterArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Marginally Better" ? mBetterArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Lower" ? lowerArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Marginally Lower" ? mLowerArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Worse" ? worseArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Marginally Worse" ? mWorseArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Same" ? flatArrow :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "Insignificant" ? Star :
                                              data.validationDataDisks[key].sim_data_disk_health_changes === "N/A" ? NA : S
                                            }
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                      );})
                      }
                    </tbody>
                </Table>
            </Col></Row></div> :
            <div className="panel" style={{paddingBottom:"15px"}}>   
            <Row><Col><div id ="TableHeader" align="Center" style={{marginTop: '10px', borderRadius:"10px 10px 0px 0px"}}>Simulation Confidence and Plots</div></Col></Row>
            <Row>
                <Col>
                    <Table className="tableview" id="tableSizing" bordered >
                        <thead>                           
                            <tr>
                                <td></td>
                                <th><b>Current</b></th>
                                <th><b>Recommended</b></th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td><b>CPU</b></td>
                                <td>
                                    <div className="valiationGraphParentDiv">
                                        <div>{data.validationVM.sim_current_cpu_confidence}</div>
                                        <div>
                                            <img src={"data:image/png;base64,"+data.validationVM.sim_current_cpu_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationVM.sim_current_cpu_graph, " Current CPU")}/>
                                        </div>
                                    </div>
                                </td>                              
                                <td>
                                    <div className="valiationGraphParentDiv">
                                        <div>{data.validationVM.sim_rec_cpu_confidence}</div>
                                        <div><img src={"data:image/png;base64,"+data.validationVM.sim_rec_cpu_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationVM.sim_rec_cpu_graph, " Recommended CPU")}/></div>
                                    </div>
                                </td>                                                       
                            </tr>
                            <tr>
                                <td><b>Memory</b></td>
                                <td>
                                    <div className="valiationGraphParentDiv">
                                        <div>{data.validationVM.sim_current_memory_confidence}</div>
                                        <div><img src={"data:image/png;base64,"+data.validationVM.sim_current_memory_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationVM.sim_current_memory_graph, " Current Memory")} /></div>
                                    </div>
                                </td>
                                <td>                                
                                    <div className="valiationGraphParentDiv">
                                        <div>{data.validationVM.sim_rec_memory_confidence}</div>
                                        <div><img src={"data:image/png;base64,"+data.validationVM.sim_rec_memory_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationVM.sim_rec_memory_graph, " Recommended Memory")}/></div>
                                    </div>
                                </td>                                                        
                            </tr>
                            <tr>
                                <td><b>Network</b></td>
                                <td>---</td>
                                <td>---</td>                                                            
                            </tr>
                            <tr>
                                <td style={{padding:"0px"}}>
                                <table style={{width:"100%"}}>
                                    <tr>
                                    <td style={{borderTop:"none", borderBottom:"none", borderLeft:"none"}}><b>OS Disk</b></td>
                                    <td style={{padding:"0px",borderTop:"none", borderRight:"none", borderLeft:"none", borderBottom:"none"}}>
                                        <table style={{width:"100%"}}><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none"}}>Read Throughput</td></tr></table>
                                        <table style={{width:"100%"}}><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none", borderBottom:"none"}}>Write Throughput</td></tr></table>
                                    </td>
                                    </tr>
                                </table>
                                </td>                               
                                <td style={{padding:"0px"}}>                                    
                                    <table style={{width:"100%"}}><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none"}}>
                                        <div className="valiationGraphParentDiv">
                                            <div>{data.validationOSDisk.sim_current_os_read_confidence}</div>
                                            <div><img src={"data:image/png;base64,"+data.validationOSDisk.sim_current_os_read_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationOSDisk.sim_current_os_read_graph, " Current OS Disk (Read)")} /></div>
                                        </div>
                                    </td></tr>
                                    <tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none", borderBottom:"none"}}>
                                        <div className="valiationGraphParentDiv">
                                            <div>{data.validationOSDisk.sim_current_os_write_confidence}</div>
                                            <div><img src={"data:image/png;base64,"+data.validationOSDisk.sim_current_os_write_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationOSDisk.sim_current_os_write_graph, " Current OS Disk (Write)")}/></div>
                                        </div>
                                    </td></tr></table>
                                </td>
                                <td style={{padding:"0px"}}>                                    
                                    <table style={{width:"100%"}}><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none"}}>
                                        <div className="valiationGraphParentDiv">
                                            <div>{data.validationOSDisk.sim_rec_os_read_confidence}</div>
                                            <div><img src={"data:image/png;base64,"+data.validationOSDisk.sim_rec_os_read_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationOSDisk.sim_rec_os_read_graph, " Recommended OS Disk(Read)")}/></div>
                                        </div>
                                    </td></tr>
                                    <tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none", borderBottom:"none"}}>
                                        <div className="valiationGraphParentDiv">
                                            <div>{data.validationOSDisk.sim_rec_os_write_confidence}</div>
                                            <div><img src={"data:image/png;base64,"+data.validationOSDisk.sim_rec_os_write_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationOSDisk.sim_rec_os_write_graph, " Recommended OS Disk(Write)")}/></div>
                                        </div>
                                    </td></tr></table>
                                </td>                          
                            </tr>
                            {Object.keys(data.validationDataDisks).map((key, i) => {
                                return (
                                  <tr>
                                    <td style={{padding:"0px"}}>
                                    <table style={{width:"100%"}}>
                                        <tr>
                                        <td style={{borderTop:"none", borderBottom:"none", borderLeft:"none"}}><b>Data Disk {i}</b></td>
                                        <td style={{padding:"0px",borderTop:"none", borderRight:"none", borderLeft:"none", borderBottom:"none"}}>
                                            <table style={{width:"100%"}}><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none"}}>Read Throughput</td></tr></table>
                                            <table style={{width:"100%"}}><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none", borderBottom:"none"}}>Write Throughput</td></tr></table>
                                        </td>
                                        </tr>
                                    </table>
                                    </td>
                                                                        <td style={{padding:"0px"}}>
                                        <table style={{width:"100%"}}><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none"}}>
                                            <div className="valiationGraphParentDiv">
                                                <div>{data.validationDataDisks[key].sim_current_datadisk_read_confidence}</div>
                                                <div><img src={"data:image/png;base64,"+data.validationDataDisks[key].sim_current_datadisk_read_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationDataDisks[key].sim_current_datadisk_read_graph, " Current Data Disk "+i+" (Read)")}/></div>
                                            </div>
                                        </td></tr><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none", borderBottom:"none"}}>
                                            <div className="valiationGraphParentDiv">
                                                <div>{data.validationDataDisks[key].sim_current_datadisk_write_confidence}</div>
                                                <div><img src={"data:image/png;base64,"+data.validationDataDisks[key].sim_current_datadisk_write_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationDataDisks[key].sim_current_datadisk_write_graph, " Current Data Disk "+i+" (Write)")}/></div>
                                            </div>
                                        </td></tr></table>
                                    </td>
                                    <td style={{padding:"0px"}}>
                                        <table style={{width:"100%"}}><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none"}}>
                                            <div className="valiationGraphParentDiv">
                                                <div>{data.validationDataDisks[key].sim_rec_datadisk_read_confidence}</div>
                                                <div><img src={"data:image/png;base64,"+data.validationDataDisks[key].sim_rec_datadisk_read_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationDataDisks[key].sim_rec_datadisk_read_graph, " Recommended Data Disk "+i+" (Read)")}/></div>
                                            </div>
                                        </td></tr><tr><td style={{borderTop:"none", borderRight:"none", borderLeft:"none", borderBottom:"none"}}>
                                            <div className="valiationGraphParentDiv">
                                                <div>{data.validationDataDisks[key].sim_rec_datadisk_write_confidence}</div>
                                                <div><img src={"data:image/png;base64,"+data.validationDataDisks[key].sim_rec_datadisk_write_graph} height="50px" width="50px" style={{cursor: "zoom-in"}} onClick={() => this.viewGraph("data:image/png;base64,"+data.validationDataDisks[key].sim_rec_datadisk_write_graph, " Recommended Data Disk "+i+" (Write)")}/></div>
                                            </div>
                                        </td></tr></table>
                                    </td>                                       
                                  </tr>
                            );})
                            }
                        </tbody>
                    </Table>
                </Col>
                </Row></div>
                }
            <Modal toggle={this.viewGraph} size="lg" isOpen={this.state.graphModal} backdrop="static">
                  <ModalHeader id="modalheader_list" cssModule={{ 'modal-title': 'modalHeader' }} toggle={this.viewGraph}><div className="modalCompanyName" dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }} />  <div className="modalTitle">Simulation Plot for {this.state.titleString}</div> </ModalHeader>
                  <ModalBody><div>
                    <img src={this.state.graphData}/>
                  </div></ModalBody>
                </Modal>
        
      </div>
    );
  }
}
export default ValidationDetailsTable;
