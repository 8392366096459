import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Modal, ModalBody,ModalFooter,Button, ModalHeader } from 'reactstrap';
import * as message from "../../Constants/MessageConstant";
// import '../AccountComponent/Account.css'
class Error extends Component{
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            modal: this.props.ErrorPopup,
            modalSize: ((this.props.modalSize !== null && this.props.modalSize !== undefined) ? this.props.modalSize : "")
          };
    }
    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
        this.props.setErrorMethod(false);
      }
    render(){
        const btnNm = this.props.btnName !== undefined ?  this.props.btnName : "OK";
        return(
            <Modal backdrop={"static"}  isOpen={this.state.modal}  centered={true} size={this.state.modalSize}>
            <ModalHeader id="modalheader_list"  cssModule={{'modal-title': 'modalHeader'}}><div className="modalCompanyName" dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }} />  {this.props.isError === false?  <div className="modalTitle">Error Message</div>: <div className="modalTitle"> {this.props.subTitle}</div>}</ModalHeader>
            <ModalBody>
               
                <p style={{fontSize:"18px"}}><div  dangerouslySetInnerHTML={{__html: this.props.Errordata}}/></p>
                    
             </ModalBody>
             <ModalFooter id="knowMoreFooter" >
             <Button id="confirmbtn_list" onClick={this.toggle} >{btnNm}</Button>{' '}
             </ModalFooter>
             </Modal>
        );
    }
}
const mapStateToProps = (state) => {
  return {
      companyDetails: state.companyDetails.data,
  }
}

export default connect(mapStateToProps, null)(Error);