import React, { Component } from "react";
import {
  Table,
  Button,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import * as message from "../../Constants/MessageConstant";
import Hashmap from "hashmap";
import BootstrapTable from "react-bootstrap-table-next";
import jstz from "jstz";
import { connect } from "react-redux";
import { priceDisplay } from "../../Constants/CommonConstant";

class ManageParkingRecommendation extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.toggleProceedConfirm = this.toggleProceedConfirm.bind(this);
    this.confirmRecommendation = this.confirmRecommendation.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.getStringDate = this.getStringDate.bind(this);
    this.isAnythingChanged = this.isAnythingChanged.bind(this);
    this.parkingCompletedClick = this.parkingCompletedClick.bind(this);
    this.renderSelect = this.renderSelect.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.renderBenefits = this.renderBenefits.bind(this);
    this.freeTrialPlanSupport = this.freeTrialPlanSupport.bind(this);

    this.state = {
      value: this.props.data,
      result: [],
      objectiveDsMap: new Hashmap(),
      initialDsMap: new Hashmap(),
      confirmStart: false,
      modalLoader: false,
      errorPopup: false,
      toggleProceed: false,
      proceedConfirmText: "",
      imChanged: false,
      errormessage: "",
      isFreeTrial: this.props.isFreeTrial,
      userName: this.props.userName,
    };
    this.columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "vmname",
        text: "VM Name",
        headerStyle: {
          wordBreak: "break-word",
          zIndex: "100",
        },
        style: {
          pointerEvents: "none",
          wordBreak: "break-word",
        },
      },
      {
        dataField: "account_name",
        text: "Metric Data Source",
        headerStyle: {
          wordBreak: "break-word",
          zIndex: "100",
        },
        style: {
          pointerEvents: "none",
          wordBreak: "break-word",
        },
      },
      {
        dataField: "benefits",
        text: "Benefits",
        formatter: this.renderBenefits,
        headerStyle: {
          padding: "0px !important",
          zIndex: "100",
        },
        style: {
          wordBreak: "break-word",
        },
      },
      {
        dataField: "status",
        text: "Status",
        headerStyle: {
          padding: "0px !important",
          width: "114px",
          zIndex: "100",
        },
        style: {
          wordBreak: "break-word",
        },
      },
      {
        dataField: "asofdate",
        text: "When",
        formatter: this.getStringDate,
        headerStyle: {
          width: "86px",
          zIndex: "100",
        },
        style: {
          pointerEvents: "none",
          wordBreak: "break-word",
        },
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        formatter: this.renderSelect,
        headerStyle: {
          width: "180px",
          zIndex: "100",
        },
        style: {
          wordBreak: "break-word",
        },
      },
    ];
  }

  async componentDidMount() {
    var { objectiveDsMap, initialDsMap, result } = this.state;
    this.state.value.map((Vm) => {
      result.push(Vm.id);
      objectiveDsMap.set(Vm.id, Vm.id);
      initialDsMap.set(Vm.id, Vm.id);
      if (
        document.getElementById(Vm.id + "chk") !== null &&
        document.getElementById(Vm.id + "chk") !== undefined
      ) {
        document.getElementById(Vm.id + "chk").disabled = false;
      }
    });
    await this.setState({
      objectiveDsMap: objectiveDsMap,
      initialDsMap: initialDsMap,
      result: [...new Set(result)],
    });
    await this.isAnythingChanged();
  }

  async checkAll(isSelect, rows, e) {
    if (isSelect) {
      var { objectiveDsMap, initialDsMap, result } = this.state;
      this.state.value.map((Vm) => {
        result.push(Vm.id);
        objectiveDsMap.set(Vm.id, Vm.id);
        initialDsMap.set(Vm.id, Vm.id);
        if (
          document.getElementById(Vm.id + "chk") !== null &&
          document.getElementById(Vm.id + "chk") !== undefined
        ) {
          document.getElementById(Vm.id + "chk").disabled = false;
        }
      });
      await this.setState({
        objectiveDsMap: objectiveDsMap,
        initialDsMap: initialDsMap,
        result: [...new Set(result)],
      });
    } else {
      await this.setState({
        result: [],
      });
    }
    await this.isAnythingChanged();
  }

  async onChange(row, isSelect, rowIndex, e) {
    if (isSelect) {
      await this.setState({
        result: [...this.state.result, row.id],
      });
    } else {
      let remove = this.state.result.indexOf(row.id);
      await this.setState({
        result: this.state.result.filter((_, key) => key !== remove),
      });
    }
    await this.setState({ result: [...new Set(this.state.result)] });
    await this.isAnythingChanged();
  }

  async isAnythingChanged() {
    if (this.state.result.length > 0) {
      if (this.state.initialDsMap.size !== this.state.objectiveDsMap.size) {
        await this.setState({ imChanged: true });
      } else if (this.state.objectiveDsMap.size > 0) {
        var initialMap = this.state.initialDsMap;
        var isChange = true;
        this.state.objectiveDsMap.forEach(function (value, key) {
          let resizeBy = document.getElementById(key + "sel").value;
          let completedMark = document.getElementById(key + "chk").checked;
          let bvm = {
            basicvmId: key,
            resultKey: value,
            resizedBy: resizeBy,
            markResizingCompleted: completedMark,
          };
          let inibvm = initialMap.get(key);
          if (inibvm !== null && inibvm !== undefined) {
            let initialBvm = {
              basicvmId: key,
              resultKey: inibvm,
              resizedBy: resizeBy,
              markResizingCompleted: false,
            };
            let inibvmstr = JSON.stringify(initialBvm);
            let newbvmstr = JSON.stringify(bvm);
            if (inibvmstr !== newbvmstr) {
              isChange = true;
              return false;
            }
          } else {
            isChange = true;
            return false;
          }
        });
        await this.setState({ imChanged: isChange });
      } else {
        await this.setState({ imChanged: false });
      }
    } else {
      await this.setState({ imChanged: false });
    }
  }

  confirmRecommendation = async () => {
    await this.setState({ modalLoader: true, toggleProceed: false });
    const timezone = jstz.determine();
    const timezoneid = timezone.name();
    var payload = [];
    this.state.result.forEach(function (value, key) {
      let resizeBy = document.getElementById(value + "sel").value;
      let completedMark = document.getElementById(value + "chk").checked;
      let bvm = {
        basicvmId: value,
        resultKey: value,
        resizedBy: resizeBy,
        markResizingCompleted: completedMark,
        userGtmId: timezoneid,
      };
      payload.push(bvm);
    });

    fetch(Constdata.PARKING_MANAGE_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ modalLoader: false });
        this.processResponse(responseJson);
      })
      .catch((error) => {
        this.setState({ modalLoader: false });
      });
  };

  processResponse(response) {
    if (response.success === true) {
      this.props.handleFetch();
      this.props.cancelUserParking();
    } else if (response.success === false) {
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: response.message,
      });
    } else if (response.status === 403 || response.status === 401) {
      this.setState({
        isRedirect: true,
        modalLoader: false,
        errorPopup: true,
        errormessage: message.SessionExpiredText,
        isErrorHeader: false,
      });
    } else {
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  }

  parkingCompletedClick = async (e) => {
    await this.isAnythingChanged();
  };

  renderSelect(cell, Vm) {
    let defaultValue = "Manually by User";
    let idVal = Vm.id;
    return (
      <div>
        <div>
          <select
            id={idVal + "sel"}
            value={defaultValue}
            onChange={this.handleChange}
          >
            <option value="Manually by User">Manually by User</option>
            <option
              value={`Automatic by ` + this.props.companyDetails.ServiceName}
              disabled
            >
              Automatic by {this.props.companyDetails.ServiceName}
            </option>
          </select>
        </div>
        <div
          style={{
            display: "inline-flex",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <div className="chk_container">
            <Input
              type="checkbox"
              className="form-check-input-nontable"
              id={idVal + "chk"}
              onChange={(event) => this.parkingCompletedClick(event)}
            />
            <span className="custom-checkbox"></span>
          </div>
          <label style={{ margin: "auto 0px auto 2px" }} for={idVal + "chk"}>
            Parking completed
          </label>
        </div>
      </div>
    );
  }

  renderBenefits(cell, row) {
    return (
      <div style={{ textAlign: "center" }}>
        <div>{row.benefits}</div>
        {row.benefits !== "No days/hours parking" ? (
          <div>
            <b>Cost Saving after Parking : </b>
            {priceDisplay(row.reduced_cost)}
          </div>
        ) : (
          <div>
            <b>No change in cost saving</b>
          </div>
        )}
      </div>
    );
  }

  getStringDate(cell, row) {
    let current_datetime = new Date(row.asofdate);
    var hours = current_datetime.getHours();
    var minutes = current_datetime.getMinutes();
    var month = current_datetime.getMonth() + 1;
    var day = current_datetime.getDate();
    var seconds = current_datetime.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    var tzAbbr = "";
    var dateObject = new Date(),
      dateString = dateObject + "",
      tzAbbr =
        dateString.match(/\(([^\)]+)\)$/) ||
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
      tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
    }

    return (
      <div style={{ textAlign: "center" }}>
        {current_datetime.getFullYear() +
          "-" +
          month +
          "-" +
          day +
          "  " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds +
          " " +
          tzAbbr}
      </div>
    );
  }

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
  };

  toggleProceedConfirm = async () => {
    if (this.state.toggleProceed === false && this.state.result.length > 0) {
      let proceedConfirmText = "";
      if (this.state.result.length === 1) {
        let completedMark = false;
        let dsid = 0;
        this.state.result.forEach(function (value, key) {
          completedMark = document.getElementById(value + "chk").checked;
          dsid = value;
        });
        var ds = this.state.value.filter(function (mds) {
          let idVal = mds.id;
          return idVal === dsid;
        })[0];
        proceedConfirmText = ds.vmname + " has been selected for parking ";
        if (completedMark === true && this.state.initialDsMap.size === 0) {
          proceedConfirmText =
            proceedConfirmText +
            ' and marked "Parking completed" (which cannot be undone)';
        } else if (
          completedMark === true &&
          this.state.initialDsMap.size === 1
        ) {
          proceedConfirmText =
            ds.vmname +
            ' has been marked "Parking completed" (which cannot be undone)';
        }
      } else {
        let anymarkedCompleted = false;
        this.state.result.forEach(function (value, key) {
          var checked = document.getElementById(value + "chk").checked;
          if (checked) {
            anymarkedCompleted = true;
            return true;
          }
        });
        if (anymarkedCompleted) {
          proceedConfirmText =
            this.state.result.length +
            ' VMs have been selected for parking and/or marked "Parking completed" (which cannot be undone)';
        } else {
          proceedConfirmText =
            this.state.result.length + " VMs have been selected for parking.";
        }
      }
      await this.setState({ proceedConfirmText: proceedConfirmText });
    } else {
      await this.setState({ proceedConfirmText: "" });
    }
    await this.setState((prevState) => ({
      toggleProceed: !prevState.toggleProceed,
    }));
  };

  freeTrialPlanSupport() {
    if (this.state.isFreeTrial) {
      if (
        this.state.userName === "support@serralabs.com"
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  render() {
    return (
      <div>
        <div>
          <BootstrapTable
            id="list_table"
            bordered={false}
            bodyClasses="tbodyColor"
            classes="table-responsive smallFontTable"
            bootstrap4={true}
            keyField={"id"}
            data={this.state.value}
            columns={this.columns}
            wrapperClasses="table-responsive"
            selectRow={{
              mode: "checkbox",
              clickToSelect: false,
              clickToExpand: false,
              onSelect: this.onChange,
              onSelectAll: this.checkAll,
              selected: this.state.result,
              nonSelectable: this.freeTrialPlanSupport()
                ? this.state.nonSelectable
                : [],
            }}
            noDataIndication={() => "No VMs Found"}
          />
        </div>
        <div
          id="bottom_btn"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            bottom: "0",
          }}
        >
          <Button
            id={
              this.state.imChanged === false ? "PPU_listdisabled" : "PPU_list"
            }
            onClick={this.toggleProceedConfirm}
            style={{ width: "180px", marginRight: "15px" }}
            disabled={!this.state.imChanged}
          >
            Proceed
          </Button>
          <Button
            className="btn-cancel btn"
            onClick={this.props.cancelUserParking}
          >
            Cancel
          </Button>
        </div>
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}

        <Modal
          size="lg"
          isOpen={this.state.toggleProceed}
          toggle={this.toggleProceedConfirm}
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleProceedConfirm}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Resizing Confirmation</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel" id="modaldis_list">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.proceedConfirmText,
                    }}
                  />
                </div>
                <div
                  className="footnotePopup"
                  style={{
                    margin: "20px",
                    fontSize: "16px",
                    textAlign: "left",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "Note : An email with a report attachment for all VM parking will be sent.",
                    }}
                  />
                </div>
              </div>
              <Form>
                <FormGroup>
                  <div align="center">
                    <Button
                      id="confirmbtn_list"
                      onClick={this.confirmRecommendation}
                    >
                      Yes
                    </Button>
                    <Button
                      id="canclebtn_list"
                      onClick={() => {
                        this.toggleProceedConfirm();
                      }}
                    >
                      No
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyDetails: state.companyDetails.data,
  };
};
export default connect(mapStateToProps, null)(ManageParkingRecommendation);
