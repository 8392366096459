import React, { Component } from 'react';
// import './Signin.css';
import { Input, Button, InputGroup, InputGroupText, InputGroupAddon,Tooltip} from 'reactstrap';
import Header from '../HeaderComponent/Header';
import * as Constdata from '../../Constants/ApiConstants';
import SerraError from '../CommonComponent/Error';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import * as message from '../../Constants/MessageConstant';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.isDisabled = this.isDisabled.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      hidden: true,
      fields: {},
      isDisableconfirm: true,
      errormessage: '',
      errorPopup: false,
      tooltipOpen: false
    };
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    const { password, passwordConfirm } = this.state.fields;
    const payload = {
      password: password,
      confirmPassword: passwordConfirm
    };
    fetch(Constdata.SAVEPASSWORD_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((responseJson) => {
        this.processResponse(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  processResponse(response) {
    console.log("inside method", response);
    if (response.success === true) {
      this.setState({ errorPopup: true, errormessage: response.message });
    }
    else {
      this.setState({ errorPopup: true, errormessage: response.message });
    }
  }
  toggleShow = () => {
    console.log("inside toggle");
    this.setState({ hidden: !this.state.hidden });
  }
  componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Forgot Password" });
    this.props.registerListners();
    document.getElementById("menuDisplay").style.display = "none";
    document.getElementById("selectDisplay").style.display = "none";
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  isDisabled = () => {
    const { passwordcheck, confirmPass } = this.state;
    const { passwordConfirm, password } = this.state.fields;
    const isAllFilled = passwordConfirm && password;
    const isValidPass = (passwordcheck === "valid" && confirmPass === "valid");
    return !(isAllFilled && isValidPass);
  }
  validator = () => {
    const { password } = this.state.fields;
    var reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
    var test = reg.test(password);
    if (test) {
      this.setState(() => {
        return { passwordcheck: "valid", isDisableconfirm: false };
      });
    }
    else {
      this.setState(() => {
        return { passwordcheck: "invalid", isDisableconfirm: true };
      });
    }
  }
  confirmpassword = () => {
    const { password, passwordConfirm } = this.state.fields;
    if (password === passwordConfirm) {
      this.setState({ confirmPass: 'valid' });
    }
    else {
      this.setState({ confirmPass: 'invalid' });
    }
  }
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    this.props.history.push({ pathname: '/' });
  }
  render() {
    let passwordvalid = <Input type={this.state.hidden ? "password" : "text"} onKeyUp={this.validator.bind(this)} className="form-control" id="password" name="password" value={this.state.fields["password"]} onChange={this.handleChange.bind(this, "password")} />;
    if (this.state.passwordcheck === "valid") {
      passwordvalid = <Input type={this.state.hidden ? "password" : "text"} onKeyUp={this.validator.bind(this)} className="form-control" id="password" name="password" value={this.state.fields["password"]} onChange={this.handleChange.bind(this, "password")} valid />;
    }
    else if (this.state.passwordcheck === "invalid") {
      passwordvalid = <Input type={this.state.hidden ? "password" : "text"} onKeyUp={this.validator.bind(this)} className="form-control" id="password" name="password" value={this.state.fields["password"]} onChange={this.handleChange.bind(this, "password")} invalid />;
    }
    let confirmpassword = <Input type="password" className="form-control" id="passwordConfirm" name="passwordConfirm" value={this.state.fields["passwordConfirm"]} onChange={this.handleChange.bind(this, "passwordConfirm")} onKeyUp={this.confirmpassword.bind(this)} disabled={this.state.isDisableconfirm} />;
    if (this.state.confirmPass === "valid") {
      confirmpassword = <Input type="password" className="form-control" id="passwordConfirm" name="passwordConfirm" value={this.state.fields["passwordConfirm"]} onChange={this.handleChange.bind(this, "passwordConfirm")} onKeyUp={this.confirmpassword.bind(this)} valid />;
    }
    else if (this.state.confirmPass === "invalid") {
      confirmpassword = <Input type="password" className="form-control" id="passwordConfirm" name="passwordConfirm" value={this.state.fields["passwordConfirm"]} onChange={this.handleChange.bind(this, "passwordConfirm")} onKeyUp={this.confirmpassword.bind(this)} invalid />;
    }
    return (
      <div className="wrapper">
        <Header processLogout={this.props.processLogut} registerListners={this.props.registerListners}/>
        <div className="bg-container1">&nbsp;</div>
        <div id="SignInClass">
        <div className="Heading">{this.props.companyDetails.ServiceNameFullName}</div>
          <div  className="HeadingTitle">Change Password</div>
         
          <div className="outer-container">
            <div className="container">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Password:</label>
                  <InputGroup>
                    {passwordvalid}
                    <Tooltip placement="right" trigger="focus" isOpen={this.state.tooltipOpen} hideArrow={true}  target="password" toggle={this.toggle} className="tooltipSignup" style={{textAlign:"left"}}>
                      <div ><h6> Password restrictions:</h6>
                    <div>
                      Between 8 to 30 characters.{<br />}
                      At least one upper case letter.{<br/>}
                    At least one lower case letter.{<br />}
                At least one one numeric character.{<br />}
                    At least one special character. {<br />}
                      </div></div>
                    </Tooltip>
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-eye-slash" onClick={this.toggleShow}></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="form-group">
                  <label >Confirm Password:</label>
                  {confirmpassword}
                </div>
                <Button type="submit" color={this.isDisabled() ? "secondary" : "primary"} disabled={this.isDisabled()} block>Submit</Button>
              </form>
            </div>
          </div>
        </div>
        {(this.state.errorPopup === true) ? <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState}></SerraError> : ""}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      companyDetails: state.companyDetails.data,
  }
}

export default connect(mapStateToProps, null)(ForgotPassword);