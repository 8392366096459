import React, { Component } from "react";
import { Row, Col, ButtonGroup } from "reactstrap";
// import './summary-styles.css';
import Header from "../HeaderComponent/Header";
import * as Constdata from "../../Constants/ApiConstants";
import SerraLoader from "../CommonComponent/Loader";
import ColorComponent from "../AnalysisComponent/SvgColorOverview";
import ResingBenefitTextComponent from "../AnalysisComponent/ResingBenefitTextComponent";
import * as message from "../../Constants/MessageConstant";
import SummaryStatus from "../AnalysisComponent/RecommendationSummary";
import SerraError from "../CommonComponent/Error";
import ReactGA from "react-ga4";
import jstz from "jstz";
import { getStringDate } from "../../Constants/CommonConstant";

class AspSummary extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showOverview = this.showOverview.bind(this);
    this.handlePDF = this.handlePDF.bind(this);
    this.state = {
      currentDateTime: new Date(),
      loggedInUser: "",
      analysisStatus:
        this.props.location.state === undefined
          ? ""
          : this.props.location.state.data,
      viewResultData:
        this.props.location.state === undefined
          ? ""
          : this.props.location.state.oldData,
      isAsp:
        this.props.location.state.isAsp !== undefined
          ? this.props.location.state.isAsp
          : false,
      entityType:
        this.props.location.state.entityType !== undefined
          ? this.props.location.state.entityType
          : "asp",
      modalLoader: false,
      resultData: [],
      aggIconData: [],
      balIconData: [],
      healthyIconData: [],
      atb: "1",
      acol: "",
      modal: false,
      errorPopup: false,
      errormessage: "",
      isErrorHeader: true,
      previousView:
        this.props.location.state === undefined
          ? ""
          : this.props.location.state.lastView,
      isFreeTrial: false,
      isRedirect: true,
      landOn:
        this.props.location.state !== undefined &&
        this.props.location.state.landOn !== undefined
          ? this.props.location.state.landOn
          : null,
      listTb:
        this.props.location.state.listTb !== undefined
          ? this.props.location.state.listTb
          : "1",
    };
  }

  componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Analysis Summary",
    });
    this.props.registerListners(true);
    const basicVmId = this.state.analysisStatus.basicvmid;
    const analysiSeqNum = this.state.analysisStatus.analysisseqnum;
    this.setState({ modalLoader: true });
    var url = `${Constdata.ASP_SUM_OVERVIEW_URL}/${analysiSeqNum}/current`;
    if (this.state.entityType === "db") {
      url = `${Constdata.DB_SUM_OVERVIEW_URL}/${analysiSeqNum}/${basicVmId}/current`;
    }
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          window.scrollTo(0, 0);
          this.setState({
            loggedInUser: responseJson.orgInfo.loggedInUser,
            resultData: responseJson.data,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
          });
          this.setState((prevState) => ({
            analysisStatus: {
              ...prevState.analysisStatus,
              status_change_datetime: responseJson.data.status_change_datetime,
            },
          }));
          var aggrIcondata = [];
          var aggrcpu = [];
          var aggrnetwork = [];
          var aggrdisks = [];
          var aggrmemory = [];
          var balIcondata = [];
          var balcpu = [];
          var balnetwork = [];
          var balmemory = [];
          var baldisks = [];
          var healthyIcondata = [];
          var healthycpu = [];
          var healthynetwork = [];
          var healthymemory = [];
          var healthydisks = [];
          responseJson.data.aggressive.vm.map((key) => {
            aggrcpu.push({
              costColor: key.current_vcpu_cost,
              healthColor: key.current_vcpu_health,
              icon: "cpu",
              resize: key.vcpuResizeImage,
              status: key.recommendedVMStatus,
            });
            aggrmemory.push({
              costColor: key.current_memory_cost,
              healthColor: key.current_memory_health,
              icon: "memory",
              resize: key.memoryResizeImage,
              status: key.recommendedVMStatus,
            });
            aggrnetwork.push({
              costColor: key.current_network_cost,
              healthColor: key.current_network_health,
              icon: "network",
              resize: key.networkResizeImage,
              status: key.recommendedVMStatus,
            });
          });

          responseJson.data.aggressive.osdisk.map((key) => {
            aggrdisks.push({
              costColor: key.current_os_disk_cost,
              healthColor: key.current_os_disk_health,
              icon: "osdisk",
              resize: key.osDiskResizeImage,
              status: key.recommendedOSDiskStatus,
            });
          });
          if (this.state.entityType !== "db") {
            Object.keys(responseJson.data.aggressive.dataDisk).map((key) => {
              return responseJson.data.aggressive.dataDisk[key].map((value) => {
                aggrdisks.push({
                  costColor: value.current_data_disk_cost,
                  healthColor: value.current_data_disk_health,
                  icon: "datadisk",
                  resize: value.diskResizeImage,
                  status: value.recommendedDataDiskStatus,
                });
              });
            });
          }
          aggrIcondata.push({
            cpu: aggrcpu,
            memory: aggrmemory,
            network: aggrnetwork,
            disks: aggrdisks,
          });
          console.log(aggrIcondata);
          this.setState({ aggIconData: aggrIcondata });
          responseJson.data.balanced.vm.map((key) => {
            balcpu.push({
              costColor: key.current_vcpu_cost,
              healthColor: key.current_vcpu_health,
              icon: "cpu",
              resize: key.vcpuResizeImage,
              status: key.recommendedVMStatus,
            });
            balmemory.push({
              costColor: key.current_memory_cost,
              healthColor: key.current_memory_health,
              icon: "memory",
              resize: key.memoryResizeImage,
              status: key.recommendedVMStatus,
            });
            balnetwork.push({
              costColor: key.current_network_cost,
              healthColor: key.current_network_health,
              icon: "network",
              resize: key.networkResizeImage,
              status: key.recommendedVMStatus,
            });
          });

          responseJson.data.balanced.osdisk.map((key) => {
            baldisks.push({
              costColor: key.current_os_disk_cost,
              healthColor: key.current_os_disk_health,
              icon: "osdisk",
              resize: key.osDiskResizeImage,
              status: key.recommendedOSDiskStatus,
            });
          });
          if (this.state.entityType !== "db") {
            Object.keys(responseJson.data.balanced.dataDisk).map((key) => {
              return responseJson.data.balanced.dataDisk[key].map((value) => {
                baldisks.push({
                  costColor: value.current_data_disk_cost,
                  healthColor: value.current_data_disk_health,
                  icon: "datadisk",
                  resize: value.diskResizeImage,
                  status: value.recommendedDataDiskStatus,
                });
              });
            });
          }
          balIcondata.push({
            cpu: balcpu,
            memory: balmemory,
            network: balnetwork,
            disks: baldisks,
          });

          this.setState({ balIconData: balIcondata });
          console.log("balIconData");
          console.log(this.state.balIconData);
          responseJson.data.conservative.vm.map((key) => {
            healthycpu.push({
              costColor: key.current_vcpu_cost,
              healthColor: key.current_vcpu_health,
              icon: "cpu",
              resize: key.vcpuResizeImage,
              status: key.recommendedVMStatus,
            });
            healthymemory.push({
              costColor: key.current_memory_cost,
              healthColor: key.current_memory_health,
              icon: "memory",
              resize: key.memoryResizeImage,
              status: key.recommendedVMStatus,
            });
            healthynetwork.push({
              costColor: key.current_network_cost,
              healthColor: key.current_network_health,
              icon: "network",
              resize: key.networkResizeImage,
              status: key.recommendedVMStatus,
            });
          });

          responseJson.data.conservative.osdisk.map((key) => {
            healthydisks.push({
              costColor: key.current_os_disk_cost,
              healthColor: key.current_os_disk_health,
              icon: "osdisk",
              resize: key.osDiskResizeImage,
              status: key.recommendedOSDiskStatus,
            });
          });
          if (this.state.entityType !== "db") {
            Object.keys(responseJson.data.conservative.dataDisk).map((key) => {
              return responseJson.data.conservative.dataDisk[key].map(
                (value) => {
                  healthydisks.push({
                    costColor: value.current_data_disk_cost,
                    healthColor: value.current_data_disk_health,
                    icon: "datadisk",
                    resize: value.diskResizeImage,
                    status: value.recommendedDataDiskStatus,
                  });
                }
              );
            });
          }
          healthyIcondata.push({
            cpu: healthycpu,
            memory: healthymemory,
            network: healthynetwork,
            disks: healthydisks,
          });

          this.setState({ healthyIconData: healthyIcondata });
          this.setState({ modalLoader: false });
          var ele = null;
          if (this.state.landOn !== null) {
            ele = document.getElementById(this.state.landOn);
          }
          if (ele !== null) {
            var offset = ele.offsetTop;
            let adj = this.state.landOn === "analysis" ? 66 : 218;

            window.scrollTo(0, offset - adj);
          } else {
            window.scrollTo(0, 0);
          }
          var pgAc = document.getElementById("pageRef");
          if (pgAc !== undefined && pgAc !== null) {
            var header = `<div id="dashHeight">
                            <div style="display: flex; text-align: center; font-size: 22px; color:#610058;">Rightsizing Summary of ${
                              this.state.analysisStatus.vmname
                            }</div>
                            <div style="text-align: center; font-size: 15px; color: #610058;">${getStringDate(
                              this.state.currentDateTime
                            )} </div>
                        </div>`;
            document.getElementById("pageRef").innerHTML = header;
            pgAc.disabled = true;
          }
        } else if (responseJson.success === false) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: false,
            isRedirect: false,
          });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
        console.error(error);
      });
  }
  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.processLogut();
      this.props.history.push("/");
    }
  };
  showOverview = async (atTb, atCl) => {
    await this.setState({ atb: atTb, acol: atCl });
    this.props.history.push({
      pathname: "/overview",
      state: {
        data: this.state.resultData,
        analysisStatus: this.state.analysisStatus,
        viewResultData: this.state.viewResultData,
        aggrIconData: this.state.aggIconData,
        balIconData: this.state.balIconData,
        healthyIconData: this.state.healthyIconData,
        actTb: this.state.atb,
        actCl: this.state.acol,
        aggBenefitData: this.state.aggBenefitData,
        balBenefitData: this.state.balBenefitData,
        healthyBenefitData: this.state.healthyBenefitData,
        lastView: this.state.previousView,
        Listkey: this.props.location.state.key,
        isFreeTrial: this.state.isFreeTrial,
        isAsp: this.state.isAsp,
        entityType: this.state.entityType,
        listTb: this.state.listTb,
        currentDateTime: this.state.currentDateTime,
      },
    });
  };
  handleClose = () => {
    this.props.history.push({
      pathname: this.state.previousView,
      state: {
        data: this.state.viewResultData,
        UserAccount: this.props.location.state.key,
        isAsp: this.state.isAsp,
        entityType: this.state.entityType,
        listTb: this.state.listTb,
      },
    });
  };

  handlePDF = async () => {
    this.setState({ modalLoader: true });
    const basicVmId = this.state.analysisStatus.basicvmid;
    const analysiSeqNum = this.state.analysisStatus.analysisseqnum;
    let filename = "";
    const timezone = jstz.determine();
    const userGtmId = timezone.name();
    const lst = [
      {
        basicVMId: basicVmId,
        analysisSeqNum: analysiSeqNum,
        accountName: this.state.analysisStatus.accountName,
      },
    ];
    const payload = {
      isSummaryPage: false,
      isIndividualSummaryPage: false,
      recList: lst,
      duration: "current",
      userGtmId: userGtmId,
    };
    var url = this.state.isAsp
      ? Constdata.GETPDF_ASP_URL
      : Constdata.GETPDF_DB_URL;
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).catch((error) => {
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    });
    if (response.status !== 500) {
      if (response.headers.get("Content-Disposition") === null) {
        let js = await response.json();
        if (js.success === false) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: js.message,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      } else {
        filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        let blob = await response.blob();
        if (blob !== undefined && blob !== null) {
          let url = window.URL.createObjectURL(blob);
          let a = document.getElementById("pdfid");
          a.href = url;
          a.download = filename;
          a.click();
          this.setState({ modalLoader: false, modalPDF: false });
        } else {
          this.setState({ modalLoader: false, modalPDF: false });
        }
      }
    } else {
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    }
  };

  render() {
    const { analysisStatus } = this.state;
    let formatted_date = getStringDate(analysisStatus.status_change_datetime);
    let forValidation =
      this.state.resultData !== undefined &&
      this.state.resultData.validationData !== null &&
      this.state.resultData.validationData !== undefined;
    let bvmStatus = this.state.resultData.bvmState;
    let bvmStatusResChanged = this.state.resultData.bvmState;
    if (
      forValidation === true &&
      (bvmStatus === "VALIDATED" ||
        bvmStatus === "CUSTOM-SIZED" ||
        bvmStatus === "OPTIMALLY SIZED" ||
        bvmStatus === "OPTIMIZING")
    ) {
      bvmStatus = "VALIDATED";
    }
    let isResizingDone =
      (this.state.resultData.aggressive !== undefined &&
        this.state.resultData.aggressive.isRecommendationSelected === true) ||
      (this.state.resultData.balanced !== undefined &&
        this.state.resultData.balanced.isRecommendationSelected) === true ||
      (this.state.resultData.conservative !== undefined &&
        this.state.resultData.conservative.isRecommendationSelected === true);

    let aggValidatedResized =
      forValidation &&
      this.state.resultData !== undefined &&
      this.state.resultData.validationData.perfType === "Aggressive" &&
      this.state.resultData.aggressive.isRecommendationSelected === true;
    let balValidatedResized =
      forValidation &&
      this.state.resultData !== undefined &&
      this.state.resultData.validationData.perfType === "Balanced" &&
      this.state.resultData.balanced.isRecommendationSelected === true;
    let conValidatedResized =
      forValidation &&
      this.state.resultData !== undefined &&
      this.state.resultData.validationData.perfType === "Conservative" &&
      this.state.resultData.conservative.isRecommendationSelected === true;
    let resizedObjective =
      this.state.resultData.aggressive !== undefined &&
      this.state.resultData.aggressive.isRecommendationSelected === true
        ? "Economical "
        : this.state.resultData.balanced !== undefined &&
          this.state.resultData.balanced.isRecommendationSelected === true
        ? "Balanced "
        : this.state.resultData.conservative !== undefined &&
          this.state.resultData.conservative.isRecommendationSelected === true
        ? "Enhanced "
        : " ";
    let resizingHeading = "Resizing of Recommendation";
    if (
      aggValidatedResized === true ||
      balValidatedResized === true ||
      conValidatedResized === true
    ) {
      resizingHeading = "Resizing of Validated Recommendation";
    }
    let br = <br />;
    return (
      <div>
        <Header
          processLogut={this.processLogout}
          registerListners={this.props.registerListners}
        />
        <div
          className="container-verf container-verf-padding"
          role="main"
          style={{ marginTop: "50px" }}
        >
          <Row>
            {this.state.resultData.isResourceChanged !== undefined ? (
              <div
                className="InfoDiv"
                style={{
                  backgroundColor: "#ffffff",
                  marginBottom: "5px",
                  borderRadius: "7px",
                }}
              >
                {this.state.resultData.isResourceChanged === true ? (
                  <div style={{ color: "#e32227", width: "80%" }}>
                    {" "}
                    {bvmStatusResChanged === "OPTIMIZING" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.entityType === "db"
                              ? message.DBResourceCHangeFlagInfoTextSummaryOptimizing +
                                "<br/>" +
                                this.state.resultData.resourceChangeDetails
                              : message.ASPResourceCHangeFlagInfoTextSummaryOptimizing +
                                "<br/>" +
                                this.state.resultData.resourceChangeDetails,
                        }}
                      />
                    ) : bvmStatusResChanged === "OPTIMALLY SIZED" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.entityType === "db"
                              ? message.DBResourceCHangeFlagInfoTextSummaryOptimized +
                                "<br/>" +
                                this.state.resultData.resourceChangeDetails
                              : message.ASPResourceCHangeFlagInfoTextSummaryOptimized +
                                "<br/>" +
                                this.state.resultData.resourceChangeDetails,
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.entityType === "db"
                              ? message.DBResourceChngedFlagInfoTextSummaryAnalyzed +
                                "<br/>" +
                                this.state.resultData.resourceChangeDetails
                              : message.ASPResourceChngedFlagInfoTextSummaryAnalyzed +
                                "<br/>" +
                                this.state.resultData.resourceChangeDetails,
                        }}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
          </Row>
          <Row>
            {this.state.resultData.priceChangeMsg !== null ? (
              <div
                className="InfoDiv"
                style={{
                  backgroundColor: "#ffffff",
                  marginBottom: "5px",
                  borderRadius: "7px",
                }}
              >
                <div style={{ color: "#e32227", width: "80%" }}>
                  <div>{this.state.resultData.priceChangeMsg}</div>
                </div>
              </div>
            ) : null}
          </Row>
          <Row
            className="pageBgHeader"
            style={{
              alignItems: "center",
              height: "unset",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Col sm="12" style={{ display: "flex", alignItems: "center" }}>
              <div id="spaceFiller" style={{ width: "10%" }}></div>
              <div
                style={{
                  width: "80%",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {this.state.resultData.vminfo}
                <div  style={{fontSize: '18px'}}>
                  {this.state.resultData &&
                  this.state.resultData.analysisDatetimestamp
                    ? `Analyzed: ${getStringDate(
                        this.state.resultData.analysisDatetimestamp
                      )}`
                    : null}

                  {this.state.resultData &&
                  (this.state.resultData.bvmState === "ANALYZED" ||
                    this.state.resultData.bvmState === "VERIFIED" ||
                    this.state.resultData.bvmState === "OPTIMIZED")
                    ? this.state.resultData.metricDataDurationUsed !== null
                      ? `, ${this.state.resultData.metricDataDurationUsed}`
                      : null
                    : null}
                </div>
              </div>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonGroup>
                  <div
                    outline
                    onClick={() => {
                      this.showOverview("1", "");
                    }}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                  >
                    <img
                      style={{ margin: "5px" }}
                      src={require("../../images/Show_derails1.svg")}
                      title="Show Details"
                    />
                  </div>
                  <div
                    outline
                    onClick={this.handlePDF}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                  >
                    <img
                      style={{ margin: "5px" }}
                      src={require("../../images/PDF_Icon1.svg")}
                      title="Save as PDF Report"
                    />
                  </div>
                  <div
                    outline
                    onClick={this.handleClose}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={require("../../images/Close_Icon1.svg")}
                      title="Close"
                    />
                  </div>
                </ButtonGroup>
              </div>
            </Col>
          </Row>
          <div class="sum-header" id="analysis" style={{ marginTop: "0px" }}>
            <div class="mod-tab">
              <b>
                <h4>
                  Economical
                  <label style={{ fontWeight: "300", fontSize: "16px" }}>
                    (&eta;90)
                  </label>
                </h4>
              </b>
              <span className="objectiveTagLine">
                <i>Cost Reducing</i>
              </span>
            </div>
            <div class="mod-tab" id="modTwo">
              <b>
                <h4>
                  Balanced
                  <label style={{ fontWeight: "300", fontSize: "16px" }}>
                    (&eta;95)
                  </label>
                </h4>
              </b>
              <span className="objectiveTagLine">
                <i>Cost Reducing and Performance Improving</i>
              </span>
            </div>
            <div class="mod-tab">
              <b>
                <h4>
                  Enhanced
                  <label style={{ fontWeight: "300", fontSize: "16px" }}>
                    (&eta;100)
                  </label>
                </h4>
              </b>
              <span className="objectiveTagLine">
                <i>Performance Improving</i>
              </span>
            </div>
          </div>
          <div class="Sub-legend">
            <Row>
              <Col id="healthcolor">
                <label>
                  <b>Legend</b>
                </label>
                <div className="legendLable">
                  <div className="legendclass Good">Good</div>
                  <div className="legendclass Fair">Fair</div>
                  <div className="legendclass Poor">Poor</div>
                </div>
              </Col>
            </Row>
          </div>
          <div class="Sub-Heading pageBgWhite">Optimization Analysis</div>
          <div class="sum-cont">
            <div class="sum-item">
              <div class="sum-inner-item">
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("1", "1");
                    }}
                  >
                    Show Details
                  </a>
                </div>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.aggIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.aggIconData[0].cpu}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType === "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.aggIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.aggIconData[0].disks}
                          isResize={false}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
                <Row>
                  <div className="maindiv-summary">
                    {this.state.aggIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.aggIconData[0].memory}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.aggIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.aggIconData[0].network}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType !== "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.aggIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.aggIconData[0].disks}
                          isResize={false}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
              </div>
            </div>
            <div class="sum-item" id="modTwo">
              <div class="sum-inner-item">
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("2", "7");
                    }}
                  >
                    Show Details
                  </a>
                </div>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.balIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.balIconData[0].cpu}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType === "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.balIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.balIconData[0].disks}
                          isResize={false}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
                <Row>
                  <div className="maindiv-summary">
                    {this.state.balIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.balIconData[0].memory}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.balIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.balIconData[0].network}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType !== "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.balIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.balIconData[0].disks}
                          isResize={false}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
              </div>
            </div>
            <div class="sum-item">
              <div class="sum-inner-item">
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("3", "13");
                    }}
                  >
                    Show Details
                  </a>
                </div>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.healthyIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.healthyIconData[0].cpu}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType === "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.healthyIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.healthyIconData[0].disks}
                          isResize={false}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
                <Row>
                  <div className="maindiv-summary">
                    {this.state.healthyIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.healthyIconData[0].memory}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.healthyIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.healthyIconData[0].network}
                        isResize={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType !== "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.healthyIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.healthyIconData[0].disks}
                          isResize={false}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
              </div>
            </div>
          </div>
          <div class="Sub-Heading pageBgWhite">
            Optimization Recommendations
          </div>
          <div class="sum-cont">
            <div class="sum-item">
              <div class="sum-inner-item">
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("1", "2");
                    }}
                  >
                    Show Details
                  </a>
                </div>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.aggIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.aggIconData[0].cpu}
                        isResize={true}
                        isLine={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType === "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.aggIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.aggIconData[0].disks}
                          isResize={true}
                          isLine={false}
                          showInfo={false}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
                <Row>
                  <div className="maindiv-summary">
                    {this.state.aggIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.aggIconData[0].memory}
                        isResize={true}
                        isLine={false}
                        showInfo={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.aggIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.aggIconData[0].network}
                        isResize={true}
                        isLine={false}
                        showInfo={false}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType !== "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.aggIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.aggIconData[0].disks}
                          isResize={true}
                          isLine={false}
                          showInfo={false}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
              </div>
            </div>
            <div class="sum-item" id="modTwo">
              <div class="sum-inner-item">
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("2", "8");
                    }}
                  >
                    Show Details
                  </a>
                </div>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.balIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.balIconData[0].cpu}
                        isResize={true}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType === "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.balIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.balIconData[0].disks}
                          isResize={true}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
                <Row>
                  <div className="maindiv-summary">
                    {this.state.balIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.balIconData[0].memory}
                        isResize={true}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.balIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.balIconData[0].network}
                        isResize={true}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType !== "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.balIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.balIconData[0].disks}
                          isResize={true}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
              </div>
            </div>
            <div class="sum-item">
              <div class="sum-inner-item">
                {" "}
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("3", "14");
                    }}
                  >
                    Show Details
                  </a>
                </div>{" "}
                <Row>
                  <div className="maindiv-summary">
                    {this.state.healthyIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.healthyIconData[0].cpu}
                        isResize={true}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType === "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.healthyIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.healthyIconData[0].disks}
                          isResize={true}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
                <Row>
                  <div className="maindiv-summary">
                    {this.state.healthyIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.healthyIconData[0].memory}
                        isResize={true}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                <Row>
                  <div className="maindiv-summary">
                    {this.state.healthyIconData[0] !== undefined ? (
                      <ColorComponent
                        data={this.state.healthyIconData[0].network}
                        isResize={true}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                        showCostPerfMarker={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                {this.state.entityType !== "db" ? (
                  <Row>
                    <div className="maindiv-summary">
                      {this.state.healthyIconData[0] !== undefined ? (
                        <ColorComponent
                          data={this.state.healthyIconData[0].disks}
                          isResize={true}
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                          showCostPerfMarker={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : null}
              </div>
            </div>
          </div>
          <div class="Sub-Heading pageBgWhite" id="analysisSummary">
            Expected Benefits & Drawbacks of Recommendations
          </div>
          <div class="sum-cont">
            <div class="sum-item">
              <div class="sum-inner-item">
                {" "}
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("1", "3");
                    }}
                  >
                    Show Details
                  </a>
                </div>
                {this.state.resultData.aggressive !== undefined &&
                this.state.resultData.aggressive.summary.costSummaryText !==
                  undefined &&
                this.state.resultData.aggressive.summary.costSummaryText !==
                  null ? (
                  <Row>
                    <div className="maindiv-text-summary maindiv-text-summary-grad">
                      {this.state.resultData.aggressive !== undefined ? (
                        <ResingBenefitTextComponent
                          data={
                            this.state.resultData.aggressive.summary
                              .costSummaryDescription
                          }
                          title={
                            this.state.resultData.aggressive.summary
                              .costSummaryText
                          }
                          isAsp={this.state.isAsp}
                          entityType={this.state.entityType}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                ) : (
                  ""
                )}
                {this.state.resultData.aggressive !== undefined &&
                this.state.resultData.aggressive.summary.perfSummaryText !==
                  undefined &&
                this.state.resultData.aggressive.summary.perfSummaryText !==
                  null ? (
                  <Row>
                    <div className="maindiv-text-summary maindiv-text-summary-grad">
                      <ResingBenefitTextComponent
                        data={
                          this.state.resultData.aggressive.summary
                            .perfSummaryDescription
                        }
                        title={
                          this.state.resultData.aggressive.summary
                            .perfSummaryText
                        }
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                      />
                    </div>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div class="sum-item">
              <div class="sum-inner-item">
                {" "}
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("2", "9");
                    }}
                  >
                    Show Details
                  </a>
                </div>
                {this.state.resultData.balanced !== undefined &&
                this.state.resultData.balanced.summary.costSummaryText !==
                  undefined &&
                this.state.resultData.balanced.summary.costSummaryText !==
                  null ? (
                  <Row>
                    <div className="maindiv-text-summary maindiv-text-summary-grad">
                      <ResingBenefitTextComponent
                        title={
                          this.state.resultData.balanced.summary.costSummaryText
                        }
                        data={
                          this.state.resultData.balanced.summary
                            .costSummaryDescription
                        }
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                      />
                    </div>
                  </Row>
                ) : (
                  ""
                )}
                {this.state.resultData.balanced !== undefined &&
                this.state.resultData.balanced.summary.perfSummaryText !==
                  undefined &&
                this.state.resultData.balanced.summary.perfSummaryText !==
                  null ? (
                  <Row>
                    <div className="maindiv-text-summary maindiv-text-summary-grad">
                      <ResingBenefitTextComponent
                        data={
                          this.state.resultData.balanced.summary
                            .perfSummaryDescription
                        }
                        title={
                          this.state.resultData.balanced.summary.perfSummaryText
                        }
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                      />
                    </div>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div class="sum-item">
              <div class="sum-inner-item">
                {" "}
                <div id="ShowDetails">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showOverview("3", "15");
                    }}
                  >
                    Show Details
                  </a>
                </div>
                {this.state.resultData.conservative !== undefined &&
                this.state.resultData.conservative.summary.costSummaryText !==
                  undefined &&
                this.state.resultData.conservative.summary.costSummaryText !==
                  null ? (
                  <Row>
                    <div className="maindiv-text-summary maindiv-text-summary-grad">
                      <ResingBenefitTextComponent
                        title={
                          this.state.resultData.conservative.summary
                            .costSummaryText
                        }
                        data={
                          this.state.resultData.conservative.summary
                            .costSummaryDescription
                        }
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                      />
                    </div>
                  </Row>
                ) : (
                  ""
                )}
                {this.state.resultData.conservative !== undefined &&
                this.state.resultData.conservative.summary.perfSummaryText !==
                  undefined &&
                this.state.resultData.conservative.summary.perfSummaryText !==
                  null ? (
                  <Row>
                    <div className="maindiv-text-summary maindiv-text-summary-grad">
                      <ResingBenefitTextComponent
                        data={
                          this.state.resultData.conservative.summary
                            .perfSummaryDescription
                        }
                        title={
                          this.state.resultData.conservative.summary
                            .perfSummaryText
                        }
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                      />
                    </div>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {this.state.resultData.aggressive !== undefined ? (
            this.state.resultData.aggressive.isRecommendationSelected ===
            true ? (
              <div class="Sub-Heading pageBgWhite" id="resize">
                {resizingHeading}
              </div>
            ) : this.state.resultData.balanced !== undefined ? (
              this.state.resultData.balanced.isRecommendationSelected ===
              true ? (
                <div class="Sub-Heading pageBgWhite" id="resize">
                  {resizingHeading}
                </div>
              ) : this.state.resultData.conservative !== undefined ? (
                this.state.resultData.conservative.isRecommendationSelected ===
                true ? (
                  <div class="Sub-Heading pageBgWhite" id="resize">
                    {resizingHeading}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )
          ) : null}
          <div class="sum-cont" style={{ borderRadius: "0px 0px 10px 10px" }}>
            <div
              class="sum-item"
              id={
                this.state.resultData.aggressive !== undefined
                  ? this.state.resultData.aggressive
                      .isRecommendationSelected === true
                    ? "modTwo"
                    : ""
                  : ""
              }
            >
              {this.state.resultData.aggressive !== undefined ? (
                this.state.resultData.aggressive.isRecommendationSelected ===
                true ? (
                  <div class="sum-inner-item">
                    {" "}
                    <div id="ShowDetails">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.showOverview("1", "6");
                        }}
                      >
                        Show Details
                      </a>
                    </div>
                    <Row>
                      <SummaryStatus
                        data={this.state.resultData.aggressive}
                        bvmState={this.state.resultData.bvmState}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                      />
                    </Row>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>

            <div
              class="sum-item"
              id={
                this.state.resultData.balanced !== undefined
                  ? this.state.resultData.balanced.isRecommendationSelected ===
                    true
                    ? "modTwo"
                    : ""
                  : ""
              }
            >
              {this.state.resultData.balanced !== undefined ? (
                this.state.resultData.balanced.isRecommendationSelected ===
                true ? (
                  <div class="sum-inner-item">
                    {" "}
                    <div id="ShowDetails">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.showOverview("2", "12");
                        }}
                      >
                        Show Details
                      </a>
                    </div>
                    <Row>
                      <SummaryStatus
                        data={this.state.resultData.balanced}
                        bvmState={this.state.resultData.bvmState}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                      />
                    </Row>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>

            <div
              class="sum-item"
              id={
                this.state.resultData.conservative !== undefined
                  ? this.state.resultData.conservative
                      .isRecommendationSelected === true
                    ? "modTwo"
                    : ""
                  : ""
              }
            >
              {this.state.resultData.conservative !== undefined ? (
                this.state.resultData.conservative.isRecommendationSelected ===
                true ? (
                  <div class="sum-inner-item">
                    <div id="ShowDetails">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.showOverview("3", "18");
                        }}
                      >
                        Show Details
                      </a>
                    </div>

                    <Row>
                      <SummaryStatus
                        data={this.state.resultData.conservative}
                        bvmState={this.state.resultData.bvmState}
                        isAsp={this.state.isAsp}
                        entityType={this.state.entityType}
                      />
                    </Row>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
          {this.state.errorPopup === true ? (
            <SerraError
              Errordata={this.state.errormessage}
              ErrorPopup={this.state.errorPopup}
              setErrorMethod={this.setErrorPopupState}
              isError={this.state.isErrorHeader}
            ></SerraError>
          ) : (
            ""
          )}
          {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        </div>
        <a id="pdfid" />
      </div>
    );
  }
}
export default AspSummary;
