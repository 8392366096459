import React, { Component } from 'react';
import { Table, Button, Input, Modal, ModalHeader, ModalBody, Form, FormGroup } from 'reactstrap';
import * as Constdata from '../../Constants/ApiConstants';
import SerraLoader from '../CommonComponent/Loader';
import SerraError from '../CommonComponent/Error';
import * as message from '../../Constants/MessageConstant';
import Hashmap from 'hashmap';
import BootstrapTable from 'react-bootstrap-table-next';
import jstz from 'jstz';

class ManageValidations extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.confirmValidation = this.confirmValidation.bind(this);
        this.setErrorPopupState= this.setErrorPopupState.bind(this);
        this.renderRecommendationCell = this.renderRecommendationCell.bind(this);
        this.thClicked = this.thClicked.bind(this);
        this.renderExpandedRow = this.renderExpandedRow.bind(this);
        this.renderVmStatus = this.renderVmStatus.bind(this);
        this.getStringDate = this.getStringDate.bind(this);
        this.toggleProceedConfirm = this.toggleProceedConfirm.bind(this);
        this.state = {
            value : this.props.data,
            objectiveBvmMap : new Hashmap(),
            confirmStart:false,            
            modalLoader:false,
            errorPopup:false,
            expandKeyValue:new Hashmap(),
            exapanding:[],
            errormessage :"",
            toggleProceed:false
        };
        this.columns = [{
            dataField: 'basicVMId',
            text: 'ID',
            hidden: true
        },
        {
            dataField: 'name',
            text: 'VM Name',
            headerStyle: {
                width: '180px',
                zIndex:'100',
                wordBreak:"break-word"
            },
            style:{
                pointerEvents:"none",
                wordBreak:"break-word"
            }
        },
        {
            dataField: 'accountName',
            text: 'VM Metric Data Source',
            headerStyle: {
                width: '180px',
                wordBreak:"break-word",
                zIndex:'100'
            },
            style:{
                pointerEvents:"none",
                wordBreak:"break-word"
            }
        },
        {
            dataField: 'selection',
            isDummyField: true,
            text: 'Benefits & Drawbacks',
            formatter: this.renderRecommendationCell,
            attrs: { name: 'dummyid' },
            headerStyle:{
                zIndex:'100'
            }
        },
        {
            dataField: 'bvmState',
            text: 'Status',
            formatter:this.renderVmStatus,
            headerStyle: {
                padding: '0px !important',
                width: '180px',
                zIndex:'100'
            },
            style:{
                wordBreak:"break-word"
            }
        },
        {
            dataField: 'status_change_datetime',
            text: 'When',
            formatter: this.getStringDate,
            headerStyle: {
                width: '120px',
                zIndex:'100'
            },
            style:{
                pointerEvents:"none",
                wordBreak:"break-word"
            }
        }];
            
        this.expandRow = {
                showExpandColumn: false,
                onlyOneExpanding: false,
                animation:"grow" ,
                renderer: this.renderExpandedRow,
                expanded: this.state.exapanding
        };
    }
    
    toggleProceedConfirm = () => {
        this.setState(prevState => ({
          toggleProceed: !prevState.toggleProceed
        }));
    }
    
    componentDidMount(){
        var dummies = document.getElementsByName("dummyid");
        for(var i=0 ; i<dummies.length; i++){
            dummies[i].setAttribute('style', 'padding:0px !important');
        }
    }
    
    renderVmStatus(cell, Vm) {      
        let obj = "";
        let platform = Vm.metricsPlatform === "Host" ? "(Only Host Metrics Used)" : "";
        if(Vm.validationPerfType !== null && Vm.validationPerfType !== undefined){
            switch(Vm.validationPerfType){
                case "Aggressive":
                    obj = "Economical";
                    break;
                case "Balanced":
                    obj = "Balanced";
                    break;
                case "Conservative":
                    obj = "Enhanced";
                    break;
                default: obj = "";
            }
        }
        return <div>
            <div className="textfieldCentered">{Vm.bvmState}</div>
            <div className="textfieldCentered">{obj}</div>
            {platform !=="" ? <div style={{fontSize:"12px", textAlign:"center"}}>{platform}</div> : null}
            </div>;
    }

    getStringDate = (cell, row) => {
        console.log("me"+row.status_change_datetime);
        let current_datetime = new Date(row.status_change_datetime);
        var hours = current_datetime.getHours();
        var minutes = current_datetime.getMinutes();
        var month = current_datetime.getMonth() + 1;
        var day = current_datetime.getDate();
        var seconds = current_datetime.getSeconds();

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        var tzAbbr = "";
        var dateObject = new Date(),
        dateString = dateObject + "",
        tzAbbr = (
                dateString.match(/\(([^\)]+)\)$/) ||
                dateString.match(/([A-Z]+) [\d]{4}$/)
                );
        if (tzAbbr) {
tzAbbr = tzAbbr[1].match(/[A-Z]/g).join("");
}

        return (
                <div style={{textAlign: "center"}}>
                    {current_datetime.getFullYear() + "-" + month + "-" + day + "  " + hours + ':' + minutes+ ':' + seconds+ ' ' + tzAbbr}
                </div>
                );
    }
    
    thClicked =async (bvmid,obj,e)=>{
        
        //alert(e.target.parentElement.parentElement.parentElement.parentElement.parentElement.tagName);
        //e.target.parentElement.parentElement.parentElement.parentElement.parentElement.click();
        var {expandKeyValue,exapanding} = this.state;
        const index = exapanding.indexOf(bvmid);
        if (index > -1) {
            exapanding.splice(index, 1);
        }
        let isadded = true;
        if(expandKeyValue.has(bvmid)){
            if(expandKeyValue.get(bvmid) === obj){
                expandKeyValue.remove(bvmid);  
                document.getElementById(bvmid+obj).className = "fa fa-angle-down";                
            } else {
                isadded = true;
                expandKeyValue.set(bvmid,obj);
                exapanding.push(bvmid);
                document.getElementById(bvmid+obj).className = "fa fa-angle-up";
            }
        } else {
            isadded = true;
            expandKeyValue.set(bvmid,obj);
            exapanding.push(bvmid);
            document.getElementById(bvmid+obj).className = "fa fa-angle-up";
        }
        if(isadded){
            switch(obj){
                case "aggressive":
                    document.getElementById(bvmid+"balanced").className = "fa fa-angle-down";
                    document.getElementById(bvmid+"conservative").className = "fa fa-angle-down";
                        break;
                case "balanced":
                    document.getElementById(bvmid+"aggressive").className = "fa fa-angle-down";
                    document.getElementById(bvmid+"conservative").className = "fa fa-angle-down";
                        break;
                case "conservative" :
                    document.getElementById(bvmid+"aggressive").className = "fa fa-angle-down";
                    document.getElementById(bvmid+"balanced").className = "fa fa-angle-down";
                        break;
            }
        }
        await this.setState({expandKeyValue : expandKeyValue,  exapanding: exapanding});
    }
    
    renderExpandedRow(Vm){
        if(this.state.expandKeyValue.has(Vm.basicVMId)){
        let obj = this.state.expandKeyValue.get(Vm.basicVMId);
        let title = obj === "aggressive" ? "Economical" : obj ==="balanced" ? "Balanced" : "Enhanced";        
        return(
                <div>                
                <div style={{textAlign:"center"}}><h4>{title}</h4></div>
                <Table style={{textAlign:"left"}} className="tableview " id="tableSizing" >
                    <thead style={{textAlign:"left"}} >
                        <tr>
                            <td style={{width:"40px", borderTop:"none", borderLeft:"none"}}></td>
                            <th>Original Spec & Limits</th>
                            <th>Recommended Spec & Limits</th>
                            <th style={{width:"40px"}}>Cost Changes</th>
                            <th style={{width:"70px"}}>Performance Changes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th><span className='labelColor'>CPU</span></th>
                            <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Num vCPU {Vm[obj].vmDTO.current_num_vcpu}, CPU Speed{Vm[obj].vmDTO.current_cpuspeed})</div></td>
                            <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Num vCPU {Vm[obj].vmDTO.rec_num_vcpu}, CPU Speed {Vm[obj].vmDTO.rec_cpuspeed})</div></td>
                            <td rowspan="3">{Vm[obj].vmDTO.vm_cost_changes}</td>
                            <td>{Vm[obj].vmDTO.vm_cpu_performance_changes}</td>
                        </tr>
                        <tr>
                            <th><span className='labelColor'>Memory</span></th>
                            <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.current_memory})</div></td>
                            <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Size {Vm[obj].vmDTO.rec_memory})</div></td>
                            <td>{Vm[obj].vmDTO.vm_memory_performance_changes}</td>
                        </tr>
                        <tr>
                            <th><span className='labelColor'>Network</span></th>
                            <td><div>{Vm[obj].vmDTO.current_vmsize_name}</div><div>(Bandwidth {Vm[obj].vmDTO.current_networkbandwidth})</div></td>
                            <td><div>{Vm[obj].vmDTO.rec_vmsize_name}</div><div>(Bandwidth {Vm[obj].vmDTO.rec_networkbandwidth})</div></td>
                            <td>{Vm[obj].vmDTO.vm_network_performance_changes}</td>
                        </tr>
                        <tr>
                            <th><span className='labelColor'>OS Disk</span></th>
                            <td><div>{Vm[obj].osDisk.current_os_disksize_name}({Vm[obj].osDisk.current_osdisk_type})</div><div>(Throuput {Vm[obj].osDisk.current_osdisk_bandwidth}, IOPS {Vm[obj].osDisk.current_osdisk_iops}, Size {Vm[obj].osDisk.current_os_diskSizeGB})</div></td>
                            <td><div>{Vm[obj].osDisk.rec_os_disksize_name}({Vm[obj].osDisk.rec_osdisk_type})</div><div>(Throuput {Vm[obj].osDisk.rec_osdisk_bandwidth}, IOPS {Vm[obj].osDisk.rec_osdisk_iops}, Size {Vm[obj].osDisk.rec_os_diskSizeGB})</div></td>
                            <td>{Vm[obj].osDisk.osdisk_cost_changes}</td>
                            <td>{Vm[obj].osDisk.osdisk_performance_changes}</td>
                        </tr>
                        {Object.keys(Vm[obj].dataDiskMap).map((key) => {
                            return (<tr>
                                <th><span className='labelColor'>Disk {key}</span></th>
                                <td><div>{Vm[obj].dataDiskMap[key].current_data_disksize_name}({Vm[obj].dataDiskMap[key].current_datadisk_type})</div><div>(Throuput {Vm[obj].dataDiskMap[key].current_datadisk_bandwidth}, IOPS {Vm[obj].dataDiskMap[key].current_datadisk_iops}, Size {Vm[obj].dataDiskMap[key].current_datadisk_sizeGB})</div></td>
                                <td><div>{Vm[obj].dataDiskMap[key].rec_data_disksize_name}({Vm[obj].dataDiskMap[key].rec_datadisk_type})</div><div>(Throuput {Vm[obj].dataDiskMap[key].rec_datadisk_bandwidth}, IOPS {Vm[obj].dataDiskMap[key].rec_datadisk_iops}, Size {Vm[obj].dataDiskMap[key].rec_datadisk_sizeGB})</div></td>
                                <td>{Vm[obj].dataDiskMap[key].cost_changes}</td>
                                <td>{Vm[obj].dataDiskMap[key].performance_changes}</td>
                            </tr>);
                        })}
                    </tbody>
                    </Table></div>);
        }else{
            var {expandKeyValue,exapanding} = this.state;
            const index = exapanding.indexOf(Vm.basicVMId);
            if (index > -1) {
                exapanding.splice(index, 1);
            }
            expandKeyValue.remove(Vm.basicVMId);  
            this.setState({expandKeyValue : expandKeyValue,  exapanding: exapanding});
        }
    }
    
    renderRecommendationCell (cell,Vm){
        return (
            <table style={{width:"100%"}}>
            <thead style={{backgroundColor:"#E5E5E5"}}>
                <tr>
                    <th style={{borderLeft: "none"}}>
                        <div>
                            <Input style={{marginRight:"5px"}} type="radio" id={Vm.basicVMId} name={Vm.name} 
                            onClick={e =>this.onChange(Vm.basicVMId, Vm.vmResultKeyMap.Aggressive, e)} />
                            Economical
                        </div>
                    </th>
                    <th>
                        <div>
                            <Input style={{marginRight:"5px"}} type="radio" id={Vm.basicVMId} name={Vm.name} 
                            onClick={e =>this.onChange(Vm.basicVMId, Vm.vmResultKeyMap.Balanced, e)} />
                            Balanced
                        </div>
                    </th>
                    <th style={{borderRight: "none"}}>
                        <div>
                            <Input style={{marginRight:"5px"}} type="radio" id={Vm.basicVMId} name={Vm.name} 
                            onClick={e =>this.onChange(Vm.basicVMId, Vm.vmResultKeyMap.Conservative, e)} />
                            Enhanced
                        </div>
                    </th>
                </tr> 
            </thead>
            <tbody>
                <tr>
                    <td className="textfield thExpandSelector" onClick={() => {this.thClicked(Vm.basicVMId,"aggressive");}} style={{borderLeft: "none", borderBottom: "none"}}>
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>{Vm.aggressive.summary.costSummaryText}</td>
                            <td style={{padding:"0px !important", border:"none"}} rowspan="2"> <i id={Vm.basicVMId+"aggressive"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td>
                        </tr>
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>{Vm.aggressive.summary.perfSummaryText}</td>
                        </tr>
                    </td>
                    <td className="textfield thExpandSelector" onClick={() => {this.thClicked(Vm.basicVMId,"balanced");}} style={{borderLeft: "none", borderBottom: "none"}}>
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>{Vm.balanced.summary.costSummaryText}</td>
                            <td style={{padding:"0px !important", border:"none"}} rowspan="2"> <i id={Vm.basicVMId+"balanced"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td>
                        </tr>
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>{Vm.balanced.summary.perfSummaryText}</td>
                        </tr>
                    </td>
                    <td className="textfield thExpandSelector" onClick={() => {this.thClicked(Vm.basicVMId,"conservative");}} style={{borderLeft: "none", borderBottom: "none", borderRight: "none"}}>
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>{Vm.conservative.summary.costSummaryText}</td>
                            <td style={{padding:"0px !important", border:"none"}} rowspan="2"> <i id={Vm.basicVMId+"conservative"} class="fa fa-angle-down" style={{fontSize:"26px"}}></i></td>
                        </tr>
                        <tr>
                            <td style={{padding:"0px !important", border:"none"}}>{Vm.conservative.summary.perfSummaryText}</td>
                        </tr>
                    </td>
                </tr>
            </tbody>
            </table>
        );
    }        
    setErrorPopupState = (event) => {
        this.setState({errorPopup: event});
    }
    
    confirmValidation = async() => {
        await this.setState({ modalLoader:true, toggleProceed: false });
        const timezone = jstz.determine();
        const timezoneid = timezone.name();
        var payload = {};
        
        this.state.objectiveBvmMap.forEach(function(value, key) {
            payload[value] = key;
        });
        var reqBody = {
            userGtmId: timezoneid,
            validationData:payload
        };
        fetch(Constdata.START_VALIDATION_URL, {
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(reqBody)
        }).then((response) => response.json())
            .then((responseJson) => {
              this.setState({ modalLoader: false });
              this.processResponse(responseJson);
            })
        .catch((error) => {
            this.setState({ modalLoader: false });
            console.error(error);
        });
        
    }
    processResponse(response) {
        if (response.success === true) {
            this.props.handleFetch();
            this.props.cancelValidations();
        } else if (response.success === false) {
            this.setState({modalLoader: false, errorPopup: true, errormessage: response.message});
        } else if (response.status === 403 || response.status === 401) {
            this.setState({isRedirect: true, modalLoader: false, errorPopup: true, errormessage: message.SessionExpiredText, isErrorHeader: false});
        } else {
            this.setState({modalLoader: false, errorPopup: true, errormessage: message.ServerError, isErrorHeader: false});
        }
    }
    async onChange(basicVmid, objKey, e) {
        var {objectiveBvmMap} = this.state;
        objectiveBvmMap.set(basicVmid,objKey);
        await this.setState({objectiveBvmMap : objectiveBvmMap});        
    }

    render() {
        return (
                <div>
                    <div style={{textAlign:"center"}}>
                        <BootstrapTable id="list_table"
                            bordered={false}
                            bodyClasses="tbodyColor"
                            classes="table-responsive"
                            bootstrap4={true}
                            keyField='basicVMId'
                            data={ this.state.value }
                            columns={ this.columns }                            
                            expandRow={ this.expandRow }
                            wrapperClasses="table-responsive" 
                            noDataIndication={ () => 'No VMs Found' }                            
                    />
                    </div>
                    <div id="bottom_btn" style={{ display: "flex", justifyContent:"center", width:"100%", bottom:"0"}}>
                        <Button id={this.state.objectiveBvmMap.size === 0 ? "PPU_listdisabled" : "PPU-btn"} onClick={this.toggleProceedConfirm} style={{width:"180px", marginRight:"15px"}} disabled={this.state.objectiveBvmMap.size === 0}>Proceed</Button>
                        <Button className="btn-cancel btn" onClick={this.props.cancelValidations}>Cancel</Button>                        
                    </div>
                    {(this.state.modalLoader === true) ? <SerraLoader></SerraLoader> :""}
                    {(this.state.errorPopup === true) ?  <SerraError Errordata={this.state.errormessage} ErrorPopup={this.state.errorPopup} setErrorMethod={this.setErrorPopupState} isError={this.state.isErrorHeader} ></SerraError> :""}
                    <Modal size="lg" isOpen={this.state.toggleProceed} toggle={this.toggleProceedConfirm}>
                        <ModalHeader id="modalheader_list" toggle={this.toggleProceedConfirm} cssModule={{ 'modal-title': 'modalHeader' }}>
                            <div className="modalCompanyName" dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }} />
                            <div className="modalTitle">Validation Confirmation</div>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="box-titel" id="modaldis_list">
                                    <div style={{display:"flex", justifyContent:"center"}}>
                                        <p dangerouslySetInnerHTML={{__html: this.state.objectiveBvmMap.size === 1 ? "Please confirm to perform validation of the selected VM: "+this.state.value[0].name : "Please confirm to perform validation of "+this.state.objectiveBvmMap.size+" selected VMs"}}/>
                                    </div>
                                    <div className="footnotePopup" style={{margin:"20px", fontSize:"16px", textAlign:"left"}}> 
                                        <div dangerouslySetInnerHTML={{ __html: "This can take between 75 and 90 minutes to complete per VM although validations can run concurrently after they are created." }} />
                                        <div dangerouslySetInnerHTML={{ __html: "Note : An email with a report attachment for all VMs validated will be sent." }} />
                                    </div>
                                </div>
                                <Form>
                                    <FormGroup>
                                        <div align="center">
                                            <Button id="confirmbtn_list" onClick={this.confirmValidation}>Yes</Button>
                                            <Button outline id="canclebtn_list" onClick={() => { this.toggleProceedConfirm(); }}>No</Button>
                                        </div>
                                    </FormGroup>
                                </Form>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
                );
    }

}
export default ManageValidations;

